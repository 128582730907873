import { createEvent, createStore, sample } from 'effector';
import { errorHandlerFx } from '../../../model/errorHandler';
import { createEffectJWT } from '../../../model/JWT';
import {
  getOrganizationPlatforms,
  ordGetList,
  platformGetList,
  RGetOrganizationPlatforms,
  ROrdGetList,
  RPlatformGetList,
} from '../../../apiRPC/organization';

export const ordGetListFX = errorHandlerFx(createEffectJWT(ordGetList));
export const $ordList = createStore<ROrdGetList>([]).on(
  ordGetListFX.doneData,
  (_, payload) => payload,
);

export const onOrdGetList = createEvent();

sample({
  clock: onOrdGetList,
  source: $ordList,
  filter: (source) => source.length === 0,
  fn: () => undefined,
  target: ordGetListFX,
});

export const $ordOption = $ordList.map((state) =>
  state?.map((item) => ({
    label: item.title,
    value: item.id,
  })),
);

export function getOrdNameById(id: number, ordList: ROrdGetList): string {
  const findItem = ordList.find((item) => item.id === id);
  return findItem ? findItem.title : id.toString(10);
}

export const platformGetListFX = errorHandlerFx(
  createEffectJWT(platformGetList),
);
export const getOrganizationPlatformsFX = errorHandlerFx(
  createEffectJWT(getOrganizationPlatforms),
);

export const onResetOrganizationPlatforms = createEvent();
export const $organizationPlatforms = createStore<RGetOrganizationPlatforms>([])
  .on(getOrganizationPlatformsFX.doneData, (_, payload) => payload)
  .reset(onResetOrganizationPlatforms);
export const $platformList = createStore<RPlatformGetList['rows']>([]).on(
  platformGetListFX.doneData,
  (_, payload) => payload.rows,
);

export const $organizationPlatformsUids = $organizationPlatforms.map((state) =>
  state.map((item) => item.uuid),
);
export const onPlatformGetList = createEvent();

sample({
  clock: onPlatformGetList,
  source: $platformList,
  filter: (source) => source.length === 0,
  fn: () => undefined,
  target: platformGetListFX,
});

export const $platformOption = $platformList.map((state) =>
  state?.map((item) => ({
    label: item.name,
    value: item.uuid,
  })),
);

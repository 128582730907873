import request from '../../request';

export type RContentSetName = {
  uuid: string;
  name: string;
  size: number;
  ext: string;
  type: 'image' | 'video' | 'link' | 'other';
  color: string;
  width: number;
  height: number;
  scaled: boolean;
  createdAt: number;
  updatedAt: number;
  url: string;
  downloadUrl: string;
  comment: string | null;
  deleted: boolean;
  account?: string;
};
export type PContentSetName = { uuid: string; name: string };
export const contentSetName = request<RContentSetName, PContentSetName>({
  method: 'content.setName',
});

export type PContentDelete = { uuid: string };
export type RContentDelete = boolean;
export const contentDelete = request<RContentDelete, PContentDelete>({
  method: 'content.delete',
});
export type PContentRestore = { uuid: string };
export type RContentRestore = boolean;
export const contentRestore = request<RContentRestore, PContentRestore>({
  method: 'content.restore',
});

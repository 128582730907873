import { InputMaskElement } from 'imask';
import { useFormik } from 'formik';
import { useIMask } from 'react-imask';

type Opts = Parameters<typeof useIMask>['0'];
type Events = Parameters<typeof useIMask>['1'];
const optionsFloatMask: Opts = {
  mask: Number,
  scale: 4,
  // signed: false,
  thousandsSeparator: ' ',
  padFractionalZeros: false,
  normalizeZeros: false,
  min: 0,
  max: 9999999999,
  radix: ',',
  mapToRadix: ['.'],
};

export function getOptsMask<
  Form extends object,
  MaskElement extends InputMaskElement,
>(
  field: string,
  formik: ReturnType<typeof useFormik<Form>>,
  opt?: Opts,
): [Opts, Events] {
  const options = { ...optionsFloatMask, ...opt } as Opts;
  return [
    {
      ...options,
      prepare(
        value: string,
        masked: unknown,
        flags: { input?: boolean; tail?: boolean },
      ) {
        if (value === '0' && !flags?.input) return '';
        return value;
      },
    },
    {
      onComplete: async (value) => {
        await formik.setFieldValue(field, value);
        await formik.validateField(field);
      },
    },
  ];
}

import React, { FC } from 'react';
import { Box, IconButton, Paper, TextField } from '@mui/material';
import { FiTrash } from 'react-icons/fi';
import css from '../../../styles.module.scss';
import { BootstrapTooltip } from '../../../../../../../components/BootstrapTooltip';

type Props = {
  textData?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  textDataError?: string;
  onDelete: () => void;
  disabled?: boolean;
};
export const TextType: FC<Props> = ({
  textData,
  onChange,
  textDataError,
  onDelete,
  disabled,
}) => (
  <Paper
    elevation={0}
    sx={{
      padding: '30px',
    }}
  >
    <Box
      display="grid"
      gridTemplateColumns=" 1fr 40px"
      gap="0 30px"
      alignItems="center"
    >
      <TextField
        label="Текстовые данные"
        variant="standard"
        value={textData}
        name="textData"
        inputProps={{ maxLength: 1000 }}
        autoComplete="none"
        onChange={onChange}
        error={Boolean(textDataError)}
        helperText={textDataError}
        fullWidth
        disabled={disabled}
        multiline
      />
      <BootstrapTooltip title="Удалить" placement="top">
        <IconButton onClick={onDelete} disabled={disabled}>
          <FiTrash color="#D32F2F" size={20} className={css.del_btn} />
        </IconButton>
      </BootstrapTooltip>
    </Box>
  </Paper>
);

import React, { FC } from 'react';
import { Box, Paper, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const Sign: FC = () => (
  <Container component="main" maxWidth="sm">
    <Box pt={16}>
      <Paper elevation={8}>
        <Box padding={1}>
          <Outlet />
        </Box>
      </Paper>
    </Box>
  </Container>
);

import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, Paper, Stack, TextField } from '@mui/material';
import { useUnit } from 'effector-react';
import { FiTrash } from 'react-icons/fi';
import { isErrorProps } from '../../../../../../apiRPC/request';
import {
  $cardUid,
  cardCreativesGroupsUpdateFX,
  deleteGroupWithCreatives,
} from './model';
import { CardCreativeGroupItem } from '../../../../../../apiRPC/card/creatives/groups';
import { BootstrapTooltip } from '../../../../../../components/BootstrapTooltip';

type Form = Pick<CardCreativeGroupItem, 'title'>;
type Props = { title: string; uuid: string };
export const ItemUpdate: FC<Props> = ({ title, uuid }) => {
  const [disabled, setDisable] = useState(false);
  const cardUid = useUnit($cardUid);
  const formik = useFormik<Form>({
    initialValues: { title },
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required('Обязательное поле'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (values.title === title) return;
      setDisable(true);
      try {
        await cardCreativesGroupsUpdateFX({
          uuid,
          fields: { title: values.title },
        });
      } catch (e) {
        console.error(e);

        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      } finally {
        setDisable(false);
      }
    },
  });

  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;

    if (
      !(
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLInputElement
      )
    )
      return;
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <Paper>
      <Stack
        direction="row"
        padding={1}
        spacing={2}
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <TextField
          label="Имя"
          variant="standard"
          name="title"
          inputProps={{ maxLength: 255 }}
          autoComplete="none"
          value={formik.values.title}
          error={Boolean(formik.errors.title)}
          helperText={formik.errors.title}
          onChange={formik.handleChange}
          disabled={disabled}
          size="small"
          fullWidth
          onBlur={(e) => {
            if (!e.relatedTarget) return;
            formik.submitForm();
          }}
          onKeyDown={onPressEnter}
        />

        <BootstrapTooltip title="Удалить" placement="top">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (!cardUid) return;
              setDisable(true);

              deleteGroupWithCreatives({ card: cardUid, uuid }).catch(() => {
                setDisable(false);
              });
            }}
            sx={{ ml: 'auto' }}
            disabled={disabled}
          >
            <FiTrash color="#D32F2F" size={20} />
          </IconButton>
        </BootstrapTooltip>
      </Stack>
    </Paper>
  );
};

import React, { FC, useEffect } from 'react';
import {
  Avatar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  AppBar,
  useTheme,
  Button,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';
import { useUnit } from 'effector-react';
import { useLocation, useRoutes } from 'react-router-dom';
import { FiLogOut, FiUser } from 'react-icons/fi';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { $account, getAccountFx } from '../../model/account';
import { logOut } from '../../model/JWT/logOut';
import { routing } from './routing';
import { NavigateMenu } from './NavigateMenu';
import { themeHeader } from './theme';
import { toggleMenuR } from '../../GRID/components/RightMenu/model';
import { useIsRightMenu } from '../../helper/hooks/useIsrightMenu';
import { ORD } from './modules/ORD';
import { addressesORD } from '../../ORD/addresses';

const PaperProps = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 0,
  width: 10,
  height: 10,
  bgcolor: 'background.paper',
  transform: 'translateY(-50%) translateX(-50%) rotate(45deg)',
  zIndex: 0,
};

const themeH = createTheme(themeHeader);
export const Header: FC = () => {
  const account = useUnit($account);
  const theme = useTheme();
  const Routing = useRoutes(routing);
  const location = useLocation();
  const isRightMenu = useIsRightMenu();

  useEffect(() => {
    getAccountFx({});
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1300,
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'flex-start' }}>
        <NavigateMenu />

        {location.pathname.includes(addressesORD.ORDPath) ? <ORD /> : Routing}
        <Box
          display="flex"
          alignItems="center"
          onClick={handleMenu}
          justifyContent="flex-end"
          sx={{
            cursor: 'pointer',
          }}
        >
          <Box display="flex" justifyContent="center">
            <Avatar
              sx={{
                opacity: 0.5,
              }}
            />
          </Box>
          <Typography
            variant="body2"
            noWrap
            marginLeft={2.5}
            color={theme.palette.info.light}
            textTransform="initial"
          >
            {account?.email}
          </Typography>
          <ArrowDropDownIcon
            sx={{
              color: '#ACBBFF',
              marginLeft: '10px',
              transition: '0.2s',
              transform: anchorEl ? 'rotate(180deg)' : '0',
            }}
          />
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&:before': {
                left: '50%',
                ...PaperProps,
              },
            },
          }}
        >
          <MenuItem disabled>
            <ListItemIcon>
              <FiUser size={24} />
            </ListItemIcon>
            <ListItemText>Настройки профиля</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              logOut();
            }}
          >
            <ListItemIcon>
              <FiLogOut size={24} />
            </ListItemIcon>
            <ListItemText>Выход</ListItemText>
          </MenuItem>
        </Menu>

        {isRightMenu && (
          <ThemeProvider theme={themeH}>
            <Button
              variant="outlined"
              size="small"
              sx={{ minWidth: 'fit-content' }}
              onClick={() => toggleMenuR()}
              id="btmOpenRightMenu"
            >
              <MoreHorizIcon />
            </Button>
          </ThemeProvider>
        )}
      </Toolbar>
    </AppBar>
  );
};

import React from 'react';
import { variant } from '@effector/reflect';

import { $modalType } from '../model/switcherForModal';
import { ResourceModal } from './Modals/resource';
import { TextModal } from './Modals/TextModal';
import { ListResourceModal } from './Modals/ListModal';

export const ListModal = variant({
  source: $modalType,
  cases: {
    resource: ResourceModal,
    text: TextModal,
    list: ListResourceModal,
    // HTML: ,
    // archive: ,
    // none: ,
  },
});

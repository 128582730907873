import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
  Store,
} from 'effector';
import deepEquals from 'deep-equal';
import type { Canceler } from 'axios';
import axios from 'axios';
import {
  cardCreate,
  cardGet,
  cardGetList,
  cardMove,
  cardSetPosition,
  Filter,
  PCardGetList,
  PCardMove,
  PCardPosition,
  RCardGet,
  RCardGetList,
} from '../../../apiRPC/card';
import { errorHandlerFx } from '../../../model/errorHandler';
import { createEffectJWT } from '../../../model/JWT';
import { defaultSort, moveArray } from '../../../helper/sort';
import { columnGetFX } from '../column';
import { clockMarkForUpdateCart } from '../Marks';
import { updateMarksFX } from './update/marks';
import { updateMembersFX } from './update/members';
import { effectListener } from '../../../model/effectListener';
import {
  contentDeleteFX,
  contentRestoreFX,
  updateAttachmentsFX,
} from './update/attachments';
import { Item } from '../../../apiRPC/column';
import { $board, getBoardFx } from '../board';
import { updateStatusFX } from './update/status';
import {
  $cardCommentsTotal,
  abortCardGetComments,
  commentsReset,
} from './comment';
import {
  $isEmptyMarks,
  $isEmptyMembers,
  $isSelfMember,
  $listExcludeMarks,
  $listExcludeMembers,
  $listMarks,
  $listMembers,
  $matchFilter,
} from '../../../components/Header/Filter/model';
import { $account } from '../../../model/account';
import { FilterData, isAnyFilter, isFullFilter } from './helper/filter';
import { $currentUuid, setCurrentUuid } from '../../pages/desk/model';
import { columnGetListFistLoadFx } from '../Columns';

export const cardCreateFX = errorHandlerFx(createEffectJWT(cardCreate));

export type PCardGetListActive = { filter: Omit<Filter, 'status'> };
const cancelTokenCardGetList: Canceler[] = [];
export const { abort: abortCardGetListFistLoad, fx: cardGetListFX } =
  effectListener(
    errorHandlerFx(
      createEffectJWT((p: PCardGetListActive) => {
        const source = axios.CancelToken.source();
        cancelTokenCardGetList.push(source.cancel);
        return cardGetList(
          { filter: { ...p.filter, status: 'ACTIVE' } },
          source.token,
        );
      }),
    ),
    60 * 1000,
  );

cardGetListFX.watch(() => {
  if (cardGetListFX.pending.getState()) {
    abortCardGetListFistLoad();
    cancelTokenCardGetList.forEach((item) => item());
  }
});

export type TCardListsSorted = Record<string, RCardGetList['rows']>;

export type DnDData = {
  currentIndex: number;
  targetIndex: number;
  columnId: string;
  soursColumnId: string;
  uuid: string;
  isChangedColumn: boolean;
  newCardListsSorted?: TCardListsSorted;
};
export const setSortCart = createEvent<DnDData>();
export const updateItem = createEvent<Partial<RCardGet> & { uuid: string }>();
export const updateCart = createEvent<Partial<RCardGet> & { uuid: string }>();
export const updateCarts = createEvent<RCardGetList['rows']>();

export const addCart = createEvent<RCardGet>();

export const $cardLists = createStore<RCardGetList | null>(null)
  .on(cardGetListFX.doneData, (state, payload) => {
    if (deepEquals(payload, state)) return undefined;
    return payload;
  })
  .on(updateItem, (state, payload) => {
    if (!state) return undefined;

    const itemIndex = state.rows.findIndex((row) => row.uuid === payload.uuid);
    if (itemIndex < 0) return undefined;
    const item = { ...state.rows[itemIndex] };

    (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
      if (key in item) {
        const val = payload[key];
        if (typeof item[key] === typeof val) {
          (item[key] as typeof val) = val;
        }
      }
    });

    state.rows[itemIndex] = item;
    return { rows: [...state.rows], total: state.total };
  })
  .on(updateCarts, (state, payload) => {
    if (!state) return undefined;

    payload.forEach((item) => {
      const index = state.rows.findIndex((i) => i.uuid === item.uuid);
      if (index < 0) return;
      state.rows[index] = item;
    });
    return { total: state.total, rows: [...state.rows] };
  })
  .on(updateStatusFX.doneData, (state, payload) => {
    if (payload.status === 'ACTIVE') {
      const newState = !state
        ? { total: 1, rows: [] }
        : { total: state.total + 1, rows: [...state.rows] };
      newState.rows.push(payload);
      return newState;
    }
    if (payload.status === 'CLOSED') {
      const newState = !state
        ? { total: 0, rows: [] }
        : {
            total: state.total - 1,
            rows: [...state.rows.filter((item) => item.uuid !== payload.uuid)],
          };
      return newState;
    }
    return undefined;
  })
  .on(addCart, (state, payload) => {
    const newState = !state
      ? { total: 1, rows: [] }
      : { total: state.total, rows: [...state.rows] };
    newState.rows.push(payload);
    return newState;
  });

export const $cardListsSorted: Store<TCardListsSorted> = combine(
  {
    cardLists: $cardLists,
    isEmptyMembers: $isEmptyMembers,
    isSelfMember: $isSelfMember,
    listMembers: $listMembers,
    isEmptyMarks: $isEmptyMarks,
    listMarks: $listMarks,
    listExcludeMembers: $listExcludeMembers,
    listExcludeMarks: $listExcludeMarks,
    matchFilter: $matchFilter,
  },
  ({
    cardLists,
    isEmptyMembers,
    isSelfMember,
    listMembers,
    isEmptyMarks,
    listMarks,
    listExcludeMembers,
    listExcludeMarks,
    matchFilter,
  }) => {
    const result: TCardListsSorted = {};
    const sort = cardLists?.rows.sort(defaultSort);
    const selfUid = isSelfMember ? $account.getState()?.uuid : null;

    const isMembersFilter =
      isEmptyMembers ||
      isSelfMember ||
      Boolean(listMembers.length) ||
      Boolean(listExcludeMembers.length);

    const isMarksFilter =
      Boolean(listMarks.length) ||
      Boolean(listExcludeMarks.length) ||
      isEmptyMarks;

    const isFilter = isMembersFilter || isMarksFilter;

    const listMembersUuids = new Set(listMembers.map((item) => item.uuid));
    const listMarksUuids = new Set(listMarks.map((item) => item.uuid));
    const listExcludeMembersUuids = new Set(
      listExcludeMembers.map((item) => item.uuid),
    );
    const listExcludeMarksUuids = new Set(
      listExcludeMarks.map((item) => item.uuid),
    );

    sort?.forEach((card) => {
      if (!card) return;
      const key = card.column;
      if (result[key] === undefined) {
        result[key] = [];
      }

      if (!isFilter) {
        result[key].push(card);
        return;
      }

      const dataForFilter: FilterData = {
        card,
        selfUid,
        isEmptyMembers,
        isSelfMember,
        isEmptyMarks,
        listMembersUuids,
        listMarksUuids,
        listExcludeMembersUuids,
        listExcludeMarksUuids,
      };
      if (matchFilter === 'ANY' && isAnyFilter(dataForFilter)) {
        result[key].push(card);
        return;
      }
      if (matchFilter === 'FULL' && isFullFilter(dataForFilter)) {
        result[key].push(card);
      }
    });

    return result;
  },
);

export const $countCardListsSorted = $cardListsSorted.map((cardListsSorted) =>
  Object.values(cardListsSorted).reduce(
    (currentSum, item) => currentSum + item.length,
    0,
  ),
);

const $lastParamsForList = createStore<PCardGetList | null>(null).on(
  [cardGetListFX.done],
  (_, payload) => payload.params,
);

const $lastParamsCurrentUid: Store<PCardGetList | null> = combine(
  { lastParamsForList: $lastParamsForList, currentUuid: $currentUuid },
  ({ lastParamsForList, currentUuid }) => {
    if (!lastParamsForList) return null;
    if (currentUuid)
      return { filter: { ...lastParamsForList.filter, board: currentUuid } };
    return lastParamsForList;
  },
);

export const $pendingCardOrColumn = combine(
  [cardGetListFX.pending, columnGetListFistLoadFx.pending],
  ([pendingCard, pendingColumn]) => pendingCard || pendingColumn,
);

sample({
  clock: $board,
  source: $currentUuid,
  filter: (currentUuid, board) => {
    if (!board?.uuid) return false;
    return board?.uuid !== currentUuid;
  },
  fn: (_, board) => board!.uuid,
  target: setCurrentUuid,
});

sample({
  clock: cardCreateFX.done,
  source: $lastParamsCurrentUid,
  filter: (state) => Boolean(state),
  fn: (state) => state!,
  target: cardGetListFX,
});

export type PCardSortAndMove = {
  pCardMove?: PCardMove;
  pCardPosition: PCardPosition;
};
export const cardSortAndMoveFX = errorHandlerFx(
  createEffectJWT(async ({ pCardMove, pCardPosition }: PCardSortAndMove) => {
    if (pCardMove) {
      await cardMove(pCardMove);
    }
    await cardSetPosition(pCardPosition);
  }),
);

export const cardGetFX = errorHandlerFx(createEffectJWT(cardGet));
export const cardGetPreloaderFX = errorHandlerFx(createEffectJWT(cardGet));
export const resetCard = createEvent();
export const onSetNameContent = createEvent<{ uuid: string; name: string }>();
export const $card = createStore<RCardGet | null>(null)
  .on(
    [cardGetFX.doneData, cardGetPreloaderFX.doneData],
    (_, payload) => payload,
  )
  .on(
    [
      updateItem,
      updateCart,
      updateStatusFX.doneData,
      updateAttachmentsFX.doneData,
    ],
    (state, payload) => {
      if (!state) return undefined;
      if (!payload) return undefined;
      if (state.uuid !== payload.uuid) return undefined;

      (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
        if (key in state) {
          const val = payload[key];
          if (typeof state[key] === typeof val) {
            (state[key] as typeof val) = val;
          }
        }
      });

      return { ...state };
    },
  )
  .on(onSetNameContent, (state, payload) => {
    if (!state) return undefined;
    const findAttach = state.attachments?.find(
      (attach) => attach.uuid === payload.uuid,
    );
    if (!findAttach) return undefined;
    findAttach.name = payload.name;
    return { ...state };
  })
  .reset(resetCard);

sample({
  clock: [cardGetFX.doneData, cardGetPreloaderFX.doneData],
  fn: (state) => ({ uuid: state.column }),
  target: columnGetFX,
});
sample({
  clock: [cardSortAndMoveFX.done],
  filter: ({ params }) => Boolean(params.pCardMove?.column),
  fn: ({ params }) => ({ uuid: params!.pCardMove!.column }),
  target: columnGetFX,
});

sample({
  clock: $card,
  source: $board,
  filter: (board, card) => Boolean(card?.board) && board?.uuid !== card?.board,
  fn: (_, card) => ({ uuid: card?.board || '' }),
  target: getBoardFx,
});

sample({
  clock: clockMarkForUpdateCart,
  source: $lastParamsCurrentUid,
  filter: (state) => Boolean(state),
  fn: (state) => state!,
  target: cardGetListFX,
});

export const clockForReloadCardList = [
  updateMarksFX.done,
  updateMembersFX.done,
  updateAttachmentsFX.done,
  contentDeleteFX.done,
  contentRestoreFX.done,
  cardSortAndMoveFX.done,
  $cardCommentsTotal,
];

sample({
  clock: clockForReloadCardList,
  source: $lastParamsCurrentUid,
  filter: (state) => Boolean(state),
  fn: (state) => state!,
  target: cardGetListFX,
});

type TargetData = {
  sorted: RCardGetList['rows'];
  pCardMove?: PCardMove;
};

sample({
  clock: setSortCart,
  source: $cardListsSorted,
  fn: (state, payload) => {
    const array = payload.newCardListsSorted
      ? payload.newCardListsSorted[payload.columnId] || []
      : state[payload.columnId] || [];

    if (payload.isChangedColumn) {
      const item = state[payload.soursColumnId] || [];
      const findItem = item.find((i) => i.uuid === payload.uuid);
      if (findItem) {
        findItem.column = payload.columnId;
        array.unshift(findItem);
      }
    }

    const newArray = moveArray({
      array,
      currentIndex: payload.currentIndex,
      targetIndex: payload.targetIndex,
    });

    newArray.forEach((item, i) => {
      // eslint-disable-next-line no-param-reassign
      item.sort = (i + 1) * 10;
    });

    const data: TargetData = { sorted: newArray };
    if (payload.isChangedColumn) {
      data.pCardMove = { uuid: payload.uuid, column: payload.columnId };
    }
    return data;
  },
  target: createEffect(({ sorted, pCardMove }: TargetData) => {
    updateCarts(sorted);
    if (pCardMove) updateCart(pCardMove);
    const items: Item[] = sorted.map((item) => ({
      uuid: item.uuid,
      sort: item.sort,
    }));

    cardSortAndMoveFX({ pCardPosition: { items }, pCardMove });
  }),
});

forward({
  from: resetCard,
  to: [commentsReset, abortCardGetComments],
});

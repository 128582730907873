export function insert(str: string, substr: string, pos: number) {
  const array = str.split('');
  array.splice(pos, 0, substr);
  return array.join('');
}

export function decodeValueChars(str: string): string {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
}

import { createEvent, restore } from 'effector';
import { errorHandlerFx } from '../../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../../model/JWT';
import { resourcesUpdate } from '../../../../../../../apiRPC/creative/resources';

export const resourcesUpdateFX = errorHandlerFx(
  createEffectJWT(resourcesUpdate),
);

export const submitResourcesUpdateForm = createEvent();
export const resetResourcesUpdateForm = createEvent();

export const setValidUpdate = createEvent<boolean>();
export const $validUpdate = restore(setValidUpdate, true);

export const setChangeUpdate = createEvent<boolean>();
export const $changeUpdate = restore(setChangeUpdate, true);

import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { variant } from '@effector/reflect';
import { Box, Container } from '@mui/material';
import TabHeader from './TabHeader';
import LoadingBasicForm from '../../../Campaigns/Campaign/Update/LoadingBasicForm';
import Basic from './Basik';
import { ContainerResourse } from './Resourse';
import { $fieldFormUpdateCreative } from './model/fieldFormUpdateCreative';
import {
  creativeGetFX,
  onGetListCampaign,
  resetCreativeModel,
  resourcesGetCountFX,
} from './model';
import { onOrdGetList } from '../../../../model/ord';

const ComponentUpdate = variant({
  source: $fieldFormUpdateCreative,
  cases: {
    Resources: ContainerResourse,
    Basic,
    loading: LoadingBasicForm,
  },
  default: LoadingBasicForm,
  hooks: {
    mounted: () => {
      onOrdGetList();
      onGetListCampaign();
    },
  },
});

export const Update: FC = () => {
  const { uuid } = useParams();

  useEffect(() => {
    if (!uuid) return undefined;
    creativeGetFX({ uuid });
    resourcesGetCountFX({ creative: uuid });
    return resetCreativeModel;
  }, [uuid]);

  return (
    <>
      <TabHeader />
      <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          height="calc(100vh - 108px )"
        >
          <ComponentUpdate />
        </Box>
      </Container>
    </>
  );
};

import * as Yup from 'yup';
import React, { FC, useEffect, useState } from 'react';
import { useStoreMap, useUnit } from 'effector-react';
import { useFormik } from 'formik';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FiTrash } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/system';
import {
  $cardCreativesGroupsList,
  $checkedCreatives,
  $dictionaryCreativeSizes,
  addCheckedCreative,
  cardCreativesDeleteFX,
  cardCreativesGetFX,
  cardCreativesUpdateFX,
  dellCheckedCreative,
} from '../model';
import { isErrorProps } from '../../../../../../../apiRPC/request';
import { BootstrapTooltip } from '../../../../../../../components/BootstrapTooltip';
import { CardCreativeItem } from '../../../../../../../apiRPC/card/creatives';
import { CodeEditorLight } from '../../../../../../../UI/CodeEditorLight';

type FormCreateItem = Omit<CardCreativeItem, 'card' | 'url' | 'groupUuid'>;
type Form = FormCreateItem;

const regexSize = /^[1-9][0-9]{1,3}x[1-9][0-9]{1,3}$/;
const validationSchema = Yup.object().shape({
  size: Yup.string()
    .matches(regexSize, 'Невалидное значение')
    .required('Обязательное для заполнения поле'),
  html: Yup.string()
    .required('Обязательное для заполнения поле')
    .nullable()
    .when('type', ([type], schema) => {
      if (type === 'html')
        return schema.matches(
          /(^$)|((?:.|\n|\r)*<html(?:.|\n|\r)*<body)/,
          'Невалидное значение',
        );
      if (type === 'video')
        return schema.matches(/^(https?:\/\/)/, 'Невалидное значение');
      return schema.required('Обязательное для заполнения поле');
    }),
  auditCode: Yup.string().trim(),
  name: Yup.string().trim(),
  type: Yup.string(),
});
type Props = {
  uuid: string;
  size: string;
  name?: string;
  updatePosition?: () => void;
  url: string;
  groupUuid: string | null;
};
export const ItemUpdate: FC<Props> = ({
  size,
  uuid,
  name,
  updatePosition,
  url,
  groupUuid,
}) => {
  const [dictionaryCreativeSizes] = useUnit([$dictionaryCreativeSizes]);

  const isChecked = useStoreMap({
    store: $checkedCreatives,
    keys: [uuid],
    fn: (state, keys) => state.has(keys[0]),
    defaultValue: false,
  });

  const group = useStoreMap({
    store: $cardCreativesGroupsList,
    keys: [groupUuid],
    fn: (state, keys) => {
      if (keys[0] === null) return '';
      return state?.find((item) => item.uuid === keys[0])?.title ?? '';
    },
    defaultValue: '',
  });
  const [disabled, setDisable] = useState(false);
  const [data, setData] = useState<Pick<
    CardCreativeItem,
    'html' | 'auditCode' | 'type'
  > | null>(null);
  const [isOpenCopyTitle, setOpenCopyTitle] = useState(false);
  useEffect(() => {
    if (!isOpenCopyTitle) return;
    setTimeout(() => {
      setOpenCopyTitle(false);
    }, 3000);
  }, [isOpenCopyTitle]);
  const formik = useFormik<Form>({
    initialValues: { size, html: '', auditCode: '', uuid, name },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (
        values.size === size &&
        values.html === (data?.html ?? '') &&
        values.auditCode === (data?.auditCode ?? '') &&
        values.name === name
      )
        return;

      setDisable(true);

      try {
        const result = await cardCreativesUpdateFX({
          uuid,
          fields: {
            size: values.size,
            html: values.html,
            auditCode: values.type === 'html' ? values.auditCode : undefined,
            name: values.name,
          },
        });

        setData({
          html: result.html,
          auditCode: result.auditCode,
          type: result.type,
        });
      } catch (e) {
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      } finally {
        setDisable(false);
      }
    },
  });

  useEffect(() => {
    if (!data) return;

    formik.setValues(
      {
        ...formik.values,
        auditCode: data.auditCode,
        html: data.html,
        type: data.type,
      },
      true,
    );
  }, [data]);

  const onDelete = async () => {
    setDisable(true);
    try {
      await cardCreativesDeleteFX({ uuid });
    } catch (e) {
      console.error(e);

      if (isErrorProps(e)) {
        e?.data?.fields?.forEach((field) => {
          const text = field.description;
          formik.setFieldError(field.field, text);
        });
      }
    } finally {
      setDisable(false);
    }
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;

    if (
      !(
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLInputElement
      )
    )
      return;
    const textarea = e.target;
    if (e.key === 'Enter' && e.ctrlKey) {
      const index = textarea.selectionStart;
      if (index === null) return;
      const str = formik.values.auditCode;
      const firstPart = str?.slice(0, index) ?? 0;
      const secondPart = str?.slice(index) ?? 0;
      formik
        .setFieldValue(e.target.name, `${firstPart}\n${secondPart}`)
        .then(() => {
          textarea.selectionStart = index + 1;
          textarea.selectionEnd = index + 1;
          textarea.blur();
          textarea.focus();
          e.stopPropagation();
        });
      e.preventDefault();
      return;
    }
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <Accordion
      onChange={async (_, value) => {
        if (!value) {
          setData(null);
          setTimeout(() => {
            updatePosition?.();
          }, 10);
          return;
        }
        setDisable(true);
        const result = await cardCreativesGetFX({ uuid });
        setData({
          html: result.html,
          auditCode: result.auditCode,
          type: result.type,
        });
        setDisable(false);
        setTimeout(() => {
          updatePosition?.();
        }, 10);
      }}
      sx={{ mb: 1 }}
      disabled={disabled}
      TransitionProps={{ unmountOnExit: true, enter: false, exit: false }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="arrow" />}
        sx={{
          userSelect: 'auto',
          padding: 1,
          '.MuiAccordionSummary-content': { flexGrow: 1, margin: 0 },
          '.MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
        }}
      >
        <Stack
          display="flex"
          direction="row"
          spacing={2}
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box>
            {group && (
              <Tooltip title="Скопирован" open={isOpenCopyTitle}>
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ wordBreak: 'break-word' }}
                  onMouseDown={(event) => {
                    if (event.button !== 1) return;
                    event.stopPropagation();
                    navigator?.clipboard?.writeText(group).then(() => {
                      setOpenCopyTitle(true);
                    });
                  }}
                >
                  {group} /{' '}
                </Typography>
              </Tooltip>
            )}
            {Boolean(name && size) && (
              <>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ wordBreak: 'break-word' }}
                >
                  {name} ({size})
                </Typography>
              </>
            )}

            {Boolean(!name && size) && (
              <Typography component="span" variant="body1">
                {size}
              </Typography>
            )}
          </Box>
          <Stack spacing={1} direction="row">
            <BootstrapTooltip title="Просмотреть" placement="top">
              <IconButton
                sx={{ ml: 'auto' }}
                disabled={disabled}
                color="primary"
                component={NavLink}
                to={url}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <VisibilityIcon color="primary" />
              </IconButton>
            </BootstrapTooltip>

            <BootstrapTooltip title="Удалить" placement="top">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDisable(true);
                  onDelete().catch(() => {
                    setDisable(false);
                  });
                }}
                sx={{ ml: 'auto' }}
                disabled={disabled}
              >
                <FiTrash color="#D32F2F" size={20} />
              </IconButton>
            </BootstrapTooltip>

            <BootstrapTooltip title="Выбрать" placement="top">
              <Checkbox
                size="small"
                checked={isChecked}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  const val = e.target.checked;
                  if (!val) {
                    dellCheckedCreative(uuid);
                    return;
                  }
                  addCheckedCreative(uuid);
                }}
                disabled={disabled}
              />
            </BootstrapTooltip>
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack padding={1} spacing={2}>
          <Stack display="flex" direction="row" spacing={2} alignItems="center">
            <TextField
              label="Имя"
              variant="standard"
              name="name"
              inputProps={{ maxLength: 255 }}
              autoComplete="none"
              value={formik.values.name ?? ''}
              error={Boolean(formik.errors.name)}
              helperText={formik.errors.name}
              onChange={formik.handleChange}
              disabled={disabled}
              size="small"
              fullWidth
              onBlur={(e) => {
                if (!e.relatedTarget) return;
                formik.submitForm();
              }}
              onKeyPress={onPressEnter}
            />

            <Autocomplete
              disabled={disabled}
              size="small"
              value={formik.values.size}
              onChange={(event, newValue) => {
                if (!newValue) return;
                formik
                  .setFieldValue(`size`, newValue)
                  .then(() => formik.submitForm());
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                if (!inputValue) return options;
                const filtered = options.filter((item) =>
                  item.toUpperCase().includes(inputValue.toUpperCase()),
                );
                const isExisting = options.includes(inputValue);
                if (
                  inputValue !== '' &&
                  !isExisting &&
                  regexSize.test(inputValue)
                ) {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={dictionaryCreativeSizes}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  label="Размер"
                  required
                  inputProps={{ ...params.inputProps, maxLength: 255 }}
                  helperText={formik.errors.size}
                  error={Boolean(formik.errors.size)}
                  sx={{
                    '.MuiFormHelperText-root': {
                      position: 'absolute',
                      textWrap: 'nowrap',
                      bottom: '-20px',
                    },
                  }}
                />
              )}
            />
          </Stack>

          <Stack direction="row" spacing={1}>
            <FormControl
              size="small"
              sx={{ width: '100px', flexShrink: 0 }}
              variant="standard"
              disabled
            >
              <InputLabel>Тип</InputLabel>
              <Select
                size="small"
                value={formik.values.type || 'html'}
                label="Тип"
                onChange={(e: SelectChangeEvent<string>) => {
                  formik.setFieldValue('type', e.target.value, true);
                }}
                readOnly
              >
                <MenuItem
                  sx={{
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-word',
                  }}
                  value="html"
                >
                  HTML
                </MenuItem>
                <MenuItem
                  sx={{
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-word',
                  }}
                  value="video"
                >
                  Видео
                </MenuItem>
              </Select>
            </FormControl>

            {formik.values.type === 'html' && (
              <TextField
                fullWidth
                size="small"
                label="Код аудита"
                variant="standard"
                name="auditCode"
                inputProps={{ maxLength: 10000 }}
                autoComplete="none"
                multiline
                value={formik.values.auditCode}
                error={Boolean(formik.errors.auditCode)}
                helperText={formik.errors.auditCode}
                onChange={formik.handleChange}
                disabled={disabled}
                onBlur={(e) => {
                  if (!e.relatedTarget) return;
                  formik.submitForm();
                }}
                maxRows={5}
                onKeyPress={onPressEnter}
              />
            )}
          </Stack>

          {formik.values.type === 'html' && (
            <CodeEditorLight
              label="HTML"
              placeholder="HTML"
              multiline
              name="html"
              inputProps={{ maxLength: 20000 }}
              required
              value={formik.values.html}
              onChange={(e) => {
                const val = e.target.value;
                formik.setFieldValue('html', val).then(() => {
                  formik.validateField('html');
                });
              }}
              error={Boolean(formik.errors.html)}
              helperText={formik.errors.html}
              disabled={disabled}
              onBlur={(e) => {
                if (!e.relatedTarget) return;
                formik.submitForm();
              }}
              onKeyEnter={() => {
                formik.submitForm();
              }}
            />
          )}
          {formik.values.type === 'video' && (
            <TextField
              label="Ссылка"
              variant="standard"
              name="html"
              inputProps={{ maxLength: 20000 }}
              autoComplete="none"
              multiline
              value={formik.values.html}
              error={Boolean(formik.errors.html)}
              helperText={formik.errors.html}
              onChange={formik.handleChange}
              disabled={disabled}
              onBlur={(e) => {
                if (!e.relatedTarget) return;
                formik.submitForm();
              }}
              maxRows={5}
              onKeyPress={onPressEnter}
            />
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

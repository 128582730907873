import { useStoreMap, useUnit } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { $account } from '../../../../../../../model/account';
import {
  cardAddCommentFX,
  cardGetCommentsFX,
  commentsReset,
} from '../../../../../../model/card/comment';
import { useHandleClickOutside } from '../../../../../../../helper/hooks/useHandleClickOutside';
import {
  $files,
  $listFilesForComments,
  delFileForComment,
} from '../../../../../../../helper/attachments';
import { cardGetFX } from '../../../../../../model/card';

type Params = { uuid: string };
export function useAddComment({ uuid }: Params) {
  const [account, listFilesForComments] = useUnit([
    $account,
    $listFilesForComments,
  ]);
  const [isOpenForm, setOpenForm] = useState(false);
  const [disableRipple, setDisableRipple] = useState(false);
  const [comment, setComment] = useState('');
  const pendingAdd = useUnit(cardAddCommentFX.pending);

  const isUploadFile = useStoreMap({
    store: $files,
    keys: [],
    fn: (state) =>
      Boolean(
        Array.from(state.values()).find((item) => item.commentUid === 'NEW'),
      ),
    defaultValue: false,
  });

  const disabledBtn = !comment || pendingAdd || isUploadFile;
  const disabledTxt = pendingAdd;

  const refForClickOutside = useRef(null);
  const refEmojiMenu = useRef<HTMLDivElement>(null);
  const refLinkMenu = useRef<HTMLDivElement>(null);
  const refTextArea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => setDisableRipple(isOpenForm), 400);
  }, [isOpenForm]);

  const onOpenForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
    setComment('');
    refTextArea.current?.blur();
  };
  const onSubmit = () => {
    const contents = listFilesForComments
      .filter((item) => item.commentUid === 'NEW')
      ?.map((item) => item.uuid);
    cardAddCommentFX({
      card: uuid,
      text: comment,
      fields: {
        contents,
      },
    }).then(() => {
      delFileForComment(contents);
      onCloseForm();
      cardGetFX({ uuid });
    });
  };

  const onBlur = () => {
    if (comment) return;
    onCloseForm();
  };

  useHandleClickOutside({
    refForClickOutside: [refForClickOutside, refEmojiMenu, refLinkMenu],
    onClick: onBlur,
  });

  const onSaveHandleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Escape') {
      onCloseForm();
      return;
    }

    if (e.key === 'Enter' && e.ctrlKey && !disabledBtn) {
      onSubmit();
    }
  };

  return {
    account,
    onOpenForm,
    refForClickOutside,
    disableRipple,
    isOpenForm,
    comment,
    setComment,
    disabledTxt,
    disabledBtn,
    onSubmit,
    refEmojiMenu,
    refLinkMenu,
    onSaveHandleKeyDown,
    refTextArea,
  };
}

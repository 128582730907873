import {
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import React, { FC, useTransition } from 'react';
import { useUnit } from 'effector-react';
import {
  $isEmptyMembers,
  $isSelfMember,
  $listMembers,
  onHandleEmptyMembers,
  onHandleSelfMember,
  onSetMembers,
  resetListMembers,
} from './model';
import { $accountsSort } from '../../../GRID/model/card/accounts';

export const Members: FC = () => {
  const [isPendingEmptyMembers, startTransitionEmptyMembers] = useTransition();
  const [isPendingSelfMember, startTransitionSelfMember] = useTransition();
  const [isPendingListMembers, startTransitionListMembers] = useTransition();
  const accounts = useUnit($accountsSort);
  const isEmptyMembers = useUnit($isEmptyMembers);
  const isSelfMember = useUnit($isSelfMember);
  const listMembers = useUnit($listMembers);

  const isAllChecked = listMembers.length === accounts.length;
  const indeterminate = Boolean(listMembers.length) && !isAllChecked;

  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: 'normal' }}>Участники</ListSubheader>
      }
      disablePadding
    >
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            if (isPendingEmptyMembers) return;
            startTransitionEmptyMembers(onHandleEmptyMembers);
          }}
          role="checkbox"
          dense
          sx={{ py: 0, height: 32 }}
        >
          <Checkbox
            edge="start"
            checked={isEmptyMembers}
            tabIndex={-1}
            disableRipple
          />

          <ListItemText primary="Нет участников" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            if (isPendingSelfMember) return;
            startTransitionSelfMember(onHandleSelfMember);
          }}
          role={undefined}
          dense
          sx={{ py: 0, height: 32 }}
        >
          <Checkbox
            edge="start"
            checked={isSelfMember}
            tabIndex={-1}
            disableRipple
          />

          <ListItemText id="_CheckboxMe" primary="Карточки, назначенные мне" />
        </ListItemButton>
      </ListItem>
      <ListItem role={undefined} dense sx={{ py: 0, height: 32, mt: 1 }}>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={isAllChecked}
          indeterminate={indeterminate}
          onChange={() => {
            if (isPendingListMembers) return;
            if (isAllChecked || indeterminate) {
              startTransitionListMembers(resetListMembers);
              return;
            }
            startTransitionListMembers(() => {
              onSetMembers(accounts);
            });
          }}
        />

        <FormControl fullWidth>
          <Autocomplete
            options={accounts}
            getOptionLabel={(v) => v.email}
            multiple
            size="small"
            disableCloseOnSelect
            fullWidth
            onChange={(_, value) => {
              if (isPendingListMembers) return;
              startTransitionListMembers(() => {
                onSetMembers(value);
              });
            }}
            openOnFocus
            value={listMembers}
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            renderOption={(props, option, { selected }) => (
              <MenuItem
                {...props}
                key={option.uuid}
                value={option.uuid}
                sx={{
                  pl: '0px !important',
                  py: 0,
                  height: 32,
                }}
              >
                <Checkbox checked={selected} />
                <Tooltip
                  title={option.email}
                  placement="bottom"
                  disableInteractive
                >
                  <ListItemText
                    sx={{
                      span: {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                    primary={option.email}
                    id={option.uuid}
                  />
                </Tooltip>
              </MenuItem>
            )}
            renderTags={(value) => (
              <Typography variant="body2">Выбрано {value.length}</Typography>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Выбор участников" />
            )}
            noOptionsText="Не найдено"
          />
        </FormControl>
      </ListItem>
    </List>
  );
};

import { combine, Store } from 'effector';
import { campaignInvoiceParamsGetListFX } from './additional';
import {
  $settings,
  campaignGetFX,
  contractGetFX,
  iContractGetFX,
  Settings,
} from './index';

export const $fieldFormUpdateCampaign: Store<'loading' | Settings> = combine(
  {
    campaignGetPending: campaignGetFX.pending,
    settings: $settings,
    contractGetPending: contractGetFX.pending,
    iContractGetPending: iContractGetFX.pending,
  },
  ({ campaignGetPending, settings, contractGetPending, iContractGetPending }) =>
    campaignGetPending || contractGetPending || iContractGetPending
      ? 'loading'
      : settings,
);
export const $fieldAdditional: Store<'loading' | 'Success'> = combine(
  {
    campaignInvoiceParamsPending: campaignInvoiceParamsGetListFX.pending,
  },
  ({ campaignInvoiceParamsPending }) => {
    if (campaignInvoiceParamsPending) return 'loading';
    return 'Success';
  },
);

export type ParamsMoveArray<T> = {
  array: T[];
  currentIndex: number;
  targetIndex: number;
};
export function moveArray<T>({
  array,
  currentIndex,
  targetIndex,
}: ParamsMoveArray<T>): T[] {
  const isLeftRight = currentIndex < targetIndex;
  const [big, small] = isLeftRight
    ? [targetIndex, currentIndex]
    : [currentIndex, targetIndex];
  const a = array.slice(0, small);
  const b = array.slice(small, big + 1);
  const c = array.slice(big + 1, array.length);

  if (isLeftRight) {
    const first = b[0];
    b?.shift();
    const newB = first ? [...b, first] : b;
    return [...a, ...newB, ...c];
  }

  const last = b[b.length - 1];
  b?.pop();
  const newB = last ? [last, ...b] : b;
  return [...a, ...newB, ...c];
}
type SortItem = { sort: number; createdAt: number; uuid: string };
type SortArray = SortItem[];
export type PMoveSortArray<T extends SortArray> = {
  array: T;
  difference: number;
  index: number;
};

export function defaultSort(a: SortItem, b: SortItem) {
  if (a.sort === b.sort) {
    return a.createdAt - b.createdAt;
  }
  return a.sort - b.sort;
}

export type DataForSort = { uuid: string; fields: { sort: number } };
export function moveSortArray<T extends SortArray>({
  array,
  difference,
  index,
}: PMoveSortArray<T>): [T, DataForSort[]] {
  const sorted = array.sort(defaultSort);

  let dataForSort: DataForSort[] = [];
  const leftShift = difference > 0;
  const borderIndex = index + difference;
  const currentSort = array[borderIndex]?.sort;
  if (currentSort === undefined) return [sorted, dataForSort];

  sorted[index].sort = leftShift ? currentSort + 1 : currentSort - 1;
  dataForSort.push({
    uuid: sorted[index].uuid,
    fields: { sort: sorted[index].sort },
  });

  if (leftShift) {
    for (let i = borderIndex + 1; i < sorted.length; i += 1) {
      sorted[i].sort += 1;
      dataForSort.push({
        uuid: sorted[i].uuid,
        fields: { sort: sorted[i].sort },
      });
    }
  }
  if (!leftShift) {
    for (let i = borderIndex - 1; i >= 0; i -= 1) {
      sorted[i].sort -= 1;
      dataForSort.push({
        uuid: sorted[i].uuid,
        fields: { sort: sorted[i].sort },
      });
    }
  }

  const max = 65535;
  const overflowL = dataForSort.some((item) => item.fields.sort < 0);

  if (overflowL) {
    const min = dataForSort.reduce(
      (p, v) => (p < v.fields.sort ? p : v.fields.sort),
      0,
    );

    dataForSort = sorted.map((item) => ({
      uuid: item.uuid,
      fields: { sort: item.sort + min * -1 },
    }));

    return [sorted, dataForSort];
  }

  const overflowR = dataForSort.some((item) => item.fields.sort > max);

  if (overflowR) {
    dataForSort = sorted.map((item) => ({
      uuid: item.uuid,
      fields: { sort: item.sort - 500 },
    }));

    return [sorted, dataForSort];
  }

  return [sorted, dataForSort];
}

import { RouteObject } from 'react-router-dom';
import { GRID } from './modules/GRID';
import { ORD } from './modules/ORD';
import { cardPath, deskPath, desksPath } from '../../GRID/addresses';
import { ORDPath } from '../../ORD/addresses';

export const routing: RouteObject[] = [
  { path: `${ORDPath}/*`, element: <ORD /> },
  { path: `${desksPath}/*`, element: <GRID /> },
  { path: `${deskPath}/*`, element: <GRID isSearch /> },
  { path: `${cardPath}/*`, element: <GRID isSearch /> },
  { path: 'Organizations/*', element: <ORD /> },
  { path: 'Organization/*', element: <ORD /> },
  { path: 'ContractsList/*', element: <ORD /> },
  { path: 'Contracts/*', element: <ORD /> },
  { path: 'Contract/*', element: <ORD /> },
  { path: '*', element: <GRID /> },
];

import React, { FC } from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { grey, yellow } from '@mui/material/colors';
import { Status } from '../../../../../../apiRPC/card';

type Props = {
  status: Status;
  onClose: () => void;
};
export const StatusTitle: FC<Props> = ({ status, onClose }) => {
  if (status === 'CLOSED')
    return (
      <DialogTitle
        component="div"
        sx={{
          background: yellow[50],
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          mb: 1,
        }}
      >
        <ArchiveIcon color="action" fontSize="small" /> Архивная карточка
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );

  return (
    <DialogTitle component="div">
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

import { FC } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import * as React from 'react';
import { setMenuView } from '../model';
import { TitleItem } from '../TitleItem';

export const DefaultMenu: FC = () => (
  <>
    <TitleItem title="Меню" />

    <List>
      <ListItem disablePadding divider>
        <ListItemButton
          onClick={() => setMenuView('CardArchival')}
          id="rMenuCardArchival"
        >
          <ListItemIcon>
            <ArchiveIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Архив" />
        </ListItemButton>
      </ListItem>
    </List>
  </>
);

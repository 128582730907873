// eslint-disable-next-line
export function throttle<T extends (...args: any) => any>(f: T, t: number) {
  let lastCall: number | undefined;
  return (...args: Parameters<T>) => {
    const previousCall = lastCall;
    lastCall = Date.now();
    if (previousCall === undefined || lastCall - previousCall > t) {
      f(...args);
    }
  };
}

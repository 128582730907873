import { ThemeOptions } from '@mui/material';

export const themeHeader: ThemeOptions = {
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
  },
  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            text: {
              primary: '#FFFFFF',
              secondary: '#FFFFFF',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
          },
        },
      ],
    },
  },
};

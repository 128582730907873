import { createEvent, restore, sample } from 'effector';
import {
  $lastFilterCampaign,
  campaignGetListFX,
  limitCampaign,
} from '../index';

export const setSort = createEvent<'asc' | 'desc' | null>();

export const resetSortData = createEvent();
export const $sortData = restore(setSort, null).reset(resetSortData);

sample({
  clock: $sortData,
  source: {
    lastFilter: $lastFilterCampaign,
  },
  fn: ({ lastFilter }, payload) => {
    const oldFilter = { ...(lastFilter?.filter || {}) };

    const index = oldFilter.sort?.findIndex((item) => item.at(0) === 'budget');

    if (index !== undefined && index !== -1) {
      oldFilter.sort?.splice(index, 1);
    }

    if (payload) {
      if (oldFilter.sort) {
        oldFilter.sort.unshift(['budget', payload]);
      }
      if (!oldFilter.sort) {
        oldFilter.sort = [['budget', payload]];
      }
    }

    const filter = {
      ...oldFilter,
      limit: limitCampaign,
      offset: 0,
    };
    return {
      params: { filter },
    };
  },

  target: campaignGetListFX,
});

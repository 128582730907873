import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { Badge } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { $account } from '../../../../../../model/account';
import { Member } from '../../../../../../apiRPC/card';

const badgeStyle = {
  '& .MuiBadge-badge': {
    fontSize: 10,
  },
};

type Props = {
  members: Member[];
};
export const YouMembericon: FC<Props> = ({ members }) => {
  const account = useUnit($account);

  const isYouMember =
    account?.email && members.find((member) => member.email === account.email);

  if (!isYouMember) return null;
  return (
    <Badge badgeContent={0} color="primary" sx={badgeStyle}>
      <VisibilityIcon fontSize="small" color="primary" />
    </Badge>
  );
};

import { factoryListFilter } from '../../../../../../model/factories/factoryListFilter';
import { campaignGetList } from '../../../../../../apiRPC/сampaign';

export const {
  getListFX: getListCampaignFX,
  getListForScrollFX: getListCampaignForScrollFX,
  onGetList: onGetListCampaign,
  $list: $campaignList,
  searchList: searchCampaign,
  onLoadLists: onLoadListsCampaign,
  resetCatalogs: resetCatalogsCampaign,
  $lastFilter: $lastFilterCampaign,
} = factoryListFilter({ handler: campaignGetList });

import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { useUnit } from 'effector-react';
import 'react-datepicker/dist/react-datepicker.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FiCalendar, FiX } from 'react-icons/fi';
import cn from 'classnames';
import { YearMonthPicker } from '../../../../../../UI/YearMonthPicker';
import {
  $month,
  maxDate,
  minDate,
  resetMonth,
  setMonth,
} from '../../model/filter/date';
import css from './styles.module.scss';

export const MonthFilter: FC = () => {
  const month = useUnit($month);
  const [error, setError] = useState(false);
  return (
    <Box className={css.YearMonthPicker}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
        dateFormats={{ fullDate: 'MM-yyyy' }}
      >
        <YearMonthPicker
          className={cn(css.YearMonthPickerBody, {
            _isError: error,
          })}
          minDate={minDate}
          maxDate={maxDate}
          openTo="month"
          views={['month', 'year']}
          inputFormat="MM-yyyy"
          disableMaskedInput={false}
          value={month}
          onError={(e) => setError(Boolean(e))}
          onChange={(newValue) => {
            setMonth(newValue);
          }}
          components={{
            OpenPickerIcon: FiCalendar,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: 'MM-ГГГГ',
                variant: 'outlined',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                endAdornment: (
                  <>
                    {params.inputProps?.value && (
                      <IconButton
                        id="IconButton"
                        onClick={() => resetMonth()}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <InputAdornment position="start">
                          <FiX size={18} color="#0000008a" />
                        </InputAdornment>
                      </IconButton>
                    )}
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

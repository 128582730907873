import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useUnit } from 'effector-react';
import React, { FC } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import { Info } from '../../../../../../components/Info';
import { addressesORD } from '../../../../../addresses';
import Header from '../../../../../components/Header';
import css from '../../styles.module.scss';
import SetItem from '../SetItem';
import { $campaign } from '../model';
import {
  $additionalChanged,
  $additionalDisable,
  $filteredInvoices,
  $campaignInvoiceParamsPending,
  $disabledChanged,
  $Invoices,
  $updateError,
  $variant,
  onSubmitForm,
  resetFormS,
  setInvoice,
  setVariant,
  variantsMap,
} from '../model/additional';
import Modal from '../../../../../../components/Modal';

const Additional: FC = () => {
  const theme = useTheme();

  const [
    campaign,
    filteredInvoices,
    invoices,
    additionalDisable,
    additionalChanged,
    disabledChanged,
    pendingUpdate,
    updateError,
  ] = useUnit([
    $campaign,
    $filteredInvoices,
    $Invoices,
    $additionalDisable,
    $additionalChanged,
    $disabledChanged,
    $campaignInvoiceParamsPending,
    $updateError,
  ]);

  const variant = useUnit($variant);

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Header>
        <Box display="flex">
          <NavLink to={addressesORD.campaignsPath}>
            <Tooltip title="Назад">
              <IconButton>
                <FiChevronLeft size={20} />
              </IconButton>
            </Tooltip>
          </NavLink>
          <Box flex={1}>
            <Typography
              className={css.name}
              variant="h1"
              sx={{
                marginLeft: '2px',
              }}
              title={campaign?.name}
            >
              Кампания {campaign?.name}
            </Typography>
            <Typography
              mt={0.5}
              sx={{
                color: theme.palette.secondary.light,
              }}
            >
              {campaign?.uuid}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled={!additionalChanged}
            sx={{
              marginLeft: pendingUpdate ? '36px' : '0px',
            }}
            onClick={() => resetFormS()}
          >
            Отмена
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={() => onSubmitForm()}
            disabled={additionalDisable}
            loading={pendingUpdate}
            sx={{
              marginLeft: '36px',
            }}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      {updateError && (
        <Info text={updateError} setOpenInfo={() => null} error />
      )}
      <Box
        sx={{
          height: `calc(100% - 90px ${updateError && '- 86px'})`,
          overflow: 'auto',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            padding: '30px',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '175%',
              }}
            >
              Параметры выставления акта
            </Box>

            <Box>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={variant}
                onChange={(e) => setVariant(+e.target.value)}
                sx={{
                  height: '42px',
                  marginRight: '15px',
                  '& .MuiSelect-select': {
                    fontSize: '15px',
                  },
                }}
              >
                {Array.from(variantsMap).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="outlined"
                sx={{
                  height: '42px',
                }}
                onClick={() => setInvoice(variant)}
              >
                Добавить
              </Button>
            </Box>
          </Box>
        </Paper>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: '1fr',
            gap: '20px 0',
          }}
        >
          {invoices.map(([key, id]) => (
            <SetItem
              title={variantsMap.get(key) || key.toString(10)}
              id={id}
              key={id}
              variant={key}
            />
          ))}
          {filteredInvoices.map((item) => {
            const date = new Date(item.month * 1000);
            return (
              <SetItem
                key={item.uuid}
                data={item}
                title={`${
                  variantsMap.get(item.variant) || item.variant
                } • ${new Intl.DateTimeFormat('ru', {
                  month: 'long',
                }).format(date)}  ${date.getFullYear()}`}
              />
            );
          })}
        </Box>
      </Box>

      <ReactRouterPrompt when={additionalChanged && !disabledChanged}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};

export default Additional;

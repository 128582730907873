import request from '../../request';
import type { ColorsMark } from '../../../GRID/model/Marks';

export type PMarkCreate = {
  // min 1 , max 50
  title: string;
  color: ColorsMark;
  board: string;
};

export type RMarkCreate = {
  uuid: string;
  title: string;
  color: ColorsMark;
  board: string;
};
export const markCreate = request<RMarkCreate, PMarkCreate>({
  method: 'mark.create',
});

export type Fields = {
  // min 1 , max 50
  title?: string;
  color?: ColorsMark;
};
export type PMarkUpdate = {
  uuid: string;
  fields: Fields;
};

export type RMarkUpdate = {
  uuid: string;
  title: string;
  color: ColorsMark;
  board: string;
};

export const markUpdate = request<RMarkUpdate, PMarkUpdate>({
  method: 'mark.update',
});

export type PMarkGet = { uuid: string };

export type RMarkGet = {
  uuid: string;
  title: string;
  color: ColorsMark;
  board: string;
};
export const markGet = request<RMarkGet, PMarkGet>({
  method: 'mark.get',
});

export type PMarkGetList = { board: string };
export type RMarkGetList = RMarkGet[];
export const markGetList = request<RMarkGetList, PMarkGetList>({
  method: 'mark.getList',
});

export type PMarkDelete = {
  uuid: string;
};
export type RMarkDelete = boolean;

export const markDelete = request<RMarkDelete, PMarkDelete>({
  method: 'mark.delete',
});

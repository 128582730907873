import React, { FC } from 'react';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
  title: string;
  onClose: () => void;
  onBack?: () => void;
  ButtonEl?: JSX.Element;
  sx?: SxProps<Theme>;
  sxTitle?: SxProps<Theme>;
}
export const MenuHeader: FC<Props> = ({
  title,
  onClose,
  onBack,
  ButtonEl,
  sx,
  sxTitle,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        display="flex"
        paddingX={2}
        paddingBottom={1}
        position="sticky"
        mt={-1}
        pt={1}
        zIndex={9}
        top={0}
        sx={{ background: 'white', ...sx }}
      >
        {onBack && (
          <IconButton aria-label="back" size="small" onClick={onBack}>
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
        )}

        <Typography
          variant="subtitle1"
          color={theme.palette.grey[700]}
          marginX="auto"
          sx={sxTitle}
        >
          {title}
        </Typography>

        {ButtonEl}
        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
};

import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  ButtonProps,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useUnit } from 'effector-react';
import { green, red, yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import { updateDatesFX } from '../../../../../model/card/update/dates';

function generateTextDates(
  dateStart: Date | null,
  dateEnd: Date | null,
): string {
  const dateStartText = dateStart ? format(dateStart, 'P', { locale: ru }) : '';
  const dateEndText = dateEnd ? `${format(dateEnd, 'P', { locale: ru })} ` : '';

  if (dateStartText && dateEndText) {
    return `${dateStartText} - ${dateEndText}`;
  }

  return dateStartText || dateEndText;
}

type Statuses = 'default' | 'success' | 'overdue' | 'inWorking';
const statusColor: Record<Statuses, string> = {
  default: 'white',
  success: green[500],
  overdue: red[500],
  inWorking: yellow[500],
};

interface ButtonStatus extends ButtonProps {
  status: Statuses;
}
const ButtonStatus = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'status',
})<ButtonStatus>(({ status }) => ({
  background: statusColor[status],
  '&:hover': {
    background: statusColor[status],
  },
}));

function getStatus(
  dateStart: Date | null,
  dateEnd: Date | null,
  completed: boolean,
): Statuses {
  if (completed) return 'success';
  if (dateEnd && Date.now() > +dateEnd) return 'overdue';
  if (dateStart && Date.now() > +dateStart) return 'inWorking';
  if (dateEnd && Date.now() < +dateEnd) return 'inWorking';
  return 'default';
}
type Props = {
  dateStart: number | null;
  dateEnd: number | null;
  uuid: string;
  completed: boolean;
};
export const DatesInfo: FC<Props> = ({
  dateStart,
  dateEnd,
  uuid,
  completed,
}) => {
  const [toggleShow, setToggleShow] = useState(true);
  const dateStartD = dateStart ? new Date(dateStart * 1000) : null;
  const dateEndD = dateEnd ? new Date(dateEnd * 1000) : null;
  const pending = useUnit(updateDatesFX.pending);

  const [status, setStatus] = useState(
    getStatus(dateStartD, dateEndD, completed),
  );

  useEffect(() => {
    const start = dateStart ? new Date(dateStart * 1000) : null;
    const end = dateEnd ? new Date(dateEnd * 1000) : null;
    setStatus(getStatus(start, end, completed));
    const id = setInterval(() => {
      setStatus(getStatus(start, end, completed));
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, [dateStart, dateEnd, completed]);

  const onCheck = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (pending) return null;
    updateDatesFX({
      uuid,
      fields: {
        dates: {
          completed: !completed,
        },
      },
    });

    return null;
  };

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      onMouseEnter={() => {
        setToggleShow(false);
      }}
      onMouseLeave={() => {
        setToggleShow(true);
      }}
      component={ButtonStatus}
      status={status}
      disableRipple
      onClick={onCheck}
    >
      {!toggleShow && (
        <Checkbox
          size="small"
          sx={{ padding: 0 }}
          checked={completed}
          disabled={pending}
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckIcon />}
          disableRipple
          color="default"
        />
      )}

      {toggleShow && <AccessTimeIcon fontSize="small" color="action" />}
      <Typography color="black" fontSize="small" sx={{ pointerEvents: 'none' }}>
        {generateTextDates(dateStartD, dateEndD)}
      </Typography>
    </Stack>
  );
};

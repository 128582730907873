import { createEvent, createStore, forward, sample } from 'effector';
import axios, { Canceler } from 'axios';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import {
  cardHistoryGetList,
  PCardHistoryGetList,
  RCardHistoryGetList,
} from '../../../../../../apiRPC/card/cardHistory';
import { effectListener } from '../../../../../../model/effectListener';

const listHistory = 100;
export const cardHistoryGetListFX = errorHandlerFx(
  createEffectJWT(cardHistoryGetList),
);

const cancelTokenCardCardHistory: Canceler[] = [];
export const {
  abort: abortCardHistoryGetList,
  fx: cardHistoryGetListListenerFX,
} = effectListener(
  errorHandlerFx(
    createEffectJWT((params: PCardHistoryGetList) => {
      const source = axios.CancelToken.source();
      cancelTokenCardCardHistory.push(source.cancel);
      return cardHistoryGetList(params, source.token);
    }),
  ),
  30 * 1000,
);

export const onLoadCardHistory = createEvent<string>();
export const onResetCardHistoryGetList = createEvent();

export const $cardHistoryGetList = createStore<RCardHistoryGetList['rows']>([])
  .on(cardHistoryGetListListenerFX.doneData, (state, payload) => {
    if (
      (payload.rows[0]?.actionTimestamp ?? 0) <=
      (state[0]?.actionTimestamp ?? 0)
    )
      return undefined;

    const lastTimestamp = state[0]?.actionTimestamp ?? 0;
    const lastIndex = payload.rows.findIndex(
      (item) => item.actionTimestamp === lastTimestamp,
    );
    if (lastIndex < 1) return undefined;
    const newPayload = payload.rows.slice(0, lastIndex);

    return [...newPayload, ...state];
  })
  .on(cardHistoryGetListFX.doneData, (state, payload) => [
    ...state,
    ...payload.rows,
  ])
  .reset(onResetCardHistoryGetList);

const $totalRows = createStore(1)
  .on(
    [cardHistoryGetListFX.doneData, cardHistoryGetListFX.doneData],
    (_, payload) => payload.total,
  )
  .reset(onResetCardHistoryGetList);

forward({
  from: onResetCardHistoryGetList,
  to: abortCardHistoryGetList,
});

sample({
  clock: onLoadCardHistory,
  source: {
    cardHistoryList: $cardHistoryGetList,
    totalRows: $totalRows,
  },
  filter: ({ cardHistoryList, totalRows }) =>
    totalRows > cardHistoryList.length,
  fn: ({ cardHistoryList }, payload) => ({
    card: payload,
    filter: {
      limit: listHistory,
      offset: cardHistoryList.length,
    },
  }),
  target: cardHistoryGetListFX,
});

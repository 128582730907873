import React, { FC } from 'react';
import {
  List,
  ListItem,
  Select,
  MenuItem,
  FormControl,
  Typography,
  ListItemText,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { $matchFilter, MatchFilter, onSetMatchFilter } from './model';

const mapValue = new Map([
  ['ANY', 'Любое совпадение'],
  ['FULL', 'Полное совпадение'],
]);
export const OptionsFilter: FC = () => {
  const matchFilter = useUnit($matchFilter);
  return (
    <List disablePadding>
      <ListItem role={undefined} dense sx={{ py: 0, height: 32 }}>
        <FormControl fullWidth>
          <Select
            fullWidth
            size="small"
            renderValue={(v: MatchFilter) => (
              <Typography>{mapValue.get(v) || v}</Typography>
            )}
            value={matchFilter}
            onChange={(e) => {
              onSetMatchFilter(e.target.value as MatchFilter);
            }}
          >
            <MenuItem
              value="ANY"
              sx={{ flexDirection: 'column', alignItems: 'baseline' }}
            >
              <ListItemText primary="Любое совпадение" />
              <Typography variant="body2">
                сопоставляет любую метку и любого участника
              </Typography>
            </MenuItem>
            <MenuItem
              value="FULL"
              sx={{ flexDirection: 'column', alignItems: 'baseline' }}
            >
              <ListItemText primary="Полное совпадение" />
              <Typography variant="body2">
                сопоставляет все метки и всех участников
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </ListItem>
    </List>
  );
};

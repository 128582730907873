import { createEvent, createStore } from 'effector';
import { factoryListFilter } from '../../../../../model/factories/factoryListFilter';
import { creativeGetList } from '../../../../../apiRPC/creative';

export const limit = 30;
export const {
  getListFX: getListCreativeFX,
  getListForScrollFX: getListCreativeForScrollFX,
  onGetList: onGetListCreative,
  $list: $creativeList,
  searchList,
  onLoadLists: onLoadListsCreative,
  resetCatalogs: resetCatalogsCreative,
  $lastFilter: $lastFilterCreative,
} = factoryListFilter({ handler: creativeGetList, limit });

export const onRestFieldTypeCreative = createEvent();
export const $fieldTypeCreative = createStore<
  'loading' | 'ErrorEmpty' | 'ErrorNoContent' | 'Success'
>('loading')
  .on(getListCreativeFX.fail, () => 'ErrorNoContent')
  .on(getListCreativeFX.pending, (_, payload) =>
    payload ? 'loading' : undefined,
  )
  .on(getListCreativeFX.doneData, (_, payload) =>
    payload?.rows.length ? 'Success' : 'ErrorEmpty',
  )
  .reset(onRestFieldTypeCreative);

import React, { FC, useEffect, useRef } from 'react';
import { Backdrop, useTheme } from '@mui/material';
import { useUnit } from 'effector-react';
import CircularProgress from '@mui/material/CircularProgress';
import { resetColumnList } from '../../model/Columns';
import { Routers } from './routers';

import { DragColumnContextMemo } from './DragColumnContext';
import { $currentUuid } from './model';
import { ContainerGrid } from '../../components/ContainerGrid';
import { $pendingCardOrColumn } from '../../model/card';

const Loading: FC = () => {
  const theme = useTheme();
  return (
    <Backdrop
      sx={{
        color: theme.palette.info.light,
      }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
export const Desk: FC = () => {
  useEffect(
    () => () => {
      resetColumnList();
    },
    [],
  );

  const currentUuid = useUnit($currentUuid);

  const lastUid = useRef(currentUuid);

  const pendingCardOrColumn = useUnit($pendingCardOrColumn);

  const pending = lastUid.current !== currentUuid && pendingCardOrColumn;

  useEffect(() => {
    lastUid.current = currentUuid;
  }, [pending]);

  return (
    <ContainerGrid>
      <Routers />
      {pending ? <Loading /> : <DragColumnContextMemo />}
    </ContainerGrid>
  );
};

export const DeskMemo = React.memo(Desk);

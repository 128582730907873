import Divider from '@mui/material/Divider';
import React, { FC } from 'react';
import { MainMenu } from '../MainMenu';
import { MenuProjects } from '../MenuProject';

export const GRID: FC = () => (
  <>
    <MainMenu />
    <Divider />
    <MenuProjects />
  </>
);

import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate } from '../../../../apiRPC/card';
import {
  contentDelete,
  contentRestore,
} from '../../../../apiRPC/card/file/content';

export type CardUpdateAttachments = {
  uuid: string;
  fields: { attachments: { add?: string[]; delete?: string[] } };
  // нужен для внутреннего отслеживания файла
  idFile: string;
};
const cardUpdateWithFailId = ({
  uuid,
  fields,
  idFile,
}: CardUpdateAttachments) => cardUpdate({ uuid, fields });

export const updateAttachmentsFX = errorHandlerFx(
  createEffectJWT<CardUpdateAttachments, RUpdate, Error>(cardUpdateWithFailId),
);

export const contentDeleteFX = errorHandlerFx(createEffectJWT(contentDelete));
export const contentRestoreFX = errorHandlerFx(createEffectJWT(contentRestore));

import {
  combine,
  createEvent,
  createStore,
  forward,
  restore,
  sample,
  Store,
} from 'effector';
import { $cardListsSorted } from '../../../../GRID/model/card';
import { $columnListSort } from '../../../../GRID/model/Columns';

export const setSearchVal = createEvent<string>();
export const resetSearchVal = createEvent();
export const $searchVal = restore(setSearchVal, '').reset(resetSearchVal);

type ColumnUuid = string;
type CardUuid = string;
type CardIndex = number;
type Temp = string;
export type FindItem = [ColumnUuid, CardUuid, CardIndex];

export type SearchMap = {
  cards: Record<CardUuid, Temp>;
  findList: FindItem[];
};

export const $searchMap: Store<SearchMap | null> = combine(
  $columnListSort,
  $cardListsSorted,
  $searchVal,
  (columnListSort, cardListsSorted, searchVal) => {
    if (!searchVal) return null;
    const cards: SearchMap['cards'] = {};
    const findList: SearchMap['findList'] = [];
    columnListSort.forEach((column) => {
      cardListsSorted[column.uuid]?.forEach((card, cardIndex) => {
        if (card.title.toUpperCase().includes(searchVal.toUpperCase())) {
          findList.push([column.uuid, card.uuid, cardIndex]);
          cards[card.uuid] = searchVal;
        }
      });
    });
    return { cards, findList };
  },
);

export const $countSearches = $searchMap.map(
  (state) => state?.findList.length || 0,
);

export const increaseCursor = createEvent();
export const decreaseCursor = createEvent();
export const setCursor = createEvent<number>();
export const resetCursor = createEvent();
export const $cursor = createStore(-1)
  .on(increaseCursor, (state) => state + 1)
  .on(decreaseCursor, (state) => state - 1)
  .on(setCursor, (_, payload) => payload)
  .reset(resetCursor);

export const $currentFindItem = combine(
  $searchMap,
  $cursor,
  (searchMap, cursor) => {
    if (!searchMap) return null;

    return searchMap.findList[cursor] || null;
  },
);
forward({
  from: $searchVal,
  to: resetCursor,
});

sample({
  clock: $searchVal,
  source: { searchMap: $searchMap, cursor: $cursor },
  filter: ({ searchMap, cursor }, searchVal) =>
    Boolean(searchMap?.findList.length) &&
    cursor === -1 &&
    searchVal.length > 3,
  target: increaseCursor,
});

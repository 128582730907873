import React, { useEffect, useState } from 'react';
import {
  Box,
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { MenuHeader } from '../../../../../../components/MenuHeader';

import { AvatarMenu } from '../../../../../../components/AvatarMenu';
import { MenuFixed } from '../../../../../../UI/MenuFixed';
import { Account, RGetAccounts } from '../../../../../../apiRPC/board';

export const ListItemTextDots = styled(ListItemText)(() => ({
  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  anchorEl?: Element | null;
  openMenu: boolean;
  handleClose: () => void;
  onBack?: () => void;
  listChecked: string[];
  accounts: RGetAccounts;
  onClickMember: (member: Account, isChecked: boolean) => void;
};
export const MenuMembers = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      anchorEl,
      openMenu,
      handleClose,
      onBack,
      listChecked,
      accounts,
      onClickMember,
    },
    ref,
  ) => {
    const [search, setSearch] = useState('');
    const theme = useTheme();
    useEffect(() => {
      if (!openMenu) {
        setSearch('');
      }
    }, [openMenu]);
    return (
      <MenuFixed
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        TransitionComponent={Fade}
        disableAutoFocusItem
        ref={ref}
      >
        <MenuHeader onBack={onBack} title="Участники" onClose={handleClose} />
        <MenuList disabledItemsFocusable disableListWrap>
          <Box padding={2}>
            <TextField
              fullWidth
              label="Поиск участников..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                e.preventDefault();
                e.stopPropagation();
                return null;
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                return null;
              }}
            />
          </Box>
          <Box paddingX={2} paddingY={0}>
            <Typography
              component="span"
              fontSize={12}
              variant="body1"
              fontWeight={600}
              color={theme.palette.grey[700]}
            >
              Участники доски
            </Typography>
          </Box>

          {accounts
            .filter((item) =>
              item.email.toUpperCase().includes(search.toUpperCase()),
            )
            .map((item) => {
              const isChecked = listChecked.includes(item.uuid);
              return (
                <MenuItem
                  key={item.uuid}
                  onClick={() => {
                    onClickMember(item, isChecked);
                  }}
                  sx={{ height: 38 }}
                >
                  <ListItemIcon>
                    <AvatarMenu>{item.email}</AvatarMenu>
                  </ListItemIcon>
                  <ListItemTextDots>{item.email}</ListItemTextDots>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    hidden={!isChecked}
                  >
                    <DoneIcon fontSize="small" />
                  </Typography>
                </MenuItem>
              );
            })}
        </MenuList>
      </MenuFixed>
    );
  },
);

import React, { FC } from 'react';
import { Box, Button, Fade, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { grey } from '@mui/material/colors';
import { MenuFixed } from '../../../../../../UI/MenuFixed';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import { contentDeleteFX } from '../../../../../model/card/update/attachments';

type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  uuidFile?: string;
  cardId: string;
};
export const MenuDelete: FC<Props> = ({
  open,
  anchorEl,
  onClose,
  uuidFile,
  cardId,
}) => {
  const pending = useUnit(contentDeleteFX.pending);
  const disabled = !uuidFile || pending;
  const onDelete = () => {
    if (!uuidFile) return;
    onClose();
    contentDeleteFX({
      uuid: uuidFile,
    });
  };

  return (
    <MenuFixed
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuHeader title="Удаление вложения" onClose={onClose} />

      <Box paddingX={2} paddingTop={2} width={300}>
        <Typography component="span" variant="body1" color={grey[700]}>
          {uuidFile ? 'Удалить?' : 'Не выбран файл для удаления'}
        </Typography>
      </Box>

      <Box paddingX={2} pt={4} justifyContent="space-between" display="flex">
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={onDelete}
          disabled={disabled}
        >
          Удалить
        </Button>
      </Box>
    </MenuFixed>
  );
};

import { format, formatISO, isValid, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useUnit } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import classNames from 'classnames';
import { $anchorEl, $cardUid, $dates, $isOpen, onCloseMenuDate } from './model';
import { updateDatesFX } from '../../../../../model/card/update/dates';
import styles from './styles.module.scss';
import { insert } from '../../../../../../helper/methods/string';

function validateAndPropagate(
  value: string,
  setter: (d: Date) => void,
  defaultDate: Date,
) {
  const dateString = value.replace(/[^.\d]/g, '');
  const ruFormat = dateString.includes('.')
    ? dateString
    : dateString.length > 5
    ? insert(insert(dateString, '.', 4), '.', 2)
    : dateString.length > 2
    ? insert(dateString, '.', 2)
    : dateString;

  const result = parse(ruFormat, 'P', defaultDate, {
    locale: ru,
  });

  const validResult = isValid(result) ? result : defaultDate;
  result.setHours(defaultDate.getHours());
  result.setMinutes(defaultDate.getMinutes());
  setter(new Date(validResult));
}
function validateAndPropagateTime(
  value: string,
  setter: (d: Date) => void,
  defaultDate: Date,
) {
  const dateString = value.replace(/[^:\d]/g, '');
  const ruFormat = dateString.includes(':')
    ? dateString
    : dateString.length > 2
    ? insert(dateString, ':', 2)
    : dateString;

  const result = parse(ruFormat, 'p', defaultDate, {
    locale: ru,
  });
  const validResult = isValid(result) ? result : defaultDate;
  setter(new Date(validResult));
}

export function useDateMenu() {
  const open = useUnit($isOpen);
  const anchorEl = useUnit($anchorEl);
  const cardUid = useUnit($cardUid);
  const dates = useUnit($dates);

  const pending = useUnit(updateDatesFX.pending);
  const onClose = () => {
    onCloseMenuDate();
  };

  const bufferStartDate = useRef<Date | null>(null);
  const bufferEndDate = useRef<Date | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [startDateForInput, setStartDateForInput] = useState<string>('');
  const [endDateForInput, setEndDateForInput] = useState<string>('');
  const [endTimeForInput, setEndTimeForInput] = useState<string>('');

  const [checkedStart, setCheckedStart] = useState<boolean>(false);
  const [checkedEnd, setCheckedEnd] = useState<boolean>(true);

  const [focus, setFocus] = useState<'start' | 'end'>('end');

  function reset() {
    bufferStartDate.current = null;
    bufferEndDate.current = null;
    setStartDate(null);
    setEndDate(new Date());
    setCheckedStart(false);
    setCheckedEnd(true);
    setFocus('end');
  }

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  useEffect(() => {
    setStartDateForInput(startDate ? format(startDate, 'dd.MM.yyyy') : '');
    if (startDate) bufferStartDate.current = startDate;
  }, [startDate]);
  useEffect(() => {
    setEndDateForInput(endDate ? format(endDate, 'dd.MM.yyyy') : '');
    setEndTimeForInput(endDate ? format(endDate, 'HH:mm') : '');
    if (endDate) bufferEndDate.current = endDate;
  }, [endDate]);

  useEffect(() => {
    if (!dates) return;
    if (!open) return;
    if (dates.dateStart) {
      setCheckedStart(true);
      setStartDate(new Date(dates.dateStart * 1000));
    }
    if (dates.dateEnd) {
      setCheckedEnd(true);
      setEndDate(new Date(dates.dateEnd * 1000));
    }
  }, [dates, open]);

  const range = checkedStart && checkedEnd;

  const selectedDate = range ? undefined : checkedStart ? startDate : endDate;
  const onChange: ReactDatePickerProps<string, true | false>['onChange'] = (
    dateRange,
  ) => {
    if (!range && dateRange instanceof Date) {
      if (checkedStart) {
        setStartDate(dateRange);
        return;
      }
      setEndDate(dateRange);
      return;
    }

    if (!Array.isArray(dateRange)) {
      console.error('Папаметр range!==true');
      return;
    }

    const [start] = dateRange;

    const nonNullableDate = start && startDate && endDate;

    if (focus === 'start') {
      setStartDate(start);
      if (nonNullableDate && +start > +endDate) {
        const newEnd = new Date(start);
        newEnd.setHours(endDate.getHours());
        newEnd.setMinutes(endDate.getMinutes());
        newEnd.setDate(start!.getDate() + 1);
        setEndDate(newEnd);
      }
      setFocus('end');
      return;
    }
    if (focus === 'end') {
      const dateForTime = endDate || new Date();
      const newEnd = new Date(start || new Date());
      newEnd.setHours(dateForTime.getHours());
      newEnd.setMinutes(dateForTime.getMinutes());
      setEndDate(newEnd);
      if (nonNullableDate && +start < +startDate) {
        const newStart = new Date(start);
        newStart.setDate(start!.getDate() - 1);
        setStartDate(newStart);
      }
    }
  };

  const activeClass = (date: Date) => {
    const activeClassField =
      date.getTime() === endDate?.getTime()
        ? styles['date-picker-field__active']
        : undefined;

    const startActiveClass =
      startDate && date.getTime() === startDate.getTime()
        ? styles['date-picker-field__active--start']
        : undefined;

    const endActiveClass =
      endDate && date.getTime() === endDate.getTime()
        ? styles['date-picker-field__active--end']
        : undefined;

    const rangeColor =
      startDate &&
      endDate &&
      date.getTime() >= startDate.getTime() &&
      date.getTime() <= endDate.getTime()
        ? styles['date-picker-field__range']
        : undefined;

    return classNames(
      rangeColor,
      startActiveClass,
      endActiveClass,
      activeClassField,
    );
  };

  const isFocusedStart = focus === 'start' && checkedStart;
  const isFocusedEnd = focus === 'end' && checkedEnd;

  const onCheckStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;
    setCheckedStart(val);
    if (!val) {
      bufferStartDate.current = startDate;
      setStartDate(null);
      setFocus('end');
    }
    if (val) {
      const defaultDate = bufferStartDate.current || new Date();
      let currentDate: Date = defaultDate;

      if (endDate && +endDate < +defaultDate) {
        currentDate = new Date(endDate);
        currentDate.setDate(endDate.getDate() - 1);
      }

      setStartDate(currentDate);
      setFocus('start');
    }
  };

  const onCheckEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;
    setCheckedEnd(val);
    if (!val) {
      setEndDate(null);
      setFocus('start');
    }
    if (val) {
      setEndDate(bufferEndDate.current || new Date());
      setFocus('end');
    }
  };

  const onSubmit = () => {
    if (!cardUid) return;

    const changeStart =
      dates?.dateStart !== startDate &&
      !(
        dates?.dateStart &&
        startDate &&
        +new Date(dates.dateStart * 1000) === +startDate
      );

    const changeEnd =
      dates?.dateEnd !== endDate &&
      !(
        dates?.dateEnd &&
        endDate &&
        +new Date(dates.dateEnd * 1000) === +endDate
      );

    if (!changeStart && !changeEnd) {
      onClose();
      return;
    }
    updateDatesFX({
      uuid: cardUid,
      fields: {
        dates: {
          completed: dates?.completed || false,
          dateStart: startDate ? formatISO(startDate) : null,
          dateEnd: endDate ? formatISO(endDate) : null,
        },
      },
    }).then(() => onClose());
  };

  const onDelete = () => {
    if (!cardUid) return;

    if (!dates?.dateStart && !dates?.dateEnd) {
      onClose();
      return;
    }
    updateDatesFX({
      uuid: cardUid,
      fields: {
        dates: {
          completed: false,
          dateStart: null,
          dateEnd: null,
        },
      },
    }).then(() => onClose());
  };

  return {
    anchorEl,
    open,
    onClose,
    activeClass,
    selectedDate,
    onChange,
    startDate,
    endDate,
    range,
    isFocusedStart,
    checkedStart,
    onCheckStart,
    startDateForInput,
    setFocus,
    setStartDateForInput,
    validateAndPropagate,
    setStartDate,
    bufferStartDate,
    isFocusedEnd,
    checkedEnd,
    onCheckEnd,
    endDateForInput,
    setEndDateForInput,
    setEndDate,
    bufferEndDate,
    endTimeForInput,
    setEndTimeForInput,
    validateAndPropagateTime,
    onSubmit,
    pending,
    onDelete,
  };
}

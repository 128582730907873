import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from './router/routesMap';
import './assets/reactToastify.scss';
import './assets/normalaze.scss';
import './assets/global.scss';
import { ButtonApi } from './components/ChangeAPI';
import { themeOptions } from './theme';

const theme = createTheme(themeOptions);

const router = createBrowserRouter(routes);
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <RouterProvider router={router} />
      </CssBaseline>
      <ToastContainer />
      <ButtonApi />
    </ThemeProvider>
  );
}

export default App;

import React, { FC, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Fade,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  PopoverActions,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { styled } from '@mui/material/styles';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import {
  $anchorEl,
  $cardCreativesGroupsList,
  $currentGroup,
  $isDisabledMove,
  $isOpen,
  $selectedGroup,
  onCloseMenuCreatives,
  onMoveCreative,
  setCurrentGroup,
  setSelectedGroup,
} from './model';
import { getId } from '../../../../../../helper/getId';
import { FormCreate } from './FormCreate';
import { onSetCreateIds } from './FormCreate/model';
import { FormUpdate } from './FormUpdate';
import { PendingComponent } from './PendingComponent';
import { onSetOpenFormCreateFromZip } from './FormCreateFromZip/model';
import { FormCreateFromZip } from './FormCreateFromZip';

export const MyMenu = styled(Menu)(() => ({
  '& .MuiPopover-paper': { width: 600, height: '100%' },
  '& .MuiList-root': {
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '100%',
  },
}));

export const CreativesMenu: FC = () => {
  const [
    anchorEl,
    isOpen,
    cardCreativesGroupsList,
    currentGroup,
    selectedGroup,
    isDisabledMove,
  ] = useUnit([
    $anchorEl,
    $isOpen,
    $cardCreativesGroupsList,
    $currentGroup,
    $selectedGroup,
    $isDisabledMove,
  ]);

  const ref = useRef<PopoverActions>(null);

  const updatePosition = () => {
    ref.current?.updatePosition();
  };

  const emptyGroupsList = cardCreativesGroupsList?.length === 0;
  return (
    <MyMenu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={() => onCloseMenuCreatives()}
      TransitionComponent={Fade}
      action={ref}
    >
      <MenuHeader
        title="Креативы"
        onClose={() => onCloseMenuCreatives()}
        sx={{ alignItems: 'center', gap: 1 }}
        sxTitle={{ ml: 'inherit' }}
        ButtonEl={
          <Stack spacing={1} alignItems="end">
            <ButtonGroup variant="outlined" size="small">
              <Button
                size="small"
                onClick={() => {
                  onSetOpenFormCreateFromZip(true);
                }}
              >
                Добавить архив
              </Button>
              <Button
                size="small"
                onClick={() => {
                  onSetCreateIds(getId());
                }}
              >
                Добавить
              </Button>
            </ButtonGroup>

            <Stack spacing={1} alignItems="end" direction="row">
              <FormControl size="small" sx={{ width: '170px' }}>
                <InputLabel
                  sx={{
                    fontSize: 14,
                    lineHeight: '100%',
                    '&.MuiInputLabel-shrink': { lineHeight: 'inherit' },
                  }}
                >
                  Группа
                </InputLabel>
                <Select
                  size="small"
                  value={currentGroup}
                  defaultValue="all"
                  label="Группа"
                  onChange={(e: SelectChangeEvent<string>) => {
                    setCurrentGroup(e.target.value);
                  }}
                  autoWidth={false}
                  sx={{
                    '.MuiInputBase-input': { fontSize: 14, padding: '5px' },
                  }}
                  MenuProps={{
                    sx: { '& .MuiPopover-paper': { width: 600 } },
                  }}
                >
                  <MenuItem value="all">
                    <em>{emptyGroupsList ? 'Нет созданных' : 'Все'}</em>
                  </MenuItem>
                  {cardCreativesGroupsList?.map((item) => (
                    <MenuItem
                      sx={{
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-word',
                      }}
                      key={item.uuid}
                      value={item.uuid}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ButtonGroup variant="outlined" size="small">
                <FormControl size="small" sx={{ width: '170px' }}>
                  <InputLabel
                    sx={{
                      fontSize: 14,
                      lineHeight: '100%',
                      '&.MuiInputLabel-shrink': { lineHeight: 'inherit' },
                    }}
                  >
                    Перенести выбранные в
                  </InputLabel>
                  <Select
                    size="small"
                    value={selectedGroup}
                    label="Перенести выбранные в"
                    onChange={(e: SelectChangeEvent<string>) => {
                      setSelectedGroup(e.target.value);
                    }}
                    autoWidth={false}
                    sx={{
                      '.MuiInputBase-input': { fontSize: 14, padding: '5px' },
                    }}
                    MenuProps={{
                      sx: { '& .MuiPopover-paper': { width: 600 } },
                    }}
                  >
                    <MenuItem
                      sx={{
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-word',
                      }}
                      value="null"
                    >
                      <em>Без группы</em>
                    </MenuItem>
                    {cardCreativesGroupsList?.map((item) => (
                      <MenuItem
                        sx={{
                          whiteSpace: 'break-spaces',
                          wordBreak: 'break-word',
                        }}
                        key={item.uuid}
                        value={item.uuid}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  size="small"
                  onClick={() => {
                    onMoveCreative();
                  }}
                  disabled={isDisabledMove}
                >
                  Перенести
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        }
      />
      <Box
        paddingX={2}
        paddingY={1}
        gap={1}
        display="flex"
        flexDirection="column"
        height="calc(100% - 80px)"
      >
        <FormCreateFromZip />
        <FormCreate updatePosition={updatePosition} />

        <PendingComponent />

        <FormUpdate updatePosition={updatePosition} />
      </Box>
    </MyMenu>
  );
};

export const CreativesMenuMemo = React.memo(CreativesMenu);

import React, { FC } from 'react';
import {
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import { MenuHeader } from '../../MenuHeader';
import { MenuFixed } from '../../../UI/MenuFixed';
import { setOpenModal } from '../../../GRID/model/board';
import { onSetOpenWorkspaceCreate } from '../../../GRID/model/workspace';

export type Props = {
  anchorEl?: Element | null;
  openMenu: boolean;
  handleClose: () => void;
};
export const MenuCreate: FC<Props> = ({ anchorEl, openMenu, handleClose }) => {
  const onOpenWorkspaceCreate = () => {
    handleClose();
    onSetOpenWorkspaceCreate(true);
  };
  const onOpenDescCreate = () => {
    handleClose();
    setOpenModal(true);
  };
  return (
    <MenuFixed
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuHeader title="Создать" onClose={handleClose} />

      <MenuItem
        onClick={onOpenDescCreate}
        sx={{ whiteSpace: 'pre-wrap', alignItems: 'start' }}
      >
        <ListItemIcon>
          <ViewKanbanIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography color="primary" variant="body1">
            Создать новую доску
          </Typography>

          <Typography fontSize={12}>
            Доска представляет собой совокупность карточек, упорядоченных в
            списках. Используйте её для управления проектом, отслеживания или
            организации чего угодно.
          </Typography>
        </ListItemText>
      </MenuItem>

      <MenuItem
        onClick={onOpenWorkspaceCreate}
        sx={{ whiteSpace: 'pre-wrap', alignItems: 'start' }}
      >
        <ListItemIcon>
          <GroupsIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography color="primary" variant="body1">
            Создать рабочее пространство
          </Typography>

          <Typography fontSize={12}>
            Рабочее пространство представляет собой группу досок и людей. Оно
            поможет организовать работу в компании, внештатную работу, семейные
            дела или отдых с друзьями.
          </Typography>
        </ListItemText>
      </MenuItem>
    </MenuFixed>
  );
};

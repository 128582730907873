import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
  sample,
} from 'effector';
import {
  campaignGet,
  campaignUpdate,
  RCampaignGet,
} from '../../../../../../apiRPC/сampaign';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import { contractGet, RContractGet } from '../../../../../../apiRPC/contracts';
import {
  $formsChangedBudget,
  $formsErrorBudget,
  $formsPendingBudget,
  campaignBudgetGetListFX,
  onSubmitBudgetForms,
  resetFormsBudget,
} from './budget';

export const campaignGetFX = errorHandlerFx(createEffectJWT(campaignGet));
export const campaignGetNoReloadFX = errorHandlerFx(
  createEffectJWT(campaignGet),
);
export const resetCampaign = createEvent();
export const $campaign = createStore<RCampaignGet | null>(null)
  .on(
    [campaignGetFX.doneData, campaignGetNoReloadFX.doneData],
    (_, payload) => payload,
  )
  .reset(resetCampaign);

export const campaignUpdateFX = errorHandlerFx(createEffectJWT(campaignUpdate));
export type Settings = 'Basic' | 'Additional';
export const setSettings = createEvent<Settings>();
export const resetSettings = createEvent();
export const $settings = restore(setSettings, 'Basic').reset(resetSettings);

export const contractGetFX = errorHandlerFx(createEffectJWT(contractGet));
export const resetContract = createEvent();
export const $contract = createStore<RContractGet | null>(null)
  .on(contractGetFX.doneData, (_, payload) => payload)
  .reset(resetContract);

export const iContractGetFX = errorHandlerFx(createEffectJWT(contractGet));
export const resetiContract = createEvent();
export const $iContract = createStore<RContractGet | null>(null)
  .on(iContractGetFX.doneData, (_, payload) => payload)
  .reset(resetiContract);

export const setDeletedInvoice = createEvent<string>();
export const resetDeletedInvoices = createEvent();
export const $deletedInvoices = createStore<string[]>([])
  .on(setDeletedInvoice, (state, payload) => [...state, payload])
  .reset(resetDeletedInvoices);

sample({
  clock: campaignGetFX.doneData,
  filter: (payload) => Boolean(payload),
  target: createEffect((payload: RCampaignGet | null) => {
    if (!payload) return;
    contractGetFX({ uuid: payload.contract });
    if (payload.invoiceContract)
      iContractGetFX({ uuid: payload.invoiceContract });
  }),
});

export const setDisabledBasicForm = createEvent<boolean>();
export const $disabledBasicForm = restore(setDisabledBasicForm, true);

export const setChangedBasicForm = createEvent<boolean>();
export const $changedBasicForm = restore(setChangedBasicForm, true);

export const setIsPromptBasicForm = createEvent<boolean>();
export const $isPromptBasicForm = restore(setIsPromptBasicForm, true);

export const $changedAllForm = combine(
  $changedBasicForm,
  $formsChangedBudget,
  (changedBasicForm, formsChangedBudget) =>
    changedBasicForm || formsChangedBudget,
);
export const $isPromptAllForm = combine(
  $isPromptBasicForm,
  $formsChangedBudget,
  (isPromptBasicForm, formsChangedBudget) =>
    isPromptBasicForm || formsChangedBudget,
);

export const $disabledAllForm = combine(
  $disabledBasicForm,
  $formsErrorBudget,
  $changedAllForm,
  campaignUpdateFX.pending,
  campaignBudgetGetListFX.pending,
  (
    disabledBasicForm,
    formsDisabledBudget,
    changedAllForm,
    campaignUpdateFXPending,
    campaignBudgetGetListFXPending,
  ) =>
    disabledBasicForm ||
    formsDisabledBudget ||
    !changedAllForm ||
    campaignUpdateFXPending ||
    campaignBudgetGetListFXPending,
);

export const $pendingAllForms = combine(
  campaignGetFX.pending,
  campaignGetNoReloadFX.pending,
  campaignUpdateFX.pending,
  $formsPendingBudget,
  (...arg) => arg.reduce((prev, current) => prev || current),
);

export const onResetALlForm = createEvent();
export const onResetBasicForm = createEvent();

forward({
  from: onResetALlForm,
  to: [onResetBasicForm, resetFormsBudget],
});

export const onSubmitALlForm = createEvent();
export const onSubmitBasicForm = createEvent();

forward({
  from: onSubmitALlForm,
  to: [onSubmitBasicForm, onSubmitBudgetForms],
});

export function resetCampaignModel() {
  resetCampaign();
  resetSettings();
  resetiContract();
  resetContract();
  resetDeletedInvoices();
  $disabledBasicForm.reset();
  $isPromptBasicForm.reset();
}

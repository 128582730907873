import React, { FC } from 'react';
import { Box, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import CodeEditor, {
  TextareaCodeEditorProps,
} from '@uiw/react-textarea-code-editor';
import css from './styles.module.scss';

type Props = {
  onKeyEnter?: () => void;
} & TextFieldProps;

const Code = React.forwardRef<HTMLTextAreaElement, TextareaCodeEditorProps>(
  (props, ref) => (
    <Box overflow="auto" height={120} width="100%" fontSize={14}>
      <CodeEditor {...props} ref={ref} />
    </Box>
  ),
);
export const CodeEditorLight: FC<Props> = ({ onKeyEnter, ...props }) => (
  <TextField
    {...props}
    sx={{ height: 170 }}
    InputProps={{
      inputProps: {
        placeholder: '',
        className: css.container,
        onKeyDown: (e) => {
          if (e.key !== 'Enter') return undefined;
          if (e.ctrlKey) return undefined;
          e.stopPropagation();
          e.preventDefault();
          onKeyEnter?.();
          return false;
        },
      },
      inputComponent: Code,
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

import React, { FC, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, TextField, Typography } from '@mui/material';
import TitleIcon from '@mui/icons-material/Title';
import { useUnit } from 'effector-react';
import { updateTitleFX } from '../../../../../model/card/update/title';

const styleBoxGridInput = {
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  alignItems: 'start',
  gap: 1,
};

export type Props = { title: string; uuid: string; columnTitle?: string };
export const Title: FC<Props> = ({ title, uuid, columnTitle }) => {
  const theme = useTheme();
  const [newTitle, setNewTitle] = useState(title);
  const [error, setError] = useState(false);
  const pending = useUnit(updateTitleFX.pending);
  const isSend = useRef(false);

  function onSend(v: string) {
    if (isSend.current) return;
    if (title === v) return;
    const test = v.replaceAll('\n', '');
    if (test.length < 3) {
      setError(true);
      return;
    }
    if (v.length < 3) {
      setError(true);
      return;
    }
    isSend.current = true;
    updateTitleFX({ uuid, fields: { title: v } })
      .then()
      .catch(() => {
        setNewTitle(title);
      });
  }

  return (
    <>
      <Box sx={styleBoxGridInput}>
        <TitleIcon color="action" />

        <TextField
          disabled={pending}
          variant="standard"
          value={newTitle}
          error={error}
          inputProps={{ maxLength: 255 }}
          onChange={(e) => {
            if (error) setError(false);
            isSend.current = false;
            setNewTitle(e.target.value);
          }}
          onBlur={() => onSend(newTitle)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSend(newTitle);
            }
          }}
          multiline
          sx={{
            '& textarea': {
              resize: 'none',
            },
          }}
        />
      </Box>

      <Box ml={4}>
        <Typography
          variant="body2"
          alignItems="top"
          lineHeight="normal"
          color={theme.palette.grey[500]}
        >
          В колонке {columnTitle || '...'}
        </Typography>
      </Box>
    </>
  );
};

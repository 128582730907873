import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../../../components/Header';
import { addressesORD } from '../../../../addresses';
import { SearchFilter } from './Search';
import { MonthFilter } from './MonthFilter';

const CampaignsHeader: FC = () => (
  <Header>
    <Typography variant="h1">Кампании</Typography>
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <SearchFilter />

      <MonthFilter />

      <Button
        component={NavLink}
        to={addressesORD.campaignCreatePath}
        variant="contained"
        sx={{
          marginLeft: '20px',
        }}
      >
        Добавить
      </Button>
    </Box>
  </Header>
);

export default CampaignsHeader;

import {
  combine,
  createEvent,
  createStore,
  forward,
  sample,
  Store,
} from 'effector';
import { delay } from 'patronum/delay';
import { errorHandlerFx } from '../../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../../model/JWT';
import {
  resourcesCreate,
  resourcesDelete,
  resourcesGetList,
  RResourcesGetList,
} from '../../../../../../../apiRPC/creative/resources';
import { $creative, resourcesGetCountFX } from '../../model';
import { resourcesUpdateFX } from '../Update/model';

export const resourcesCreateFX = errorHandlerFx(
  createEffectJWT(resourcesCreate),
);

export const resourcesGetListFX = errorHandlerFx(
  createEffectJWT(resourcesGetList),
);
const resourcesGetListNoReloadFX = errorHandlerFx(
  createEffectJWT(resourcesGetList),
);
const resourcesDeleteFX = errorHandlerFx(createEffectJWT(resourcesDelete));

export const resetResourcesList = createEvent();
export const $resourcesList = createStore<RResourcesGetList['items'] | null>(
  null,
)
  .on(
    [resourcesGetListFX.doneData, resourcesGetListNoReloadFX.doneData],
    (_, payload) => payload.items,
  )
  .reset(resetResourcesList);

export const $fieldAdditional: Store<'loading' | 'Success' | 'IsShop'> =
  combine(
    {
      resourcesGetListPending: resourcesGetListFX.pending,
      data: $creative,
    },
    ({ resourcesGetListPending, data }) => {
      if (resourcesGetListPending) return 'loading';
      if (data?.shop) return 'IsShop';
      return 'Success';
    },
  );

export const setResourceForDelete = createEvent<string>();
export const resetResourceForDelete = createEvent();

export const $resourceForDelete = createStore<Set<string>>(new Set())
  .on(
    setResourceForDelete,
    (state, payload) => new Set([...Array.from(state), payload]),
  )
  .reset(resetResourceForDelete);

export const $resourcesListFilter = combine(
  $resourcesList,
  $resourceForDelete,
  (resourcesList, resourceForDelete) =>
    resourcesList?.filter((item) => !resourceForDelete.has(item.uuid)),
);
export const $isDellAllResources = combine(
  $resourcesList,
  $resourceForDelete,
  (resourcesList, resourceForDelete) => {
    if (!resourcesList?.length) return false;

    return resourcesList?.length === resourceForDelete.size;
  },
);

export const onDeleteResources = createEvent();

sample({
  clock: onDeleteResources,
  source: {
    resourceForDelete: $resourceForDelete,
    resourcesList: $resourcesList,
  },
  filter: ({ resourceForDelete }) => Boolean(resourceForDelete.size),
  fn: ({ resourceForDelete, resourcesList }) => ({
    creative: resourcesList?.[0].creative || '',
    items: Array.from(resourceForDelete),
  }),
  target: resourcesDeleteFX,
});

sample({
  clock: [
    resourcesCreateFX.done,
    resourcesDeleteFX.done,
    resourcesUpdateFX.done,
  ],
  fn: ({ params }) => ({ creative: params.creative }),
  target: [resourcesGetListNoReloadFX, resourcesGetCountFX],
});

forward({
  from: delay({ source: resourcesDeleteFX.finally, timeout: 800 }),
  to: resetResourceForDelete,
});

export const $anyPending = combine(
  [
    resourcesCreateFX.pending,
    resourcesGetListFX.pending,
    resourcesDeleteFX.pending,
    resourcesUpdateFX.pending,
  ],
  (units) => units.includes(true),
);

export function resetResourcesModel() {
  resetResourcesList();
  resetResourceForDelete();
}

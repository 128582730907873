import React, { FC, useRef } from 'react';
import { Fade, MenuItem } from '@mui/material';
import { useUnit } from 'effector-react';
import { MenuFixed } from '../../../../../../UI/MenuFixed';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import { ContainerMarksMenu } from '../../Marks/ContanerMarksMenu';
import { updateMarksForCard } from '../../../../../model/Marks/MarcsForCard';
import { useMarks } from '../../../modals/TaskOverview/Marks/useMarks';
import { MenuMembers } from '../../Members/MenuMembers';
import { useMembers } from '../../../modals/TaskOverview/Members/useMembers';
import { updateStatusFX } from '../../../../../model/card/update/status';
import { navigateForEffect } from '../../../../../../model/routing';

interface Props {
  anchorEl?: Element | null;
  openMenu: boolean;
  handleClose: () => void;
  board: string;
  uuid: string;
}
export const EditMenu: FC<Props> = ({
  anchorEl,
  openMenu,
  handleClose,
  board,
  uuid,
}) => {
  const onOpenCard = () => {
    navigateForEffect(`/c/${uuid}/`);
    handleClose();
  };
  const horizontal =
    window.innerWidth - (anchorEl?.getBoundingClientRect().right ?? 0) < 380
      ? 'left'
      : 'right';

  const anchorMarksMenu = useRef<HTMLLIElement>(null);
  const {
    onOpenMenuMarks,
    openMenuMarks,
    setOpenMenuMarks,
    ListUuidMarkForCard,
    onClickMark,
  } = useMarks({ isOpenDialog: openMenu, board, card: uuid });

  const anchorMembersMenu = useRef<HTMLLIElement>(null);

  const {
    onOpenMenuMembers,
    openMenuMembers,
    accounts,
    ListUuidMembersForCard,
    onClickMember,
    onCloseMenuMembers,
  } = useMembers({ isOpenDialog: openMenu, card: uuid });

  const pendingArchive = useUnit(updateStatusFX.pending);

  const onSetArchive = () => {
    updateStatusFX({ uuid, fields: { status: 'CLOSED' } });
    handleClose();
  };
  return (
    <>
      <MenuFixed
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal, vertical: 'top' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontal === 'left' ? 'right' : 'left',
        }}
      >
        <MenuHeader title="Действия" onClose={handleClose} />
        <MenuItem onClick={onOpenCard}>Открыть карточку</MenuItem>
        <MenuItem ref={anchorMarksMenu} onClick={onOpenMenuMarks}>
          Изменить метки
        </MenuItem>
        <MenuItem ref={anchorMembersMenu} onClick={onOpenMenuMembers}>
          Изменить участников
        </MenuItem>
        <MenuItem disabled={pendingArchive} onClick={onSetArchive}>
          Архивировать
        </MenuItem>
      </MenuFixed>
      <ContainerMarksMenu
        anchorEl={anchorMarksMenu.current}
        board={board}
        openMenuMarks={openMenuMarks}
        setOpenMenuMarks={setOpenMenuMarks}
        listChecked={ListUuidMarkForCard}
        onClickMark={onClickMark}
        handleClose={() => updateMarksForCard()}
      />
      <MenuMembers
        openMenu={openMenuMembers}
        anchorEl={anchorMembersMenu?.current}
        handleClose={onCloseMenuMembers}
        accounts={accounts}
        listChecked={ListUuidMembersForCard}
        onClickMember={onClickMember}
      />
    </>
  );
};

import { Box, IconButton, TextField } from '@mui/material';
import React, { FC } from 'react';
import { FiAlertCircle, FiTrash } from 'react-icons/fi';
import CampaignAccordion from '../../../../../components/CampaignAccordion';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';
import CalendarField from '../../../../../components/CalendarField';
import { SelectMS } from '../../../../../UI/form/SelectMS';
import { UseAdditionalItem } from './hooks/UseAdditionalItem';
import { InvoiceParam } from '../../../../../apiRPC/сampaign/campaignInvoiceParams';

type TSetItemProps = {
  title: string;
  data?: InvoiceParam;
  id?: string;
  variant?: number;
};

const SetItem: FC<TSetItemProps> = ({ title, data, id, variant }) => {
  const {
    onDelete,
    formik,
    handleChange,
    typeOptions,
    refNumberOfUnitsPlanMask,
    valNumberOfUnitsPlanMask,
    refNumberOfUnitsMask,
    valNumberOfUnitsMask,
    refAmountPerUnitMask,
    valAmountPerUnitMask,
    refTotalActualShowsMask,
    valTotalActualShowsMask,
    refTotalPlannedShowsMask,
    valTotalPlannedShowsMask,
    refTotalAmountMask,
    valTotalAmountMask,
    refTotalExportAmountMask,
    valTotalExportAmountMask,
  } = UseAdditionalItem({ data, id, variant });
  const error = !formik.isValid;
  const errorText = '';
  return (
    <CampaignAccordion
      defaultExpanded={Boolean(!data)}
      title={title}
      icon={
        <BootstrapTooltip title="Удалить" placement="top">
          <IconButton onClick={onDelete}>
            <FiTrash color="#D32F2F" size={20} />
          </IconButton>
        </BootstrapTooltip>
      }
      error={
        error && (
          <BootstrapTooltip title={errorText} placement="top">
            <Box
              sx={{
                marginLeft: '14px',
                svg: {
                  verticalAlign: 'middle',
                },
              }}
            >
              <FiAlertCircle color="#D32F2F" size={20} />
            </Box>
          </BootstrapTooltip>
        )
      }
    >
      <Box
        sx={{
          padding: '12px 30px 30px 30px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: '30px',
          }}
        >
          <CalendarField
            required
            label="Месяц"
            views={['month', 'year']}
            inputFormat="MM.yyyy"
            placeholder="ММ.ГГГГ"
            disableMaskedInput={false}
            value={formik.values.month}
            onChange={(v) => {
              formik.setFieldValue('month', v).then(() => {
                formik.validateField('month');
              });
            }}
            error={formik.errors.month}
            helperText={formik.errors.month}
            onClear={() =>
              formik.setFieldValue('month', null).then(() => {
                formik.validateField('month');
              })
            }
          />

          <SelectMS
            error={Boolean(formik.errors.type)}
            required
            label="Тип расчета"
            value={formik.values.type}
            name="type"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            options={typeOptions}
            helperText={formik.errors.type}
          />

          <TextField
            label="Кол-во единиц, план"
            inputRef={refNumberOfUnitsPlanMask}
            variant="standard"
            value={valNumberOfUnitsPlanMask}
            name="numberOfUnitsPlan"
            autoComplete="none"
            error={Boolean(formik.errors.numberOfUnitsPlan)}
            helperText={formik.errors.numberOfUnitsPlan}
          />

          <TextField
            label="Кол-во единиц."
            inputRef={refNumberOfUnitsMask}
            variant="standard"
            value={valNumberOfUnitsMask}
            name="numberOfUnits"
            autoComplete="none"
            error={Boolean(formik.errors.numberOfUnits)}
            helperText={formik.errors.numberOfUnits}
          />

          <TextField
            label="Цена за единицу, руб"
            inputRef={refAmountPerUnitMask}
            variant="standard"
            value={valAmountPerUnitMask}
            name="amountPerUnit"
            autoComplete="none"
            error={Boolean(formik.errors.amountPerUnit)}
            helperText={formik.errors.amountPerUnit}
          />
          <TextField
            label="Итоговое кол-во показов, факт"
            variant="standard"
            inputRef={refTotalActualShowsMask}
            value={valTotalActualShowsMask}
            name="totalActualShows"
            autoComplete="none"
            error={Boolean(formik.errors.totalActualShows)}
            helperText={formik.errors.totalActualShows}
          />
          <TextField
            label="Итоговое кол-во показов, план"
            variant="standard"
            inputRef={refTotalPlannedShowsMask}
            value={valTotalPlannedShowsMask}
            name="totalPlannedShows"
            autoComplete="none"
            error={Boolean(formik.errors.totalPlannedShows)}
            helperText={formik.errors.totalPlannedShows}
          />
          <TextField
            label="Итоговая сумма, руб"
            variant="standard"
            value={valTotalAmountMask}
            inputRef={refTotalAmountMask}
            name="totalAmount"
            autoComplete="none"
            error={Boolean(formik.errors.totalAmount)}
            helperText={formik.errors.totalAmount}
          />
          <TextField
            label="Итоговая сумма выгрузки, руб"
            variant="standard"
            inputRef={refTotalExportAmountMask}
            value={valTotalExportAmountMask}
            name="totalExportAmount"
            autoComplete="none"
            error={Boolean(formik.errors.totalExportAmount)}
            helperText={formik.errors.totalExportAmount}
          />
        </Box>
      </Box>
    </CampaignAccordion>
  );
};

export default SetItem;

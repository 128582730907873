import React, { FC } from 'react';
import { useFormik } from 'formik';

import { Box, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import Modal from '../../../../../../../../components/Modal';
import { LabelForContracts } from '../../../../../../Campaigns/UI/LabelForContracts';
import { resetModalType } from '../../model/switcherForModal';
import { Resource } from '../../../../../../../../apiRPC/creative/resources';
import { resourcesCreateFX } from '../../model';
import { isErrorProps } from '../../../../../../../../apiRPC/request';

type Form = {
  textData: Resource['textData'];
};

const initialValues: Form = {
  textData: '',
};

export const TextModal: FC = () => {
  const { uuid } = useParams();
  const pending = useUnit(resourcesCreateFX.pending);
  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object().shape({
      textData: Yup.string().required('Обязательное для заполнения поле'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (!uuid) return;

      try {
        await resourcesCreateFX({
          creative: uuid,
          items: [
            {
              type: 'text',
              textData: values.textData,
            },
          ],
        });
        resetModalType();
      } catch (e) {
        console.error({ e });
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const handleChange: typeof formik['handleChange'] = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value, true);
  };

  return (
    <Modal
      open
      handleClose={resetModalType}
      onConfirm={formik.submitForm}
      title="Добавление  текста"
      outlinedText="Отмена"
      containedText="Заполнить"
      disabledContained={!formik.isValid || pending}
      disabledOutlined={pending}
    >
      <Box display="grid" gridTemplateColumns="1fr">
        <TextField
          label={
            <LabelForContracts
              text="Текстовые данные"
              title="Под текстом понимается: заголовок, подзаголовок, описание УТП и другие составляющие креатива"
            />
          }
          placeholder="Текстовые данные"
          multiline
          rows={10}
          inputProps={{ maxLength: 1000 }}
          name="textData"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.textData)}
          helperText={formik.errors.textData}
          value={formik.values.textData}
        />
      </Box>
    </Modal>
  );
};

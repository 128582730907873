import React, { FC } from 'react';
import { Box } from '@mui/material';

import { UpdateHeader } from '../UpdateHeader';
import { Statuses } from './Statuses';
import { Form } from './Form';

const Basic: FC = () => (
  <Box>
    <UpdateHeader />
    <Box display="flex" flexDirection="column">
      <Statuses />
    </Box>

    <Form />
  </Box>
);

export default Basic;

import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { ReactSVG } from 'react-svg';
import errorSvg from '../../assets/icons/errors/no-access.svg';

const NoAccessError = () => (
  <Container maxWidth={false} sx={{ width: 'fit-content' }}>
    <Box width="100%" display="flex" flexDirection="column" height="100vh">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <ReactSVG src={errorSvg} />
        <Typography
          sx={{
            marginTop: '40px',
            fontWeight: '700',
            fontSize: '20px',
          }}
        >
          Нет доступа, обратитесь к администратору
        </Typography>
      </Box>
    </Box>
  </Container>
);

export default NoAccessError;

import { createEvent, restore, sample } from 'effector';
import { format, isValid } from 'date-fns';
import { debounce } from 'patronum/debounce';
import axios, { Canceler } from 'axios';
import {
  $lastFilterCampaign,
  campaignGetListFX,
  limitCampaign,
} from '../index';

export const minDate = new Date('2020-01-01');
export const maxDate = new Date('2030-01-01');

export const setMonth = createEvent<null | Date>();
export const resetMonth = createEvent();
export const $month = restore(setMonth, null).reset(resetMonth);

const cancelTokens: Canceler[] = [];
sample({
  clock: debounce({ source: $month, timeout: 500 }),
  source: {
    lastFilter: $lastFilterCampaign,
  },
  filter: ({ lastFilter }, payload) => {
    if (payload === null && lastFilter?.filter.month) return true;
    if (payload === null) return false;
    if (!isValid(payload)) return false;
    if (+payload > +maxDate) return false;
    if (+payload < +minDate) return false;
    return true;
  },
  fn: ({ lastFilter }, payload) => {
    cancelTokens.forEach((item) => item());
    const source = axios.CancelToken.source();
    cancelTokens.push(source.cancel);
    const oldFilter = { ...(lastFilter?.filter || {}) };
    if (!payload) {
      delete oldFilter.month;
    } else {
      oldFilter.month = format(payload, 'yyyy-MM-01');
    }
    const filter = {
      ...oldFilter,
      limit: limitCampaign,
      offset: 0,
    };
    return {
      params: { filter },
    };
  },
  target: campaignGetListFX,
});

import axios, { Canceler, CancelToken } from 'axios';
import { createEvent, createStore, sample } from 'effector';
import { debounce } from 'patronum/debounce';
import { errorHandlerFx } from '../errorHandler';
import { createEffectJWT } from '../JWT';

type Filter = {
  limit?: number;
  offset?: number;
  search?: string;
};

type Parameters = {
  filter?: Filter;
};

type Done<Item> = {
  total: number;
  rows: Item[];
};
type Options<P extends Parameters, Item, D extends Done<Item>> = {
  additionFilter?: P['filter'];
  limit?: number;
  handler: (params: P, cancelToken?: CancelToken) => D | Promise<D>;
};
export function factoryListFilter<
  P extends Parameters,
  Item,
  D extends Done<Item>,
>({ additionFilter, limit = 30, handler }: Options<P, Item, D>) {
  type FasP = {
    params: P;
    cancelToken?: CancelToken;
  };

  const getListCancel = (p: FasP) => handler(p?.params, p?.cancelToken);

  const getListFX = errorHandlerFx(createEffectJWT(getListCancel));

  const getListForScrollFX = errorHandlerFx(createEffectJWT(handler));

  const onGetList = createEvent();
  const onResetList = createEvent();
  const $list = createStore<D['rows']>([])
    .on(getListFX.doneData, (_, payload) => payload.rows)
    .on(getListForScrollFX.doneData, (state, payload) => [
      ...state,
      ...payload.rows,
    ])
    .reset(onResetList);

  const $totalRows = createStore<number>(0)
    .on(getListFX.doneData, (_, payload) => payload.total)
    .on(getListForScrollFX.doneData, (_, payload) => payload.total);

  const $lastFilter = createStore<P | null>(null)
    .on(getListFX.done, (_, { params }) => params?.params ?? null)
    .reset(onResetList);

  sample({
    clock: onGetList,
    source: {
      lastFilter: $lastFilter,
    },
    fn: ({ lastFilter }) => {
      const oldFilter = { ...(lastFilter?.filter || {}) };
      const filter = {
        filter: {
          ...oldFilter,
          limit,
          offset: 0,
          ...additionFilter,
        },
      } as P;
      const result: FasP = { params: filter };
      return result;
    },
    target: getListFX,
  });

  const searchList = createEvent<string>();

  const cancelTokenSearchFilter: Canceler[] = [];

  sample({
    clock: debounce({ source: searchList, timeout: 300 }),
    source: {
      lastFilter: $lastFilter,
    },
    fn: ({ lastFilter }, payload) => {
      cancelTokenSearchFilter.forEach((item) => item());
      const source = axios.CancelToken.source();
      cancelTokenSearchFilter.push(source.cancel);
      const oldFilter = { ...(lastFilter?.filter || {}) };

      const filter = {
        filter: {
          ...oldFilter,
          search: payload,
          limit,
          offset: 0,
          ...additionFilter,
        },
      } as P;

      const result: FasP = { params: filter, cancelToken: source.token };
      return result;
    },
    target: getListFX,
  });

  const onLoadLists = createEvent();

  sample({
    clock: onLoadLists,
    source: {
      lastFilter: $lastFilter,
      list: $list,
      totalRows: $totalRows,
    },
    filter: ({ list, totalRows }) =>
      Boolean(list.length) && totalRows > list.length,
    fn: ({ list, lastFilter }) => {
      const filter = {
        filter: {
          ...(lastFilter?.filter || {}),
          limit,
          offset: list.length,
          ...additionFilter,
        },
      } as P;

      return filter;
    },
    target: getListForScrollFX,
  });

  function resetCatalogs(): void {
    onResetList();
    onGetList();
  }

  return {
    // еффект загрузки списка
    getListFX,
    getListForScrollFX,
    // запросит сроки за ново
    onGetList,
    $list,
    searchList,
    // применяется для добовления строк при скролле
    onLoadLists,
    // сбрасывает каталог
    resetCatalogs,
    // значение последнего фильтра
    $lastFilter,
  };
}

import request from '../request';

export type PCreate = {
  // Минимальная длина 3 символа, максимальная 255
  title: string;
  workspace: string;
};
export type RCreate = { uuid: string };

export const createBoard = request<RCreate, PCreate>({
  method: 'board.create',
});

export type Status = 'ACTIVE' | 'CLOSED';
export type Fields = {
  title: string;
  status: Status;
  sort: number;
};
export type PUpdate = {
  uuid: string;
  fields: Partial<Fields>;
};
export type RUpdate = boolean;
export const updateBoard = request<RUpdate, PUpdate>({
  method: 'board.update',
});

export type PGetBoard = { uuid: string };
export type Board = {
  uuid: string;
  title: string;
  sort: number;
  status: Status;
  owner: string;
  createdAt: number;
  workspace: string;
};
export const getBoard = request<Board, PGetBoard>({
  method: 'board.get',
});

export type Filter = {
  limit: number;
  offset: number;
  workspace: string;
};
export type PGetListBoard = {
  filter: Filter;
};

export type RGetListBoard = {
  total: number;
  rows: Board[];
};
export const getListBoard = request<RGetListBoard, PGetListBoard>({
  method: 'board.getList',
});

export type PGetAccounts = {
  uuid: string;
};
export type Account = {
  uuid: string;
  email: string;
};
export type RGetAccounts = Account[];

export const getAccounts = request<RGetAccounts, PGetAccounts>({
  method: 'board.getAccounts',
});

import { styled, SxProps, Theme } from '@mui/material/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

type YearMonthPickerSxProps = {
  sx?: SxProps<Theme> | undefined;
};

export const YearMonthPicker = styled(DatePicker<Date | null>)(() => ({
    ' .MuiFormLabel-root': {
      transform: 'translateY(-50%)',
      top: '50%',
    },
    fieldset: {
      display: 'none',
    },
    ' .MuiInputBase-root': {
      paddingRight: '2px',
      fontWeight: '500',
      fontSize: '14px',
      color: '#171717',
    },
    ' .MuiFormHelperText-root': {
      position: 'absolute',
      left: '-14px',
      bottom: '-20px',
    },
    ' .MuiOutlinedInput-root': {
      input: {
        padding: '6px 4px',
      },
    },
    ' .MuiInputBase-input': {
      textTransform: 'capitalize',
    },
    ' .MuiInputAdornment-root': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    ' .MuiButtonBase-root': {
      marginRight: 0,
    },
    svg: {
      color: '#4B61BF',
    },
  }));

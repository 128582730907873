import request from '../../request';

type Filter = {
  limit: number;
  offset: number;
};

export type Action = 'CREATE' | 'UPDATE' | 'DELETE';
export type HistoryRow = {
  actionObject: string;
  actionText: string;
  action: Action;
  actionTimestamp: number;
  cardId: string;
  email: string;
};
export type PCardHistoryGetList = {
  card: string;
  filter: Filter;
};
export type RCardHistoryGetList = {
  total: number;
  rows: HistoryRow[];
};
export const cardHistoryGetList = request<
  RCardHistoryGetList,
  PCardHistoryGetList
>({
  method: 'cardHistory.getList',
});

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN_EXPIRATION_TIME = 'accessTokenExpirationTime';

export const setAccessToken = (v: string) =>
  window.localStorage.setItem(ACCESS_TOKEN, v);
export const setRefreshToken = (v: string) =>
  window.localStorage.setItem(REFRESH_TOKEN, v);
export const setAccessTokenExpirationTime = (v: number) =>
  window.localStorage.setItem(ACCESS_TOKEN_EXPIRATION_TIME, v.toString(10));

export type TJWTInfo = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpirationTime: number;
};
export const setJWTInfo = (jwtInfo: TJWTInfo) => {
  const { accessToken, refreshToken, accessTokenExpirationTime } = jwtInfo;
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setAccessTokenExpirationTime(accessTokenExpirationTime);
};

export const removeJWTInfo = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_TIME);
};

export const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN);
export const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN);
export const getAccessTokenExpirationTime = () => {
  const v = Number(window.localStorage.getItem(ACCESS_TOKEN_EXPIRATION_TIME));
  return Number.isNaN(v) ? null : v;
};

export const hasJWTInfo = (): boolean => {
  if (!getAccessToken()) return false;
  if (!getRefreshToken()) return false;
  if (!getAccessTokenExpirationTime()) return false;
  return true;
};

export const isActualJWTInfo = (): boolean => {
  const accessTokenExpirationTime = getAccessTokenExpirationTime();
  if (!accessTokenExpirationTime) return false;
  return Date.now() < accessTokenExpirationTime * 1000 - 1000 * 60 * 5;
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { AccordionProps } from '@mui/material/Accordion/Accordion';
import React, { FC, ReactNode } from 'react';
import { FiChevronDown } from 'react-icons/fi';

interface TCampaignAccordionProps extends Omit<AccordionProps, 'children'> {
  title: string;
  children: ReactNode;
  icon?: ReactNode;
  error?: ReactNode;
  hideIcon?: boolean;
}

const CampaignAccordion: FC<TCampaignAccordionProps> = ({
  title,
  children,
  icon,
  error,
  hideIcon,
  ...accordionProps
}) => (
  <Accordion disableGutters {...accordionProps}>
    <AccordionSummary
      expandIcon={
        <>
          {icon}
          {hideIcon ? null : (
            <FiChevronDown color="#000000" size={26} className="arrow" />
          )}
        </>
      }
    >
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '175%',
          color: '#171717',
        }}
      >
        {title}
      </Typography>
      {error}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export default CampaignAccordion;

import request from '../request';

export type PSign = {
  email: string;
  // Минимальная длина 3 символа, максимальная 255
  password: string;
};

export type RSignIn = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpirationTime: number;
};

/** Авторизация с использованием учетных данных. */
export const signIn = request<RSignIn, PSign>({
  method: 'account.signIn',
  token: false,
});

/** Регистрация новой учетной записи */
export const signUp = request<boolean, PSign>({
  method: 'account.signUp',
  token: false,
});

export type RRefreshToken = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpirationTime: number;
  /**
   * `RRefreshToken` is an object with three properties: `accessToken`, `refreshToken`, and `accessTokenExpirationTime`.
   *
   * The first two properties are strings, and the third is a number.
   * @property {string} accessToken - The access token that will be used to authenticate the user.
   * @property {string} refreshToken - The refresh token that was returned from the server.
   * @property {number} accessTokenExpirationTime - The time at which the access token will expire.
   */
};
export type PRefreshToken = {
  // - Токен обновления
  token: string;
};

/** Обновление токенов / продление сессии */
export const refreshToken = request<RRefreshToken, PRefreshToken>({
  method: 'account.refreshToken',
  token: false,
});

type Filter = {
  // Ограничение кол-ва записей в ответе
  limit?: number;
  // Смещение
  offset?: number;
  // Статус
  status?: string;
  uuid?: string | string[];
};

export type Row = {
  uuid: string;
  email: string;
  role: string;
  status: string;
  createdAt: number;
};

export type RGetList = {
  total: number;
  rows: Row[];
};
export type PGetList = {
  filter: Filter;
};

/** Получить список аккаунтов */
export const getList = request<RGetList, PGetList>({
  method: 'account.getList',
});

export type Role = 'USER' | 'MANAGER' | 'ADMIN' | 'SUPERADMIN';
export type Status = 'NOT_CONFIRMED' | 'ACTIVE' | 'INACTIVE' | 'BLOCKED';
export type PGetAccount = { uuid?: string };
export type RGetAccount = {
  uuid: string;
  email: string;
  role: Role;
  status: Status;
  createdAt: number;
};

/** Получить учетную запись */
export const getAccount = request<RGetAccount, PGetAccount>({
  method: 'account.get',
});

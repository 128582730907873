import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { variant } from '@effector/reflect';
import LoadingUpdate from './components/LoadingUpdate';
import { restContract } from '../../ContractsList/model';
import {
  ordGetCatalogFX,
  organizationGetCatalogFX,
} from '../../model/catalogs';
import { resetFilters } from '../../ContractsList/model/filtres';
import { $fieldFormUpdate, contractGetFX, resetStoreContract } from './model';
import { UpdateForm } from './UpdateForm';

const ComponentUpdate = variant({
  source: $fieldFormUpdate,
  cases: {
    Success: UpdateForm,
    loading: LoadingUpdate,
  },
  default: LoadingUpdate,
  hooks: {
    mounted: () => {
      organizationGetCatalogFX(undefined);
      ordGetCatalogFX(undefined);
    },
    unmounted: () => {
      restContract();
      resetFilters();
    },
  },
});

export const Update: FC = () => {
  const { uuid } = useParams();
  useEffect(() => {
    if (!uuid) return undefined;
    contractGetFX({ uuid });
    return resetStoreContract;
  }, [uuid]);

  return <ComponentUpdate />;
};

import { createEvent, createStore } from 'effector';

export const onSetCreateIds = createEvent<string>();
export const onDeleteIds = createEvent<string>();
export const onResetIds = createEvent();
export const $createIds = createStore<string[]>([])
  .on(onSetCreateIds, (state, payload) => [payload, ...state])
  .on(onDeleteIds, (state, payload) => [
    ...state.filter((item) => item !== payload),
  ])
  .reset(onResetIds);

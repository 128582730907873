import request from '../request';
import { ColorsMark } from '../../GRID/model/Marks';

export type Fields = {
  // Описание. Максимальная длина 1024
  description?: string;
  marks?: { add?: string[] };
  members?: { add?: string[] };
  sort?: number;
};
export type PCreate = {
  title: string;
  column: string;
  fields?: Fields;
};
export type RCreate = {
  uuid: string;
};
export type UpdateDates = {
  dateStart?: string | null;
  dateEnd?: string | null;
  completed?: boolean;
};
export type Dates = {
  dateStart: number | null;
  dateEnd: number | null;
  completed: boolean;
};

export const cardCreate = request<RCreate, PCreate>({
  method: 'card.create',
});

export type Status = 'ACTIVE' | 'CLOSED';
export type UpdateItem = { add?: string[]; delete?: string[] };
export type FieldsUpdate = {
  title?: string;
  description?: string;
  status?: Status;
  // Сортировка. Значение от 0 до 65535
  sort?: number;
  marks?: UpdateItem;
  members?: UpdateItem;
  attachments?: UpdateItem;
  dates?: UpdateDates;
};
export type PUpdate = {
  uuid: string;
  fields: FieldsUpdate;
};

export type Mark = {
  uuid: string;
  title: string;
  color: ColorsMark;
  board?: string;
};
export type Member = {
  uuid: string;
  email: string;
};

export type Attachments = {
  uuid: string;
  name: string;
  size: number;
  ext: string;
  type: 'image' | 'video' | 'link' | 'other';
  color: string;
  width: number;
  height: number;
  scaled: boolean;
  createdAt: number;
  updatedAt: number;
  url: string;
  downloadUrl: string;
  comment: string | null;
  deleted: boolean;
  account: string | null;
};

export type RCardGet = {
  uuid: string;
  code?: string;
  title: string;
  description: string;
  sort: number;
  status: Status;
  board: string;
  column: string;
  owner: string;
  createdAt: number;
  marks: Mark[];
  members: Member[];
  attachments?: Attachments[];
  dates?: Dates | null;
  countComments: number;
  countAttachments: number;
  countCreatives: number;
};

export type RUpdate = RCardGet;

export const cardUpdate = request<RUpdate, PUpdate>({
  method: 'card.update',
});

export type PCardGet = { uuid: string };
export const cardGet = request<RCardGet, PCardGet>({
  method: 'card.get',
});

export type Filter = {
  limit?: number;
  offset?: number;
  board?: string;
  column?: string;
  status?: Status;
};
export type PCardGetList = { filter: Filter };
export type RCardGetList = { total: number; rows: RCardGet[] };

export const cardGetList = request<RCardGetList, PCardGetList>({
  method: 'card.getList',
});

export type PCardSearch = {
  text: string;
  limit?: number;
  offset?: number;
};
export type CardSearchItem = {
  uuid: string;
  title: string;
  board: string;
  column: string;
  boardTitle: string;
  columnTitle: string;
};
export type RCardSearch = { total: number; rows: CardSearchItem[] };
export const cardSearch = request<RCardSearch, PCardSearch>({
  method: 'card.search',
});

export type PCardMove = { uuid: string; column: string };
export type RCardMove = boolean;
export const cardMove = request<RCardMove, PCardMove>({
  method: 'card.move',
});

export type PCardDelete = { uuid: string };
export type RCardDelete = boolean;
export const cardDelete = request<RCardDelete, PCardDelete>({
  method: 'card.delete',
});
export type RCardPosition = boolean;
export type Items = { uuid: string; sort: number };
export type PCardPosition = { items: Items[] };
export const cardSetPosition = request<RCardPosition, PCardPosition>({
  method: 'card.setPosition',
});

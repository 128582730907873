import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $accountsSort } from '../../../../../model/card/accounts';
import {
  $listMembersForCard,
  $ListUuidMembersForCard,
  resetMembersUuid,
  setMembersUuidForAdd,
  setMembersUuidForDel,
  updateMembersForCard,
} from '../../../../../model/card/accounts/membersForCard';
import { Account } from '../../../../../../apiRPC/board';
import { cardGetFX } from '../../../../../model/card';

type Parameters = {
  isOpenDialog: boolean;
  card?: string;
};

const onClickMember = (member: Account, isChecked: boolean) => {
  if (isChecked) {
    setMembersUuidForDel(member.uuid);
    return;
  }
  setMembersUuidForAdd(member.uuid);
};

export function useMembers({ isOpenDialog, card }: Parameters) {
  const [openMenuMembers, setOpenMenuMembers] = useState(false);

  const accounts = useUnit($accountsSort);

  const ListUuidMembersForCard = useUnit($ListUuidMembersForCard);
  const listMembersForCard = useUnit($listMembersForCard);
  const showMembers = listMembersForCard.length > 0;

  useEffect(() => {
    if (!isOpenDialog) {
      resetMembersUuid();
    }
  }, [isOpenDialog]);

  useEffect(() => {
    if (!openMenuMembers) return;
    if (!card) return;
    cardGetFX({ uuid: card });
  }, [openMenuMembers, card]);
  const onOpenMenuMembers = () => setOpenMenuMembers(true);

  const onCloseMenuMembers = () => {
    setOpenMenuMembers(false);
    updateMembersForCard();
  };
  return {
    showMembers,
    listMembersForCard,
    onOpenMenuMembers,
    openMenuMembers,
    accounts,
    ListUuidMembersForCard,
    onClickMember,
    onCloseMenuMembers,
  };
}

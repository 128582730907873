import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate, Status } from '../../../../apiRPC/card';

export type CardUpdateStatus = {
  uuid: string;
  fields: { status: Status };
};

export const updateStatusFX = errorHandlerFx(
  createEffectJWT<CardUpdateStatus, RUpdate, Error>(cardUpdate),
);

import { createEvent, sample } from 'effector';
import { SortCampaign, Status } from '../../../../../../apiRPC/сampaign';
import {
  $lastFilterCampaign,
  campaignGetListFX,
  limitCampaign,
} from '../index';

export type StatusTabs = Status | 'ALL';
export const onSetStatus = createEvent<StatusTabs>();

sample({
  clock: onSetStatus,
  source: {
    lastFilter: $lastFilterCampaign,
  },
  fn: ({ lastFilter }, payload) => {
    const oldFilter = { ...(lastFilter?.filter || {}) };

    if (payload === 'ALL') {
      delete oldFilter.status;
    } else {
      oldFilter.status = payload;
    }

    const sortOld = oldFilter.sort || [['dateStart', 'desc']];

    const sort: SortCampaign = sortOld;

    const filter = {
      ...oldFilter,
      sort,
      limit: limitCampaign,
      offset: 0,
    };
    return {
      params: { filter },
    };
  },

  target: campaignGetListFX,
});

import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { FiAlertCircle } from 'react-icons/fi';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';
import css from '../../Campaign/styles.module.scss';

type LabelForContractsP = {
  text: string;
  required?: boolean;
  title?: string;
};

export const LabelForContracts: FC<LabelForContractsP> = ({
  text,
  required,
  title,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={0.2}
      alignItems="center"
      sx={{
        pointerEvents: 'all',
      }}
    >
      <span>{text}</span>
      {required && (
        <Box component="span" color={theme.palette.error.main}>
          *
        </Box>
      )}
      <BootstrapTooltip title={title} placement="top">
        <Box className={css.tooltip} ml={1}>
          <FiAlertCircle color={theme.palette.primary.main} />
        </Box>
      </BootstrapTooltip>
    </Box>
  );
};

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { AiOutlineUnorderedList, AiOutlineAlignLeft } from 'react-icons/ai';
import { BsBox, BsCode } from 'react-icons/bs';

import { FiArchive } from 'react-icons/fi';
import { setModalType } from '../model/switcherForModal';
import { setItemsForm } from '../Form/model';

const PaperProps = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 0,
  width: 10,
  height: 10,
  bgcolor: 'background.paper',
  transform: 'translateY(-50%) translateX(-50%) rotate(45deg)',
  zIndex: 0,
};

interface IDropDownProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

export const MenuAddResources: FC<IDropDownProps> = ({
  anchorEl,
  handleClose,
}) => (
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '&:before': {
          left: '50%',
          ...PaperProps,
        },
      },
    }}
  >
    <MenuItem
      onClick={() => {
        setItemsForm([
          { type: 'resource', description: '', mediaExampleUrl: '' },
        ]);
        handleClose();
      }}
    >
      <ListItemIcon>
        <BsBox size={24} />
      </ListItemIcon>
      <ListItemText>Добавить ресурс</ListItemText>
    </MenuItem>

    <MenuItem
      onClick={() => {
        setItemsForm([{ type: 'text', textData: '' }]);
        handleClose();
      }}
    >
      <ListItemIcon>
        <AiOutlineAlignLeft size={24} />
      </ListItemIcon>
      <ListItemText>Добавить текст</ListItemText>
    </MenuItem>

    <MenuItem
      onClick={() => {
        setModalType('list');
        handleClose();
      }}
    >
      <ListItemIcon>
        <AiOutlineUnorderedList size={24} />
      </ListItemIcon>
      <ListItemText>Добавить списком</ListItemText>
    </MenuItem>

    <MenuItem onClick={() => setModalType('HTML')} disabled>
      <ListItemIcon>
        <BsCode size={24} />
      </ListItemIcon>
      <ListItemText>Загрузить из HTML</ListItemText>
    </MenuItem>

    <MenuItem onClick={() => setModalType('archive')} disabled>
      <ListItemIcon>
        <FiArchive size={24} />
      </ListItemIcon>
      <ListItemText>Загрузить архив</ListItemText>
    </MenuItem>
  </Menu>
);

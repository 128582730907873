export const errorsMessage: Record<number, string> = {
  0: '	Произошла неизвестная внутренняя ошибка	',
  1: '	Произошла неизвестная внутренняя ошибка	',
  90: '	Ошибка: Не найден элемент	',
  99: '	Ошибка: Один из необходимых параметров неверный или не был передан	',
  100: '	Неверный токен	',
  101: '	Время жизни токена истекло	',
  120: '	Пользователь с таким email заблокирован. Обратитесь к администратору	',
  121: '	Пользователь с таким email не активен. Обратитесь к администратору	',
  122: '	Пользователь с таким email не подтвержден	',
  125: '	Неверный email или пароль	',
  126: '	Неверный email или пароль	',
  127: '	Пользователь с таким email уже существует	',
  190: '	Ошибка: Нет доступа	',
  225: '	Доска не найдена	',
  325: '	Колонка не найдена	',
  425: '	Карточка не найдена	',
  525: '	Кампания не найдена	',
  530: '	Указанный идентификатор контракта уже используется	',
  625: '	Договор не найден	',
  725: '	Креатив не найден	',
  726: '	Обновление креатива заблокировано	',
  825: '	Акт не найден	',
  925: '	Метка не найдена	',
  927: '	Метка уже существует	',
  1025: '	Контент не найден	',
  1130: '	Ошибка создания hash для медиаконтента	',
  1131: '	Ошибка получения ресурсов из HTML	',
  1125: '	Медиаконтент креатива не найден	',
  1199: '	Неверные параметры медиаконтента	',
  1225: '	Организация не найдена	',
  1228: '	Организация с указанным ИНН уже существует	',
  3000: '	Внутренняя ошибка загрузки	',
  3001: '	Неверный токен загрузки	',
  3002: '	Устаревший токен загрузки	',
  3011: '	Неверное расширение файла	',
  3012: '	Неверный mime-type файла	',
  3013: '	Неверный размер	',
  3200: 'Ошибка ORD	',

  3030: 'Слишком длинное название файла по ссылке	',

  1325: 'Комментарий не найден',
  1425: 'Площадка не найдена',
  1525: 'Детализация не найдена',
  1625: 'Статистика детализации не найдена',
  1725: 'Пространство не найдено',
  1727: 'Пространство с указанным кодом уже существует',

  1229: 'Организация с указанным "Абонентским номером мобильного телефона" уже существует',
  1230: 'Организация с указанным EPAY уже существует',
  1231: 'Организация с указанным "Регистрационным номером" уже существует',
  1232: 'Организация с указанным "Номером налогоплательщика либо его аналог" уже существует',
  632: 'Договор с таким идентификатором договора в ORD уже существует',
  89: 'Действие не разрешено. Например запрещено изменение статуса на другой у записи.',
  531: 'Кампания связана с другими записями. Необходимо их удалить прежде чем продолжить операцию.',

  70: 'Внутренняя ошибка',
};

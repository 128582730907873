import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import type { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import { toast } from 'react-toastify';
import { getId } from '../../../../../../helper/getId';
import { RCampaignGet } from '../../../../../../apiRPC/сampaign';
import {
  $campaign,
  $deletedInvoices,
  resetDeletedInvoices,
  setDeletedInvoice,
} from './index';
import { createEffectJWT } from '../../../../../../model/JWT';
import { ErrorProps, isErrorProps } from '../../../../../../apiRPC/request';
import { navigateForEffect } from '../../../../../../model/routing';
import { addressesORD } from '../../../../../addresses';
import { errorsMessage } from '../../../../../../apiRPC/errors';
import {
  campaignInvoiceParamsCreate,
  campaignInvoiceParamsDelete,
  campaignInvoiceParamsGetList,
  campaignInvoiceParamsUpdate,
  InvoiceParam,
  InvoiceParamsCreatItem,
  InvoiceParamsUpdateItem,
  PCInvoiceParamsCreat,
  PCInvoiceParamsDelete,
  PCInvoiceParamsUpdate,
  RCInvoiceParamsGetList,
  Variant,
} from '../../../../../../apiRPC/сampaign/campaignInvoiceParams';

export const variantsMap = new Map([
  [0, 'По умолчанию'],
  [1, 'Набор 1'],
  [2, 'Набор 2'],
  [3, 'Набор 3'],
]);

export const campaignInvoiceParamsGetListFX = createEffectJWT(
  campaignInvoiceParamsGetList,
);
export const campaignInvoiceParamsCreateFX = createEffectJWT(
  campaignInvoiceParamsCreate,
);
export const campaignInvoiceParamsUpdateFX = createEffectJWT(
  campaignInvoiceParamsUpdate,
);
export const campaignInvoiceParamsDeleteFX = createEffectJWT(
  campaignInvoiceParamsDelete,
);

export const resetCampaignInvoiceParamsList = createEvent();
export const $campaignInvoiceParamsList = createStore<
  RCInvoiceParamsGetList['rows']
>([])
  .on(campaignInvoiceParamsGetListFX.doneData, (_, payload) => payload.rows)
  .reset(resetCampaignInvoiceParamsList);

export const $filteredInvoices = combine(
  {
    campaignInvoiceParamsList: $campaignInvoiceParamsList,
    deletedInvoices: $deletedInvoices,
  },
  ({ campaignInvoiceParamsList, deletedInvoices }) =>
    campaignInvoiceParamsList.filter(
      (item) => !deletedInvoices.includes(item.uuid),
    ),
);

export const resetUpdateError = createEvent();
export const $campaignInvoiceParamsPending = combine(
  [
    campaignInvoiceParamsCreateFX.pending,
    campaignInvoiceParamsUpdateFX.pending,
    campaignInvoiceParamsDeleteFX.pending,
  ],
  (units) => units.includes(true),
);

export const $updateError = createStore('')
  .on(
    [
      campaignInvoiceParamsCreateFX.failData,
      campaignInvoiceParamsUpdateFX.failData,
      campaignInvoiceParamsDeleteFX.failData,
    ],
    (_, payload: ErrorProps) => {
      // @ts-ignore
      if (payload?.code === 'ERR_CANCELED') return undefined;
      const defaultText = 'Не известная ошибка';
      let text = defaultText;
      if (isErrorProps(payload)) {
        const code = payload?.code;
        const message = code ? errorsMessage[code] : undefined;
        text = ` ${message || defaultText} \r\n ${
          payload?.data?.debugCode
            ? `debugCode:${payload?.data?.debugCode}`
            : ''
        } `;
      }
      return text;
    },
  )
  .reset([resetUpdateError]);
export const setVariant = createEvent<number>();
export const resetVariant = createEvent();
export const $variant = restore(setVariant, 0).reset(resetVariant);

export const setInvoice = createEvent<number>();
export const delInvoice = createEvent<string>();
export const resetInvoices = createEvent();
export const $Invoices = createStore<[number, string][]>([])
  .on(setInvoice, (state, id) => [[id, getId()], ...state])
  .on(delInvoice, (state, payload) => state.filter(([_, id]) => id !== payload))
  .reset(resetInvoices);

export type Form = {
  // Месяц
  month: Date | null;
  // Тип расчета (по умолчанию cpa)
  type: InvoiceParam['type'];
  // Кол-во единиц, план.
  numberOfUnitsPlan: number | null;
  // Кол-во единиц.
  numberOfUnits: number | null;
  // Цена за единицу, руб
  amountPerUnit: number | null;
  // Итоговое количество показов, факт.
  totalActualShows: number | null;
  // Итоговое количество показов, план
  totalPlannedShows: number | null;
  // Итоговая сумма, руб
  totalAmount: number | null;
  // Итоговая сумма выгрузки, руб
  totalExportAmount: number | null;
  variant?: Variant;
  uuid?: string;
};
const mapName = new Map([
  ['month', 'Поле "Месяц"'],
  ['type', 'Поле "Тип расчета"'],
  ['numberOfUnitsPlan', 'Поле "Кол-во единиц, план."'],
  ['numberOfUnits', 'Поле "Кол-во единиц."'],
  ['amountPerUnit', 'Поле "Цена за единицу, руб"'],
  ['totalActualShows', 'Поле "Итоговое количество показов, факт."'],
  ['totalPlannedShows', 'Поле "Итоговое количество показов, план"'],
  ['totalAmount', 'Поле "Итоговая сумма, руб"'],
]);
type FormItem = ReturnType<typeof useFormik<Form>>;
type InvoicesForm = Map<string, FormItem>;
export const addInvoicesForm = createEvent<{ formik: FormItem; id: string }>();
export const delInvoicesForm = createEvent<string>();
export const delInvoicesForms = createEvent<string[]>();

const deleteEvents = [delInvoicesForm, delInvoice, setDeletedInvoice];
export const resetInvoicesForm = createEvent();
export const $invoicesForms = createStore<InvoicesForm | null>(null)
  .on(addInvoicesForm, (state, { formik, id }) => {
    const current = state || new Map<string, FormItem>();
    current.set(id, formik);
    return new Map<string, FormItem>(current);
  })
  .on(deleteEvents, (state, id) => {
    if (!state) return undefined;
    state.delete(id);
    if (!state.size) return null;
    return new Map<string, FormItem>(state);
  })
  .on(delInvoicesForms, (state, payload) => {
    if (!state) return undefined;
    payload.forEach((item) => state.delete(item));
    if (!state.size) return null;
    return new Map<string, FormItem>(state);
  })
  .reset(resetInvoicesForm);

const deleteAddInvoices = createEvent();
sample({
  clock: deleteAddInvoices,
  source: { invoices: $Invoices },
  fn: ({ invoices }) => invoices.map(([id, key]) => key),
  target: delInvoicesForms,
});

function formIsChanged(formVal: Form, data?: InvoiceParam): boolean {
  if (!data) return true;
  let res = false;
  type CheckedObj = {
    month: number;
    type: InvoiceParam['type'];
    numberOfUnitsPlan: number;
    numberOfUnits: number;
    amountPerUnit: number;
    totalActualShows: number;
    totalPlannedShows: number;
    totalAmount: number;
    totalExportAmount: number;
  };
  const checkedObj: CheckedObj = {
    month: +(formVal?.month || 0) / 1000,
    type: formVal.type,
    numberOfUnitsPlan: formVal.numberOfUnitsPlan ?? Infinity,
    numberOfUnits: formVal.numberOfUnits ?? Infinity,
    amountPerUnit: formVal.amountPerUnit ?? Infinity,
    totalActualShows: formVal.totalActualShows ?? Infinity,
    totalPlannedShows: formVal.totalPlannedShows ?? Infinity,
    totalAmount: formVal.totalAmount ?? Infinity,
    totalExportAmount: formVal.totalExportAmount ?? Infinity,
  };
  const keys = Object.keys(checkedObj);
  for (const key of keys) {
    if (
      checkedObj[key as keyof CheckedObj] !== data[key as keyof InvoiceParam]
    ) {
      res = true;
      break;
    }
  }
  return res;
}

export const setDisabledChanged = createEvent<boolean>();
export const resetDisabledChanged = createEvent();
export const $disabledChanged = restore(setDisabledChanged, false).reset(
  resetDisabledChanged,
);

export const $additionalChanged = combine(
  {
    invoicesForms: $invoicesForms,
    deletedInvoices: $deletedInvoices,
    campaignInvoiceParamsList: $campaignInvoiceParamsList,
  },
  ({ invoicesForms, deletedInvoices, campaignInvoiceParamsList }) => {
    if (deletedInvoices.length) return true;
    if (!invoicesForms) return false;
    const forms = Array.from(invoicesForms.values());
    let isFormIsChanged = false;

    for (const formik of forms) {
      const data = campaignInvoiceParamsList.find(
        (item) => item.uuid === formik.values.uuid,
      );
      if (formIsChanged(formik.values, data)) {
        isFormIsChanged = true;
        break;
      }
    }
    return isFormIsChanged;
  },
);
export const $additionalDisable = combine(
  {
    invoicesForms: $invoicesForms,
    deletedInvoices: $deletedInvoices,
    additionalChanged: $additionalChanged,
  },
  ({ invoicesForms, deletedInvoices, additionalChanged }) => {
    if (!invoicesForms) return !deletedInvoices.length;
    let isValid = true;
    const forms = Array.from(invoicesForms.values());
    for (const formik of forms) {
      if (!formik.isValid) {
        isValid = false;
        break;
      }
    }
    if (!isValid) return true;
    if (deletedInvoices.length) return false;
    return !additionalChanged;
  },
);

export const onSubmitForm = createEvent();

const onSubmitFormFX = createEffect(
  async ({
    invoicesForms,
    campaign,
    deletedInvoices,
    campaignInvoiceParamsList,
  }: {
    invoicesForms: InvoicesForm | null;
    campaign: RCampaignGet;
    deletedInvoices: string[];
    campaignInvoiceParamsList: RCInvoiceParamsGetList['rows'];
  }) => {
    if (!campaign.uuid) return;
    setDisabledChanged(true);
    let isValid = true;

    const forUpdate: PCInvoiceParamsUpdate['items'] = [];
    const forCreat: PCInvoiceParamsCreat['items'] = [];
    const forDelete: PCInvoiceParamsDelete['items'] = [];

    const idMapCreat: string[] = [];
    const idMapUpdate: string[] = [];

    if (invoicesForms) {
      for await (const [id, formik] of Array.from(invoicesForms)) {
        const errors = await formik.validateForm();
        if (!isEmpty(errors)) {
          isValid = false;
        }
      }

      if (!isValid) return;

      for await (const [id, formik] of Array.from(invoicesForms)) {
        const { values } = formik;

        if (
          values.uuid !== undefined &&
          formIsChanged(
            formik.values,
            campaignInvoiceParamsList.find((item) => item.uuid === values.uuid),
          )
        ) {
          const data: InvoiceParamsUpdateItem = {
            uuid: values.uuid,
            month: +values.month! / 1000,
            type: values.type,
            numberOfUnitsPlan: values.numberOfUnitsPlan ?? 0,
            numberOfUnits: values.numberOfUnits ?? 0,
            amountPerUnit: values.amountPerUnit ?? 0,
            totalActualShows: values.totalActualShows ?? 0,
            totalPlannedShows: values.totalPlannedShows ?? 0,
            totalAmount: values.totalAmount ?? 0,
            totalExportAmount: values.totalExportAmount ?? 0,
          };
          forUpdate.push(data);
          idMapUpdate.push(id);
        }

        if (values.uuid === undefined) {
          const data: InvoiceParamsCreatItem = {
            month: +values.month! / 1000,
            type: values.type,
            numberOfUnitsPlan: values.numberOfUnitsPlan ?? undefined,
            numberOfUnits: values.numberOfUnits ?? undefined,
            amountPerUnit: values.amountPerUnit ?? undefined,
            totalActualShows: values.totalActualShows ?? undefined,
            totalPlannedShows: values.totalPlannedShows ?? undefined,
            totalAmount: values.totalAmount ?? undefined,
            totalExportAmount: values.totalExportAmount ?? undefined,
            variant: values.variant ?? undefined,
          };
          forCreat.push(data);
          idMapCreat.push(id);
        }
      }
    }
    deletedInvoices.forEach((item) => {
      forDelete.push(item);
    });
    try {
      if (forUpdate.length)
        await campaignInvoiceParamsUpdateFX({
          campaign: campaign.uuid,
          items: forUpdate,
        });
      if (forCreat.length)
        await campaignInvoiceParamsCreateFX({
          campaign: campaign.uuid,
          items: forCreat,
        });
      if (forDelete.length)
        await campaignInvoiceParamsDeleteFX({
          campaign: campaign.uuid,
          items: forDelete,
        });

      resetUpdateError();
      toast.success('Кампания успешно изменена', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      navigateForEffect(
        `${addressesORD.campaignsPath}?status=${campaign.status}`,
      );
    } catch (e) {
      console.error({ e });
      setDisabledChanged(false);
      if (isErrorProps(e)) {
        const method = e?.method;
        e?.data?.fields?.forEach((field) => {
          const id =
            method === 'campaignInvoiceParams.create'
              ? idMapCreat.at(field?.index ?? -1)
              : idMapUpdate.at(field?.index ?? -1);
          if (id === undefined) return;
          const formik = invoicesForms?.get(id);
          if (!formik) return;
          let text = field.description;
          Array.from(mapName).forEach(([key, val]) => {
            text = text.replace(key, val);
          });
          formik.setFieldError(field.field, text);
        });
      }
    }
  },
);

sample({
  clock: onSubmitForm,
  source: {
    invoicesForms: $invoicesForms,
    campaign: $campaign,
    deletedInvoices: $deletedInvoices,
    campaignInvoiceParamsList: $campaignInvoiceParamsList,
  },
  filter: ({ campaign }) => Boolean(campaign),
  fn: ({
    invoicesForms,
    campaign,
    deletedInvoices,
    campaignInvoiceParamsList,
  }) => ({
    invoicesForms,
    campaign: campaign!,
    deletedInvoices,
    campaignInvoiceParamsList,
  }),
  target: onSubmitFormFX,
});

export const resetFormS = createEvent();
sample({
  clock: resetFormS,
  target: createEffect(() => {
    deleteAddInvoices();
    resetDisabledChanged();
    resetVariant();
    resetInvoices();
    resetDeletedInvoices();
    resetUpdateError();
  }),
});

export function resetAdditional() {
  deleteAddInvoices();
  resetDisabledChanged();
  resetVariant();
  resetInvoices();
  resetInvoicesForm();
  resetDeletedInvoices();
  resetUpdateError();
  resetCampaignInvoiceParamsList();
}

import { Box } from '@mui/material';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ContainerOrd } from '../ContainerOrd';

export const Organization: FC = () => (
  <ContainerOrd>
    <Box>
      <Outlet />
    </Box>
  </ContainerOrd>
);

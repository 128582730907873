import { Box, Button, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useUnit } from 'effector-react';

import Loading from '../../../../../../UI/Loading';
import { HeaderResourse } from './HeaderResourse';
import { Statuses } from './Statuses';
import { MenuAddResources } from './MenuAddResources';
import { ListModal } from './ListModal';
import { Info } from '../../../../../../components/Info';
import { $isDellAllResources, resourcesCreateFX } from './model';
import { Form } from './Form';

import { FormUpdate } from './Update';

const Resourse: FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pendingCreate = useUnit(resourcesCreateFX.pending);
  const isDellAllResources = useUnit($isDellAllResources);

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <HeaderResourse />
      {isDellAllResources && (
        <Info
          text="Запрещено удалять все ресурсы"
          setOpenInfo={() => null}
          error
        />
      )}
      <Box display="flex" flexDirection="column" gap="20px 0">
        <Statuses />

        <Paper
          elevation={0}
          sx={{
            padding: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight={700}>Ресурсы</Typography>
          <Button variant="outlined" onClick={handleMenu}>
            Добавить
          </Button>
          <MenuAddResources anchorEl={anchorEl} handleClose={handleClose} />
          <ListModal />
        </Paper>

        {pendingCreate && (
          <Info
            text="Пакет ресурсов ожидает загрузки"
            customIcon={<Loading />}
            noCloseBtn
          />
        )}

        <Form />
        <FormUpdate />
      </Box>
    </Box>
  );
};

export default Resourse;

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { Skeleton } from '@mui/material';
import { cardCreativesGetListFX } from './model';
import { $card } from '../../../../../model/card';

export const PendingComponent: FC = () => {
  const pendingGetList = useUnit(cardCreativesGetListFX.pending);
  const card = useUnit($card);

  if (!pendingGetList) return null;
  return (
    <>
      {Array.from(new Array(card?.countCreatives)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          width="100%"
          height={65}
          sx={{ flexShrink: 0 }}
        />
      ))}
    </>
  );
};

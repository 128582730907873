import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { orange, yellow } from '@mui/material/colors';
import { useStoreMap } from 'effector-react';
import {
  $currentFindItem,
  $searchMap,
} from '../../../../../../components/Header/GRID/Search/model';
import { Highlight } from '../../../../../../components/Highlight';

const style = {
  textOverflow: 'ellipsis',
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};
type Props = { title: string; uuid: string };
export const Title: FC<Props> = ({ title, uuid }) => {
  const filter = useStoreMap({
    store: $searchMap,
    keys: [uuid],
    fn: (state, CardUuid) => state?.cards[CardUuid[0]],
    defaultValue: '',
  });

  const isCurrentFindItem = useStoreMap({
    store: $currentFindItem,
    keys: [uuid],
    fn: (state, CardUuid) => state?.at(1) === CardUuid[0],
    defaultValue: false,
  });

  const color = isCurrentFindItem ? orange[500] : yellow[500];

  return (
    <Typography variant="body2" sx={style}>
      <Highlight str={title} filter={filter} color={color} />
    </Typography>
  );
};

import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createTheme,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { blueGrey } from '@mui/material/colors';

import { TabContext, TabPanel } from '@mui/lab';
import { useUnit } from 'effector-react';
import { Attachments as FilesAttachments } from '../../../../../../apiRPC/card';
import { MenuDelete } from './MenuDelete';
import { Params } from '../../../../../../helper/useFileUpload/types';
import { AttachmentViewer } from './AttachmentViewer';
import { CardForLink } from './Cards/CardForLink';
import {
  $accountListForFile,
  onSetOpenContent,
} from '../../../../../../helper/attachments';

import { themeGrid } from '../../../../../../theme/themeGrid';
import { contentRestoreFX } from '../../../../../model/card/update/attachments';
import { $cardCommentsUid } from '../../../../../model/card/comment';
import { seTHistoryCommentTab } from '../../../../../model/taskOverview';

const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const themeG = createTheme(themeGrid);
const styleMedia = {
  display: 'flex',
  width: 150,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: blueGrey[50],
  flexShrink: 0,
  height: 'auto',
  backgroundSize: 'contain',
  alignSelf: 'stretch',
};
const styleBoxGridText = {
  display: 'grid',
  gridTemplateColumns: 'max-content max-content ',
  alignItems: 'center',
  gap: 1,
};
const listExtForView = ['PNG', 'JPEG', 'JPG', 'TXT'];
const maxShowAttachments = 4;
type Props = {
  uploadFiles: Map<
    string,
    { file: File | string; cardId: string; params?: Params }
  >;
  cardId: string;
  attachments: FilesAttachments[];
  archives: FilesAttachments[];
};

export const Attachments: FC<Props> = ({
  uploadFiles,
  cardId,
  attachments,
  archives,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [uuidFile, setUuidFile] = useState<string>();
  const [hideAttachments, setHideAttachments] = useState<boolean>(true);
  const openDellMenu = Boolean(anchorEl);

  const cardCommentsSet = useUnit($cardCommentsUid);
  const accountListForFile = useUnit($accountListForFile);

  const handleClick =
    (uuid: string) => (event: React.MouseEvent<HTMLElement>) => {
      setUuidFile(uuid);
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      event.preventDefault();
      return null;
    };

  const recoverАile =
    (uuid: string) => (event: React.MouseEvent<HTMLElement>) => {
      contentRestoreFX({ uuid });
      event.stopPropagation();
      event.preventDefault();
      return null;
    };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortAttachments = useMemo(() => {
    const sort = attachments.sort((a, b) => b.createdAt - a.createdAt);
    return hideAttachments ? sort.slice(0, maxShowAttachments) : sort;
  }, [attachments, hideAttachments]);

  const hiddenAttachments = attachments.length - sortAttachments.length;

  const [view, setView] = useState<FilesAttachments | null>(null);

  const openView =
    (file: FilesAttachments) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setView(file);
    };

  const closeView = () => {
    setView(null);
  };

  const [currentTab, serCurrentTab] = useState<'Attachments' | 'Archive'>(
    'Attachments',
  );

  return (
    <>
      <TabContext value={currentTab}>
        <Box sx={styleBoxGridText}>
          <AttachFileIcon color="action" />
          <ThemeProvider theme={themeG}>
            <Tabs onChange={(e, v) => serCurrentTab(v)} value={currentTab}>
              <Tab label="Вложения" value="Attachments" />
              <Tab label="Архив" value="Archive" />
            </Tabs>
          </ThemeProvider>
        </Box>
        <Box ml={4} mt={1}>
          <TabPanel value="Attachments">
            <Stack spacing={1}>
              {Array.from(uploadFiles)
                .filter(([_, { cardId: id }]) => id === cardId)
                .map(([id]) => (
                  <Skeleton
                    key={id}
                    variant="rectangular"
                    width="100%"
                    height={100}
                  />
                ))}

              {sortAttachments.map((item) => {
                const isExistComment = item.comment
                  ? cardCommentsSet.has(item.comment)
                  : false;

                if (item.type === 'link')
                  return (
                    <CardForLink
                      item={item}
                      handleClick={handleClick}
                      key={item.uuid}
                    />
                  );
                return (
                  <Card
                    key={item.uuid}
                    sx={{ minHeight: 100, overflow: 'hidden' }}
                  >
                    <CardActionArea
                      sx={{
                        display: 'flex',
                        minHeight: 100,
                        justifyContent: 'start',
                        alignItems: 'flex-start',
                        userSelect: 'text',
                      }}
                      aria-dropeffect="none"
                      aria-grabbed={false}
                      draggable="false"
                      component="a"
                      href={item.downloadUrl}
                      download={`${item.name}.${item.ext}`}
                      onClick={
                        listExtForView.includes(item.ext.toUpperCase())
                          ? openView(item)
                          : undefined
                      }
                    >
                      {item.type === 'image' ? (
                        <CardMedia sx={styleMedia} image={item.url} />
                      ) : (
                        <CardMedia sx={styleMedia}>
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            color="text.secondary"
                          >
                            {item.ext || item.type.toUpperCase()}
                          </Typography>
                        </CardMedia>
                      )}

                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto', paddingY: 0.5 }}>
                          <Typography
                            component="div"
                            variant="subtitle2"
                            fontWeight={600}
                            sx={{ wordBreak: 'break-all' }}
                          >
                            {item.name}.{item.ext}
                          </Typography>

                          <Box display="flex" gap={0.5} alignItems="baseline">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              component="div"
                            >
                              Добавлено{' '}
                              {accountListForFile.get(item.account || '')
                                ?.email || null}{' '}
                              {new Intl.DateTimeFormat(
                                'ru',
                                optionsTime,
                              ).format(item.createdAt * 1000)}
                            </Typography>

                            {!isExistComment && (
                              <Button
                                size="small"
                                variant="text"
                                onClick={handleClick(item.uuid)}
                              >
                                <Typography
                                  variant="body2"
                                  component="div"
                                  fontStyle="italic"
                                >
                                  Удалить
                                </Typography>
                              </Button>
                            )}
                            {isExistComment && (
                              <Button
                                size="small"
                                variant="text"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  if (!item.comment) return;
                                  onSetOpenContent(item.comment);
                                  seTHistoryCommentTab('COMMENT');
                                  setTimeout(() => {
                                    const elementComment =
                                      document.getElementById(item.uuid);
                                    if (elementComment) {
                                      elementComment.scrollIntoView({
                                        behavior: 'smooth',
                                      });
                                    }
                                  }, 300);
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  component="div"
                                  fontStyle="italic"
                                >
                                  Перейти к комментарию
                                </Typography>
                              </Button>
                            )}
                          </Box>
                        </CardContent>
                      </Box>
                    </CardActionArea>
                  </Card>
                );
              })}

              {attachments.length > maxShowAttachments && (
                <Button
                  variant="text"
                  onClick={() => setHideAttachments((prevState) => !prevState)}
                >
                  {hideAttachments
                    ? `Посмотреть все вложения (скрыто ${hiddenAttachments})`
                    : 'Показать меньше вложений.'}
                </Button>
              )}
            </Stack>
          </TabPanel>

          <TabPanel value="Archive">
            <Stack spacing={1}>
              {archives.map((item) => {
                if (item.type === 'link')
                  return (
                    <CardForLink
                      item={item}
                      handleClick={recoverАile}
                      key={item.uuid}
                      restore
                    />
                  );
                return (
                  <Card
                    key={item.uuid}
                    sx={{ minHeight: 100, overflow: 'hidden' }}
                  >
                    <CardActionArea
                      sx={{
                        display: 'flex',
                        minHeight: 100,
                        justifyContent: 'start',
                        alignItems: 'flex-start',
                        userSelect: 'text',
                      }}
                      aria-dropeffect="none"
                      aria-grabbed={false}
                      draggable="false"
                      component="a"
                      href={item.downloadUrl}
                      download={`${item.name}.${item.ext}`}
                      onClick={
                        listExtForView.includes(item.ext.toUpperCase())
                          ? openView(item)
                          : undefined
                      }
                    >
                      {item.type === 'image' ? (
                        <CardMedia sx={styleMedia} image={item.url} />
                      ) : (
                        <CardMedia sx={styleMedia}>
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            color="text.secondary"
                          >
                            {item.ext || item.type.toUpperCase()}
                          </Typography>
                        </CardMedia>
                      )}

                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto', paddingY: 0.5 }}>
                          <Typography
                            component="div"
                            variant="subtitle2"
                            fontWeight={600}
                            sx={{ wordBreak: 'break-all' }}
                          >
                            {item.name}.{item.ext}
                          </Typography>

                          <Box display="flex" gap={0.5} alignItems="baseline">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              component="div"
                            >
                              Добавлено{' '}
                              {accountListForFile.get(item.account || '')
                                ?.email || null}{' '}
                              {new Intl.DateTimeFormat(
                                'ru',
                                optionsTime,
                              ).format(item.createdAt * 1000)}
                            </Typography>

                            <Button
                              size="small"
                              variant="text"
                              onClick={recoverАile(item.uuid)}
                            >
                              <Typography
                                variant="body2"
                                component="div"
                                fontStyle="italic"
                              >
                                Восстановить
                              </Typography>
                            </Button>
                          </Box>
                        </CardContent>
                      </Box>
                    </CardActionArea>
                  </Card>
                );
              })}
            </Stack>
          </TabPanel>
        </Box>
      </TabContext>
      <MenuDelete
        open={openDellMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        uuidFile={uuidFile}
        cardId={cardId}
      />
      <AttachmentViewer
        open={Boolean(view)}
        name={view?.name || ''}
        url={view?.url || ''}
        close={closeView}
        ext={view?.ext || ''}
        createdAt={view?.createdAt || 0}
        size={view?.size || 0}
        cardId={cardId}
        uuidFile={view?.uuid || ''}
        downloadUrl={view?.downloadUrl || ''}
        hideDelBtn={Boolean(view?.comment)}
        deleted={view?.deleted || false}
        account={view?.account || ''}
      />
    </>
  );
};

import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  Store,
} from 'effector';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import {
  contractGet,
  contractSendErir,
  contractUpdate,
  PContractGetList,
  RContractGet,
} from '../../../../../../apiRPC/contracts';
import {
  ordGetCatalogFX,
  organizationGetCatalogFX,
} from '../../../model/catalogs';
import { finalContractGetListFX, parentContractGetListFX } from '../../model';

export const contractGetFX = errorHandlerFx(createEffectJWT(contractGet));
const resetContract = createEvent();
export const $contract = createStore<RContractGet | null>(null)
  .on(contractGetFX.doneData, (_, payload) => payload)
  .reset(resetContract);

export const contractUpdateFX = errorHandlerFx(createEffectJWT(contractUpdate));
export const contractSendErirFX = errorHandlerFx(
  createEffectJWT(contractSendErir),
);

export const finalContractGetFX = errorHandlerFx(createEffectJWT(contractGet));
export const resetFinalContract = createEvent();
export const $finalContract = createStore<RContractGet | null>(null)
  .on(finalContractGetFX.doneData, (_, payload) => payload)
  .reset(resetFinalContract);

export const parentContractGetFX = errorHandlerFx(createEffectJWT(contractGet));
export const resetParentContract = createEvent();
export const $parentContract = createStore<RContractGet | null>(null)
  .on(parentContractGetFX.doneData, (_, payload) => payload)
  .reset(resetParentContract);

sample({
  clock: $contract,
  filter: (payload) => Boolean(payload),
  target: createEffect((payload: RContractGet | null) => {
    if (!payload) return;

    finalContractGetListFX({
      params: {
        filter: { ord: payload.ord, limit: 30, category: 'Final' },
      },
    });
    parentContractGetListFX({
      params: { filter: { ord: payload.ord, limit: 30 } },
    });

    if (payload.finalContract)
      finalContractGetFX({ uuid: payload.finalContract });

    if (payload.parent) parentContractGetFX({ uuid: payload.parent });
  }),
});

export const setDefaultsParentContractsList = createEvent();

sample({
  clock: setDefaultsParentContractsList,
  source: $contract,
  filter: (contract) => Boolean(contract),
  fn: (contract) => ({
    params: { filter: { ord: contract!.ord, limit: 30 } },
  }),
  target: parentContractGetListFX,
});

export const setDefaultsFinalContractList = createEvent();

sample({
  clock: setDefaultsFinalContractList,
  source: $contract,
  filter: (contract) => Boolean(contract),
  fn: (contract) => {
    const res: { params: PContractGetList } = {
      params: {
        filter: { ord: contract!.ord, limit: 30, category: 'Final' },
      },
    };
    return res;
  },
  target: finalContractGetListFX,
});

export const $fieldFormUpdate: Store<'loading' | 'Success'> = combine(
  {
    contractGetPending: contractGetFX.pending,
    organizationGetCatalogPending: organizationGetCatalogFX.pending,
    ordGetCatalogPending: ordGetCatalogFX.pending,
  },
  ({
    contractGetPending,
    organizationGetCatalogPending,
    ordGetCatalogPending,
  }) =>
    contractGetPending || organizationGetCatalogPending || ordGetCatalogPending
      ? 'loading'
      : 'Success',
);

export function resetStoreContract() {
  resetParentContract();
  resetContract();
  resetFinalContract();
}

import React, { FC, useEffect } from 'react';
import { useUnit } from 'effector-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import {
  $anyPending,
  $resourcesListFilter,
  setResourceForDelete,
} from '../model';
import { ItemResource } from './Items/Item';
import { TextResourse } from './Items/TextResourse';
import { Resource } from '../../../../../../../apiRPC/creative/resources';

import {
  resetResourcesUpdateForm,
  resourcesUpdateFX,
  setChangeUpdate,
  setValidUpdate,
  submitResourcesUpdateForm,
} from './model';
import { isErrorProps } from '../../../../../../../apiRPC/request';

function formIsChanged(
  resourcesList: Resource[] | undefined,
  values: Resource[],
) {
  return (
    (resourcesList?.reduce(
      (previousValue, currentValue) => currentValue.textData + previousValue,
      '',
    ) ?? '') !==
    (values?.reduce(
      (previousValue, currentValue) => currentValue.textData + previousValue,
      '',
    ) ?? '')
  );
}
const validationSchema = Yup.array().of(
  Yup.object().shape({
    type: Yup.string().required('Обязательное для заполнения поле'),
    description: Yup.string().when('type', ([type], schema) =>
      schema.test({
        test: (value) => !(!value && type === 'resource'),
        message: 'Обязательное для заполнения поле',
      }),
    ),
    mediaExampleUrl: Yup.string()
      .when('type', ([type], schema) =>
        schema.test({
          test: (value) => !(!value && type === 'resource'),
          message: 'Обязательное для заполнения поле',
        }),
      )
      .url('Неверный формат URL'),
    textData: Yup.string().when('type', ([type], schema) =>
      schema.test({
        test: (value) => !(!value && type === 'text'),
        message: 'Обязательное для заполнения поле',
      }),
    ),
  }),
);

const initialValues: Resource[] = [];

type FormUpdateResources = Resource[];
export const FormUpdate: FC = () => {
  const resourcesList = useUnit($resourcesListFilter);
  const { uuid } = useParams();
  const pending = useUnit($anyPending);
  const formik = useFormik<FormUpdateResources>({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (!formIsChanged(resourcesList, values)) return;
      if (!uuid) return;

      const data = values.map((item) => ({
        uuid: item.uuid,
        textData: item.textData,
        type: item.type,
        description: item.description,
        mediaExampleUrl: item.mediaExampleUrl,
      }));
      try {
        const resources = await resourcesUpdateFX({
          creative: uuid,
          items: data,
        });
        await formik.setValues(resources ?? [], true);
      } catch (e) {
        console.error({ e });
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            if (typeof field.index === 'number')
              f.setFieldError(`[${field.index}]${field.field}`, text);
          });
        }
      }
    },
  });

  useEffect(() => {
    const stop = submitResourcesUpdateForm.watch(() => {
      formik.submitForm();
    });
    return stop;
  }, [formik]);

  useEffect(() => {
    const data = resourcesList?.map((item) => {
      const currentElement = formik.values.find((i) => i.uuid === item.uuid);
      if (currentElement) {
        return {
          ...item,
          textData: currentElement.textData,
          description: currentElement.description,
          mediaExampleUrl: currentElement.mediaExampleUrl,
        };
      }
      return item;
    });
    formik.setValues(data ?? [], true);
  }, [resourcesList]);

  useEffect(() => {
    const stop = resetResourcesUpdateForm.watch(() => {
      formik.setValues(resourcesList ?? [], true);
    });
    return stop;
  }, [formik]);

  const isChanged = formIsChanged(resourcesList, formik.values);

  useEffect(() => {
    setChangeUpdate(isChanged);
  }, [isChanged]);

  useEffect(() => {
    setValidUpdate(formik.isValid);
  }, [formik.isValid]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {formik.values?.map((item, index) => {
        if (item.type === 'resource')
          return (
            <ItemResource
              key={item.uuid}
              mediaExampleUrl={item.mediaExampleUrl}
              description={item.description || item.textData}
              onDelete={() => setResourceForDelete(item.uuid)}
            />
          );
        if (item.type === 'text')
          return (
            <TextResourse
              key={item.uuid}
              textData={item.textData}
              onDelete={() => setResourceForDelete(item.uuid)}
              error={Boolean(formik.errors[index]?.textData)}
              helperText={formik.errors[index]?.textData}
              onChange={(e) => {
                formik.setFieldValue(
                  `[${index}]textData`,
                  e.target.value,
                  true,
                );
              }}
              disabled={pending}
            />
          );

        return null;
      })}
    </>
  );
};

import axios from 'axios';
import { getAccessToken } from '../../../model/JWT/methods';
import { isErrorProps, JsonRPCError } from '../../request';
import { currentApi } from '../../../model/currentApi';

const api = currentApi.value.includes('dev')
  ? 'https://grid-api-dev.terratraf.com/content/'
  : 'https://grid-api.terratraf.com/content/';

export type RSendMultipleFiles = {
  uuid: string;
  name: string;
  size: number;
  ext: string;
  type: 'link' | 'video' | 'image' | 'other';
  color: string;
  width: number;
  height: number;
  scaled: boolean;
  createdAt: number;
  updatedAt: number;
  url: string;
  idFile: string;
  cardId: string;
  commentUid: string;
  downloadUrl: string;
  account?: string;
};

export type PSendMultipleFiles = {
  formData: FormData;
  idFile: string;
  cardId: string;
  commentUid?: string;
};
export const sendMultipleFiles = async ({
  formData,
  idFile,
  cardId,
  commentUid,
}: PSendMultipleFiles): Promise<RSendMultipleFiles> => {
  try {
    const access_token: string | null = getAccessToken();
    const response = await axios({
      method: 'post',
      url: api,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (response?.data?.error) {
      throw new JsonRPCError(response?.data?.error);
    }

    if (response?.data?.result === undefined) {
      throw new JsonRPCError({ message: 'unknown error' });
    }

    return { ...response.data.result, idFile, cardId, commentUid };
  } catch (err) {
    if (!window.navigator.onLine) {
      throw new JsonRPCError({ message: 'offline error' });
    }
    if (err instanceof JsonRPCError) {
      throw err;
    }
    if (isErrorProps(err)) {
      throw new JsonRPCError(err);
    }
    throw err;
  }
};

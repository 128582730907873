import React, { FC, useRef, useState } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { Draggable, Droppable, DroppableProvided } from '@hello-pangea/dnd';
import styles from './styles.module.scss';

import { Title } from './Title';
import { $cardListsSorted } from '../../../model/card';
import { Task } from '../Card/Task';
import { ListCards } from './ListCards';

const styleCardContent = { height: 'inherit', overflow: 'hidden' };
type Props = {
  uuid: string;
  title: string;
  index: number;
  board: string;
};
export const Column: FC<Props> = React.memo(({ title, uuid, index, board }) => {
  const [scrollerRef, setScrollerRef] =
    useState<React.RefObject<HTMLDivElement> | null>(null);

  const cardRef = useRef<HTMLDivElement>(null);

  return (
    <Draggable draggableId={uuid} index={index} key={uuid}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          height="100%"
          overflow="auto"
          flexShrink={0}
        >
          <Card className={styles.container} ref={cardRef}>
            <Title
              uuid={uuid}
              title={title}
              dragHandleProps={provided.dragHandleProps}
              scrollerRef={scrollerRef}
            />
            <CardContent sx={styleCardContent}>
              <Droppable
                droppableId={uuid}
                type="CARDS"
                mode="virtual"
                key={uuid}
                renderClone={(providedVirtual, snapshot, rubric) => {
                  const itemR =
                    $cardListsSorted.getState()?.[uuid]?.[rubric.source.index];

                  return (
                    <Task
                      draggableProps={providedVirtual.draggableProps}
                      dragHandleProps={
                        providedVirtual.dragHandleProps || undefined
                      }
                      ref={providedVirtual.innerRef}
                      title={itemR.title}
                      createdAt={itemR.createdAt}
                      uuid={itemR.uuid}
                      board={board}
                      key={itemR.uuid}
                      marks={itemR.marks}
                      members={itemR.members}
                      attachmentsCount={itemR?.attachments?.length || 0}
                      dates={itemR?.dates}
                      countComments={itemR?.countComments}
                      countAttachments={itemR?.countAttachments}
                    />
                  );
                }}
              >
                {(providedIn: DroppableProvided, snapshot) => (
                  <ListCards
                    uuid={uuid}
                    board={board}
                    setScrollerRef={setScrollerRef}
                    providedIn={providedIn}
                    snapshot={snapshot}
                    cardRef={cardRef}
                  />
                )}
              </Droppable>
            </CardContent>
          </Card>
        </Box>
      )}
    </Draggable>
  );
});

import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import deepEquals from 'deep-equal';
import { useIMask } from 'react-imask';
import {
  $campaignList,
  $creative,
  $currentCampaign,
  creativeUpdateFX,
  onResetBasicForm,
  onSubmitBasicForm,
  setChangedBasicForm,
  setCreative,
  setDisabledBasicForm,
} from '../model';
import {
  PCreativeCreate,
  PCreativeUpdate,
} from '../../../../../../apiRPC/creative';
import { isErrorProps } from '../../../../../../apiRPC/request';
import { CampaignItem } from '../../../../../../apiRPC/сampaign';

export type FormUpdateCreative = Omit<
  PCreativeCreate['fields'],
  'shop' | 'type' | 'form' | 'campaign' | 'creativeOkveds'
> & {
  shop: PCreativeCreate['fields']['shop'] | string;
  type?: PCreativeCreate['fields']['type'];
  form?: PCreativeCreate['fields']['form'];
  campaign?: CampaignItem;
  creativeOkveds: string;
  url_temp: string;
};

function formIsChanged(
  formikValue: FormUpdateCreative,
  creative: PCreativeCreate['fields'] | null,
): boolean {
  let res = false;
  if (!creative) return res;

  const creativeOkveds = formikValue.creativeOkveds.length
    ? formikValue.creativeOkveds.split(',')
    : [];

  const checkedObj: PCreativeUpdate['fields'] = {
    name: formikValue.name,
    campaign: formikValue.campaign?.uuid ?? '',
    creativeOkveds,
    description: formikValue.description,
    type: formikValue.type,
    form: formikValue.form,
    targetAudienceDescription: formikValue.targetAudienceDescription,
    url: formikValue.url,
    html: formikValue.html,
    defaultResourceDescription: formikValue.defaultResourceDescription,
    shop: formikValue.shop ? +formikValue.shop : null,
  };

  const keys = Object.keys(checkedObj);

  for (const key of keys) {
    const valCheckedObj = checkedObj[key as keyof PCreativeUpdate['fields']];
    const valCreative = creative[key as keyof PCreativeCreate['fields']];

    if (key === 'shop' && !valCheckedObj && !valCreative) {
      continue;
    }
    if (
      typeof valCheckedObj === 'object' &&
      !deepEquals(valCheckedObj, valCreative)
    ) {
      res = true;
      break;
    }
    if (typeof valCheckedObj !== 'object' && valCheckedObj !== valCreative) {
      res = true;
      break;
    }
  }

  return res;
}
const initialValues: FormUpdateCreative = {
  name: '',
  campaign: undefined,
  creativeOkveds: '',
  description: '',
  type: undefined,
  form: undefined,
  targetAudienceDescription: '',
  url: [],
  html: '',
  defaultResourceDescription: '',
  shop: '',
  url_temp: '',
};

export function useUpdate() {
  const { uuid } = useParams();
  const [creative, campaignList, currentCampaign] = useUnit([
    $creative,
    $campaignList,
    $currentCampaign,
  ]);
  const [disableModal, setDisableModal] = useState(false);

  const formik = useFormik<FormUpdateCreative>({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Обязательное для заполнения поле'),
      campaign: Yup.object().required('Обязательное для заполнения поле'),
      creativeOkveds: Yup.string()
        .matches(/(^$)|(^[0-9]+(?:[,.][0-9]+)*$)/i, 'Невалидное значение')
        .matches(
          /(^$)|(^([^\s,]{5,},)*[^\s,]{5,}$)/gi,
          'Строка должна содержать минимум 5 знаков между запятыми',
        ),
      description: Yup.string().required('Обязательное для заполнения поле'),
      type: Yup.string().required('Обязательное для заполнения поле'),
      form: Yup.string().required('Обязательное для заполнения поле'),
      targetAudienceDescription: Yup.string(),
      url: Yup.array().of(
        Yup.string()
          .required('Обязательное для заполнения поле')
          .matches(
            /^(http|https):\/\/[^\s/$.?#].[^\s]*$/,
            'Невалидное значение',
          ),
      ),
      url_temp: Yup.string()
        .matches(
          /(^$)|(^(http|https):\/\/[^\s/$.?#].[^\s]*$)/,
          'Невалидное значение',
        )
        .when('url', ([url], schema) =>
          schema.test({
            test: (value) => !(!value && !url?.length),
            message: 'Обязательное для заполнения поле',
          }),
        ),
      html: Yup.string()
        .matches(
          /(^$)|((?:.|\n|\r)*<html(?:.|\n|\r)*<body)/,
          'Невалидное значение',
        )
        .nullable(),
      defaultResourceDescription: Yup.string(),
      shop: Yup.string().nullable(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      setDisableModal(true);
      try {
        const data: PCreativeUpdate['fields'] = {
          name: values.name,
          campaign: values.campaign?.uuid ?? '',
          creativeOkveds: values.creativeOkveds.split(','),
          description: values.description,
          type: values.type,
          form: values.form,
          targetAudienceDescription: values.targetAudienceDescription,
          url: values.url,
          html: values.html,
          defaultResourceDescription: values.defaultResourceDescription,
          shop: values.shop ? +values.shop : null,
        };
        const result = await creativeUpdateFX({
          uuid: uuid ?? '',
          fields: data,
        });
        setCreative(result);
        toast.success('Креатив успешно изменен', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } catch (e) {
        setDisableModal(false);
        console.error(e);

        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const handleChange: typeof formik['handleChange'] = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value, true);
  };

  const {
    ref: refValueShop,
    value: valValueShop,
    setTypedValue: setValueShop,
  } = useIMask(
    {
      mask: Number,
      scale: 0,
      thousandsSeparator: '',
      padFractionalZeros: false,
      normalizeZeros: false,
      min: 0,
      max: 32767,
      prepare(
        value: string,
        masked: unknown,
        flags: { input?: boolean; tail?: boolean },
      ) {
        if (value === '0' && !flags?.input) return '';
        return value;
      },
    },
    {
      onComplete: (value) => {
        formik.setFieldValue('shop', value, true);
      },
    },
  );

  useEffect(() => {
    if (!creative) return;
    if (creative.shop) setValueShop(creative.shop);
    formik.setValues(
      {
        name: creative.name,
        campaign: currentCampaign ?? formik.values.campaign,
        creativeOkveds: creative.creativeOkveds.join(','),
        description: creative.description,
        type: creative.type,
        form: creative.form,
        targetAudienceDescription: creative.targetAudienceDescription,
        url: creative.url,
        html: creative.html,
        defaultResourceDescription: creative.defaultResourceDescription,
        shop: creative.shop,
        url_temp: formik.values.url_temp,
      },
      true,
    );
  }, [creative]);

  useEffect(() => {
    const stop = onResetBasicForm.watch(() => {
      if (!creative) return;
      if (creative.shop) setValueShop(creative.shop);
      formik.setValues(
        {
          name: creative.name,
          campaign: currentCampaign ?? undefined,
          creativeOkveds: creative.creativeOkveds.join(','),
          description: creative.description,
          type: creative.type,
          form: creative.form,
          targetAudienceDescription: creative.targetAudienceDescription,
          url: creative.url,
          html: creative.html,
          defaultResourceDescription: creative.defaultResourceDescription,
          shop: creative.shop,
          url_temp: '',
        },
        true,
      );
    });
    return stop;
  }, [creative, formik]);

  useEffect(() => {
    if (!currentCampaign) return;
    formik.setFieldValue('campaign', currentCampaign, true);
  }, [currentCampaign]);

  function addUrl(value: string) {
    if (formik.errors.url_temp) return;
    formik.setFieldValue('url', [...formik.values.url, value]);
    formik.setFieldValue('url_temp', '');
  }
  const onBlurByTempUrl: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const value = e.target.value;
    if (!value) return;
    addUrl(value);
  };
  const onPressEnterByTempUrl: React.KeyboardEventHandler<HTMLDivElement> = (
    e,
  ) => {
    if (e.key !== 'Enter') return;
    const value = formik.values.url_temp;
    if (!value) return;
    addUrl(value);
  };

  const onDeleteItemUrl = (index: number) => () => {
    const arStart = formik.values.url.slice(0, index);
    const arEnd = formik.values.url.slice(index + 1, formik.values.url.length);
    formik.setFieldValue('url', [...arStart, ...arEnd]).then(() => {
      formik.validateField('url');
      formik.validateField('url_temp');
    });
  };

  const formChanged = formIsChanged(formik.values, creative);

  const btnSendDisabled = !formik.isValid || !formChanged;
  const isPrompt = disableModal ? false : formChanged;

  useEffect(() => {
    setDisabledBasicForm(btnSendDisabled);
  }, [btnSendDisabled]);

  useEffect(() => {
    setChangedBasicForm(formChanged || Boolean(formik.values.url_temp));
  }, [formChanged, formik.values.url_temp]);

  useEffect(() => {
    const stop = onSubmitBasicForm.watch(() => {
      formik.submitForm();
    });
    return stop;
  }, [formik]);

  return {
    formik,
    handleChange,
    campaignList,
    currentCampaign,
    onBlurByTempUrl,
    onPressEnterByTempUrl,
    onDeleteItemUrl,
    isPrompt,
    refValueShop,
    valValueShop,
  };
}

import React, { useEffect } from 'react';

type RefForClickOutside =
  | React.RefObject<HTMLElement>
  | React.RefObject<HTMLElement>[];

export function fabricHandleClickOutside(
  refForClickOutside: RefForClickOutside,
  onClick: () => void,
) {
  return (event: MouseEvent) => {
    const target = event.target;
    if (!(target instanceof Element)) return;

    if (Array.isArray(refForClickOutside)) {
      if (
        refForClickOutside.every(
          (item) => (item.current?.contains(target) || false) === false,
        )
      ) {
        onClick();
      }

      return;
    }

    if ((refForClickOutside.current?.contains(target) || false) === false) {
      onClick();
    }
  };
}

type Params = {
  refForClickOutside: RefForClickOutside;
  onClick: () => void;
};

export const useHandleClickOutside = ({
  refForClickOutside,
  onClick,
}: Params) => {
  useEffect(() => {
    const handleClickOutside = fabricHandleClickOutside(
      refForClickOutside,
      onClick,
    );
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refForClickOutside]);
};

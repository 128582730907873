import { TextField } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import React, { FC } from 'react';
import { keymap } from '@codemirror/view';
import {
  insertNewlineAndIndent,
  cursorLineUp,
  cursorLineDown,
} from '@codemirror/commands';

import { TextFieldProps } from '@mui/material/TextField/TextField';
import css from './styles.module.scss';

const basicSetup = {
  highlightActiveLine: false,
  highlightSelectionMatches: false,
  highlightActiveLineGutter: false,
  defaultKeymap: false,
};

type Props = {
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  onChange: (v: string) => void;
  onKeyEnter?: () => void;
} & Omit<TextFieldProps, 'onChange'>;

export const CodeMirrorInput: FC<Props> = ({
  height,
  minHeight,
  maxHeight,
  width,
  minWidth,
  maxWidth,
  onKeyEnter,
  ...props
}) => (
  <TextField
    {...props}
    InputProps={{
      inputProps: {
        placeholder: '',
        height,
        minHeight,
        maxHeight,
        width,
        minWidth,
        maxWidth,
        basicSetup,
        className: css.container,
        extensions: [
          keymap.of([
            {
              key: 'ctrl-Enter',
              run: insertNewlineAndIndent,
            },
            {
              key: 'Enter',
              run: () => {
                onKeyEnter?.();
                return false;
              },
              preventDefault: Boolean(onKeyEnter),
            },
            {
              key: 'ArrowUp',
              run: cursorLineUp,
            },
            {
              key: 'ArrowDown',
              run: cursorLineDown,
            },
          ]),
        ],
      },
      // @ts-ignore
      inputComponent: CodeMirror,
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

import { Badge, Box, Tab, Tabs } from '@mui/material';
import { useUnit } from 'effector-react';
import React from 'react';
import { $currentTab, $resourcesCount, setTab } from '../model';

const TabHeader = () => {
  const currentTab = useUnit($currentTab);
  const resourcesCount = useUnit($resourcesCount);

  return (
    <Box
      sx={{
        background: '#3F55B5',
        boxShadow: '45px 0px 20px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tabs value={currentTab} textColor="secondary" indicatorColor="secondary">
        <Tab
          value="Basic"
          label="Основные настройки"
          id="Tab_Basic"
          onClick={() => setTab('Basic')}
        />

        <Tab
          value="Resources"
          label={
            <Badge color="secondary" badgeContent={resourcesCount}>
              Ресурсы
            </Badge>
          }
          id="Tab_Resources"
          onClick={() => setTab('Resources')}
        />
      </Tabs>
    </Box>
  );
};

export default TabHeader;

import { attach, createEffect, createEvent, restore, sample } from 'effector';
import type { RefObject } from 'react';
import { $cardListsSorted, addCart, cardCreateFX } from '../index';
import {
  cardSetPosition,
  Mark,
  Member,
  PCreate,
  RCardGet,
} from '../../../../apiRPC/card';
import { Item } from '../../../../apiRPC/column';
import { $markList } from '../../Marks';
import { $accounts } from '../accounts';

export const onOpenInputAdd = createEvent<string | null>();
export const onOpenLastInputAdd = createEvent<string>();

export const setScrollerRef = createEvent<RefObject<HTMLDivElement>>();
export const $scrollerRef = restore(setScrollerRef, null);

export const onCloseInputAdd = createEvent();
export const $OpenInputAddFor = restore(onOpenInputAdd, null).reset(
  onCloseInputAdd,
);

export const onSetIndexInputAdd = createEvent<number>();
export const onNextIndex = createEvent();
export const $IndexInputAdd = restore(onSetIndexInputAdd, 0)
  .on(onNextIndex, (state) => state + 1)
  .reset(onCloseInputAdd);

export const onCardCreate = createEvent<PCreate>();

type OpenLastInputAdd = {
  index: number;
  uuid: string;
};
sample({
  clock: onOpenLastInputAdd,
  source: $cardListsSorted,
  fn: (state, payload) => {
    const array = state[payload] || [];
    return { index: array.length, uuid: payload };
  },
  target: createEffect(({ index, uuid }: OpenLastInputAdd) => {
    onSetIndexInputAdd(index);
    onOpenInputAdd(uuid);
  }),
});

type CardCreate = {
  array: RCardGet[];
  sort: number;
  payload: PCreate;
};

export const createCardAndSortFX = createEffect(
  async ({ array, sort, payload }: CardCreate) => {
    const isSortMin = sort < 0;
    if (isSortMin) {
      const items: Item[] = [];
      array.forEach((item, i) => {
        items.push({
          uuid: item.uuid,
          sort: (i + 1) * 100,
        });
      });
      await cardSetPosition({ items });
    }

    const currentSort = isSortMin ? 99 : sort;
    const res = await cardCreateFX({
      title: payload.title,
      column: payload.column,
      fields: {
        marks: { add: payload.fields?.marks?.add },
        members: { add: payload.fields?.members?.add },
        sort: currentSort,
      },
    });

    return { res, currentSort };
  },
);

export const createCardAndSortIndexFX = attach({
  effect: createCardAndSortFX,
  source: { cardListsSorted: $cardListsSorted, indexInputAdd: $IndexInputAdd },
  mapParams: (payload: PCreate, { cardListsSorted, indexInputAdd }) => {
    const array = cardListsSorted[payload.column] || [];
    const currentSort = array[indexInputAdd]?.sort || 500;
    const sort =
      indexInputAdd >= array.length
        ? array[array.length - 1]?.sort || 500
        : currentSort - 1;

    return {
      array,
      sort,
      payload,
    };
  },
});

sample({
  clock: createCardAndSortIndexFX.done,

  source: { markList: $markList, accounts: $accounts },
  fn: ({ markList, accounts }, { params, result }) => {
    const marks: Mark[] | undefined = params.fields?.marks?.add?.map((item) => {
      const m = markList?.find((i) => i.uuid === item);
      return {
        uuid: item,
        title: m?.title || '',
        color: m?.color || 'none',
      };
    });

    const members: Member[] | undefined = params.fields?.members?.add?.map(
      (item) => {
        const m = accounts?.find((i) => i.uuid === item);
        return {
          uuid: item,
          email: m?.email || '',
        };
      },
    );

    const dat: RCardGet = {
      uuid: result.res.uuid,
      title: params.title,
      description: '',
      sort: result.currentSort,
      status: 'ACTIVE',
      board: '',
      column: params.column,
      owner: '',
      createdAt: Date.now() / 1000,
      members: members || [],
      marks: marks || [],
      attachments: [],
      countComments: 0,
      countAttachments: 0,
      countCreatives: 0,
    };
    return dat;
  },
  target: addCart,
});

import React, { FC, useEffect, useState } from 'react';
import { useUnit } from 'effector-react/effector-react.umd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { MuiFileInput } from 'mui-file-input';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  $isOpenFormCreateFromZip,
  cardCreativesCreateFromZipFX,
  onSetOpenFormCreateFromZip,
  sendMultipleFilesFX,
} from './model';
import { $cardCreativesGroupsList, $cardUid } from '../model';
import {
  AuditCode,
  PCardCreativesCreateFromZip,
  RCardCreativesCreateFromZip,
} from '../../../../../../../apiRPC/card/creatives';
import { isErrorProps } from '../../../../../../../apiRPC/request';
import { BootstrapTooltip } from '../../../../../../../components/BootstrapTooltip';

type Form = Omit<
  PCardCreativesCreateFromZip['fields'],
  'file' | 'auditCode'
> & {
  file: File | null;
  auditCode: string[];
};

function getBidValue(scriptString: string) {
  const regex = /pid=([^&]*)/;

  const match = regex.exec(scriptString);

  if (match && match.length >= 2) {
    return match[1];
  }
  return null;
}

const validationSchema = Yup.object().shape({
  auditCode: Yup.array().of(Yup.string().trim()),
  name: Yup.string().trim(),
  file: Yup.mixed((input): input is File => input instanceof File)
    .test(
      'fileSize',
      'Ограничение на загрузку 50 MB',
      (value) => value && value.size <= 5e7,
    )
    .nullable()
    .required('Обязательное поле'),
  groupUuid: Yup.string().nullable().trim(),
});

export const FormCreateFromZip: FC = () => {
  const [isOpenFormCreateFromZip, cardUid, cardCreativesGroupsList] = useUnit([
    $isOpenFormCreateFromZip,
    $cardUid,
    $cardCreativesGroupsList,
  ]);
  const [disabled, setDisable] = useState(false);

  const formik = useFormik<Form>({
    initialValues: { auditCode: [''], name: '', file: null, groupUuid: 'null' },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (!cardUid) return;
      setDisable(true);
      try {
        const nameAliases = values.auditCode.map((auditCode) =>
          getBidValue(auditCode),
        );
        if (
          nameAliases.find(
            (item) => (item || '').length + (values.name || '').length > 254,
          )
        ) {
          f.setFieldError('name', 'Слишком длинное название');
          return;
        }

        const formData = new FormData();
        formData.append('file', values.file!);
        formData.append('type', 'grid-creative-zip');

        const resultFile = await sendMultipleFilesFX({
          formData,
          idFile: '0',
          cardId: cardUid,
        });

        const auditCode: AuditCode[] = [];
        values.auditCode.forEach((item, index) => {
          if (!item && index > 0) return;
          const nameAlias = getBidValue(item);
          auditCode.push({
            name: nameAlias ? `${nameAlias}` : '',
            code: item,
          });
        });

        await cardCreativesCreateFromZipFX({
          card: cardUid,
          fields: {
            name: values.name,
            auditCode,
            file: resultFile.uuid,
            groupUuid: values.groupUuid === 'null' ? null : values.groupUuid,
          },
        });

        onSetOpenFormCreateFromZip(false);
        f.resetForm();
      } catch (e) {
        console.error(e);

        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      } finally {
        setDisable(false);
      }
    },
  });
  useEffect(() => {
    if (isOpenFormCreateFromZip) return;
    formik.resetForm();
    setDisable(false);
  }, [isOpenFormCreateFromZip]);

  if (!isOpenFormCreateFromZip) return null;
  return (
    <Paper>
      <Stack
        padding={1}
        spacing={2}
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Stack display="flex" direction="row" spacing={2} alignItems="center">
          <TextField
            label="Имя"
            variant="standard"
            name="name"
            inputProps={{ maxLength: 240 }}
            autoComplete="none"
            value={formik.values.name}
            error={Boolean(formik.errors.name)}
            helperText={formik.errors.name}
            onChange={formik.handleChange}
            disabled={disabled}
            size="small"
            fullWidth
          />

          <Button
            sx={{ ml: 2 }}
            variant="contained"
            size="small"
            type="submit"
            disabled={disabled}
          >
            Сохранить
          </Button>

          <IconButton
            size="small"
            onClick={() => onSetOpenFormCreateFromZip(false)}
            disabled={disabled}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>

        <FormControl size="small">
          <InputLabel>Группа</InputLabel>
          <Select
            size="small"
            defaultValue="null"
            value={formik.values.groupUuid || 'null'}
            label="Группа"
            onChange={(e: SelectChangeEvent<string>) => {
              formik.setFieldValue('groupUuid', e.target.value, true);
            }}
            MenuProps={{
              sx: { '& .MuiPopover-paper': { width: 600 } },
            }}
          >
            <MenuItem
              sx={{
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
              }}
              value="null"
            >
              <em>Без группы</em>
            </MenuItem>
            {cardCreativesGroupsList?.map((item) => (
              <MenuItem
                sx={{
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                }}
                key={item.uuid}
                value={item.uuid}
              >
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {formik.values.auditCode?.map((item, index) => (
          <TextField
            key={index}
            label="Код аудита"
            variant="standard"
            name={`auditCode[${index}]`}
            inputProps={{ maxLength: 10000 }}
            autoComplete="none"
            multiline
            value={item}
            error={Boolean(formik.errors.auditCode?.[index])}
            helperText={formik.errors.auditCode?.[index]}
            onChange={formik.handleChange}
            disabled={disabled}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            InputProps={{
              endAdornment:
                index !== 0 ? (
                  <BootstrapTooltip title="Удалить" placement="top">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        const newAuditCode = [...formik.values.auditCode];
                        newAuditCode.splice(index, 1);
                        formik.setFieldValue('auditCode', newAuditCode);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="Добавить" placement="top">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() =>
                        formik.setFieldValue('auditCode', [
                          ...formik.values.auditCode,
                          '',
                        ])
                      }
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                ),
            }}
          />
        ))}
        <MuiFileInput
          size="small"
          label="Прикрепить zip архив"
          required
          onChange={(value) => {
            formik.setFieldValue('file', value, true);
          }}
          value={formik.values.file}
          error={Boolean(formik.errors.file)}
          helperText={formik.errors.file}
          inputProps={{ accept: '.zip' }}
          disabled={disabled}
          sx={{
            '.MuiFileInput-placeholder,.MuiFileInput-TextField,.MuiFileInput-Typography-size-text,.MuiFileInput-IconButton, div,input,input[type="file" i]::-webkit-file-upload-button':
              {
                cursor: 'pointer',
              },
          }}
        />
      </Stack>
    </Paper>
  );
};

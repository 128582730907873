import { FC } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

import { useUnit } from 'effector-react';
import { decodeValueChars } from '../../../../../../../helper/methods/string';
import { LinkName } from '../LinkName';
import { Attachments } from '../../../../../../../apiRPC/card';
import {
  $accountListForFile,
  onSetOpenContent,
} from '../../../../../../../helper/attachments';
import { seTHistoryCommentTab } from '../../../../../../model/taskOverview';

const styleMedia = {
  display: 'flex',
  width: 150,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: blueGrey[50],
  flexShrink: 0,
  height: 'auto',
  backgroundSize: 'contain',
  alignSelf: 'stretch',
};
const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
const PopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10],
      },
    },
  ],
};

type Props = {
  item: Attachments;
  handleClick(uuid: string): (event: React.MouseEvent<HTMLElement>) => null;
  restore?: boolean;
};
export const CardForLink: FC<Props> = ({ item, handleClick, restore }) => {
  const accountListForFile = useUnit($accountListForFile);
  return (
    <Card key={item.uuid} sx={{ minHeight: 100, overflow: 'hidden' }}>
      <CardContent
        sx={{
          display: 'flex',
          minHeight: 100,
          justifyContent: 'start',
          alignItems: 'flex-start',
          userSelect: 'text',
          padding: '0px !important',
        }}
        aria-dropeffect="none"
        aria-grabbed={false}
        draggable="false"
        component="div"
      >
        <CardMedia
          sx={styleMedia}
          component="a"
          href={item.downloadUrl}
          target="_blank"
        >
          <Typography variant="h6" fontWeight={600} color="text.secondary">
            LINK
          </Typography>
        </CardMedia>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto', paddingY: 0.5 }}>
            <Typography
              component="div"
              variant="subtitle2"
              fontWeight={600}
              sx={{ wordBreak: 'break-all' }}
            >
              <Tooltip
                title={decodeValueChars(item.url)}
                placement="top-start"
                PopperProps={PopperProps}
              >
                <Box>
                  <Link href={item.downloadUrl} target="_blank">
                    {decodeValueChars(item.url)}
                  </Link>

                  <LinkName
                    name={decodeValueChars(item.name)}
                    uuid={item.uuid}
                  />
                </Box>
              </Tooltip>
            </Typography>

            <Box display="flex" gap={0.5} alignItems="baseline">
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                Добавлено{' '}
                {accountListForFile.get(item.account || '')?.email || null}{' '}
                {new Intl.DateTimeFormat('ru', optionsTime).format(
                  item.createdAt * 1000,
                )}
              </Typography>
              {restore && (
                <Button
                  size="small"
                  variant="text"
                  onClick={handleClick(item.uuid)}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    fontStyle="italic"
                  >
                    Восстановить
                  </Typography>
                </Button>
              )}
              {!item.comment && !restore && (
                <Button
                  size="small"
                  variant="text"
                  onClick={handleClick(item.uuid)}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    fontStyle="italic"
                  >
                    Удалить
                  </Typography>
                </Button>
              )}
              {item.comment && !restore && (
                <Button
                  size="small"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!item.comment) return;
                    onSetOpenContent(item.comment);
                    seTHistoryCommentTab('COMMENT');
                    setTimeout(() => {
                      const elementComment = document.getElementById(item.uuid);
                      if (elementComment) {
                        elementComment.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }, 300);
                  }}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    fontStyle="italic"
                  >
                    Перейти к комментарию
                  </Typography>
                </Button>
              )}
            </Box>
          </CardContent>
        </Box>
      </CardContent>
    </Card>
  );
};

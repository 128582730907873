import { combine, createEvent, createStore, forward, sample } from 'effector';
import { getId } from '../../../../../../helper/getId';
import {
  campaignBudgetBatchManage,
  campaignBudgetCreate,
  campaignBudgetDelete,
  CampaignBudgetFieldsUpdate,
  campaignBudgetGetList,
  RCampaignBudgetGetList,
} from '../../../../../../apiRPC/сampaign/campaignBudget';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';

export const onAddNewBudget = createEvent();
export const onDelNewBudget = createEvent<string>();
export const resetFormCreate = createEvent();
export const $addBudget = createStore<string[]>([])
  .on(onAddNewBudget, (state) => [...state, getId()])
  .on(onDelNewBudget, (state, payload) => state.filter((id) => id !== payload))
  .reset(resetFormCreate);

export const campaignBudgetCreateFX = errorHandlerFx(
  createEffectJWT(campaignBudgetCreate),
);

export const campaignBudgetGetListFX = errorHandlerFx(
  createEffectJWT(campaignBudgetGetList),
);
export const campaignBudgetDeleteFX = errorHandlerFx(
  createEffectJWT(campaignBudgetDelete),
);
export const campaignBudgetBatchManageFX = errorHandlerFx(
  createEffectJWT(campaignBudgetBatchManage),
);

export const resetCampaignBudgetList = createEvent();
export const $campaignBudgetList = createStore<RCampaignBudgetGetList>([])
  .on(campaignBudgetGetListFX.doneData, (_, payload) => payload)
  .on(campaignBudgetCreateFX.doneData, (state, payload) => {
    const newState = [...state, payload];
    return newState.sort((a, b) => b.month - a.month);
  })
  .on(campaignBudgetDeleteFX.done, (state, { params }) =>
    state.filter((item) => !params.items.includes(item.uuid)),
  )
  .reset(resetCampaignBudgetList);

export const resetFormChangedListBudget = createEvent();
export const setChangedListBudget = createEvent<string>();
export const delChangedListBudget = createEvent<string>();
export const $formChangedListBudget = createStore<Set<string>>(new Set())
  .on(
    setChangedListBudget,
    (state, payload) => new Set([...Array.from(state), payload]),
  )
  .on(delChangedListBudget, (state, payload) => {
    state.delete(payload);
    return new Set([...Array.from(state)]);
  })
  .reset(resetFormChangedListBudget);

export const resetErrorListBudgetUpdate = createEvent();
export const setErrorListBudgetUpdate = createEvent<string>();
export const delErrorListBudgetUpdate = createEvent<string>();
export const $formErrorBudgetUpdate = createStore<string[]>([])
  .on(setErrorListBudgetUpdate, (state, payload) => [...state, payload])
  .on(delErrorListBudgetUpdate, (state, payload) =>
    state.filter((item) => item !== payload),
  )
  .reset(resetErrorListBudgetUpdate);

export const setErrorListBudgetCreate = createEvent<string>();
export const delErrorListBudgetCreate = createEvent<string>();
export const $formErrorBudgetCreate = createStore<string[]>([])
  .on(setErrorListBudgetCreate, (state, payload) => [...state, payload])
  .on([delErrorListBudgetCreate, onDelNewBudget], (state, payload) =>
    state.filter((item) => item !== payload),
  )
  .reset(resetFormCreate);

export const $formsErrorBudget = combine(
  $formErrorBudgetUpdate,
  $formErrorBudgetCreate,
  (formDisabledBudgetUpdate, formErrorBudgetCreate) =>
    Boolean(formDisabledBudgetUpdate.length) ||
    Boolean(formErrorBudgetCreate.length),
);

export const $formsPendingBudget = combine(
  campaignBudgetGetListFX.pending,
  campaignBudgetCreateFX.pending,
  campaignBudgetBatchManageFX.pending,
  (...arg) => arg.reduce((prev, current) => prev || current),
);

export const $formsChangedBudget = combine(
  $formChangedListBudget,
  $addBudget,
  (formChangedListBudget, addBudget) =>
    Boolean(formChangedListBudget.size) || Boolean(addBudget.length),
);

export const resetFormUpdate = createEvent();
export const resetFormsBudget = createEvent();

forward({
  from: resetFormsBudget,
  to: [resetFormCreate, resetFormUpdate],
});

export const onSubmitCreateBudgetForm = createEvent();
export const onSubmitUpdateBudgetForm = createEvent();
export const onSubmitBudgetForms = createEvent();

forward({
  from: onSubmitBudgetForms,
  to: [onSubmitCreateBudgetForm, onSubmitUpdateBudgetForm],
});

export const pushListBatchUpdate = createEvent<CampaignBudgetFieldsUpdate>();
export const resetListBatchUpdate = createEvent();
export const $listBatchUpdate = createStore<CampaignBudgetFieldsUpdate[]>([])
  .on(pushListBatchUpdate, (state, payload) => {
    if (state.find((item) => item.uuid === payload.uuid)) return undefined;
    return [...state, payload];
  })
  .on(campaignBudgetBatchManageFX.finally, () => [])
  .reset(resetListBatchUpdate);

sample({
  source: {
    formChangedListBudget: $formChangedListBudget,
    campaignBudgetList: $campaignBudgetList,
  },
  clock: $listBatchUpdate,
  filter: ({ formChangedListBudget, campaignBudgetList }, listBatchUpdate) => {
    if (!listBatchUpdate.length) return false;
    if (listBatchUpdate.length !== formChangedListBudget.size) return false;
    return true;
  },
  fn: ({ formChangedListBudget, campaignBudgetList }, listBatchUpdate) => ({
    campaign: campaignBudgetList[0].campaign,
    update: listBatchUpdate,
  }),
  target: campaignBudgetBatchManageFX,
});

sample({
  clock: campaignBudgetBatchManageFX.done,
  fn: ({ params }) => ({ campaign: params.campaign }),
  target: campaignBudgetGetListFX,
});
export function resetBudget() {
  resetFormCreate();
  resetCampaignBudgetList();
  resetFormChangedListBudget();
  resetErrorListBudgetUpdate();
  resetListBatchUpdate();
}

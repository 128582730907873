import { FC } from 'react';
import {
  Typography,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { WorkspaceItem } from '../../../../apiRPC/workspace';

type Props = { workspaceData: WorkspaceItem; onChange: () => void };
export const Info: FC<Props> = ({ workspaceData, onChange }) => (
  <Box>
    <Card>
      <CardHeader
        title={
          <Typography
            variant="h4"
            color="primary"
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            {workspaceData.title}
          </Typography>
        }
      />

      <CardContent>
        <Typography
          gutterBottom
          variant="body2"
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {workspaceData.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="small"
          endIcon={<EditIcon />}
          onClick={onChange}
        >
          Редактировать
        </Button>
      </CardActions>
    </Card>
  </Box>
);

import React from 'react';
import { Box, Fade } from '@mui/material';
import data from '@emoji-mart/data';
/* @ts-ignore */
import Picker from '@emoji-mart/react';

import { MenuHeader } from '../../../../../../components/MenuHeader';
import { MenuFixed } from '../../../../../../UI/MenuFixed';
import styles from './styles.module.scss';

export type EmojiClickData = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
};
type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  onClick: (d: EmojiClickData) => void;
};
export const EmojiMenu = React.forwardRef<HTMLDivElement, Props>(
  ({ open, anchorEl, onClose, onClick }, ref) => (
    <MenuFixed
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      ref={ref}
    >
      <MenuHeader title="Эмодзи…" onClose={onClose} />
      <Box
        className={styles.emojiContainer}
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <Picker
          data={data}
          onEmojiSelect={onClick}
          emojiVersion={13.1}
          locale="ru"
          perLine={8}
          theme="light"
          previewPosition="none"
          skinTonePosition="none"
        />
      </Box>
    </MenuFixed>
  ),
);

export type uuidsCard = { uuid: string }[];
export function isDel(
  card: uuidsCard | undefined,
  uuidForDel: string[],
): boolean {
  return card?.some((item) => uuidForDel.includes(item.uuid)) ?? false;
}
export function isAdd(
  card: uuidsCard | undefined,
  uuidForAdd: string[],
): boolean {
  return uuidForAdd.some((uuid) => {
    const find = card?.find((item) => item.uuid === uuid);
    return !find;
  });
}

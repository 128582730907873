import React from 'react';
import { Divider, Fade, MenuItem } from '@mui/material';
import { useUnit } from 'effector-react';
import { MenuHeader } from '../../../../components/MenuHeader';
import { MenuFixed } from '../../../../UI/MenuFixed';
import { onOpenInputAdd } from '../../../model/card/AddCardInput';
import { columnUpdateStatusFX } from '../../../model/column';

type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  uuid: string;
  scrollerRef: React.RefObject<HTMLDivElement> | null;
};

export const ColumnMenu = React.forwardRef<HTMLDivElement, Props>(
  ({ open, anchorEl, onClose, uuid, scrollerRef }, ref) => {
    const onClickAddCart = () => {
      onClose();
      onOpenInputAdd(uuid);
      scrollerRef?.current?.scrollTo(0, 0);
    };

    const pendingArchive = useUnit(columnUpdateStatusFX.pending);
    const onArchiveColumn = () => {
      columnUpdateStatusFX({ uuid, fields: { status: 'CLOSED' } }).finally(
        onClose,
      );
    };

    return (
      <MenuFixed
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        <MenuHeader title="Действия со списком" onClose={onClose} />
        <MenuItem onClick={onClickAddCart}>Добавить карточку</MenuItem>
        <Divider />
        <MenuItem disabled={pendingArchive} onClick={onArchiveColumn}>
          Архивировать список
        </MenuItem>
      </MenuFixed>
    );
  },
);

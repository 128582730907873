import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useUnit } from 'effector-react';
import ReactRouterPrompt from 'react-router-prompt';
import Modal from '../../../../../components/Modal';
import { Budget } from './Budget';
import { UpdateForm } from './UpdateForm';
import { UpdateHeader } from './UpdateHeader';

import { $isPromptAllForm } from './model';

const Basic: FC = () => {
  const isPrompt = useUnit($isPromptAllForm);
  return (
    <Box>
      <UpdateHeader />
      <Box display="flex" flexDirection="column" gap={5}>
        <UpdateForm />
        <Budget />
      </Box>
      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};

export default Basic;

import {
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import React, { FC, useTransition } from 'react';
import { useUnit } from 'effector-react';
import {
  $isEmptyMarks,
  $listMarks,
  onHandleEmptyMarks,
  onHandleMark,
  resetListMarks,
  onSetMarks,
} from './model';
import { $markList } from '../../../GRID/model/Marks';
import { Marc } from '../../../GRID/components/Marck';

export const Marks: FC = () => {
  const markList = useUnit($markList);
  const isEmptyMarks = useUnit($isEmptyMarks);
  const listMarks = useUnit($listMarks);
  const [isPendingEmptyMarks, startTransitionEmptyMarks] = useTransition();
  const [isPendingListMarks, startTransitionListMarks] = useTransition();

  const isAllChecked = listMarks.length === markList?.length;
  const indeterminate = Boolean(listMarks.length) && !isAllChecked;

  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: 'normal' }}>Метки</ListSubheader>
      }
      disablePadding
    >
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            if (isPendingEmptyMarks) return;
            startTransitionEmptyMarks(onHandleEmptyMarks);
          }}
          role="checkbox"
          dense
          sx={{ py: 0, height: 32 }}
        >
          <Checkbox
            edge="start"
            checked={isEmptyMarks}
            tabIndex={-1}
            disableRipple
          />

          <ListItemText primary="Нет меток" />
        </ListItemButton>
      </ListItem>

      {markList?.slice(0, 3).map((item) => (
        <ListItem disablePadding key={item.uuid}>
          <ListItemButton
            onClick={() => {
              if (isPendingListMarks) return;
              startTransitionListMarks(() => {
                onHandleMark(item);
              });
            }}
            role={undefined}
            dense
            sx={{ py: 0, height: 32 }}
          >
            <Checkbox
              edge="start"
              checked={Boolean(
                listMarks.find((mark) => mark.uuid === item.uuid),
              )}
              tabIndex={-1}
              disableRipple
            />
            <Marc
              label={item.title}
              colorMark={item.color}
              size="small"
              sx={{ cursor: 'pointer' }}
            />
          </ListItemButton>
        </ListItem>
      ))}

      <ListItem role={undefined} dense sx={{ py: 0, height: 32, mt: 1 }}>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={isAllChecked}
          indeterminate={indeterminate}
          onChange={() => {
            if (isPendingListMarks) return;
            if (isAllChecked || indeterminate) {
              startTransitionListMarks(resetListMarks);
              return;
            }
            startTransitionListMarks(() => {
              if (markList) onSetMarks(markList);
            });
          }}
        />

        <FormControl fullWidth>
          <Autocomplete
            options={markList || []}
            getOptionLabel={(v) => v.title}
            multiple
            size="small"
            disableCloseOnSelect
            fullWidth
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            onChange={(_, value) => {
              if (isPendingListMarks) return;
              startTransitionListMarks(() => {
                onSetMarks(value);
              });
            }}
            value={listMarks}
            openOnFocus
            renderOption={(props, option, { selected }) => (
              <MenuItem
                {...props}
                key={option.uuid}
                value={option.uuid}
                sx={{
                  pl: '0px !important',
                  py: 0,
                  height: 32,
                  width: 'fit-content',
                  overflow: 'auto',
                  minWidth: '100%',
                }}
              >
                <Checkbox checked={selected} />
                <Marc
                  label={option.title}
                  colorMark={option.color}
                  size="small"
                  sx={{ cursor: 'pointer' }}
                />
              </MenuItem>
            )}
            renderTags={(value) => (
              <Typography variant="body2">Выбрано {value.length}</Typography>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Выбор меток" />
            )}
            noOptionsText="Не найдено"
          />
        </FormControl>
      </ListItem>
    </List>
  );
};

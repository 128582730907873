import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import Search from '../../../../../components/Search';
import { $searchFilter, onSetSearchFilter } from '../model/filter/search';

export const SearchFilter: FC = () => {
  const searchFilter = useUnit($searchFilter);
  return (
    <Search
      placeholder="Название "
      value={searchFilter}
      inputProps={{ maxLength: 80 }}
      onChange={(e) => onSetSearchFilter(e.target.value)}
      onClose={() => onSetSearchFilter('')}
    />
  );
};

import request from '../request';

export type Members = string[];
export type Fields = {
  title: string;
  description: string;
  code: string;
  members: { add?: Members; delete?: Members };
};
export type PWorkspaceCreate = {
  fields: Partial<Fields> & { title: string };
};

export type RWorkspaceCreate = { uuid: string };
export const workspaceCreate = request<RWorkspaceCreate, PWorkspaceCreate>({
  method: 'workspace.create',
});

export type PWorkspaceUpdate = {
  identifier: string;
  fields: Partial<Fields>;
};
export type RWorkspaceUpdate = boolean;
export const workspaceUpdate = request<RWorkspaceUpdate, PWorkspaceUpdate>({
  method: 'workspace.update',
});

export type MembersGet = { uuid: string; email: string };
export type PWorkspaceGet = { identifier: string };
export type WorkspaceItem = {
  uuid: string;
  title: string;
  description: string;
  owner: string;
  code: string;
  createdAt: number;
  members: MembersGet[];
};
export type RWorkspaceGet = WorkspaceItem;
export const workspaceGet = request<RWorkspaceGet, PWorkspaceGet>({
  method: 'workspace.get',
});

export type RWorkspaceGetList = {
  total: number;
  rows: WorkspaceItem[];
};
export const workspaceGetList = request<RWorkspaceGetList>({
  method: 'workspace.getList',
});

import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Fade, Menu } from '@mui/material';
import { Box } from '@mui/system';
import { useUnit } from 'effector-react';
import {
  $anchorEl,
  $cardCreativesGroupsList,
  $isOpen,
  onCloseMenuCreativesGroup,
} from './model';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import { FormCreate } from './FormCreate';
import { ItemUpdate } from './ItemUpdate';

export const MyMenu = styled(Menu)(() => ({
  '& .MuiPopover-paper': { width: 600, height: '100%' },
  '& .MuiList-root': {
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '100%',
  },
}));

export const CardCreativesGroupMenu: FC = () => {
  const [anchorEl, isOpen, cardCreativesGroupsList] = useUnit([
    $anchorEl,
    $isOpen,
    $cardCreativesGroupsList,
  ]);
  const [isOpenCreate, setOpenCreate] = useState(false);
  const onCloseCreate = () => setOpenCreate(false);

  return (
    <MyMenu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={() => onCloseMenuCreativesGroup()}
      TransitionComponent={Fade}
    >
      <MenuHeader
        title="Группы для креативов"
        onClose={onCloseMenuCreativesGroup}
        ButtonEl={
          <Button size="small" onClick={() => setOpenCreate(true)}>
            Добавить
          </Button>
        }
      />
      <Box
        paddingX={2}
        paddingY={1}
        gap={1}
        display="flex"
        flexDirection="column"
        height="calc(100% - 40px)"
      >
        {isOpenCreate && <FormCreate onCloseCreate={onCloseCreate} />}

        {cardCreativesGroupsList.map((item) => (
          <ItemUpdate key={item.uuid} title={item.title} uuid={item.uuid} />
        ))}
      </Box>
    </MyMenu>
  );
};

import * as React from 'react';
import type { FC } from 'react';
import { useUnit } from 'effector-react';
import { Drawer } from '@mui/material';

import { variant } from '@effector/reflect';
import { useEffect, useState } from 'react';
import { DrawerHeader } from '../../../components/DrawerHeader';
import { $MenuView, $openMenuR, restMenuView } from './model';
import { DefaultMenu } from './DefaultMenu';
import { CardArchival } from './CardArchival';
import { ColumnArchival } from './ColumnArchival';

const DrawerWidth = 360;

export const ComponentMenu = variant({
  source: $MenuView,
  cases: {
    Default: DefaultMenu,
    CardArchival,
    ColumnArchival,
  },
  default: DefaultMenu,
  hooks: {
    unmounted: restMenuView,
  },
});

export const RightMen: FC = () => {
  const openMenuR = useUnit($openMenuR);
  const [width, setWidth] = useState<number | undefined>(() =>
    openMenuR ? DrawerWidth : undefined,
  );
  useEffect(() => {
    if (openMenuR) {
      setWidth(DrawerWidth);
      return undefined;
    }
    const id = setTimeout(() => {
      setWidth(undefined);
    }, 300);
    return () => clearTimeout(id);
  }, [openMenuR]);

  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
        },
      }}
      variant="persistent"
      anchor="right"
      open={openMenuR}
    >
      <DrawerHeader />
      {openMenuR && <ComponentMenu />}
    </Drawer>
  );
};

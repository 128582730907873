import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { FC } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';
import { FiCalendar, FiX } from 'react-icons/fi';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { CalendarPickerView } from '@mui/x-date-pickers/internals/models';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker';

interface TCalendarFieldProps
  extends Partial<DatePickerProps<Date | null | undefined, Date | null>> {
  label: string;
  value: Date | null | undefined;
  onChange: (v: Date | null) => void;
  error?: string;
  helperText?: string;
  onClear?: () => void;
  views?: readonly CalendarPickerView[];
  inputFormat?: string;
  disableMaskedInput?: boolean;
  placeholder?: string;
  required?: boolean;
}

const CalendarField: FC<TCalendarFieldProps> = ({
  label,
  value,
  onChange,
  error,
  helperText,
  onClear,
  views,
  inputFormat = 'yyyy-MM-dd',
  placeholder = 'ГГГГ-ММ-ДД',
  disableMaskedInput,
  required,
  ...datePickerProps
}) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={ru}
    dateFormats={{ fullDate: inputFormat }}
  >
    <DatePicker
      {...datePickerProps}
      label={label}
      inputFormat={inputFormat}
      value={value}
      components={{
        OpenPickerIcon: FiCalendar,
      }}
      PopperProps={{
        placement: 'bottom-start',
      }}
      onChange={onChange}
      views={views}
      disableMaskedInput={disableMaskedInput}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder,
          }}
          required={required}
          variant="standard"
          error={Boolean(error)}
          helperText={helperText}
          sx={{
            marginLeft: 0,
            '.MuiButtonBase-root': {
              marginRight: 0,
              padding: 0,
              svg: {
                width: '20px',
                color: datePickerProps.disabled ? '#A6A6A6' : '#4B61BF',
                marginRight: '0px',
              },
            },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: (
              <>
                {params.inputProps?.value &&
                  onClear &&
                  !datePickerProps.disabled && (
                    <InputAdornment position="end">
                      <IconButton
                        id="IconButton"
                        onClick={onClear}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <FiX size={18} color="#0000008a" />
                      </IconButton>
                    </InputAdornment>
                  )}
                {params.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  </LocalizationProvider>
);

export default CalendarField;

import { useMatch, useParams } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { $board, getBoardFx, resetBoard } from '../../model/board';
import { makeSlug } from '../../../helper/makeSlug';
import { navigateForEffect } from '../../../model/routing';
import { cardGetListFX, cardGetPreloaderFX } from '../../model/card';
import {
  onOpenTaskOverview,
  resetOpenTaskOverview,
} from '../../model/taskOverview';
import { getAccountsFX } from '../../model/card/accounts';
import { columnGetListFistLoadFx, resetColumnList } from '../../model/Columns';
import { markGetListFx, resetMarkList } from '../../model/Marks';
import { setTitle } from '../../../components/Header/model';
import { setCurrentUuid } from './model';

export const Routers: FC = () => {
  const { uuid, cardId, dName } = useParams();
  const isDesk = useMatch('d/*');
  const board = useUnit($board);

  useEffect(() => {
    if (!uuid) return;
    setCurrentUuid(uuid);
  }, [uuid]);

  useEffect(() => {
    setTitle(board?.title || '');
  }, [board]);

  useEffect(() => {
    if (!isDesk) return;
    if (!board?.title) return;
    if (uuid !== board.uuid) return;
    const curentName = makeSlug(board.title);
    if (curentName === dName) return;
    navigateForEffect(`/d/${uuid}/${curentName}`, {
      replace: true,
    });
  }, [board?.title, uuid]);

  useEffect(() => {
    if (!cardId) {
      onOpenTaskOverview(false);
      return undefined;
    }
    cardGetPreloaderFX({ uuid: cardId }).then(() => onOpenTaskOverview(true));
    return resetOpenTaskOverview;
  }, [cardId]);

  const currentUuid = uuid || board?.uuid;

  useEffect(() => {
    if (!currentUuid) return undefined;
    getBoardFx({ uuid: currentUuid });
    return () => {
      resetBoard();
    };
  }, [currentUuid]);

  // надо переписать возможны баги все раскидать по модулям
  useEffect(() => {
    if (!currentUuid) return undefined;
    getAccountsFX({ uuid: currentUuid });

    columnGetListFistLoadFx({
      filter: { limit: 10000, offset: 0, board: currentUuid },
    });

    cardGetListFX({
      filter: {
        board: currentUuid,
      },
    });

    markGetListFx({ board: currentUuid });
    return () => {
      resetColumnList();
      resetMarkList();
    };
  }, [currentUuid]);

  return null;
};

import { createEffect, sample } from 'effector';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate } from '../../../../apiRPC/card';
import { updateItem } from '../index';
import { $OpenTaskOverview } from '../../taskOverview';
import { navigateForEffect } from '../../../../model/routing';
import { makeSlug } from '../../../../helper/makeSlug';

export type CardUpdateTitle = {
  uuid: string;
  fields: { title: string };
};

export const updateTitleFX = errorHandlerFx(
  createEffectJWT<CardUpdateTitle, RUpdate, Error>(cardUpdate),
);

sample({
  clock: updateTitleFX.done,
  fn: ({ params }) => ({
    uuid: params.uuid,
    title: params.fields.title,
  }),
  target: updateItem,
});

type NavigateData = { uuid: string; title: string };
sample({
  clock: updateTitleFX.done,
  source: $OpenTaskOverview,
  filter: (state) => state,
  fn: (_, { result }) => ({
    uuid: result.uuid,
    title: result.title,
  }),
  target: createEffect(({ uuid, title }: NavigateData) => {
    navigateForEffect(`/c/${uuid}/${makeSlug(title)}`, {
      replace: true,
    });
  }),
});

import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Box, TextField, IconButton, Stack, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useUnit } from 'effector-react';
import {
  $listMarksForCreate,
  $marksUuidForCreate,
  delMarksForCreate,
  markCreateFX,
  resetMarksForCreate,
  setMarksForCreate,
} from '../../../../model/Marks';
import { Marc } from '../../../../components/Marck';
import { RMarkGet } from '../../../../../apiRPC/card/mark';
import { MenuForAdd } from './MenuForAdd';
import {
  $listMembersForCreate,
  $membersForCreate,
  resetMembersForCreate,
} from '../../../../model/card/accounts';
import { AvatarMenu } from '../../../../../components/AvatarMenu';
import {
  createCardAndSortIndexFX,
  onCloseInputAdd,
  onNextIndex,
} from '../../../../model/card/AddCardInput';

const fabricHandleClickOutside =
  (
    listRefForClickOutside: React.RefObject<HTMLDivElement>[],
    onClose: () => void,
  ) =>
  (event: MouseEvent) => {
    const target = event.target;
    if (!(target instanceof Element)) return;

    if (
      listRefForClickOutside.every(
        (item) => (item.current?.contains(target) || false) === false,
      )
    ) {
      onClose();
    }
  };
export interface PropsAddCard {
  uuid: string;
  board: string;
  onLoad?: () => void;
  openInputAddFor: string | null;
}
export const AddCard: FC<PropsAddCard> = ({
  uuid,
  board,
  onLoad,
  openInputAddFor,
}) => {
  const [title, setTitle] = useState('');

  const listMembersForCreate = useUnit($listMembersForCreate);
  const membersForCreate = useUnit($membersForCreate);

  const listMarksForCreate = useUnit($listMarksForCreate);
  const marksUuidForCreate = useUnit($marksUuidForCreate);
  const pending = useUnit(createCardAndSortIndexFX.pending);

  const disabled = title.length < 3 || pending;
  const disabledTex = pending;

  const ref = useRef<HTMLDivElement>(null);
  const refMenu = useRef<HTMLDivElement>(null);
  const refMenuMarks = useRef<HTMLDivElement>(null);
  const refMarksCreate = useRef<HTMLDivElement>(null);
  const refMarksDelete = useRef<HTMLDivElement>(null);
  const refMenuMembers = useRef<HTMLDivElement>(null);

  const refTextField = useRef<HTMLInputElement>(null);

  const setFocus = () => {
    const inputs = refTextField.current?.getElementsByTagName('input');
    inputs?.item(0)?.focus({ preventScroll: true });
    onLoad?.();
  };
  useEffect(() => {
    const inputs = refTextField.current?.getElementsByTagName('input');
    inputs?.item(0)?.focus({ preventScroll: true });
  }, [refTextField]);

  useLayoutEffect(() => {
    if (!onLoad) return;
    onLoad();
  }, [onLoad]);

  const listRefForClickOutside = useMemo(
    () => [
      ref,
      refMenu,
      refMenuMarks,
      refMarksCreate,
      refMarksDelete,
      refMenuMembers,
    ],
    [
      ref,
      refMenu,
      refMenuMarks,
      refMarksCreate,
      refMarksDelete,
      refMenuMembers,
    ],
  );

  const onReset = () => {
    setTitle('');
    resetMarksForCreate();
    resetMembersForCreate();
  };
  const onClose = () => {
    onCloseInputAdd();
    onReset();
  };

  const onSend = () => {
    if (disabled) return;

    createCardAndSortIndexFX({
      title,
      column: uuid,
      fields: {
        marks: { add: marksUuidForCreate },
        members: { add: membersForCreate },
      },
    }).then(() => {
      onReset();
      onNextIndex();
      setTimeout(setFocus);
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSend();
    }
  };

  useEffect(() => {
    if (openInputAddFor === null) {
      resetMarksForCreate();
      resetMembersForCreate();
    }
  }, [openInputAddFor]);
  useEffect(() => {
    const handleClickOutside = fabricHandleClickOutside(
      listRefForClickOutside,
      onClose,
    );
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listRefForClickOutside]);

  const anchorEl = useRef<HTMLButtonElement>(null);

  const [openMenu, setOpenMenu] = useState(false);
  const handleClick = () => {
    setOpenMenu(true);
  };
  const handleClose = () => {
    setOpenMenu(false);
  };

  const onClickMark = useCallback(
    (item: RMarkGet) => {
      const isChecked = marksUuidForCreate.includes(item.uuid);

      if (!item.uuid) {
        markCreateFX({ title: '', color: item.color, board }).then((d) =>
          setMarksForCreate(d.uuid),
        );
        return;
      }
      if (isChecked) {
        delMarksForCreate(item.uuid);
        return;
      }
      setMarksForCreate(item.uuid);
    },
    [marksUuidForCreate],
  );

  return (
    <Box mt={1} component="div" ref={ref}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={0.5}
      >
        {listMarksForCreate.map((item) => (
          <Grid item key={item.uuid} overflow="hidden">
            <Marc label={item.title} colorMark={item.color} size="small" />
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={0.5}
        mt={1}
      >
        {listMembersForCreate.map((item) => (
          <Grid item key={item.uuid}>
            <AvatarMenu>{item.email}</AvatarMenu>
          </Grid>
        ))}
      </Grid>

      <TextField
        margin="normal"
        required
        fullWidth
        id="title"
        label="Ввести заголовок для этой карточки"
        name="title"
        inputProps={{ maxLength: 255 }}
        autoFocus
        onChange={(e) => setTitle(e.target.value)}
        onKeyDown={handleKeyDown}
        value={title}
        ref={refTextField}
        disabled={disabledTex}
      />

      <Stack direction="row">
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          disabled={disabled}
          onClick={onSend}
        >
          Добавить карточку
        </Button>

        <IconButton color="primary" aria-label="Отменить" onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <IconButton
          color="primary"
          aria-label="Опции"
          sx={{ marginLeft: 'auto' }}
          id="fade-button"
          aria-controls="fade-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleClick}
          ref={anchorEl}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>

      <MenuForAdd
        anchorEl={anchorEl.current}
        board={board}
        openMenu={openMenu}
        handleClose={handleClose}
        handleOpen={handleClick}
        onClickMark={onClickMark}
        listChecked={marksUuidForCreate}
        refMenu={refMenu}
        refMenuMarks={refMenuMarks}
        refMarksCreate={refMarksCreate}
        refMarksDelete={refMarksDelete}
        refMenuMembers={refMenuMembers}
      />
    </Box>
  );
};

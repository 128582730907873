import React, { FC, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Button,
  TextareaAutosize,
  CardActionAreaProps,
  CardActions,
  IconButton,
  CardMedia,
  Typography,
} from '@mui/material';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import { styled } from '@mui/material/styles';
import { useUnit } from 'effector-react';

import { blueGrey } from '@mui/material/colors';
import { AvatarMenu } from '../../../../../../components/AvatarMenu';

import { $cardComments } from '../../../../../model/card/comment';
import { Comment } from './Comment';
import { useAddComment } from '../Attachments/hooks/useAddComment';
import { EmojiClickData, EmojiMenu } from './EmojiMenu';
import { DataLink, LinkMenu } from './LinkMenu';
import {
  $listFilesForComments,
  delFileForComment,
} from '../../../../../../helper/attachments';
import { AttachmentViewer } from '../Attachments/AttachmentViewer';
import { MenuDelete as MenuDeleteContent } from '../Attachments/MenuDelete';
import { RSendMultipleFiles } from '../../../../../../apiRPC/card/file';
import { setFiles } from '../../../../../../helper/useFileUpload/useFileUpload';

const styleBoxGrid = {
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  alignItems: 'top',
  gap: 1,
};
const styleMedia = {
  display: 'flex',
  width: 150,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: blueGrey[50],
  flexShrink: 0,
  height: 'auto',
  backgroundSize: 'contain',
  alignSelf: 'stretch',
};
interface CardActionsTextProps extends CardActionAreaProps {
  open: boolean;
  component: string;
}
const CardActionsText = styled(CardActionArea, {
  shouldForwardProp: (prop) => !['open'].includes(prop.toString()),
})<CardActionsTextProps>(({ theme, open, ...props }) => ({
  '& textarea': { cursor: 'pointer' },
  ...(open && {
    '& textarea': { cursor: 'auto' },
    '&.MuiButtonBase-root.MuiCardActionArea-root': {
      cursor: 'auto',
      '& .MuiCardActionArea-focusHighlight': { display: 'none' },
      '&:hover': {
        background: 'transparent',
        '& .MuiCardActionArea-focusHighlight': { background: 'transparent' },
        '& .MuiCardContent-root': {
          background: 'transparent',
        },
      },
    },
  }),
}));

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'none',
  border: 'none',
  outline: 'none',
  ...theme.typography.body1,
}));

const cardActionsStyle = { display: 'flex' };

type Props = { uuid: string };

export const Comments: FC<Props> = ({ uuid }) => {
  const [cardComments, listFilesForComments] = useUnit([
    $cardComments,
    $listFilesForComments,
  ]);

  const {
    account,
    onOpenForm,
    refForClickOutside,
    disableRipple,
    isOpenForm,
    comment,
    setComment,
    disabledTxt,
    disabledBtn,
    onSubmit,
    refEmojiMenu,
    refLinkMenu,
    onSaveHandleKeyDown,
    refTextArea,
  } = useAddComment({ uuid });

  const anchorElEmojiMenu = useRef<HTMLButtonElement>(null);
  const [openEmojiMenu, setOpenEmojiMenu] = useState(false);

  const onOpenEmojiMenu = () => {
    setOpenEmojiMenu(true);
  };
  const onCloseEmojiMenu = () => {
    setOpenEmojiMenu(false);
  };

  const onClickEmoji = (d: EmojiClickData) => {
    setComment((v) => v + d.shortcodes);
    onCloseEmojiMenu();
  };

  const anchorElLinkMenu = useRef<HTMLButtonElement>(null);
  const [openLinkMenu, setOpenLinkMenu] = useState(false);

  const onOpenLinkMenu = () => {
    setOpenLinkMenu(true);
  };
  const onCloseLinkMenu = () => {
    setOpenLinkMenu(false);
  };

  const onSetLink = (d: DataLink) => {
    setFiles({ cardId: uuid, commentUid: 'NEW' })(
      d.url,
      d.urlName ? { name: d.urlName } : undefined,
    );
    onCloseLinkMenu();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [uuidFile, setUuidFile] = useState<string>();

  const delContent =
    // eslint-disable-next-line no-shadow
    (uuid: string) => (event: React.MouseEvent<HTMLElement>) => {
      setUuidFile(uuid);
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      event.preventDefault();
      return null;
    };
  const delClose = () => {
    delFileForComment(uuidFile || '');
    setAnchorEl(null);
  };

  const listExtForView = ['PNG', 'JPEG', 'JPG', 'TXT'];
  const [view, setView] = useState<RSendMultipleFiles | null>(null);

  const openView =
    (file: RSendMultipleFiles) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setView(file);
    };

  const closeView = () => {
    setView(null);
  };

  return (
    <>
      <Box sx={styleBoxGrid}>
        <AvatarMenu>{account?.email || ''}</AvatarMenu>
        <Card onClick={onOpenForm} ref={refForClickOutside}>
          <CardActionsText
            disableRipple={disableRipple}
            onClick={onOpenForm}
            open={isOpenForm}
            component="div"
            sx={{
              '.MuiCardActionArea-focusHighlight': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <CardContent>
              <TextArea
                aria-label="форма комментария"
                minRows={isOpenForm ? 3 : 1}
                maxLength={65535}
                placeholder="Напишите комментарий..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                disabled={disabledTxt}
                autoFocus={isOpenForm}
                onKeyDown={onSaveHandleKeyDown}
                ref={refTextArea}
                className="CommentContent"
                data-comment_id="NEW"
              />
            </CardContent>
          </CardActionsText>
          {isOpenForm && (
            <CardActions sx={cardActionsStyle}>
              <Button
                size="small"
                variant="contained"
                disabled={disabledBtn}
                onClick={onSubmit}
              >
                Сохранить
              </Button>

              <Box ml="auto">
                <IconButton
                  color="primary"
                  aria-label="Добавить реакцию"
                  ref={anchorElEmojiMenu}
                  onClick={onOpenEmojiMenu}
                >
                  <AddReactionOutlinedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={onOpenLinkMenu}
                  ref={anchorElLinkMenu}
                >
                  <AttachmentOutlinedIcon />
                </IconButton>
              </Box>
            </CardActions>
          )}
        </Card>
      </Box>
      <Box mt={1} ml={6} gap={1} display="flex" flexDirection="column">
        {listFilesForComments
          .filter((item) => item.commentUid === 'NEW')
          ?.map((item) => (
            <Card key={item.uuid} sx={{ minHeight: 100, overflow: 'hidden' }}>
              <CardActionArea
                sx={{
                  display: 'flex',
                  minHeight: 100,
                  justifyContent: 'start',
                  alignItems: 'flex-start',
                  userSelect: 'text',
                }}
                aria-dropeffect="none"
                aria-grabbed={false}
                draggable="false"
                component="a"
                href={item.downloadUrl}
                download={`${item.name}.${item.ext}`}
                onClick={
                  listExtForView.includes(item.ext.toUpperCase())
                    ? openView(item)
                    : undefined
                }
              >
                {item.type === 'image' ? (
                  <CardMedia sx={styleMedia} image={item.url} />
                ) : (
                  <CardMedia sx={styleMedia}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="text.secondary"
                    >
                      {item.ext || item.type.toUpperCase()}
                    </Typography>
                  </CardMedia>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto', paddingY: 0.5 }}>
                    <Typography
                      component="div"
                      variant="subtitle2"
                      fontWeight={600}
                      sx={{ wordBreak: 'break-all' }}
                    >
                      {item.name}.{item.ext}
                    </Typography>

                    <Box display="flex" gap={0.5} alignItems="baseline">
                      <Button
                        size="small"
                        variant="text"
                        onClick={delContent(item.uuid)}
                      >
                        <Typography
                          variant="body2"
                          component="div"
                          fontStyle="italic"
                        >
                          Удалить
                        </Typography>
                      </Button>
                    </Box>
                  </CardContent>
                </Box>
              </CardActionArea>
            </Card>
          ))}
      </Box>

      <Box mt={3} gap={2} display="flex" flexDirection="column">
        {cardComments.map((item) => (
          <Comment key={item.uuid} comment={item} />
        ))}
      </Box>
      <EmojiMenu
        open={openEmojiMenu}
        onClose={onCloseEmojiMenu}
        anchorEl={anchorElEmojiMenu.current}
        ref={refEmojiMenu}
        onClick={onClickEmoji}
      />
      <LinkMenu
        open={openLinkMenu}
        anchorEl={anchorElLinkMenu.current}
        onClose={onCloseLinkMenu}
        onSubmit={onSetLink}
        ref={refLinkMenu}
        cardUuid={uuid}
        commentUid="NEW"
      />
      <AttachmentViewer
        open={Boolean(view)}
        name={view?.name || ''}
        url={view?.url || ''}
        close={closeView}
        ext={view?.ext || ''}
        createdAt={view?.createdAt || 0}
        size={view?.size || 0}
        cardId={uuid}
        uuidFile={view?.uuid || ''}
        downloadUrl={view?.downloadUrl || ''}
        hideDelBtn
        deleted={false}
        account={view?.account || ''}
      />
      <MenuDeleteContent
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={delClose}
        uuidFile={uuidFile}
        cardId={uuid}
      />
    </>
  );
};

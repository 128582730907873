export function downloadBlob(
  content: BlobPart[],
  filename: string,
  blobOptions?: BlobPropertyBag,
) {
  const blob = new Blob(content, blobOptions);
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', filename);
  a.click();
  a.remove();
}

import { createEvent, createStore, sample } from 'effector';
import { CancelToken } from 'axios';
import { errorHandlerFx } from '../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../model/JWT';
import {
  contractGetList,
  PContractGetList,
  RContractGetList,
} from '../../../../../apiRPC/contracts';

type FasP = {
  params: PContractGetList;
  cancelToken?: CancelToken;
};
const contractGetListCancel = (p: FasP) =>
  contractGetList(p.params, p.cancelToken);
export const contractGetListFX = errorHandlerFx(
  createEffectJWT(contractGetListCancel),
);
export const contractGetListForScrollFX = errorHandlerFx(
  createEffectJWT(contractGetList),
);

export const onLoadContracts = createEvent();

export const limitContracts = 30;
export const onResetContractList = createEvent();
export const $contractList = createStore<RContractGetList['rows']>([])
  .on(contractGetListFX.doneData, (_, payload) => payload.rows)
  .on(contractGetListForScrollFX.doneData, (state, payload) => [
    ...state,
    ...payload.rows,
  ])
  .reset(onResetContractList);

const $totalRows = createStore<number>(0)
  .on(contractGetListFX.doneData, (_, payload) => payload.total)
  .on(contractGetListForScrollFX.doneData, (_, payload) => payload.total);

export const $lastFilter = createStore<PContractGetList | null>(null)
  .on(contractGetListFX.done, (_, { params }) => params.params)
  .reset(onResetContractList);

export const onRestFieldTypeContract = createEvent();
export const $fieldTypeContract = createStore<
  'loading' | 'ErrorEmpty' | 'ErrorNoContent' | 'Success'
>('loading')
  .on(contractGetListFX.fail, () => 'ErrorNoContent')
  .on(contractGetListFX.pending, (_, payload) =>
    payload ? 'loading' : undefined,
  )
  .on(contractGetListFX.done, () => 'Success')
  .on(contractGetListFX.doneData, (_, payload) =>
    payload?.rows.length ? 'Success' : 'ErrorEmpty',
  )
  .reset(onRestFieldTypeContract);

sample({
  clock: onLoadContracts,
  source: {
    lastFilter: $lastFilter,
    contractList: $contractList,
    totalRows: $totalRows,
  },
  filter: ({ contractList, totalRows }) =>
    Boolean(contractList.length) && totalRows > contractList.length,
  fn: ({ contractList, lastFilter }) => ({
    filter: {
      ...(lastFilter?.filter || {}),
      limit: limitContracts,
      offset: contractList.length,
    },
  }),
  target: contractGetListForScrollFX,
});

export function restContract() {
  onRestFieldTypeContract();
  onResetContractList();
}

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { AddCard, PropsAddCard } from '../index';
import {
  $IndexInputAdd,
  $OpenInputAddFor,
} from '../../../../../model/card/AddCardInput';

interface Props extends Omit<PropsAddCard, 'isOpen' | 'openInputAddFor'> {
  index?: number;
  cardListLength?: number;
}
export const AddCardContainer: FC<Props> = ({
  uuid,
  board,
  onLoad,
  index,
  cardListLength,
}) => {
  const openInputAddFor = useUnit($OpenInputAddFor);
  const isOpen = openInputAddFor === uuid;
  const indexInputAdd = useUnit($IndexInputAdd);
  if (!isOpen) return null;
  if (index !== undefined && index !== indexInputAdd) return null;
  if (cardListLength !== undefined && indexInputAdd < cardListLength)
    return null;

  return (
    <AddCard
      uuid={uuid}
      board={board}
      onLoad={onLoad}
      openInputAddFor={openInputAddFor}
    />
  );
};

export const AddCardContainerMemo = React.memo(AddCardContainer);

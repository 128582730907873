import { sample } from 'effector';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate } from '../../../../apiRPC/card';

import { updateCart } from '../index';

export type CardUpdateDescription = {
  uuid: string;
  fields: { description: string };
};
export const updateDescriptionFX = errorHandlerFx(
  createEffectJWT<CardUpdateDescription, RUpdate, Error>(cardUpdate),
);

sample({
  clock: updateDescriptionFX.done,
  fn: ({ params }) => ({
    uuid: params.uuid,
    description: params.fields.description,
  }),
  target: updateCart,
});

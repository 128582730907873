import React, { FC } from 'react';
import { useFormik } from 'formik';

import { Box, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useUnit } from 'effector-react';
import Modal from '../../../../../../../../components/Modal';
import { LabelForContracts } from '../../../../../../Campaigns/UI/LabelForContracts';
import { resetModalType } from '../../model/switcherForModal';
import {
  PResourcesCreate,
  Resource,
} from '../../../../../../../../apiRPC/creative/resources';
import { resourcesCreateFX } from '../../model';
import { isErrorProps } from '../../../../../../../../apiRPC/request';
import { setItemsForm } from '../../Form/model';

type Form = {
  description: Resource['description'];
  mediaExampleUrl: Resource['mediaExampleUrl'];
};

const initialValues: Form = {
  description: '',
  mediaExampleUrl: '',
};

export const ListResourceModal: FC = () => {
  const pending = useUnit(resourcesCreateFX.pending);
  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object().shape({
      description: Yup.string().required('Обязательное для заполнения поле'),
      mediaExampleUrl: Yup.string().required(
        'Обязательное для заполнения поле',
      ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      try {
        const urls = values.mediaExampleUrl.split('\n');
        const items: PResourcesCreate['items'] = urls.map((item) => ({
          type: 'resource',
          description: values.description,
          mediaExampleUrl: item.trim(),
        }));
        setItemsForm(items);
        resetModalType();
      } catch (e) {
        console.error({ e });
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const handleChange: typeof formik['handleChange'] = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value, true);
  };

  return (
    <Modal
      open
      handleClose={resetModalType}
      onConfirm={formik.submitForm}
      title="Добавление ресурсов списком"
      outlinedText="Отмена"
      containedText="Заполнить"
      disabledContained={!formik.isValid || pending}
      disabledOutlined={pending}
    >
      <Box
        mb={3}
        sx={{
          span: {
            color: '#666666',
          },
        }}
      >
        <TextField
          label={
            <LabelForContracts
              text="Описание ресурса"
              title="Общее для всех, можно изменить после вставки"
            />
          }
          sx={{ '.MuiInputLabel-root': { zIndex: 99 } }}
          inputProps={{ maxLength: 1000 }}
          variant="standard"
          placeholder="Ссылка на ресурс"
          fullWidth
          autoComplete="none"
          name="description"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description}
          value={formik.values.description}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr">
        <TextField
          label={
            <LabelForContracts
              text="Список ссылок"
              title="Каждая с новой строки"
            />
          }
          placeholder="Список ссылок"
          multiline
          rows={10}
          inputProps={{ maxLength: 1000 }}
          name="mediaExampleUrl"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.mediaExampleUrl)}
          helperText={formik.errors.mediaExampleUrl}
          value={formik.values.mediaExampleUrl}
        />
      </Box>
    </Modal>
  );
};

import { combine, createEvent, restore, sample } from 'effector';
import { ErirStatus, Status, Filter } from '../../../../../../apiRPC/creative';
import { factoryListFilter } from '../../../../../../model/factories/factoryListFilter';
import {
  campaignGetList,
  CampaignItem,
} from '../../../../../../apiRPC/сampaign';
import { $lastFilterCreative, getListCreativeFX, limit } from '../index';

export const onSetOrdFilter = createEvent<number | null>();
export const onResetOrdFilter = createEvent();
export const $ordFilter = restore(onSetOrdFilter, null).reset(onResetOrdFilter);

export const onSetStatusFilter = createEvent<Status | null>();
export const onResetStatusFilter = createEvent();
export const $statusFilter = restore(onSetStatusFilter, null).reset(
  onResetStatusFilter,
);

export const onSetErirStatusFilter = createEvent<ErirStatus | null>();
export const onResetErirStatusFilter = createEvent();
export const $erirStatusFilter = restore(onSetErirStatusFilter, null).reset(
  onResetErirStatusFilter,
);

export const {
  getListFX: getListCampaignFX,
  getListForScrollFX: getListCampaignForScrollFX,
  onGetList: onGetListCampaign,
  $list: $campaignList,
  searchList: searchCampaign,
  onLoadLists: onLoadListsCampaign,
  resetCatalogs: resetCatalogsCampaign,
  $lastFilter: $lastFilterCampaign,
} = factoryListFilter({ handler: campaignGetList });

export const onSetCampaignFilter = createEvent<CampaignItem | null>();
export const onResetCampaignFilter = createEvent();
export const $campaignFilter = restore(onSetCampaignFilter, null).reset(
  onResetCampaignFilter,
);

export const onSendOtherFilter = createEvent();

sample({
  clock: onSendOtherFilter,
  source: {
    ordFilter: $ordFilter,
    statusFilter: $statusFilter,
    erirStatusFilter: $erirStatusFilter,
    campaignFilter: $campaignFilter,
    lastFilterCreative: $lastFilterCreative,
  },
  filter: ({
    ordFilter,
    statusFilter,
    erirStatusFilter,
    campaignFilter,
    lastFilterCreative,
  }) => {
    const filter = lastFilterCreative?.filter;
    if ((filter?.ord ?? null) !== ordFilter) return true;
    if ((filter?.status ?? null) !== statusFilter) return true;
    if ((filter?.erirStatus ?? null) !== erirStatusFilter) return true;
    if ((filter?.campaign ?? null) !== campaignFilter) return true;
    return false;
  },
  fn: ({
    ordFilter,
    statusFilter,
    erirStatusFilter,
    campaignFilter,
    lastFilterCreative,
  }) => {
    const oldFilter = { ...(lastFilterCreative?.filter || {}) };

    if (ordFilter === null) {
      delete oldFilter.ord;
    } else {
      oldFilter.ord = ordFilter;
    }

    if (statusFilter === null) {
      delete oldFilter.status;
    } else {
      oldFilter.status = statusFilter;
    }

    if (erirStatusFilter === null) {
      delete oldFilter.erirStatus;
    } else {
      oldFilter.erirStatus = erirStatusFilter;
    }

    if (campaignFilter === null) {
      delete oldFilter.campaign;
    } else {
      oldFilter.campaign = campaignFilter.uuid;
    }

    const filter = {
      ...oldFilter,
      limit,
      offset: 0,
    };
    return { params: { filter } };
  },
  target: getListCreativeFX,
});

const namesFilters: (keyof Filter)[] = [
  'ord',
  'status',
  'erirStatus',
  'campaign',
];

export const $countFilters = combine($lastFilterCreative, (lastFilter) => {
  if (!lastFilter) return 0;
  return namesFilters.reduce<number>(
    (accumulator, currentValue) =>
      accumulator +
      (lastFilter.filter?.[currentValue] === null ||
      lastFilter.filter?.[currentValue] === undefined
        ? 0
        : 1),
    0,
  );
});
export function resetOtherFilter() {
  onResetOrdFilter();
  onResetStatusFilter();
  onResetErirStatusFilter();
  onResetCampaignFilter();
}
export const onResetOtherFilter = createEvent();
onResetOtherFilter.watch(resetOtherFilter);

sample({
  clock: onResetOtherFilter,
  source: {
    lastFilterCreative: $lastFilterCreative,
  },
  fn: ({ lastFilterCreative }) => {
    const filter = {
      search: lastFilterCreative?.filter?.search,
      limit,
      offset: 0,
    };
    return { params: { filter } };
  },
  target: getListCreativeFX,
});

import { createEvent, createStore } from 'effector';
import {
  columnGet,
  columnUpdate,
  RColumnGet,
  RColumnUpdate,
  Status,
} from '../../../apiRPC/column';
import { errorHandlerFx } from '../../../model/errorHandler';
import { createEffectJWT } from '../../../model/JWT';

export const columnGetFX = errorHandlerFx(createEffectJWT(columnGet));
export const resetColumn = createEvent();
export const $column = createStore<RColumnGet | null>(null)
  .on(columnGetFX.doneData, (_, payload) => payload)
  .reset(resetColumn);

export const columnUpdateFX = errorHandlerFx(createEffectJWT(columnUpdate));

export type PColumnUpdateStatus = {
  uuid: string;
  fields: { status: Status };
};
export const columnUpdateStatusFX = errorHandlerFx(
  createEffectJWT<PColumnUpdateStatus, RColumnUpdate, Error>(columnUpdate),
);

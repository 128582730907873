import { RouteObject, useNavigate, Outlet } from 'react-router-dom';
import React, { FC } from 'react';
import { useGate } from 'effector-react';
import { ORDRoutes, ORDRoutesDeprecated } from '../ORD/ORDRoutes';

import NotFound from '../pages/errors/NotFound';
import { WithMenuHeader } from '../components/containers/WithMenuHeader';
import { WorkContainerContent } from '../components/containers/WorkContainerContent';
import { GRIDRouter } from '../GRID/GRIDRouter';
import { signRouter } from './sign/signRouter';

import { navigateEf } from '../model/routing';

const GateComponent: FC = () => {
  const navigate = useNavigate();

  useGate(navigateEf, navigate);
  return <Outlet />;
};

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <GateComponent />,
    children: [
      ...signRouter,
      GRIDRouter,
      {
        path: '*',
        element: (
          <WithMenuHeader>
            <WorkContainerContent />
          </WithMenuHeader>
        ),
        children: ORDRoutesDeprecated,
      },

      ORDRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

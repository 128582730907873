import { FC } from 'react';
import { variant } from '@effector/reflect';
import { BasicTable } from './Table';
import { OrgHeader } from './OrgHeader';
import { $fieldType, organizationGetListFX, resetOrganizations } from './model';
import { Loadding } from '../../components/Loadding';
import { Empty } from '../../components/Errors/Empty';
import { NoConnect } from '../../components/Errors/NoConnect/NoConnect';
import { ContainerOrd } from '../ContainerOrd';

export const ComponentTable = variant({
  source: $fieldType,
  cases: {
    Success: BasicTable,
    loading: Loadding,
    ErrorEmpty: Empty,
    ErrorNoContent: NoConnect,
  },
  default: Loadding,
  hooks: {
    mounted: () => {
      organizationGetListFX(undefined);
    },
    unmounted: resetOrganizations,
  },
});

export const Organizations: FC = () => (
  <ContainerOrd>
    <OrgHeader />
    <ComponentTable />
  </ContainerOrd>
);

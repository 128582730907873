import { createEvent, restore, sample } from 'effector';
import { errorHandlerFx } from '../../../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../../../model/JWT';
import { sendMultipleFiles } from '../../../../../../../../apiRPC/card/file';
import { cardCreativesCreateFromZip } from '../../../../../../../../apiRPC/card/creatives';

export const onSetOpenFormCreateFromZip = createEvent<boolean>();
export const resetOpenFormCreateFromZip = createEvent();
export const $isOpenFormCreateFromZip = restore(
  onSetOpenFormCreateFromZip,
  false,
).reset(resetOpenFormCreateFromZip);

export const sendMultipleFilesFX = errorHandlerFx(
  createEffectJWT(sendMultipleFiles),
);
export const cardCreativesCreateFromZipFX = errorHandlerFx(
  createEffectJWT(cardCreativesCreateFromZip),
);

import { createEffect, createEvent, createStore } from 'effector';
import { signIn } from '../../apiRPC/account';
import { setJWTInfo } from '../JWT/methods';
import { ErrorProps } from '../../apiRPC/request';
import { navigateForEffect } from '../routing';
import { lastUrl } from '../JWT/lastUrl';

export const signInFx = createEffect(signIn);

export const onResetError = createEvent();
export const signInError$ = createStore<Error | ErrorProps | null>(null)
  .on(signInFx.failData, (_, payload) => payload)
  .reset(onResetError);

signInFx.doneData.watch((payload) => {
  setJWTInfo(payload);
  navigateForEffect(lastUrl.getUrl() || '/');
  lastUrl.reset();
});

import {
  Box,
  Button,
  Divider,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material/styles';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import emoji from 'remark-emoji';
import { ItemContent, Virtuoso } from 'react-virtuoso';
import { AvatarMenu } from '../../../../../../components/AvatarMenu';
import {
  $cardHistoryGetList,
  cardHistoryGetListListenerFX,
  onLoadCardHistory,
  onResetCardHistoryGetList,
} from './model';
import { MarkdownA } from '../../../../../../UI/MarkdownComponent';
import { HistoryRow } from '../../../../../../apiRPC/card/cardHistory';
import { getIndexNEntry } from '../../../../../../helper/getIndexNEntry';

const remarkPlugins: PluggableList = [
  remarkGfm,
  remarkParse as PluggableList[0],
  emoji,
];
interface PTypographyComment extends TypographyProps {
  component: string;
}
const TypographyComment = styled(Typography)<PTypographyComment>(() => ({
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  '& p': { margin: 'auto' },
}));
const markdownComponent = {
  a: MarkdownA,
};

const styleBoxGrid = {
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  alignItems: 'top',
  gap: 1,
};
const overflowHidden = { overflow: 'hidden' };
type Props = {
  uuid: string;
};
const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const maxStringLength = 350;

export const HistoryItem: FC<{ item: HistoryRow }> = ({ item }) => {
  const isBigText =
    item.actionText.length > maxStringLength ||
    item.actionText.split('\n').length - 1 > 3;

  const [isShort, setShort] = useState(isBigText);

  const indexNEntry = getIndexNEntry(item.actionText, '\n', 4);
  const maxIndex = Math.min(
    maxStringLength,
    indexNEntry === -1 ? Infinity : indexNEntry,
  );

  const onOpenText = () => {
    setShort(false);
  };
  const onCloseText = () => {
    setShort(true);
  };

  const message = isShort
    ? `${item.actionText.slice(0, maxIndex)} ...`
    : item.actionText;
  return (
    <Box sx={styleBoxGrid} key={item.actionTimestamp + item.actionText}>
      <AvatarMenu>{item.email}</AvatarMenu>
      <Box gap={1} display="flex" flexDirection="column" sx={overflowHidden}>
        <Box gap={1} display="flex" alignItems="end">
          <Typography fontWeight={600} variant="body2">
            {item.email}
          </Typography>
          <Typography fontSize={12} color="text.secondary">
            {new Intl.DateTimeFormat('ru', optionsTime).format(
              item.actionTimestamp * 1000,
            )}
          </Typography>

          {isBigText && (
            <>
              {!isShort && (
                <Button
                  onClick={onCloseText}
                  variant="text"
                  sx={{ ml: 'auto', fontSize: 12 }}
                >
                  Свернуть
                </Button>
              )}
              {isShort && (
                <Button
                  onClick={onOpenText}
                  variant="text"
                  sx={{ ml: 'auto', fontSize: 12 }}
                >
                  Развернуть
                </Button>
              )}
            </>
          )}
        </Box>
        <Box sx={{ py: 1 }}>
          <TypographyComment variant="body2" component="div">
            <ReactMarkdown
              remarkPlugins={remarkPlugins}
              linkTarget="_blank"
              components={markdownComponent}
              className="_Markdown"
            >
              {message}
            </ReactMarkdown>
          </TypographyComment>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};

const item: ItemContent<HistoryRow, void> = (_, history) => (
  <HistoryItem item={history} />
);
export const History: FC<Props> = ({ uuid }) => {
  const [cardHistoryGetList] = useUnit([$cardHistoryGetList]);

  useEffect(() => {
    onLoadCardHistory(uuid);
    cardHistoryGetListListenerFX({
      card: uuid,
      filter: { limit: 10, offset: 0 },
    });
    return () => {
      onResetCardHistoryGetList();
    };
  }, [uuid]);

  const fetchData = () => {
    onLoadCardHistory(uuid);
  };

  return (
    <Box sx={{ height: 600 }}>
      <Virtuoso
        computeItemKey={(index, row) => row.actionTimestamp + row.actionText}
        totalCount={cardHistoryGetList.length}
        data={cardHistoryGetList}
        itemContent={item}
        endReached={fetchData}
        initialItemCount={0}
      />
    </Box>
  );
};

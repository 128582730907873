import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Fade,
  MenuItem,
  MenuList,
  Typography,
  TextField,
  Button,
} from '@mui/material';

import { MenuFixed } from '../../../../../../UI/MenuFixed';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import styles from './styles.module.scss';
import { useFileUpload } from '../../../../../../helper/useFileUpload/useFileUpload';

type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  cardId: string;
};

export const MenuAtachments: FC<Props> = ({
  open,
  anchorEl,
  onClose,
  cardId,
}) => {
  const { setFiles } = useFileUpload();

  const [url, setUrl] = useState('');
  const [urlName, setUrlName] = useState('');
  const onSendUrl = () => {
    setFiles({ cardId })(url, urlName ? { name: urlName } : undefined);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setUrl('');
      setUrlName('');
    }
  }, [open]);
  return (
    <MenuFixed
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuHeader title="Прикрепить…" onClose={onClose} />
      <MenuList disabledItemsFocusable disableListWrap>
        <MenuItem component="label">
          <Typography variant="body2">С компьютера</Typography>
          <input
            type="file"
            multiple
            className={styles.hideInput}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setFiles({ cardId })(e);
              onClose();
            }}
          />
        </MenuItem>
        <Divider />
        <Box paddingX={2} paddingY={1}>
          <Typography variant="body2" fontWeight={600}>
            Прикрепить ссылку{' '}
          </Typography>

          <TextField
            margin="dense"
            hiddenLabel
            variant="filled"
            size="small"
            placeholder="Вставте сюда любую ссылку"
            fullWidth
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />

          {url && (
            <TextField
              margin="dense"
              hiddenLabel
              variant="filled"
              size="small"
              placeholder="Название файла (необязательно)"
              inputProps={{ maxLength: 255 }}
              fullWidth
              value={urlName}
              onChange={(e) => {
                setUrlName(e.target.value);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          )}

          <Button
            variant="outlined"
            size="small"
            onClick={onSendUrl}
            disabled={!url}
          >
            Прикрепить
          </Button>
        </Box>
        <Divider />
        <Box paddingX={2}>
          <Typography
            component="div"
            variant="subtitle2"
            sx={{ wordBreak: 'break-word' }}
          >
            Совет: вы можете перетаскивать файлы и ссылки прямо на карточки,
            чтобы загружать их.
          </Typography>
        </Box>
      </MenuList>
    </MenuFixed>
  );
};

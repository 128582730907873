import { Box } from '@mui/material';
import React, { FC, ReactNode } from 'react';

type props = {
  children: ReactNode;
};

const Header: FC<props> = ({ children }) => (
  <Box
    display="grid"
    gridTemplateColumns="auto auto"
    gap="0 40px"
    alignItems='center'
    flex="none"
    height={80}
  >
    {children}
  </Box>
);

export default Header;

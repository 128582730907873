import request from '../request';

export type CampaignBudgetFields = {
  uuid: string;
  campaign: string;
  month: number;
  monthF: string;
  value: number;
  valueSC: number;
  rateSC: number;
};
export type CampaignBudgetFieldsCreate = Pick<
  CampaignBudgetFields,
  'month' | 'value' | 'valueSC'
>;
export type PCampaignBudgetCreate = {
  campaign: string;
  fields: CampaignBudgetFieldsCreate;
};

export type RCampaignBudgetCreate = CampaignBudgetFields;

export const campaignBudgetCreate = request<
  RCampaignBudgetCreate,
  PCampaignBudgetCreate
>({
  method: 'campaignBudget.create',
});

export type PCampaignBudgetDelete = { campaign: string; items: string[] };
export type RCampaignBudgetDelete = CampaignBudgetFields[];
export const campaignBudgetDelete = request<
  RCampaignBudgetDelete,
  PCampaignBudgetDelete
>({
  method: 'campaignBudget.delete',
});

export type CampaignBudgetFieldsUpdate = Pick<
  CampaignBudgetFields,
  'value' | 'valueSC' | 'uuid'
>;
export type PCampaignBudgetUpdate = {
  campaign: string;
  fields: CampaignBudgetFieldsUpdate;
};
export type RCampaignBudgetUpdate = CampaignBudgetFields;
export const campaignBudgetUpdate = request<
  RCampaignBudgetUpdate,
  PCampaignBudgetUpdate
>({
  method: 'campaignBudget.update',
});
export type PCampaignBudgetBatchManage = {
  campaign: string;
  add?: CampaignBudgetFieldsCreate[];
  update?: CampaignBudgetFieldsUpdate[];
  delete?: string[];
};

export type RCampaignBudgetBatchManage = CampaignBudgetFields[];
export const campaignBudgetBatchManage = request<
  RCampaignBudgetBatchManage,
  PCampaignBudgetBatchManage
>({
  method: 'campaignBudget.batchManage',
});
export type PCampaignBudgetGetList = { campaign: string };
export type RCampaignBudgetGetList = CampaignBudgetFields[];
export const campaignBudgetGetList = request<
  RCampaignBudgetGetList,
  PCampaignBudgetGetList
>({
  method: 'campaignBudget.getList',
});

import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Typography } from '@mui/material';
import { Container } from '../Container';
import EmptySvg from '../../../../assets/icons/errors/empty.svg';

export const Empty: FC = () => (
  <Container>
    <ReactSVG src={EmptySvg} />
    <Typography fontSize={20} mt={5} fontWeight={700} mb={1.3}>
      По данному запросу ничего не найдено
    </Typography>
    <Typography>
      Возможно, что в запросе одно или несколько слов написаны
      неправильно.Попробуйте задать другой запрос.
    </Typography>
  </Container>
);

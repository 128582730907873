import React, { FC, useEffect } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useUnit } from 'effector-react';
import { useParams } from 'react-router-dom';
import { CreateBudget } from './CreateBudget';
import {
  $addBudget,
  $campaignBudgetList,
  campaignBudgetGetListFX,
  onAddNewBudget,
  resetBudget,
} from '../model/budget';
import { UpdateBudget } from './UpdateBudget';

export const Budget: FC = () => {
  const [addBudget, campaignBudgetList] = useUnit([
    $addBudget,
    $campaignBudgetList,
  ]);

  const { uuid: campaign } = useParams();
  useEffect(() => {
    if (!campaign) return undefined;
    campaignBudgetGetListFX({ campaign });
    return resetBudget;
  }, [campaign]);
  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Paper
        elevation={0}
        sx={{
          padding: '19px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '175%',
            }}
          >
            Бюджет
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={{
                height: '42px',
              }}
              onClick={() => onAddNewBudget()}
            >
              Добавить
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '1fr',
          gap: '20px 0',
        }}
      >
        {addBudget.map((id) => (
          <CreateBudget key={id} createId={id} />
        ))}
        {campaignBudgetList.map((data) => (
          <UpdateBudget key={data.uuid} data={data} />
        ))}
      </Box>
    </Box>
  );
};

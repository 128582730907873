import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';

type TLoadingGridProps = {
  count: number;
};

const LoadingGrid: FC<TLoadingGridProps> = ({ count }) => {
  const params = new Array(count);

  for (let i = 0; i < params.length; i += 1) {
    params[i] = (
      <Box key={count * i}>
        <Skeleton height={17} width={200} />
        <Skeleton height={32} />
      </Box>
    );
  }

  return <>{params.map((param) => param)}</>;
};

export default LoadingGrid;

import { useUnit } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { columnUpdateFX } from '../../../model/column';
import { updateColumn } from '../../../model/Columns';

export function useChangeTitle(title: string, uuid: string) {
  const pendingUpdateColumn = useUnit(columnUpdateFX.pending);
  const [isEditTitle, setEditTitle] = useState(false);
  const onOpenInput = () => setEditTitle(true);
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Обязательно для заполнения')
        .trim()
        .min(3, 'Минимум 3 знака'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      if (values.title === title) {
        setEditTitle(false);
        return;
      }
      columnUpdateFX({ uuid, fields: { title: values.title } }).then(() => {
        updateColumn({ uuid, fields: { title: values.title } });
        setEditTitle(false);
      });
    },
  });

  useEffect(() => {
    setEditTitle(false);
    formik.setFieldValue('title', title);
  }, [title]);

  function onFormError() {
    if (formik.errors.title) {
      setEditTitle(false);
      formik.resetForm({ values: { title }, errors: undefined });
      return true;
    }
    return false;
  }

  const onBlur = () => {
    if (onFormError()) return;
    formik.submitForm();
  };
  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;
    if (onFormError()) return;
    formik.submitForm();
  };

  return {
    onOpenInput,
    isEditTitle,
    formik,
    onPressEnter,
    pendingUpdateColumn,
    onBlur,
  };
}

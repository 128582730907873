import React, { FC } from 'react';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';

import { MenuFixed } from '../../../../../../UI/MenuFixed';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import styles from './styles.module.scss';
import { useDateMenu } from './hooks';

interface OutlinedInputForDateProps extends OutlinedInputProps {
  isFocused: boolean;
}
const OutlinedInputForDate = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== 'isFocused',
})<OutlinedInputForDateProps>(({ isFocused }) => ({
  width: 110,
  fontSize: 14,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: isFocused ? '#1976d2' : undefined,
    borderWidth: isFocused ? 2 : undefined,
  },
}));

export const DateMenu: FC = () => {
  const {
    anchorEl,
    open,
    onClose,
    activeClass,
    selectedDate,
    onChange,
    startDate,
    endDate,
    range,
    isFocusedStart,
    checkedStart,
    onCheckStart,
    startDateForInput,
    setFocus,
    setStartDateForInput,
    validateAndPropagate,
    setStartDate,
    bufferStartDate,
    isFocusedEnd,
    checkedEnd,
    onCheckEnd,
    endDateForInput,
    setEndDateForInput,
    setEndDate,
    bufferEndDate,
    endTimeForInput,
    setEndTimeForInput,
    validateAndPropagateTime,
    onSubmit,
    pending,
    onDelete,
  } = useDateMenu();
  return (
    <MenuFixed
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuHeader title="Даты" onClose={onClose} />
      <Box paddingX={2} paddingY={1} className={styles.container}>
        <DatePicker
          dayClassName={activeClass}
          selected={selectedDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={range}
          inline
          locale={ru}
        />

        <Typography
          color={isFocusedStart ? 'primary' : 'grey'}
          fontSize="small"
          fontWeight={600}
        >
          Начало
        </Typography>
        <Stack spacing={1} direction="row" alignItems="left">
          <Checkbox
            size="small"
            checked={checkedStart}
            onChange={onCheckStart}
          />
          <OutlinedInputForDate
            value={startDateForInput}
            placeholder="Д.M.ГГГГ"
            size="small"
            onFocus={() => setFocus('start')}
            disabled={!checkedStart}
            isFocused={isFocusedStart}
            onChange={(e) => setStartDateForInput(e.target.value)}
            onBlur={(e) =>
              validateAndPropagate(
                e.target.value,
                setStartDate,
                bufferStartDate.current || new Date(),
              )
            }
          />
        </Stack>

        <Typography
          color={isFocusedEnd ? 'primary' : 'grey'}
          fontSize="small"
          fontWeight={600}
        >
          Срок
        </Typography>
        <Stack spacing={1} direction="row" alignItems="left">
          <Checkbox size="small" checked={checkedEnd} onChange={onCheckEnd} />
          <OutlinedInputForDate
            placeholder="Д.M.ГГГГ"
            size="small"
            value={endDateForInput}
            onFocus={() => setFocus('end')}
            isFocused={isFocusedEnd}
            disabled={!checkedEnd}
            onChange={(e) => setEndDateForInput(e.target.value)}
            onBlur={(e) =>
              validateAndPropagate(
                e.target.value,
                setEndDate,
                bufferEndDate.current || new Date(),
              )
            }
          />
          <OutlinedInputForDate
            placeholder="Ч:мм"
            size="small"
            value={endTimeForInput}
            onFocus={() => setFocus('end')}
            isFocused={isFocusedEnd}
            disabled={!checkedEnd}
            onChange={(e) => setEndTimeForInput(e.target.value)}
            onBlur={(e) =>
              validateAndPropagateTime(
                e.target.value,
                setEndDate,
                bufferEndDate.current || new Date(),
              )
            }
          />
        </Stack>

        <FormControl fullWidth size="small" margin="dense">
          <Button
            variant="contained"
            size="small"
            onClick={onSubmit}
            disabled={pending}
          >
            Сохранить
          </Button>
        </FormControl>

        <FormControl fullWidth size="small">
          <Button
            variant="outlined"
            size="small"
            onClick={onDelete}
            disabled={pending}
          >
            Удалить
          </Button>
        </FormControl>
      </Box>
    </MenuFixed>
  );
};

import React, { FC, useEffect } from 'react';
import { Box, Button, ListItem } from '@mui/material';
import { useUnit } from 'effector-react';

import { FilterWrapper } from '../../../../../components/Filter';
import SelectField from '../../../../../UI/form/SelectField';
import { $ordOption } from '../../../../model/ord';
import { ErirStatus, Status } from '../../../../../apiRPC/creative';
import {
  $campaignFilter,
  $campaignList,
  $countFilters,
  $erirStatusFilter,
  $ordFilter,
  $statusFilter,
  onGetListCampaign,
  onLoadListsCampaign,
  onResetCampaignFilter,
  onResetOtherFilter,
  onSendOtherFilter,
  onSetCampaignFilter,
  onSetErirStatusFilter,
  onSetOrdFilter,
  onSetStatusFilter,
  resetCatalogsCampaign,
  searchCampaign,
} from '../model/filters/otherFilter';
import { ContractAutocomplete } from '../../../../components/ContractAutocomplete';
import { getListCreativeFX } from '../model';

export const dataErirStatus: { label: string; value: ErirStatus }[] = [
  {
    label: 'Не отправляется',
    value: 'NONE',
  },
  {
    label: 'В ожидании',
    value: 'PENDING',
  },
  {
    label: 'Успешно',
    value: 'SUCCESS',
  },
  {
    label: 'Ошибка',
    value: 'ERROR',
  },
];

const optionsStatus: {
  label: string;
  value: Status;
}[] = [
  {
    label: 'Выгружено',
    value: 'success',
  },
  {
    label: 'Ожидает выгрузки',
    value: 'pending',
  },
  {
    label: 'Ошибка',
    value: 'error',
  },
];

export const OtherFilters: FC = () => {
  const [
    ordOption,
    ordFilter,
    campaignList,
    campaignFilter,
    pending,
    statusFilter,
    erirStatusFilter,
    countFilters,
  ] = useUnit([
    $ordOption,
    $ordFilter,
    $campaignList,
    $campaignFilter,
    getListCreativeFX.pending,
    $statusFilter,
    $erirStatusFilter,
    $countFilters,
  ]);

  const ordFilterValue = ordOption.find((item) => item.value === ordFilter);

  useEffect(() => {
    onGetListCampaign();
  }, []);

  const statusFilterValue = optionsStatus.find(
    (item) => item.value === statusFilter,
  );

  const erirStatusFilterValue = dataErirStatus.find(
    (item) => item.value === erirStatusFilter,
  );

  return (
    <FilterWrapper
      id="OtherFilters"
      count={countFilters}
      actions={
        <>
          <Button
            variant="text"
            disabled={pending}
            onClick={() => onResetOtherFilter()}
          >
            Сбросить фильтр
          </Button>
          <Button
            variant="contained"
            disabled={pending}
            onClick={() => onSendOtherFilter()}
          >
            Применить
          </Button>
        </>
      }
    >
      <SelectField
        label="Статус"
        options={optionsStatus}
        value={statusFilterValue || null}
        onChange={(_, v) => {
          onSetStatusFilter(v?.value ?? null);
        }}
      />
      <SelectField
        label="Статус ЕРИР"
        options={dataErirStatus}
        value={erirStatusFilterValue || null}
        onChange={(_, v) => {
          onSetErirStatusFilter(v?.value ?? null);
        }}
      />

      <Box
        sx={{
          position: 'relative',
        }}
      >
        <ContractAutocomplete
          options={campaignList}
          value={campaignFilter}
          sx={{
            '.MuiInputLabel-root': { zIndex: 99 },
          }}
          label="Кампания"
          placeholder="Кампания"
          onInputChange={(event, value, reason) => {
            if (reason === 'clear') {
              onResetCampaignFilter();
              resetCatalogsCampaign();
            }
          }}
          onChange={(_, v) => {
            onSetCampaignFilter(v);
          }}
          variant="outlined"
          onScrollEnd={onLoadListsCampaign}
          search={searchCampaign}
          getOptionLabel={(option) => `${option?.name} `}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              component="li"
              key={option?.uuid}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {option?.name}
            </ListItem>
          )}
        />
      </Box>

      <SelectField
        label="ORD"
        options={ordOption}
        value={ordFilterValue || null}
        onChange={(_, v) => {
          onSetOrdFilter(v?.value ?? null);
        }}
      />
    </FilterWrapper>
  );
};

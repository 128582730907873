import { styled } from '@mui/material/styles';
import { Menu } from '@mui/material';

export const MenuFixed = styled(Menu)(() => ({
  '& .MuiPopover-paper': { width: 320 },
  '& .MuiList-root': {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

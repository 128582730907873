import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { generatePath } from 'react-router-dom';
import { errorHandlerFx } from '../../../model/errorHandler';
import { createEffectJWT } from '../../../model/JWT';
import {
  RWorkspaceGet,
  RWorkspaceGetList,
  workspaceCreate,
  workspaceGet,
  workspaceGetList,
  workspaceUpdate,
} from '../../../apiRPC/workspace';
import { getLastWorkspace, setLastWorkspace } from './storage';
import { addressesGRID } from '../../addresses';

export const onSetOpenWorkspaceCreate = createEvent<boolean>();
export const $openWorkspaceCreate = restore(onSetOpenWorkspaceCreate, false);

export const onSelectWorkspace = createEvent<string>();
// export const onSetWorkspace = createEvent<string>();
const defaultWorkspace =
  getLastWorkspace() || '921d88de-a273-464f-89c8-601bf0d53040';
export const $selectWorkspace = restore(onSelectWorkspace, defaultWorkspace);

export const workspaceCreateFX = errorHandlerFx(
  createEffectJWT(workspaceCreate),
);

export const workspaceGetListFX = errorHandlerFx(
  createEffectJWT(workspaceGetList),
);

export const $workspaceList = createStore<RWorkspaceGetList | null>(null).on(
  workspaceGetListFX.doneData,
  (_, payload) => payload,
);

export const $selectWorkspaceData = combine(
  $selectWorkspace,
  $workspaceList,
  (selectWorkspace, workspaceList) =>
    workspaceList?.rows.find((item) => item.uuid === selectWorkspace),
);

export const workspaceUpdateFX = errorHandlerFx(
  createEffectJWT(workspaceUpdate),
);

export const workspaceGetFX = errorHandlerFx(createEffectJWT(workspaceGet));
export const workspaceGetReset = createEvent();
export const $workspace = createStore<RWorkspaceGet | null>(null)
  .on(workspaceGetFX.doneData, (_, payload) => payload)
  .on(workspaceUpdateFX.done, (state, { params }) => {
    if (!state) return undefined;

    return {
      ...state,
      ...{
        title: params?.fields?.title || '',
        description: params?.fields?.description || '',
      },
    };
  })
  .reset(workspaceGetReset);

sample({
  clock: [workspaceCreateFX.doneData, workspaceUpdateFX.doneData],
  fn: () => undefined,
  target: workspaceGetListFX,
});
sample({
  clock: workspaceCreateFX.doneData,
  fn: ({ uuid }) => uuid,
  target: createEffect((workspace: string) => {
    generatePath(addressesGRID.desksWorkspacePath, { workspace });
    onSelectWorkspace(workspace);
    setLastWorkspace(workspace);
  }),
});

sample({
  clock: $selectWorkspace,
  target: createEffect((workspace: string) => {
    setLastWorkspace(workspace);
  }),
});

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { Box, Paper } from '@mui/material';
import { $creative } from '../../model';
import CreativeInfo from '../../CreativeInfo';
import {
  erirStatusMapStat,
  erirStatusMapText,
  loadingStatusMapStat,
  loadingStatusMapText,
} from '../../../../options';
import { DateFormat } from '../../../../../../../helper/methods/DateFormat';

export const Statuses: FC = () => {
  const creative = useUnit($creative);
  if (!creative) return null;

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '30px',
      }}
    >
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px">
        <CreativeInfo
          text={`${loadingStatusMapText.get(creative.loadingStatus)} ${
            creative.loadingAt
              ? `• ${DateFormat(creative.loadingAt * 1000)}`
              : ''
          }`}
          status={loadingStatusMapStat.get(creative.loadingStatus) || null}
        />
        <CreativeInfo
          text={`${erirStatusMapText.get(creative.erirStatus)} ${
            creative.erirExportedOn
              ? `•  ${DateFormat(creative.erirExportedOn * 1000)}`
              : ''
          }`}
          status={erirStatusMapStat.get(creative.erirStatus) || null}
        />
      </Box>
    </Paper>
  );
};

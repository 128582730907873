import React, { FC } from 'react';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { themeHeader } from '../theme';

const theme = createTheme(themeHeader);
export const ORD: FC = () => (
  <ThemeProvider theme={theme}>
    <Box
      sx={{
        lineHeight: '1px',
      }}
    >
      ОРД
    </Box>
    <Box ml="auto" mr={1} />
  </ThemeProvider>
);

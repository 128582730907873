import { createEvent, createStore, restore } from 'effector';

const R_MENU_IS_OPEN = 'R_MENU_IS_OPEN';
type MenuState = 'true' | 'false';
const setMenuState = (v: MenuState) =>
  window.localStorage.setItem(R_MENU_IS_OPEN, v);

const getMenuState = (): MenuState =>
  (window.localStorage.getItem(R_MENU_IS_OPEN) || 'false') as MenuState;

const getMenuStateBoolean = () => getMenuState() === 'true';

export const setOpenMenuR = createEvent<boolean>();
export const toggleMenuR = createEvent();
export const $openMenuR = restore(setOpenMenuR, getMenuStateBoolean()).on(
  toggleMenuR,
  (s) => !s,
);

$openMenuR.watch((p) => (p ? setMenuState('true') : setMenuState('false')));

type TMenuView = 'Default' | 'CardArchival' | 'ColumnArchival';

export const restMenuView = createEvent();
export const setMenuView = createEvent<TMenuView>();
export const $MenuView = createStore<TMenuView>('Default')
  .on(setMenuView, (_, p) => p)
  .reset(restMenuView);

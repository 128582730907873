import React, { useCallback, useRef, useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Grid,
  Stack,
  Tooltip,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import { useUnit } from 'effector-react';
import { Dates, Mark, Member } from '../../../../../apiRPC/card';
import { Marc } from '../../../../components/Marck';
import { AvatarMenu } from '../../../../../components/AvatarMenu';
import { EditMenu } from './EditMemu';
import { navigateForEffect } from '../../../../../model/routing';
import { makeSlug } from '../../../../../helper/makeSlug';
import { DatesInfo } from './DatesInfo';
import { YouMembericon } from './YouMembericon';
import { Title } from './Title';
import { setFiles } from '../../../../../helper/useFileUpload/useFileUpload';
import { throttle } from '../../../../../helper/throttle';
import { $OpenTaskOverview } from '../../../../model/taskOverview';

const CardActionsStyle = { flexDirection: 'column' };

const gridIconStyle = {
  marginTop: 0.5,
  padding: 1,
  paddingBottom: 0,
  marginLeft: '0!important',
};
const PopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -15],
      },
    },
  ],
};
const styleActionArea = {
  '&:hover .MuiIconButton-root': {
    opacity: 1,
  },
};
const BtnEdit = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 1,
  right: 1,
  opacity: 0,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

type Props = {
  title: string;
  createdAt: number;
  uuid: string;
  board: string;
  marks: Mark[];
  members: Member[];
  attachmentsCount: number;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dates?: Dates | null;
  hideMenu?: boolean;
  countComments: number;
  countAttachments: number;
};
export const Task = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      createdAt,
      uuid,
      board,
      marks,
      members,
      attachmentsCount,
      draggableProps,
      dragHandleProps,
      dates,
      hideMenu,
      countAttachments,
      countComments,
    },
    ref,
  ) => {
    const anchorEditMenu = useRef<HTMLAnchorElement>(null);
    const [openEditMenu, setOpenEditMenu] = useState(false);

    const onOpenEditMenu = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      setOpenEditMenu(true);
      e.preventDefault();
      e.stopPropagation();
    };
    const onCloseEditMenu = () => {
      setOpenEditMenu(false);
    };

    const onClickCard = (e: React.MouseEvent<HTMLAnchorElement>) => {
      navigateForEffect(`/c/${uuid}/${makeSlug(title)}`);
      e.stopPropagation();
      e.preventDefault();
    };

    const handlePaste = useCallback(
      throttle((e: ClipboardEvent) => {
        if (!e.clipboardData) return;
        if ($OpenTaskOverview.getState()) return;
        setFiles({ cardId: uuid, options: { showSend: true } })(
          Array.from(e.clipboardData.files),
        );
      }, 1000),
      [uuid],
    );

    return (
      <Card ref={ref} {...draggableProps} {...dragHandleProps}>
        <CardActionArea
          component="a"
          href={`/c/${uuid}/${makeSlug(title)}`}
          ref={anchorEditMenu}
          onClick={onClickCard}
          sx={styleActionArea}
          onMouseLeave={() => {
            document.body.removeEventListener('paste', handlePaste);
          }}
          onMouseEnter={() => {
            document.body.addEventListener('paste', handlePaste);
          }}
        >
          {!hideMenu && (
            <BtnEdit aria-label="edit" size="small" onClick={onOpenEditMenu}>
              <EditIcon fontSize="small" />
            </BtnEdit>
          )}
          <CardContent>
            <Title title={title} uuid={uuid} />
          </CardContent>
          <CardActions sx={CardActionsStyle}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={0.5}
              mt={-3}
            >
              {marks.map((item) => {
                if (item.color === 'none') return null;
                return (
                  <Grid item key={item.uuid} overflow="hidden">
                    <Marc
                      label={item.title}
                      colorMark={item.color}
                      size="small"
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid container direction="row" spacing={2} sx={gridIconStyle}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <YouMembericon members={members} />

                {Boolean(countAttachments) && (
                  <Box display="flex" alignItems="center">
                    <AttachFileIcon fontSize="small" color="primary" />
                    <Typography variant="body2" fontSize={12}>
                      {countAttachments}
                    </Typography>
                  </Box>
                )}
                {Boolean(countComments) && (
                  <Box display="flex" alignItems="center">
                    <ChatBubbleOutlineIcon fontSize="small" color="primary" />
                    <Typography variant="body2" fontSize={12} ml={0.5}>
                      {countComments}
                    </Typography>
                  </Box>
                )}

                {dates && (dates?.dateStart || dates?.dateEnd) && (
                  <DatesInfo
                    uuid={uuid}
                    dateStart={dates.dateStart}
                    dateEnd={dates.dateEnd}
                    completed={dates.completed}
                  />
                )}
              </Stack>
            </Grid>

            {Boolean(members.length) && (
              <Stack
                pt={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                flexWrap="wrap"
                gap={0.5}
              >
                {members.map((item) => (
                  <Tooltip
                    key={item.uuid}
                    title={item.email}
                    placement="bottom"
                    disableInteractive
                    PopperProps={PopperProps}
                  >
                    <AvatarMenu>{item.email}</AvatarMenu>
                  </Tooltip>
                ))}
              </Stack>
            )}
          </CardActions>
        </CardActionArea>
        {openEditMenu && (
          <EditMenu
            openMenu={openEditMenu}
            anchorEl={anchorEditMenu.current}
            handleClose={onCloseEditMenu}
            uuid={uuid}
            board={board}
          />
        )}
      </Card>
    );
  },
);

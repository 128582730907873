import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { errorHandlerFx } from '../../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../../model/JWT';
import {
  cardCreativesCreate,
  cardCreativesDelete,
  cardCreativesGet,
  cardCreativesGetList,
  cardCreativesMoveToGroup,
  cardCreativesUpdate,
  dictionaryGetCreativeSizes,
  RCardCreativesGetList,
  RDictionaryGetCreativeSizes,
} from '../../../../../../../apiRPC/card/creatives';
import { updateItem } from '../../../../../../model/card';
import {
  cardCreativesCreateFromZipFX,
  resetOpenFormCreateFromZip,
} from '../FormCreateFromZip/model';
import {
  cardCreativesGroupsGetList,
  RCardCreativesGroupsGetList,
} from '../../../../../../../apiRPC/card/creatives/groups';

export const cardCreativesGetFX = errorHandlerFx(
  createEffectJWT(cardCreativesGet),
);
export const cardCreativesCreateFX = errorHandlerFx(
  createEffectJWT(cardCreativesCreate),
);

export const cardCreativesDeleteFX = errorHandlerFx(
  createEffectJWT(cardCreativesDelete),
);
export const cardCreativesUpdateFX = errorHandlerFx(
  createEffectJWT(cardCreativesUpdate),
);
export const cardCreativesMoveToGroupFX = errorHandlerFx(
  createEffectJWT(cardCreativesMoveToGroup),
);

export const dictionaryGetCreativeSizesFX = errorHandlerFx(
  createEffectJWT(dictionaryGetCreativeSizes),
);
export const resetDictionaryCreativeSizes = createEvent();
export const $dictionaryCreativeSizes =
  createStore<RDictionaryGetCreativeSizes>([])
    .on(dictionaryGetCreativeSizesFX.doneData, (_, payload) => payload)
    .reset(resetDictionaryCreativeSizes);

export const cardCreativesGetListFX = errorHandlerFx(
  createEffectJWT(cardCreativesGetList),
);
export const resetCardCreativesGetLis = createEvent();
export const $cardCreativesGetLis = createStore<RCardCreativesGetList | null>(
  null,
)
  .on(cardCreativesGetListFX.doneData, (_, payload) => payload)
  .on(cardCreativesCreateFX.doneData, (state, payload) => [
    ...(state || []),
    payload,
  ])
  .on(cardCreativesCreateFromZipFX.doneData, (state, payload) => [
    ...(state || []),
    ...payload,
  ])
  .on(cardCreativesDeleteFX.done, (state, { params }) => [
    ...(state?.filter((item) => item.uuid !== params.uuid) || []),
  ])
  .on(cardCreativesUpdateFX.doneData, (state, payload) => {
    if (!state) return undefined;
    const newState = [...state];
    const index = newState.findIndex((item) => item.uuid === payload.uuid);
    if (index < 0) return undefined;
    newState[index] = payload;
    return newState;
  })
  .reset(resetCardCreativesGetLis);

type OpenMenuCreatives = {
  anchorEl: Element;
  cardUid: string;
};

export const onOpenMenuCreatives = createEvent<OpenMenuCreatives>();
export const onCloseMenuCreatives = createEvent();
const setAnchorEl = createEvent<Element>();
export const $anchorEl = restore(setAnchorEl, null).reset(onCloseMenuCreatives);
export const $isOpen = $anchorEl.map((state) => Boolean(state));

const setCardUid = createEvent<string | null>();
export const $cardUid = restore(setCardUid, null).reset(onCloseMenuCreatives);

sample({
  clock: onOpenMenuCreatives,
  target: createEffect(({ cardUid, anchorEl }: OpenMenuCreatives) => {
    setCardUid(cardUid);
    setAnchorEl(anchorEl);
  }),
});

sample({
  clock: onOpenMenuCreatives,
  filter: (payload) => Boolean(payload.cardUid),
  fn: (payload) => ({ card: payload.cardUid }),
  target: cardCreativesGetListFX,
});
sample({
  clock: onOpenMenuCreatives,
  filter: (payload) => Boolean(payload.cardUid),
  fn: () => undefined,
  target: dictionaryGetCreativeSizesFX,
});
sample({
  clock: $cardCreativesGetLis,
  source: $cardUid,
  filter: (cardUid, cardCreativesGetLis) => {
    if (!cardUid) {
      return false;
    }

    return true;
  },
  fn: (cardUid, cardCreativesGetLis) => ({
    uuid: cardUid ?? '',
    countCreatives: cardCreativesGetLis?.length ?? 0,
  }),
  target: updateItem,
});

export function resetCardCreativesStore() {
  resetCardCreativesGetLis();
  resetDictionaryCreativeSizes();
  resetOpenFormCreateFromZip();
}

export const cardCreativesGroupsGetListFX = errorHandlerFx(
  createEffectJWT(cardCreativesGroupsGetList),
);

export const setCurrentGroup = createEvent<string>();
export const resetCurrentGroup = createEvent();

export const $currentGroup = restore(setCurrentGroup, 'all').reset(
  resetCurrentGroup,
);
export const setSelectedGroup = createEvent<string>();
export const resetSelectedGroup = createEvent();

export const $selectedGroup = restore(setSelectedGroup, '').reset(
  resetSelectedGroup,
);

export const addCheckedCreative = createEvent<string>();
export const dellCheckedCreative = createEvent<string>();
export const resetCheckedCreative = createEvent();
export const $checkedCreatives = createStore<Set<string>>(new Set())
  .on(addCheckedCreative, (state, payload) => {
    const newState = new Set(state);
    newState.add(payload);
    return newState;
  })
  .on(dellCheckedCreative, (state, payload) => {
    const newState = new Set(state);
    newState.delete(payload);
    return newState;
  })
  .reset(resetCheckedCreative);

export const $cardCreativesFilteredList = combine(
  $cardCreativesGetLis,
  $currentGroup,
  (cardCreativesGetLis, currentGroup) => {
    if (!cardCreativesGetLis) return null;
    if (currentGroup === 'all') return cardCreativesGetLis;
    return cardCreativesGetLis.filter(
      (item) => item.groupUuid === currentGroup,
    );
  },
);

export const onMoveCreative = createEvent();

sample({
  clock: onMoveCreative,
  source: {
    checkedCreatives: $checkedCreatives,
    selectedGroup: $selectedGroup,
  },
  filter: ({ checkedCreatives, selectedGroup }) =>
    Boolean(checkedCreatives.size) && Boolean(selectedGroup),
  fn: ({ checkedCreatives, selectedGroup }) => ({
    group: selectedGroup === 'null' ? null : selectedGroup,
    items: Array.from(checkedCreatives),
  }),
  target: cardCreativesMoveToGroupFX,
});

export const $isDisabledMove = combine(
  $checkedCreatives,
  $selectedGroup,
  (checkedCreatives, selectedGroup) =>
    Boolean(!checkedCreatives.size || !selectedGroup),
);
sample({
  clock: cardCreativesMoveToGroupFX.doneData,
  target: resetCheckedCreative,
});
sample({
  clock: $currentGroup,
  target: resetCheckedCreative,
});
sample({
  clock: cardCreativesMoveToGroupFX.doneData,
  source: $cardUid,
  filter: (cardUid) => Boolean(cardUid),
  fn: (cardUid) => ({ card: cardUid! }),
  target: cardCreativesGetListFX,
});
export const $cardCreativesGroupsList =
  createStore<RCardCreativesGroupsGetList | null>(null).on(
    cardCreativesGroupsGetListFX.doneData,
    (_, payload) => payload,
  );

sample({
  clock: $cardUid,
  filter: (payload) => Boolean(payload),
  fn: (payload) => ({ card: payload! }),
  target: cardCreativesGroupsGetListFX,
});

onCloseMenuCreatives.watch(() => {
  resetCardCreativesStore();
  resetCurrentGroup();
  resetSelectedGroup();
  resetCheckedCreative();
});

import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  TextareaAutosize,
  IconButton,
  FormHelperText,
  FormControl,
  TypographyProps,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useUnit } from 'effector-react';
import emoji from 'remark-emoji';
import ReactMarkdown from 'react-markdown';
import type { PluggableList } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import { blueGrey } from '@mui/material/colors';
import { AvatarMenu } from '../../../../../../components/AvatarMenu';
import { CommentCard } from '../../../../../../apiRPC/card/comment';
import {
  cardDeleteCommentFX,
  cardUpdateCommentFX,
} from '../../../../../model/card/comment';
import { $account } from '../../../../../../model/account';
import { EmojiClickData, EmojiMenu } from './EmojiMenu';
import { DataLink, LinkMenu } from './LinkMenu';
import { MenuDelete } from './MenuDelete';
import { MenuDelete as MenuDeleteContent } from '../Attachments/MenuDelete';
import { MarkdownA } from '../../../../../../UI/MarkdownComponent';
import {
  $listFilesForComments,
  $openContent,
  delFileForComment,
  onResetOpenContent,
} from '../../../../../../helper/attachments';
import { AttachmentViewer } from '../Attachments/AttachmentViewer';
import { RSendMultipleFiles } from '../../../../../../apiRPC/card/file';
import { RContentSetName } from '../../../../../../apiRPC/card/file/content';
import { cardGetFX } from '../../../../../model/card';
import { setFiles } from '../../../../../../helper/useFileUpload/useFileUpload';

const remarkPlugins: PluggableList = [
  remarkGfm,
  remarkParse as PluggableList[0],
  emoji,
];
const listExtForView = ['PNG', 'JPEG', 'JPG', 'TXT'];
const styleMedia = {
  display: 'flex',
  width: 150,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: blueGrey[50],
  flexShrink: 0,
  height: 'auto',
  backgroundSize: 'contain',
  alignSelf: 'stretch',
};
const cardActionsStyle = { display: 'flex' };
const styleBoxGrid = {
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  alignItems: 'top',
  gap: 1,
};
const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'none',
  border: 'none',
  outline: 'none',
  padding: 0,
  ...theme.typography.body2,
}));
const overflowHidden = { overflow: 'hidden' };
interface PTypographyComment extends TypographyProps {
  component: string;
}
const TypographyComment = styled(Typography)<PTypographyComment>(() => ({
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  '& p': { margin: 'auto' },
}));
const markdownComponent = {
  a: MarkdownA,
};

const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
type Props = {
  comment: CommentCard;
};
export const Comment: FC<Props> = ({ comment }) => {
  const [isOpenForm, setOpenForm] = useState(false);
  const [changedText, setChangedText] = useState(() => comment.text);

  const [account, listFilesForComments, openContent] = useUnit([
    $account,
    $listFilesForComments,
    $openContent,
  ]);

  useEffect(() => {
    setChangedText(comment.text);
  }, [comment.text]);
  const fistFocus = useRef(true);

  const onFocusText = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (!fistFocus.current) return;
    e.target.select();
    fistFocus.current = false;
  };
  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChangedText(e.target.value);
  };

  const pendingDell = useUnit(cardDeleteCommentFX.pending);
  const pendingUpdate = useUnit(cardUpdateCommentFX.pending);
  const disabledDel = pendingDell;
  const disabledUpdate = pendingUpdate;
  const disabledSaveChanges = !changedText || disabledUpdate;

  const anchorElDellMenu = useRef<HTMLButtonElement>(null);
  const [openDellMenu, setOpenDellMenu] = useState(false);

  const onOpenDellMenu = () => {
    setOpenDellMenu(true);
  };
  const onCloseDellMenu = () => {
    setOpenDellMenu(false);
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (openContent !== comment.uuid) return;
    setShowContent(true);
    onResetOpenContent();
  }, [openContent]);
  const onOpenForm = () => {
    setOpenForm(true);
    setShowContent(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
    setChangedText(comment.text);
    fistFocus.current = true;
    setShowContent(false);
  };

  const onDelete = () => {
    cardDeleteCommentFX({ uuid: comment.uuid }).then(onCloseDellMenu);
  };

  const currentContents = listFilesForComments.filter(
    (item) => item.commentUid === comment.uuid,
  );
  const onSendChangeText = () => {
    if (comment.text === changedText && !currentContents.length) {
      onCloseForm();
      return;
    }
    const newContents = currentContents.map((item) => item.uuid);
    cardUpdateCommentFX({
      uuid: comment.uuid,
      fields: {
        text: changedText,
        contents: [
          ...comment.contents.map((item) => item.uuid),
          ...newContents,
        ],
      },
    }).then(() => {
      onCloseForm();
      delFileForComment(newContents);
      cardGetFX({ uuid: comment.card });
    });
  };

  const isUserCreat = account?.email === comment.sender.email;
  const showChangeButton = !isOpenForm && isUserCreat;

  const anchorElEmojiMenu = useRef<HTMLButtonElement>(null);
  const [openEmojiMenu, setOpenEmojiMenu] = useState(false);

  const onOpenEmojiMenu = () => {
    setOpenEmojiMenu(true);
  };
  const onCloseEmojiMenu = () => {
    setOpenEmojiMenu(false);
  };

  const onClickEmoji = (d: EmojiClickData) => {
    setChangedText((v) => v + d.shortcodes);
    onCloseEmojiMenu();
  };

  const anchorElLinkMenu = useRef<HTMLButtonElement>(null);
  const [openLinkMenu, setOpenLinkMenu] = useState(false);

  const onOpenLinkMenu = () => {
    setOpenLinkMenu(true);
  };
  const onCloseLinkMenu = () => {
    setOpenLinkMenu(false);
  };

  const onSetLink = (d: DataLink) => {
    setFiles({ cardId: comment.card, commentUid: comment.uuid })(
      d.url,
      d.urlName ? { name: d.urlName } : undefined,
    );
    onCloseLinkMenu();
  };

  const onSaveHandleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Escape') {
      onCloseForm();
      return;
    }

    if (e.key === 'Enter' && e.ctrlKey && !disabledSaveChanges) {
      onSendChangeText();
    }
  };

  const edited = comment.updatedAt !== comment.createdAt;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [uuidFile, setUuidFile] = useState<string>();

  const delContent =
    (uuid: string) => (event: React.MouseEvent<HTMLElement>) => {
      setUuidFile(uuid);
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      event.preventDefault();
      return null;
    };
  const delClose = () => {
    delFileForComment(uuidFile || '');
    setAnchorEl(null);
  };

  const [view, setView] = useState<RSendMultipleFiles | RContentSetName | null>(
    null,
  );

  const openView =
    (file: RSendMultipleFiles | RContentSetName) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setView(file);
    };

  const closeView = () => {
    setView(null);
  };

  return (
    <>
      <Box sx={styleBoxGrid}>
        <AvatarMenu>{comment.sender.email}</AvatarMenu>
        <Box gap={1} display="flex" flexDirection="column" sx={overflowHidden}>
          <Box gap={1} display="flex" alignItems="end">
            <Typography fontWeight={600} variant="body2">
              {comment.sender.email}
            </Typography>
            <Typography fontSize={12} color="text.secondary">
              {new Intl.DateTimeFormat('ru', optionsTime).format(
                comment.createdAt * 1000,
              )}{' '}
              {edited && (
                <>
                  (Отредактировано{' '}
                  {new Intl.DateTimeFormat('ru', optionsTime).format(
                    comment.updatedAt * 1000,
                  )}
                  )
                </>
              )}
            </Typography>
          </Box>
          <Card>
            <CardContent sx={{ paddingBottom: 0.5 }} id={comment.uuid}>
              {isOpenForm ? (
                <FormControl
                  variant="standard"
                  disabled={disabledUpdate}
                  fullWidth
                >
                  <TextArea
                    maxLength={65535}
                    onChange={onChangeText}
                    value={changedText}
                    autoFocus={isOpenForm}
                    onFocus={onFocusText}
                    onKeyDown={onSaveHandleKeyDown}
                    className="CommentContent"
                    data-comment_id={comment.uuid}
                  />
                  {changedText === '' && (
                    <FormHelperText>Вы ничего не написали!</FormHelperText>
                  )}
                </FormControl>
              ) : (
                <TypographyComment variant="body2" component="div">
                  <ReactMarkdown
                    remarkPlugins={remarkPlugins}
                    linkTarget="_blank"
                    components={markdownComponent}
                    className="_Markdown"
                  >
                    {comment.text}
                  </ReactMarkdown>
                </TypographyComment>
              )}
            </CardContent>
            {isOpenForm && (
              <CardActions sx={cardActionsStyle}>
                <Box display="flex" gap={1}>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={disabledSaveChanges}
                    onClick={onSendChangeText}
                  >
                    Сохранить
                  </Button>
                  <IconButton color="primary" onClick={onCloseForm}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
                <Box ml="auto !important">
                  <IconButton
                    color="primary"
                    aria-label="Добавить реакцию"
                    ref={anchorElEmojiMenu}
                    onClick={onOpenEmojiMenu}
                  >
                    <AddReactionOutlinedIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={onOpenLinkMenu}
                    ref={anchorElLinkMenu}
                  >
                    <AttachmentOutlinedIcon />
                  </IconButton>
                </Box>
              </CardActions>
            )}
          </Card>

          <Box display="flex" gap={1}>
            {showChangeButton && (
              <>
                {' '}
                <Button
                  variant="text"
                  size="small"
                  sx={{ fontSize: 12 }}
                  onClick={onOpenForm}
                >
                  Изменить
                </Button>
                <Button
                  variant="text"
                  size="small"
                  sx={{ fontSize: 12 }}
                  disabled={disabledDel}
                  onClick={onOpenDellMenu}
                  ref={anchorElDellMenu}
                >
                  Удалить
                </Button>
              </>
            )}
            {Boolean(
              comment.contents.filter((item) => !item.deleted).length,
            ) && (
              <Button
                variant="text"
                size="small"
                sx={{ fontSize: 12 }}
                disabled={disabledDel}
                onClick={() => setShowContent((v) => !v)}
              >
                Вложения (
                {comment.contents.filter((item) => !item.deleted).length})
              </Button>
            )}
          </Box>
        </Box>

        <EmojiMenu
          open={openEmojiMenu}
          onClose={onCloseEmojiMenu}
          onClick={onClickEmoji}
          anchorEl={anchorElEmojiMenu.current}
        />

        <LinkMenu
          open={openLinkMenu}
          anchorEl={anchorElLinkMenu.current}
          onClose={onCloseLinkMenu}
          onSubmit={onSetLink}
          cardUuid={comment.card}
          commentUid={comment.uuid}
        />

        <MenuDelete
          open={openDellMenu}
          anchorEl={anchorElDellMenu.current}
          onClose={onCloseDellMenu}
          onDelete={onDelete}
          pending={pendingDell}
        />

        <MenuDeleteContent
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={delClose}
          uuidFile={uuidFile}
          cardId={comment.card}
        />
      </Box>
      {showContent && (
        <Box mt={-1} ml={6} gap={1} display="flex" flexDirection="column">
          {comment.contents.map((item) => {
            if (item.deleted) return null;
            return (
              <Card
                key={item.uuid}
                sx={{ minHeight: 100, overflow: 'hidden' }}
                id={item.uuid}
              >
                <CardActionArea
                  sx={{
                    display: 'flex',
                    minHeight: 100,
                    justifyContent: 'start',
                    alignItems: 'flex-start',
                    userSelect: 'text',
                  }}
                  aria-dropeffect="none"
                  aria-grabbed={false}
                  draggable="false"
                  component="a"
                  href={item.downloadUrl}
                  download={`${item.name}.${item.ext}`}
                  onClick={
                    listExtForView.includes(item.ext.toUpperCase())
                      ? openView(item)
                      : undefined
                  }
                >
                  {item.type === 'image' ? (
                    <CardMedia sx={styleMedia} image={item.url} />
                  ) : (
                    <CardMedia sx={styleMedia}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        color="text.secondary"
                      >
                        {item.ext || item.type.toUpperCase()}
                      </Typography>
                    </CardMedia>
                  )}

                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto', paddingY: 0.5 }}>
                      <Typography
                        component="div"
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{ wordBreak: 'break-all' }}
                      >
                        {item.name}.{item.ext}
                      </Typography>

                      <Box display="flex" gap={0.5} alignItems="baseline">
                        {isUserCreat && (
                          <Button
                            size="small"
                            variant="text"
                            onClick={delContent(item.uuid)}
                          >
                            <Typography
                              variant="body2"
                              component="div"
                              fontStyle="italic"
                            >
                              Удалить
                            </Typography>
                          </Button>
                        )}
                      </Box>
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Card>
            );
          })}
          {currentContents.map((item) => (
            <Card key={item.uuid} sx={{ minHeight: 100, overflow: 'hidden' }}>
              <CardActionArea
                sx={{
                  display: 'flex',
                  minHeight: 100,
                  justifyContent: 'start',
                  alignItems: 'flex-start',
                  userSelect: 'text',
                }}
                aria-dropeffect="none"
                aria-grabbed={false}
                draggable="false"
                component="a"
                href={item.downloadUrl}
                download={`${item.name}.${item.ext}`}
                onClick={
                  listExtForView.includes(item.ext.toUpperCase())
                    ? openView(item)
                    : undefined
                }
              >
                {item.type === 'image' ? (
                  <CardMedia sx={styleMedia} image={item.url} />
                ) : (
                  <CardMedia sx={styleMedia}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="text.secondary"
                    >
                      {item.ext || item.type.toUpperCase()}
                    </Typography>
                  </CardMedia>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto', paddingY: 0.5 }}>
                    <Typography
                      component="div"
                      variant="subtitle2"
                      fontWeight={600}
                      sx={{ wordBreak: 'break-all' }}
                    >
                      {item.name}.{item.ext}
                    </Typography>

                    <Box display="flex" gap={0.5} alignItems="baseline">
                      {isUserCreat && (
                        <Button
                          size="small"
                          variant="text"
                          onClick={delContent(item.uuid)}
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            fontStyle="italic"
                          >
                            Удалить
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Box>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      )}
      <AttachmentViewer
        open={Boolean(view)}
        name={view?.name || ''}
        url={view?.url || ''}
        close={closeView}
        ext={view?.ext || ''}
        createdAt={view?.createdAt || 0}
        size={view?.size || 0}
        cardId={comment.card}
        uuidFile={view?.uuid || ''}
        downloadUrl={view?.downloadUrl || ''}
        hideDelBtn
        deleted={false}
        account={view?.account || ''}
      />
    </>
  );
};

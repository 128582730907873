import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import deepEquals from 'deep-equal';
import { errorHandlerFx } from '../../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../../model/JWT';
import { getListBoard, RGetListBoard } from '../../../../../../../apiRPC/board';
import {
  columnGetList,
  Filter,
  RColumnGet,
  RColumnGetList,
} from '../../../../../../../apiRPC/column';
import { defaultSort } from '../../../../../../../helper/sort';
import {
  $card,
  TCardListsSorted,
  $cardListsSorted as $currentCardListsSorted,
} from '../../../../../../model/card';
import { cardGetList, RCardGetList } from '../../../../../../../apiRPC/card';
import { $board } from '../../../../../../model/board';
import { $column } from '../../../../../../model/column';

export const onToggleMenuMove = createEvent<boolean>();
export const $isOpenMenuMove = restore(onToggleMenuMove, false);

export const getListBoardFx = errorHandlerFx(createEffectJWT(getListBoard));
export const resetListBoard = createEvent();
export const listBoard$ = createStore<RGetListBoard | null>(null)
  .on(getListBoardFx.doneData, (state, payload) => payload)
  .reset(resetListBoard);

export const setBoardUid = createEvent<string>();
export const $boardUid = restore(setBoardUid, null);

export const resetColumnList = createEvent();

type PColumnGetListActive = { filter: Omit<Filter, 'status'> };

export const columnGetListFx = errorHandlerFx(
  createEffectJWT((p: PColumnGetListActive) =>
    columnGetList({ filter: { ...p.filter, status: 'ACTIVE' } }),
  ),
);

export const $columnList = createStore<RColumnGetList | null>(null)
  .on(columnGetListFx.doneData, (_, payload) => payload)
  .reset(resetColumnList);

export const $columnListSort = $columnList.map<RColumnGet[]>((item) => {
  if (!item?.rows) return [];
  return item?.rows.sort(defaultSort);
});

export const setColumnUid = createEvent<string>();
export const $columnUid = restore(setColumnUid, null);

export const cardGetListFX = errorHandlerFx(createEffectJWT(cardGetList));
export const resetCardLists = createEvent();
export const $cardLists = createStore<RCardGetList | null>(null)
  .on(cardGetListFX.doneData, (state, payload) => {
    if (deepEquals(payload, state)) return undefined;
    return payload;
  })
  .reset(resetCardLists);
export const $cardListsSorted = $cardLists.map<TCardListsSorted>((item) => {
  const result: TCardListsSorted = {};
  const sort = item?.rows.sort(defaultSort);

  sort
    ?.filter((card) => card.status === 'ACTIVE')
    .forEach((card) => {
      if (!card) return;
      const key = card.column;
      if (result[key] === undefined) {
        result[key] = [];
      }
      result[key].push(card);
    });
  return result;
});

export const setCardUid = createEvent<string>();
export const $cardUid = restore(setCardUid, null);

export const resetAll = createEffect(() => {
  resetCardLists();
  resetColumnList();
  resetListBoard();
});

export const setCardIndex = createEvent<number>();
export const $cardIndex = restore(setCardIndex, 0);

export const $currentCardIndex = combine(
  {
    card: $card,
    cardListsSorted: $currentCardListsSorted,
    currentColumn: $column,
  },
  ({ card, cardListsSorted, currentColumn }) => {
    const index = cardListsSorted[currentColumn?.uuid || '']?.findIndex(
      (item) => item.uuid === card?.uuid,
    );
    if (index > 0) return index;
    return 0;
  },
);

sample({
  clock: [$cardUid, $cardListsSorted],
  source: {
    cardUid: $cardUid,
    cardListsSorted: $cardListsSorted,
    currentColumn: $column,
  },
  fn: ({ cardUid, cardListsSorted, currentColumn }) => {
    const index = cardListsSorted?.[currentColumn?.uuid || '']?.findIndex(
      (item) => item.uuid === cardUid,
    );
    if (index > 0) return index;
    return 0;
  },
  target: setCardIndex,
});

sample({
  clock: [$isOpenMenuMove, $board],
  source: {
    listBoard: listBoard$,
    isOpenMenuMove: $isOpenMenuMove,
    currentBoard: $board,
  },
  filter: ({ listBoard, isOpenMenuMove, currentBoard }) => {
    if (listBoard) return false;
    if (!isOpenMenuMove) return false;
    if (!currentBoard) return false;
    return true;
  },
  fn: ({ currentBoard }) => ({
    filter: { limit: 1000, offset: 0, workspace: currentBoard!.workspace },
  }),
  target: getListBoardFx,
});

sample({
  clock: $boardUid,
  source: {
    boardUid: $boardUid,
  },
  filter: ({ boardUid }) => Boolean(boardUid),
  fn: ({ boardUid }) => ({
    filter: { board: boardUid! },
  }),
  target: columnGetListFx,
});

sample({
  clock: [$isOpenMenuMove, $column],
  source: {
    columnList: $columnList,
    isOpenMenuMove: $isOpenMenuMove,
    currentColumn: $column,
  },
  filter: ({ columnList, isOpenMenuMove, currentColumn }) => {
    if (columnList) return false;
    if (!isOpenMenuMove) return false;
    if (!currentColumn) return false;
    return true;
  },
  fn: ({ currentColumn }) => ({
    filter: { board: currentColumn!.board },
  }),
  target: columnGetListFx,
});

sample({
  clock: $columnUid,
  source: {
    columnUid: $columnUid,
  },
  filter: ({ columnUid }) => Boolean(columnUid),
  fn: ({ columnUid }) => ({
    filter: { column: columnUid! },
  }),
  target: cardGetListFX,
});

sample({
  clock: [$isOpenMenuMove, $card],
  source: {
    cardList: $cardLists,
    isOpenMenuMove: $isOpenMenuMove,
    currentCard: $card,
  },
  filter: ({ cardList, isOpenMenuMove, currentCard }) => {
    if (cardList) return false;
    if (!isOpenMenuMove) return false;
    if (!currentCard) return false;
    return true;
  },
  fn: ({ currentCard }) => ({
    filter: { column: currentCard!.column },
  }),
  target: cardGetListFX,
});

sample({
  clock: [$isOpenMenuMove, $board],
  source: {
    isOpenMenuMove: $isOpenMenuMove,
    currentBoard: $board,
  },
  filter: ({ isOpenMenuMove, currentBoard }) => {
    if (!isOpenMenuMove) return false;
    if (!currentBoard) return false;
    return true;
  },
  fn: ({ currentBoard }) => currentBoard!.uuid,
  target: setBoardUid,
});

sample({
  clock: [$isOpenMenuMove, $column],
  source: {
    isOpenMenuMove: $isOpenMenuMove,
    currentColumn: $column,
  },
  filter: ({ isOpenMenuMove, currentColumn }) => {
    if (!isOpenMenuMove) return false;
    if (!currentColumn) return false;
    return true;
  },
  fn: ({ currentColumn }) => currentColumn!.uuid,
  target: setColumnUid,
});

sample({
  clock: [$isOpenMenuMove, $card],
  source: {
    isOpenMenuMove: $isOpenMenuMove,
    currentCard: $card,
  },
  filter: ({ isOpenMenuMove, currentCard }) => {
    if (!isOpenMenuMove) return false;
    if (!currentCard) return false;
    return true;
  },
  fn: ({ currentCard }) => currentCard!.uuid,
  target: setCardUid,
});

sample({
  clock: $columnList,
  source: {
    isOpenMenuMove: $isOpenMenuMove,
    columnList: $columnList,
    currentColumn: $column,
  },
  filter: ({ isOpenMenuMove, currentColumn }) => {
    if (!isOpenMenuMove) return false;
    if (!currentColumn) return false;
    return true;
  },
  fn: ({ columnList, currentColumn }) => {
    const findColumn = columnList?.rows.find(
      (item) => item.uuid === currentColumn?.uuid,
    );
    if (findColumn) return findColumn.uuid;
    return columnList?.rows?.[0]?.uuid || '';
  },
  target: setColumnUid,
});

sample({
  clock: $isOpenMenuMove,
  filter: (state) => state === false,
  target: resetAll,
});

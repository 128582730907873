import React, { FC, useRef, useState } from 'react';
import { CardHeader, IconButton, TextField, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DraggableProvided } from '@hello-pangea/dnd';
import { useChangeTitle } from '../useChangeTitle';
import { ColumnMenu } from '../ColumnMenu';

type Props = {
  uuid: string;
  title: string;
  dragHandleProps?: DraggableProvided['dragHandleProps'];
  scrollerRef: React.RefObject<HTMLDivElement> | null;
};
export const Title: FC<Props> = ({
  title,
  uuid,
  dragHandleProps,
  scrollerRef,
}) => {
  const {
    onOpenInput,
    isEditTitle,
    formik,
    onPressEnter,
    pendingUpdateColumn,
    onBlur,
  } = useChangeTitle(title, uuid);

  const [isOpenMenu, setOpenMenu] = useState(false);
  const refAnchorElForMenu = useRef<HTMLButtonElement>(null);
  const openMenu = () => setOpenMenu(true);
  const closeMenu = () => setOpenMenu(false);

  return (
    <>
      {' '}
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            ref={refAnchorElForMenu}
            onClick={openMenu}
          >
            <MoreVertIcon />
          </IconButton>
        }
        {...dragHandleProps}
        onClick={onOpenInput}
        title={
          isEditTitle ? (
            <TextField
              variant="standard"
              autoFocus
              onChange={formik.handleChange}
              value={formik.values.title}
              onBlur={onBlur}
              error={Boolean(formik.errors.title)}
              helperText={formik.errors.title || ' '}
              inputProps={{ maxLength: 255 }}
              id="title"
              onKeyDown={onPressEnter}
              disabled={pendingUpdateColumn}
              fullWidth
            />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
            >
              {title}
            </Typography>
          )
        }
      />
      <ColumnMenu
        anchorEl={refAnchorElForMenu.current}
        open={isOpenMenu}
        onClose={closeMenu}
        uuid={uuid}
        scrollerRef={scrollerRef}
      />
    </>
  );
};

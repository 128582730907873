import { combine, createEvent, createStore, sample } from 'effector';
import {
  markCreate,
  markDelete,
  markGetList,
  markUpdate,
  RMarkGet,
  RMarkGetList,
} from '../../../apiRPC/card/mark';
import { errorHandlerFx } from '../../../model/errorHandler';
import { createEffectJWT } from '../../../model/JWT';

export type ColorsMark =
  | 'yellow'
  | 'purple'
  | 'blue'
  | 'red'
  | 'green'
  | 'orange'
  | 'black'
  | 'sky'
  | 'pink'
  | 'lime'
  | 'none';

export const markGetListFx = errorHandlerFx(createEffectJWT(markGetList));
export const resetMarkList = createEvent();
export const $markList = createStore<RMarkGetList | null>(null)
  .on(markGetListFx.doneData, (_, payload) => payload)
  .reset(resetMarkList);

const $lastBoard = createStore('').on(
  markGetListFx.done,
  (_, { params }) => params.board,
);

const colors: ColorsMark[] = [
  'yellow',
  'purple',
  'blue',
  'red',
  'green',
  'orange',
  'black',
  'sky',
  'pink',
  'lime',
  'none',
];
export const $markListEveryColor = $markList.map((item) => {
  const result = [...(item || [])];
  colors.forEach((color) => {
    if (!result.find((i) => i.color === color)) {
      result.push({
        uuid: '',
        title: '',
        color,
        board: '',
      });
    }
  });
  result.sort((a, b) => {
    if (a.color === 'none') return 1;
    if (b.color === 'none') return -1;
    if (a.color > b.color) return 1;
    if (a.color < b.color) return -1;

    return 0;
  });

  return result;
});

export const markCreateFX = errorHandlerFx(createEffectJWT(markCreate));
sample({
  clock: markCreateFX.done,
  fn: ({ params }) => ({ board: params.board }),
  target: markGetListFx,
});

export type MarkForUpdate = {
  uuid?: string;
  title?: string;
  color?: ColorsMark;
  board: string;
};

export const setDataForUpdate = createEvent<MarkForUpdate>();
export const resetDataForUpdate = createEvent();
export const $markForUpdate = createStore<MarkForUpdate | null>(null)
  .on(setDataForUpdate, (_, payload) => payload)
  .reset(resetDataForUpdate);

export const resetUuidForDelete = createEvent();
export const setUuidForDelete = createEvent<string>();
export const $uuidForDelete = createStore('')
  .on(setUuidForDelete, (_, payload) => payload)
  .reset(resetUuidForDelete);

export const markUpdateFX = errorHandlerFx(createEffectJWT(markUpdate));
export const markDeleteFX = errorHandlerFx(createEffectJWT(markDelete));

sample({
  clock: [markUpdateFX.done, markDeleteFX.done],
  source: $lastBoard,
  filter: (state) => Boolean(state),
  fn: (state) => ({ board: state }),
  target: markGetListFx,
});

export const setMarksForCreate = createEvent<string>();
export const delMarksForCreate = createEvent<string>();
export const resetMarksForCreate = createEvent();
export const $marksUuidForCreate = createStore<string[]>([])
  .on(setMarksForCreate, (state, payload) => [...state, payload])
  .on(delMarksForCreate, (state, payload) =>
    state.filter((item) => item !== payload),
  )
  .reset(resetMarksForCreate);

export const $listMarksForCreate = combine(
  $marksUuidForCreate,
  $markList,
  (marksUuidForCreate, markList) => {
    if (!markList) return [];
    const result: RMarkGet[] = [];
    marksUuidForCreate.forEach((uuid) => {
      const mark = markList.find((i) => i.uuid === uuid);
      if (mark) result.push(mark);
    });
    return result;
  },
);

sample({
  clock: markDeleteFX.done,
  fn: ({ params }) => params.uuid,
  target: delMarksForCreate,
});

export const clockMarkForUpdateCart = [markUpdateFX.done, markDeleteFX.done];

import { ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      contrastText: '#fff',
      light: '#6a81e3',
      main: '#4b61bf',
      dark: '#394fac',
    },
    secondary: {
      light: '#CFCFCF',
      main: '#fff',
      contrastText: '#666666',
      dark: '#171717',
    },
    info: {
      main: '#fff0',
      light: '#ffffff',
    },
    background: {
      default: '#F2F5F6',
    },
  },
  typography: {
    h1: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: '0.02em',
    },
    button: {
      fontSize: 15,
      textTransform: 'initial',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#6a81e3',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          // height: '100%',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          padding: '0',
          '& .MuiAutocomplete-option': {
            whiteSpace: 'break-spaces',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          },
        },
        option: {
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '13px',
          paddingBottom: '13px',
          maxHeight: '200px',
          '&:hover': {
            backgroundColor: 'rgba(75, 97, 191, 0.1)',
            svg: {
              color: '#4B61BF',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '13px',
          paddingBottom: '13px',
          '&:hover': {
            backgroundColor: 'rgba(75, 97, 191, 0.1)',
            svg: {
              color: '#4B61BF',
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          marginLeft: '8px',
          marginRight: '8px',
          paddingTop: '15px',
          paddingBottom: '15px',
          borderRadius: '4px',
          '&.Mui-selected': {
            svg: {
              color: '#4B61BF',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(75, 97, 191, 0.1)',
            svg: {
              color: '#4B61BF',
            },
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: '1300',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: '1300',
          '& .MuiDialogTitle-root': {
            padding: '20px 70px 20px 30px',
            fontWeight: 700,
          },
          '& .MuiDialogContent-root': {
            padding: '20px 30px',
          },
          '& .MuiDialogActions-root': {
            padding: '20px 30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
          '& .MuiDialogClose': {
            position: 'absolute',
            right: 20,
            top: 24,
            cursor: 'pointer',
            svg: {
              verticalAlign: 'middle',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // maxWidth: '240px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#D32F2F',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#171717',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          '&:focus': {
            backgroundColor: '#ffffff00',
          },
        },
        outlined: {
          fontSize: '16px',
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { disabled: true },
          style: {
            input: { cursor: 'not-allowed' },
            textarea: { cursor: 'not-allowed' },
          },
        },
      ],
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: '3px',
          right: '-15px',
          height: '15px',
          minWidth: '15px',
          width: '20px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          backgroundColor: '#6A81E3',
          maxWidth: '260px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: '1px solid #CFCFCF',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid #4b61bf',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          '&.Mui-disabled': {
            color: '#A6A6A6',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
        indicator: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          padding: '9px 30px',
          minHeight: '32px',
          '&.Mui-selected': {
            background: 'rgba(106, 129, 227, 0.1)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#666',
          marginRight: '60px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          flex: 'none',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'scale(1, 0.90)',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          svg: {
            verticalAlign: 'middle',
          },
          '&:hover': {
            color: 'rgba(0, 0, 0, 1)',
            ' .MuiTableSortLabel-icon': {
              opacity: 1,
            },
          },
        },
        icon: {
          '&.MuiTableSortLabel-iconDirectionAsc': {
            svg: {
              verticalAlign: 'baseline',
            },
          },
          '&.MuiTableSortLabel-iconDirectionDesc': {
            svg: {
              verticalAlign: 'baseline',
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05))',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 30px',
          boxShadow: 'none',
          justifyContent: 'space-between',
          '&.Mui-expanded': {
            borderBottom: '1px solid #CFCFCF',
          },
        },
        content: {
          margin: '30px 0',
          flexGrow: '0',
          alignItems: 'center',
        },
        expandIconWrapper: {
          alignItems: 'center',
          '& .arrow': {
            marginLeft: '20px',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '&.Mui-expanded': {
            transform: 'none',

            '& .arrow': {
              transform: 'rotate(180deg)',
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
  },
};

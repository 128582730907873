import React, { FC } from 'react';
import { Box, IconButton, Paper, TextField } from '@mui/material';
import { FiTrash } from 'react-icons/fi';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import css from '../../../../../styles.module.scss';
import { BootstrapTooltip } from '../../../../../../../../../components/BootstrapTooltip';

interface Props {
  textData?: string;
  onDelete: () => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: StandardInputProps['onChange'];
}

export const TextResourse: FC<Props> = ({
  textData,
  onDelete,
  disabled,
  helperText,
  error,
  onChange,
}) => (
  <Paper
    elevation={0}
    sx={{
      padding: '30px',
    }}
  >
    <Box
      display="grid"
      gridTemplateColumns="1fr 40px"
      gap="0 30px"
      alignItems="center"
    >
      <TextField
        label="Текстовые данные"
        variant="standard"
        value={textData}
        autoComplete="none"
        fullWidth
        inputProps={{ maxLength: 1000 }}
        multiline
        onChange={onChange}
        name="description"
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
      <BootstrapTooltip title="Удалить" placement="top">
        <IconButton onClick={onDelete} disabled={disabled}>
          <FiTrash color="#D32F2F" size={20} className={css.del_btn} />
        </IconButton>
      </BootstrapTooltip>
    </Box>
  </Paper>
);

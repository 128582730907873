import { createEvent, createStore, restore } from 'effector';

export const onOpenTaskOverview = createEvent<boolean>();
export const resetOpenTaskOverview = createEvent();
export const $OpenTaskOverview = restore(onOpenTaskOverview, false).reset(
  resetOpenTaskOverview,
);

export const seTHistoryCommentTab = createEvent<'COMMENT' | 'HISTORY'>();
export const $historyCommentTab = createStore<'COMMENT' | 'HISTORY'>(
  'COMMENT',
).on(seTHistoryCommentTab, (_, payload) => payload);

import React, { FC } from 'react';
import { IconButton, List, ListItem, ListItemIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { setOpenMenuR } from '../model';

type Props = {
  title: string;
  onBack?: () => void;
};
export const TitleItem: FC<Props> = ({ title, onBack }) => (
  <List>
    <ListItem
      divider
      sx={{
        height: 62,
      }}
      secondaryAction={
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={() => setOpenMenuR(false)}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      {onBack && (
        <ListItemIcon>
          <IconButton onClick={onBack}>
            <ChevronLeftIcon />
          </IconButton>
        </ListItemIcon>
      )}
      {title}
    </ListItem>
  </List>
);

import { generatePath } from 'react-router-dom';

export const desksPath = 'Desks';
export const workspaceSegment = ':workspace';

export const deskPath = 'd';
export const uuidSegment = ':uuid';
export const dNameSegment = ':dName';

export const cardPath = 'c';
export const cardIdSegment = ':cardId';
export const cNameSegment = ':dName';

export const addressesGRID = {
  desksPath: `/${desksPath}`,
  desksWorkspacePath: `/${desksPath}/${workspaceSegment}`,

  deskPath: `/${deskPath}/${uuidSegment}`,
  deskPathWithName: `/${deskPath}/${uuidSegment}/${dNameSegment}`,

  cardPath: `/${cardPath}/${cardIdSegment}`,
  cardPathWithName: `/${cardPath}/${cardIdSegment}/${cNameSegment}`,
};

export const generateDeskPathWithName = (uuid: string, dName: string): string =>
  generatePath(addressesGRID.deskPathWithName, { uuid, dName });

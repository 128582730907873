import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { variant } from '@effector/reflect';
import { Box, Container } from '@mui/material';
import {
  ordGetCatalogFX,
  organizationGetCatalogFX,
} from '../../../Contracts/model/catalogs';
import LoadingBasicForm from './LoadingBasicForm';
import { campaignGetFX, resetCampaignModel } from './model';
import TabHeader from './TabHeader';
import Basic from './Basic';
import { $fieldFormUpdateCampaign } from './model/fieldFormUpdateCampaign';
import { ContainerAdditional } from './additional';

const ComponentUpdate = variant({
  source: $fieldFormUpdateCampaign,
  cases: {
    Additional: ContainerAdditional,
    Basic,
    loading: LoadingBasicForm,
  },
  default: LoadingBasicForm,
  hooks: {
    mounted: () => {
      organizationGetCatalogFX(undefined);
      ordGetCatalogFX(undefined);
    },
    unmounted: () => {
      resetCampaignModel();
    },
  },
});

export const Update: FC = () => {
  const { uuid } = useParams();
  useEffect(() => {
    if (!uuid) return undefined;
    campaignGetFX({ uuid });
    return resetCampaignModel;
  }, [uuid]);

  return (
    <>
      <TabHeader />
      <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          height="calc(100vh - 108px )"
        >
          <ComponentUpdate />
        </Box>
      </Container>
    </>
  );
};

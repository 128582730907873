import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { FiChevronLeft, FiChevronUp } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { addressesORD } from '../../../../../addresses';
import Header from '../../../../../components/Header';
import css from '../../styles.module.scss';

const LoadingAdditionalForm = () => {
  const theme = useTheme();
  return (
    <Box component="form">
      <Header>
        <Box display="flex">
          <NavLink to={addressesORD.campaignsPath}>
            <Tooltip title="Назад">
              <IconButton>
                <FiChevronLeft size={20} />
              </IconButton>
            </Tooltip>
          </NavLink>
          <Box flex={1}>
            <Typography
              className={css.name}
              variant="h1"
              sx={{
                marginLeft: '2px',
              }}
            >
              <Skeleton height={28} width={200} />
            </Typography>
            <Typography
              mt={0.5}
              sx={{
                color: theme.palette.secondary.light,
              }}
            >
              <Skeleton height={24} width={400} />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled
            sx={{
              marginLeft: '36px',
            }}
          >
            Отмена
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled
            sx={{
              marginLeft: '36px',
            }}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      <Box
        sx={{
          marginBottom: '20px',
          marginTop: '14px',
        }}
      >
        <Skeleton height={52} />
      </Box>
      <Paper
        elevation={0}
        sx={{
          padding: '30px',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Skeleton width={340} height={20} />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              gap: '0 20px',
            }}
          >
            <Skeleton width={220} height={42} />
            <Skeleton width={117} height={42} />
          </Box>
        </Box>
      </Paper>

      <Paper elevation={0}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '34px 30px',
            borderBottom: '1px solid #CFCFCF',
          }}
        >
          <Skeleton width={120} height={20} />
          <FiChevronUp color="#CFCFCF" size={28} />
        </Box>
        <Box
          sx={{
            padding: '30px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: '30px 38px',
          }}
        >
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
          <Box>
            <Skeleton width={167} height={14} />
            <Skeleton width={260} height={20} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoadingAdditionalForm;

import { Box, IconButton, Menu, Tooltip, useTheme } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { FiX } from 'react-icons/fi';
import { ReactComponent as FilterSvg } from '../../assets/icons/filter.svg';
import { IconButtonSquare } from '../../UI/IconButtonSquare';
import css from './styles.module.scss';

type TFilter = {
  children: ReactNode;
  count?: number;
  actions?: ReactNode;
  onOpen?: () => void;
  id?: string;
};

export const FilterWrapper: FC<TFilter> = ({
  children,
  count,
  actions,
  onOpen,
  id,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Tooltip
        title="Фильтры"
        sx={{
          marginLeft: '10px',
        }}
      >
        <IconButtonSquare color="primary" onClick={handleClick} id={id}>
          <FilterSvg />
          {!!count && (
            <Box
              className={css.value}
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {count}
            </Box>
          )}
        </IconButtonSquare>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={css.menu}>
          <Box className={css.menu_header}>
            <Box
              className={css.menu_title}
              sx={{
                color: theme.palette.secondary.dark,
              }}
            >
              Фильтр
            </Box>
            <IconButton onClick={handleClose}>
              <FiX color={theme.palette.primary.main} size={24} />
            </IconButton>
          </Box>
          <Box className={css.menu_body}>{children}</Box>

          <Box className={css.menu_actions}>{actions}</Box>
        </Box>
      </Menu>
    </Box>
  );
};

import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateBoardFX } from '../../../GRID/model/board';

export function useChangeTitle(title: string) {
  const { uuid } = useParams();

  const pendingUpdateBoard = useUnit(updateBoardFX.pending);
  const isChangeTitle = Boolean(uuid);
  const [showInputTitle, setShowInputTitle] = useState(false);
  const pendingUpdate = useRef(false);
  const formik = useFormik({
    initialValues: {
      title,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Обязательно для заполнения')
        .trim()
        .min(3, 'Минимум 3 знака'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values) => {
      if (!uuid) return;
      if (values.title === title) {
        setShowInputTitle(false);
        return;
      }

      pendingUpdate.current = true;
      updateBoardFX({ uuid, fields: { title: values.title } })
        .then(() => {
          setShowInputTitle(false);
        })
        .finally(() => {
          pendingUpdate.current = false;
        });
    },
  });

  useEffect(() => {
    if (pendingUpdate.current) return;
    setShowInputTitle(false);
    formik.setFieldValue('title', title);
  }, [title]);

  function onFormError() {
    if (formik.errors.title) {
      setShowInputTitle(false);
      formik.resetForm({ values: { title }, errors: undefined });
      return true;
    }
    return false;
  }

  const onBlur = () => {
    if (onFormError()) return;
    formik.submitForm();
  };
  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;
    if (onFormError()) return;
    formik.submitForm();
  };
  const onShowInputTitle = () => {
    if (!isChangeTitle) return;
    setShowInputTitle(true);
  };

  return {
    showInputTitle,
    formik,
    onPressEnter,
    pendingUpdateBoard,
    isChangeTitle,
    onShowInputTitle,
    onBlur,
  };
}

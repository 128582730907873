import * as React from 'react';
import type { FC, ReactNode } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { DrawerHeader } from '../../../components/DrawerHeader';

type Props = {
  children: ReactNode;
};
export const ContainerOrd: FC<Props> = ({ children }) => (
  <Box sx={{ width: '100%', minWidth: 'auto' }}>
    <DrawerHeader />

    <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="calc(100vh - 64px )"
      >
        {children}
      </Box>
    </Container>
  </Box>
);

import { variant } from '@effector/reflect';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import * as React from 'react';
import { BasicTable } from './Table';

import { Loadding } from '../../../components/Loadding';
import { Empty } from '../../../components/Errors/Empty';
import { NoConnect } from '../../../components/Errors/NoConnect/NoConnect';
import ContractsHeader from './ContractsHeader';
import {
  $fieldTypeContract,
  contractGetListFX,
  limitContracts,
  restContract,
} from './model';
import { resetFilters } from './model/filtres';
import { catalogsGet } from '../model/catalogs';
import { DrawerHeader } from '../../../../components/DrawerHeader';
import TabHeader from './TabHeader';

export const ComponentTable = variant({
  source: $fieldTypeContract,
  cases: {
    Success: BasicTable,
    loading: Loadding,
    ErrorEmpty: Empty,
    ErrorNoContent: NoConnect,
  },
  default: Loadding,
  hooks: {
    mounted: () => {
      contractGetListFX({
        params: {
          filter: { limit: limitContracts, status: 'ACTIVE' },
        },
      });
      catalogsGet();
    },
    unmounted: () => {
      restContract();
      resetFilters();
    },
  },
});

const Contracts = () => (
  <Box sx={{ width: '100%', minWidth: 'auto' }}>
    <DrawerHeader />
    <TabHeader />

    <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="calc(100vh - 108px)"
      >
        <ContractsHeader />
        <ComponentTable />
      </Box>
    </Container>
  </Box>
);

export default Contracts;

import { createEvent, restore } from 'effector';
import { PResourcesCreate } from '../../../../../../../apiRPC/creative/resources';

export const setItemsForm = createEvent<PResourcesCreate['items']>();
export const submitResourcesCreateForm = createEvent();
export const resetResourcesCreateForm = createEvent();

export const setSizeItems = createEvent<number>();
export const $sizeItems = restore(setSizeItems, 0);

export const setValidItems = createEvent<boolean>();
export const $validItems = restore(setValidItems, true);

import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { LoadingButton } from '@mui/lab';
import { useUnit } from 'effector-react';
import css from '../../styles.module.scss';
import { addressesORD } from '../../../../../addresses';
import Header from '../../../../../components/Header';
import {
  $changedBasicForm,
  $creative,
  $disabledBasicForm,
  creativeUpdateFX,
  onSubmitBasicForm,
  onResetBasicForm,
} from '../model';

export const UpdateHeader: FC = () => {
  const [creative, pendingUpdate, disabledBasicForm, changedBasicForm] =
    useUnit([
      $creative,
      creativeUpdateFX.pending,
      $disabledBasicForm,
      $changedBasicForm,
    ]);
  const theme = useTheme();

  return (
    <Header>
      <Box display="flex">
        <NavLink to={addressesORD.creativesPath}>
          <Tooltip title="Назад">
            <IconButton>
              <FiChevronLeft size={20} />
            </IconButton>
          </Tooltip>
        </NavLink>
        <Box flex={1}>
          <Typography
            className={css.name}
            variant="h1"
            sx={{
              marginLeft: '2px',
            }}
            title={creative?.name}
          >
            Креатив {creative?.name}
          </Typography>
          <Typography
            mt={0.5}
            sx={{
              color: theme.palette.secondary.light,
            }}
          >
            {creative?.uuid}{' '}
            {creative?.externalId ? `• ${creative?.externalId}` : ''}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          variant="outlined"
          disabled={!changedBasicForm || pendingUpdate}
          sx={{
            marginLeft: pendingUpdate ? '36px' : '0px',
          }}
          onClick={() => onResetBasicForm()}
        >
          Отмена
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={disabledBasicForm}
          onClick={() => onSubmitBasicForm()}
          loading={pendingUpdate}
          sx={{
            marginLeft: '36px',
          }}
        >
          Сохранить
        </LoadingButton>
      </Box>
    </Header>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import React, { FC } from 'react';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useUnit } from 'effector-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  $openWorkspaceCreate,
  onSetOpenWorkspaceCreate,
  workspaceCreateFX,
} from '../../../../../model/workspace';

export const WorkspaceCreate: FC = () => {
  const openWorkspaceCreate = useUnit($openWorkspaceCreate);
  const pending = useUnit(workspaceCreateFX.pending);
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Обязательное поле')
        .trim()
        .min(3, 'Описание слишком короткое'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      workspaceCreateFX({ fields: values }).then(() => {
        onSetOpenWorkspaceCreate(false);
        f.resetForm();
      });
    },
  });
  const handleClose = () => {
    onSetOpenWorkspaceCreate(false);
    formik.resetForm();
  };

  const disabled =
    Boolean(formik.errors.title) || !formik.values.title || pending;
  return (
    <Dialog
      open={openWorkspaceCreate}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle>
        Создайте рабочее пространство
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Повысьте производительность: участники команды смогут получать удобный
          доступ ко всем доскам.
        </DialogContentText>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            autoFocus
            inputProps={{ maxLength: 255 }}
            margin="dense"
            id="title"
            label="Название рабочего пространства"
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.title)}
            helperText={
              formik.errors.title ||
              'Укажите название вашей команды, компании или организации.'
            }
          />
        </FormControl>

        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            inputProps={{ maxLength: 4096 }}
            margin="dense"
            fullWidth
            label="Описание рабочего пространства (необязательно)"
            multiline
            minRows={5}
            sx={{
              '& textarea': {
                resize: 'none',
              },
            }}
            id="description"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            onBlur={formik.handleBlur}
            helperText="Расскажите участникам немного о рабочем пространстве."
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button type="reset" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={formik.submitForm}
          disabled={disabled}
        >
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIMask } from 'react-imask';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { InputMaskElement } from 'imask';
import {
  addInvoicesForm,
  delInvoice,
  Form,
  resetFormS,
} from '../model/additional';

import { setDeletedInvoice } from '../model';
import { InvoiceParam } from '../../../../../../apiRPC/сampaign/campaignInvoiceParams';

type Opts = Parameters<typeof useIMask>['0'];

const optionsIntMaskUint32: Opts & {
  number?: number;
} = {
  mask: Number,
  scale: 0,
  // signed: false,
  thousandsSeparator: ' ',
  padFractionalZeros: false,
  normalizeZeros: false,
  placeholderChar: '',
  min: 0,
  max: 4294967295,
  prepare(value: string, masked: unknown, flags: { input?: boolean }) {
    if (value === '0' && !flags?.input && this?.number === 0) return '';
    return value;
  },
};
const optionsFloat6Mask: Opts & { number?: number } = {
  mask: Number,
  scale: 6,
  // signed: false,
  thousandsSeparator: ' ',
  padFractionalZeros: false,
  normalizeZeros: false,
  min: 0,
  max: 999999,
  radix: ',',
  mapToRadix: ['.'],
  prepare(value: string, masked: unknown, flags: { input?: boolean }) {
    if (value === '0' && !flags?.input && this?.number === 0) return '';
    return value;
  },
};
const optionsFloat10Mask: Opts & { number?: number } = {
  mask: Number,
  scale: 6,
  // signed: false,
  thousandsSeparator: ' ',
  padFractionalZeros: false,
  normalizeZeros: false,
  min: 0,
  max: 9999999999,
  radix: ',',
  mapToRadix: ['.'],
  prepare(value: string, masked: unknown, flags: { input?: boolean }) {
    if (value === '0' && !flags?.input && this?.number === 0) return '';
    return value;
  },
};
const typeOptions: { label: string; value: Form['type'] }[] = [
  { label: 'CPA', value: 'cpa' },
  { label: 'CPC', value: 'cpc' },
  { label: 'CPM', value: 'cpm' },
  { label: 'Другое', value: 'other' },
];
const initialValues: Form = {
  month: null,
  type: 'cpa',
  numberOfUnitsPlan: null,
  numberOfUnits: null,
  amountPerUnit: null,
  totalActualShows: null,
  totalPlannedShows: null,
  totalAmount: null,
  totalExportAmount: null,
};

type SetMask = {
  data?: InvoiceParam;
  set: (value: number) => void;
  name: keyof InvoiceParam;
  formik: ReturnType<typeof useFormik<Form>>;
  isSetMask: React.MutableRefObject<Record<string, boolean>>;
};

function setMask({ data, set, name, formik, isSetMask }: SetMask) {
  if (!data) return;
  const val = data[name];
  if (typeof val === 'string') return;
  // eslint-disable-next-line no-param-reassign
  if (val === 0) isSetMask.current[name] = true;
  set(val);
  formik.setFieldValue(name, val);
}
function useSetMask({
  ref,
  data,
  set,
  name,
  formik,
  isSetMask,
}: SetMask & { ref: React.MutableRefObject<InputMaskElement|null> }) {
  useEffect(() => {
    if (!ref.current) return;
    if (!data) return;
    setMask({ data, set, name, formik, isSetMask });
  }, [ref.current, data]);
}

type Options = { data?: InvoiceParam; id?: string; variant?: number };
export function UseAdditionalItem({ data, id, variant }: Options) {
  const isSetMask = useRef<Record<string, boolean>>({});
  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object({
      month: Yup.date()
        .typeError('Некорректное значение')
        .nullable()
        .required('Обязательное для заполнения поле'),
      type: Yup.string().required('Обязательное для заполнения поле'),
      numberOfUnitsPlan: Yup.number().nullable(),
      numberOfUnits: Yup.number().nullable(),
      amountPerUnit: Yup.number().nullable(),
      totalActualShows: Yup.number().nullable(),
      totalPlannedShows: Yup.number().nullable(),
      totalAmount: Yup.number().nullable(),
      totalExportAmount: Yup.number().nullable(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    if (!variant) return;
    formik.setFieldValue('variant', variant);
  }, [variant]);

  useEffect(() => {
    if (!id) return;
    addInvoicesForm({ id, formik });
  }, [formik, id]);

  useEffect(() => {
    if (!data) return;
    formik.setFieldValue('uuid', data.uuid);
  }, [data?.uuid]);

  useEffect(() => {
    if (!data) return;
    if (!formik.values.uuid) return;
    addInvoicesForm({ id: data.uuid, formik });
  }, [formik, data?.uuid]);

  const {
    ref: refAmountPerUnitMask,
    value: valAmountPerUnitMask,
    setTypedValue: setAmountPerUnitMask,
  } = useIMask(
    {
      ...optionsFloat6Mask,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.amountPerUnit && value === '0') {
          isSetMask.current.amountPerUnit = false;
          return value;
        }
        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },
    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int =
          value === '' ? null : +value.replaceAll(' ', '').replaceAll(',', '.');
        formik.setFieldValue('amountPerUnit', int).then(() => {
          formik.validateField('amountPerUnit');
        });
      },
    },
  );

  const {
    ref: refNumberOfUnitsPlanMask,
    value: valNumberOfUnitsPlanMask,
    setTypedValue: setNumberOfUnitsPlanMask,
  } = useIMask(
    {
      ...optionsIntMaskUint32,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.numberOfUnitsPlan && value === '0') {
          isSetMask.current.numberOfUnitsPlan = false;
          return value;
        }

        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },
    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int = value === '' ? null : +value.replaceAll(' ', '');
        formik.setFieldValue('numberOfUnitsPlan', int).then(() => {
          formik.validateField('numberOfUnitsPlan');
        });
      },
    },
  );
  const {
    ref: refNumberOfUnitsMask,
    value: valNumberOfUnitsMask,
    setTypedValue: setNumberOfUnitsMask,
  } = useIMask(
    {
      ...optionsIntMaskUint32,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.numberOfUnits && value === '0') {
          isSetMask.current.numberOfUnits = false;
          return value;
        }
        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },

    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int =
          value === '' ? null : +value.replaceAll(' ', '').replaceAll(',', '.');
        formik.setFieldValue('numberOfUnits', int).then(() => {
          formik.validateField('numberOfUnits');
        });
      },
    },
  );

  const {
    ref: refTotalPlannedShowsMask,
    value: valTotalPlannedShowsMask,
    setTypedValue: setTotalPlannedShowsMask,
  } = useIMask(
    {
      ...optionsIntMaskUint32,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.totalPlannedShows && value === '0') {
          isSetMask.current.totalPlannedShows = false;
          return value;
        }
        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },
    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int = value === '' ? null : +value.replaceAll(' ', '');
        formik.setFieldValue('totalPlannedShows', int).then(() => {
          formik.validateField('totalPlannedShows');
        });
      },
    },
  );

  const {
    ref: refTotalActualShowsMask,
    value: valTotalActualShowsMask,
    setTypedValue: setTotalActualShowsMask,
  } = useIMask(
    {
      ...optionsIntMaskUint32,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.totalActualShows && value === '0') {
          isSetMask.current.totalActualShows = false;
          return value;
        }
        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },
    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int = value === '' ? null : +value.replaceAll(' ', '');
        formik.setFieldValue('totalActualShows', int).then(() => {
          formik.validateField('totalActualShows');
        });
      },
    },
  );

  const {
    ref: refTotalAmountMask,
    value: valTotalAmountMask,
    setTypedValue: setTotalAmountMask,
  } = useIMask(
    {
      ...optionsFloat10Mask,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.totalAmount && value === '0') {
          isSetMask.current.totalAmount = false;
          return value;
        }
        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },

    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int =
          value === '' ? null : +value.replaceAll(' ', '').replaceAll(',', '.');
        formik.setFieldValue('totalAmount', int).then(() => {
          formik.validateField('totalAmount');
        });
      },
    },
  );
  const {
    ref: refTotalExportAmountMask,
    value: valTotalExportAmountMask,
    setTypedValue: setTotalExportAmountMask,
  } = useIMask(
    {
      ...optionsFloat10Mask,
      prepare(value: string, masked: unknown, flags: { input?: boolean }) {
        if (isSetMask.current.totalExportAmount && value === '0') {
          isSetMask.current.totalExportAmount = false;
          return value;
        }
        if (value === '0' && !flags?.input && this?.number === 0) return '';
        return value;
      },
    },

    {
      onAccept: (value, _, e) => {
        if (!e) return;
        const int =
          value === '' ? null : +value.replaceAll(' ', '').replaceAll(',', '.');
        formik.setFieldValue('totalExportAmount', int).then(() => {
          formik.validateField('totalExportAmount');
        });
      },
    },
  );

  useLayoutEffect(() => {
    if (!data) return;
    formik.setFieldValue('month', new Date(data.month * 1000));
    formik.setFieldValue('type', data.type);
  }, [data]);

  useSetMask({
    ref: refAmountPerUnitMask,
    data,
    set: setAmountPerUnitMask,
    formik,
    name: 'amountPerUnit',
    isSetMask,
  });

  useSetMask({
    ref: refNumberOfUnitsPlanMask,
    data,
    set: setNumberOfUnitsPlanMask,
    formik,
    name: 'numberOfUnitsPlan',
    isSetMask,
  });
  useSetMask({
    ref: refNumberOfUnitsMask,
    data,
    set: setNumberOfUnitsMask,
    formik,
    name: 'numberOfUnits',
    isSetMask,
  });
  useSetMask({
    ref: refTotalPlannedShowsMask,
    data,
    set: setTotalPlannedShowsMask,
    formik,
    name: 'totalPlannedShows',
    isSetMask,
  });
  useSetMask({
    ref: refTotalActualShowsMask,
    data,
    set: setTotalActualShowsMask,
    formik,
    name: 'totalActualShows',
    isSetMask,
  });
  useSetMask({
    ref: refTotalExportAmountMask,
    data,
    set: setTotalExportAmountMask,
    formik,
    name: 'totalExportAmount',
    isSetMask,
  });
  useSetMask({
    ref: refTotalAmountMask,
    data,
    set: setTotalAmountMask,
    formik,
    name: 'totalAmount',
    isSetMask,
  });

  useEffect(() => {
    const subscription = resetFormS.watch(() => {
      if (!data) return;
      formik.setFieldValue('month', new Date(data.month * 1000));
      formik.setFieldValue('type', data.type);
      setMask({
        data,
        set: setAmountPerUnitMask,
        formik,
        name: 'amountPerUnit',
        isSetMask,
      });

      setMask({
        data,
        set: setNumberOfUnitsPlanMask,
        formik,
        name: 'numberOfUnitsPlan',
        isSetMask,
      });
      setMask({
        data,
        set: setNumberOfUnitsMask,
        formik,
        name: 'numberOfUnits',
        isSetMask,
      });
      setMask({
        data,
        set: setTotalPlannedShowsMask,
        formik,
        name: 'totalPlannedShows',
        isSetMask,
      });
      setMask({
        data,
        set: setTotalActualShowsMask,
        formik,
        name: 'totalActualShows',
        isSetMask,
      });
      setMask({
        data,
        set: setTotalExportAmountMask,
        formik,
        name: 'totalExportAmount',
        isSetMask,
      });
      setMask({
        data,
        set: setTotalAmountMask,
        formik,
        name: 'totalAmount',
        isSetMask,
      });

      setTimeout(() => formik.validateForm());
    });
    return subscription;
  }, [formik, data]);

  const onDelete: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!data && id) {
      delInvoice(id);
    }
    if (data) setDeletedInvoice(data.uuid);
  };

  const handleChange: typeof formik['handleChange'] = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value).then(() => {
      formik.validateField(name);
    });
  };

  return {
    onDelete,
    formik,
    handleChange,
    typeOptions,
    refNumberOfUnitsPlanMask,
    valNumberOfUnitsPlanMask,
    refNumberOfUnitsMask,
    valNumberOfUnitsMask,
    refAmountPerUnitMask,
    valAmountPerUnitMask,
    refTotalActualShowsMask,
    valTotalActualShowsMask,
    refTotalPlannedShowsMask,
    valTotalPlannedShowsMask,
    refTotalAmountMask,
    valTotalAmountMask,
    refTotalExportAmountMask,
    valTotalExportAmountMask,
  };
}

class LastUrl {
  private url: string | null = null;

  setUrl(url: string) {
    this.url = url;
  }

  reset() {
    this.url = null;
  }

  getUrl() {
    return this.url;
  }
}

export const lastUrl = new LastUrl();

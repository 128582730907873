import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Fade,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import { MarksItem } from './marksItem';
import {
  $markListEveryColor,
  setDataForUpdate,
} from '../../../../../model/Marks';
import { RMarkGet } from '../../../../../../apiRPC/card/mark';
import { MenuFixed } from '../../../../../../UI/MenuFixed';

type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  onBack?: () => void;
  openMenuMarksCreate: () => void;
  board: string;
  listChecked: string[];
  onClickMark: (item: RMarkGet) => void;
};
export const MenuMarks = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      open,
      anchorEl,
      onClose,
      onBack,
      openMenuMarksCreate,
      board,
      listChecked,
      onClickMark,
    },
    ref,
  ) => {
    const theme = useTheme();
    const [search, setSearch] = useState('');
    const markList = useUnit($markListEveryColor);

    useEffect(() => {
      if (!open) {
        setSearch('');
      }
    }, [open]);
    return (
      <MenuFixed
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        <MenuHeader title="Метки" onBack={onBack} onClose={onClose} />

        <Box padding={2}>
          <TextField
            fullWidth
            label="Поиск меток..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box paddingX={2} paddingY={0}>
          <Typography
            component="span"
            fontSize={12}
            variant="body1"
            fontWeight={600}
            color={theme.palette.grey[700]}
          >
            Метки
          </Typography>
        </Box>

        {markList
          .filter((item) =>
            item.title.toUpperCase().includes(search.toUpperCase()),
          )
          .map((item) => (
            <MarksItem
              color={item.color}
              name={item.title}
              key={item.uuid || item.color}
              onUpdate={() => {
                setDataForUpdate({
                  color: item.color,
                  title: item.title,
                  uuid: item.uuid,
                  board: item.board,
                });
                openMenuMarksCreate();
              }}
              onClickMark={() => onClickMark(item)}
              isChecked={listChecked.includes(item.uuid)}
            />
          ))}

        <Box paddingX={2} paddingY={2}>
          <Button fullWidth variant="outlined" onClick={openMenuMarksCreate}>
            Создать новую метку
          </Button>
        </Box>
      </MenuFixed>
    );
  },
);

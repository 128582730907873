import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useUnit } from 'effector-react';
import { $isOpen } from '../model';
import { ItemCreate } from './Item';
import { $createIds, onDeleteIds, onResetIds } from './model';

type Props = {
  updatePosition: () => void;
};
export const FormCreate: FC<Props> = ({ updatePosition }) => {
  const isOpen = useUnit($isOpen);
  const createIds = useUnit($createIds);

  useLayoutEffect(() => {
    setTimeout(() => {
      updatePosition();
    }, 10);
  }, [createIds.length]);

  const onDelItem = (id: string) => {
    onDeleteIds(id);
  };

  useEffect(() => {
    if (!isOpen) {
      onResetIds();
    }
  }, [isOpen]);

  return (
    <>
      {createIds.map((item) => (
        <ItemCreate id={item} key={item} onDelItem={onDelItem} />
      ))}
    </>
  );
};

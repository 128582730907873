import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate } from '../../../../apiRPC/card';

export type CardUpdateMembers = {
  uuid: string;
  fields: { members: { add?: string[]; delete?: string[] } };
};

export const updateMembersFX = errorHandlerFx(
  createEffectJWT<CardUpdateMembers, RUpdate, Error>(cardUpdate),
);

import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { errorHandlerFx } from '../../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../../model/JWT';
import {
  cardCreativesGroupsCreate,
  cardCreativesGroupsDelete,
  cardCreativesGroupsGetList,
  cardCreativesGroupsUpdate,
  RCardCreativesGroupsGetList,
} from '../../../../../../../apiRPC/card/creatives/groups';
import {
  cardCreativesGetList,
  cardCreativesMoveToGroup,
} from '../../../../../../../apiRPC/card/creatives';

type OpenMenuCreativesGroup = {
  anchorEl: Element;
  cardUid: string;
};

export const onOpenMenuCreativesGroup = createEvent<OpenMenuCreativesGroup>();
export const onCloseMenuCreativesGroup = createEvent();
const setAnchorEl = createEvent<Element>();
export const $anchorEl = restore(setAnchorEl, null).reset(
  onCloseMenuCreativesGroup,
);
export const $isOpen = $anchorEl.map((state) => Boolean(state));

const setCardUid = createEvent<string | null>();
export const $cardUid = restore(setCardUid, null).reset(
  onCloseMenuCreativesGroup,
);

sample({
  clock: onOpenMenuCreativesGroup,
  target: createEffect(({ cardUid, anchorEl }: OpenMenuCreativesGroup) => {
    setCardUid(cardUid);
    setAnchorEl(anchorEl);
  }),
});

export const cardCreativesGroupsCreateFX = errorHandlerFx(
  createEffectJWT(cardCreativesGroupsCreate),
);
export const cardCreativesGroupsDeleteFX = errorHandlerFx(
  createEffectJWT(cardCreativesGroupsDelete),
);
export const cardCreativesGroupsUpdateFX = errorHandlerFx(
  createEffectJWT(cardCreativesGroupsUpdate),
);
export const cardCreativesGroupsGetListFX = errorHandlerFx(
  createEffectJWT(cardCreativesGroupsGetList),
);

export const resetCardCreativesGroupsList = createEvent();
export const $cardCreativesGroupsList =
  createStore<RCardCreativesGroupsGetList>([])
    .on(cardCreativesGroupsGetListFX.doneData, (_, payload) => payload)
    .on(cardCreativesGroupsCreateFX.doneData, (state, payload) => [
      ...state,
      payload,
    ])
    .on(cardCreativesGroupsDeleteFX.done, (state, { params }) =>
      state.filter((item) => item.uuid !== params.uuid),
    )
    .on(cardCreativesGroupsUpdateFX.doneData, (state, payload) => {
      const index = state.findIndex((item) => item.uuid === payload.uuid);
      if (index < 0) return undefined;
      state.splice(index, 1, payload);
      return [...state];
    })
    .reset(resetCardCreativesGroupsList);

sample({
  clock: $cardUid,
  filter: (payload) => Boolean(payload),
  fn: (payload) => ({ card: payload! }),
  target: cardCreativesGroupsGetListFX,
});

const cardCreativesGetListFX = errorHandlerFx(
  createEffectJWT(cardCreativesGetList),
);
const cardCreativesMoveToGroupFX = errorHandlerFx(
  createEffectJWT(cardCreativesMoveToGroup),
);

type PDeleteGroupWithCreatives = { card: string; uuid: string };
export const deleteGroupWithCreatives = createEffect(
  async ({ card, uuid }: PDeleteGroupWithCreatives) => {
    const cardCreativesList = await cardCreativesGetListFX({ card });
    const itemsCards = cardCreativesList
      .filter((item) => item.groupUuid === uuid)
      .map((item) => item.uuid);
    if (itemsCards.length) {
      await cardCreativesMoveToGroupFX({ group: null, items: itemsCards });
    }
    await cardCreativesGroupsDeleteFX({ uuid });
  },
);

export function resetCardCreativesGroups() {
  resetCardCreativesGroupsList();
}

sample({
  clock: onCloseMenuCreativesGroup,
  target: createEffect(resetCardCreativesGroups),
});

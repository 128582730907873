import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, TextareaAutosize, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import { useUnit } from 'effector-react';
import { PluggableList } from "react-markdown/lib/react-markdown";
import { updateDescriptionFX } from '../../../../../model/card/update/description';
import { MarkdownA } from '../../../../../../UI/MarkdownComponent';

const remarkPlugins:PluggableList = [remarkGfm, remarkParse as PluggableList[0]];
const markdownComponent = {
  a: MarkdownA,
};
const styleBoxGridText = {
  display: 'grid',
  gridTemplateColumns: 'max-content max-content max-content',
  alignItems: 'center',
  gap: 1,
};

const TextBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: theme.palette.grey[50],
  '&:hover': {
    background: theme.palette.grey[100],
  },
}));

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'none',
  outline: 'none',
  ...theme.typography.body2,
}));

export type Props = { description: string; uuid: string };
export const Description: FC<Props> = ({ uuid, description }) => {
  const [newDescription, setNewDescription] = useState(description);
  const [isChanged, setIsChanged] = useState(false);
  const showDefaultText = !description && !isChanged;
  const showText = description && !isChanged;
  const showExchangeBottom = !showDefaultText && !isChanged;
  const pending = useUnit(updateDescriptionFX.pending);
  function onSend(v: string) {
    if (newDescription === description) {
      setIsChanged(false);
      return;
    }
    updateDescriptionFX({ uuid, fields: { description: v } }).then(() => {
      setIsChanged(false);
    });
  }

  const onCancel = () => {
    setIsChanged(false);
    setNewDescription(description);
  };
  const onSaveHandleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (pending) return;
    if (e.key === 'Escape') {
      onCancel();
      return;
    }

    if (e.key === 'Enter' && e.ctrlKey) {
      onSend(newDescription);
    }
  };
  return (
    <>
      <Box sx={styleBoxGridText}>
        <DescriptionIcon color="action" />
        <Typography
          variant="subtitle1"
          alignItems="bottom"
          fontWeight={600}
          lineHeight="normal"
          color="text.secondary"
        >
          Описание
        </Typography>

        {showExchangeBottom && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setIsChanged(true)}
          >
            Изменить
          </Button>
        )}
      </Box>

      {isChanged && (
        <Box ml={4} mt={1}>
          <TextArea
            aria-label="описание"
            minRows={3}
            maxLength={10000}
            placeholder="Вы можете добавить более подробное описание"
            disabled={pending}
            autoFocus={isChanged}
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            onKeyDown={onSaveHandleKeyDown}
          />

          <Box gap={1} display="flex">
            <Button
              variant="contained"
              size="small"
              onClick={() => onSend(newDescription)}
              disabled={pending}
            >
              Сохранить
            </Button>
            <Button variant="outlined" size="small" onClick={onCancel}>
              Отменить
            </Button>
          </Box>
        </Box>
      )}

      {showDefaultText && (
        <TextBox
          ml={4}
          mt={1}
          height="91px"
          padding={1}
          onClick={() => setIsChanged(true)}
        >
          <Typography variant="body2" component="article">
            Добавить более подробное описание
          </Typography>
        </TextBox>
      )}

      {showText && (
        <TextBox
          ml={4}
          mt={1}
          paddingX={1}
          onDoubleClick={(e) => {
            if (e.target instanceof Element && e.target.nodeName === 'A') {
              e.stopPropagation();
              return;
            }

            setIsChanged(true);
          }}
          overflow="auto"
        >
          <Typography
            variant="body2"
            component="article"
            sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          >
            <ReactMarkdown
              remarkPlugins={remarkPlugins}
              linkTarget="_blank"
              components={markdownComponent}
              className="_Markdown"
            >
              {description}
            </ReactMarkdown>
          </Typography>
        </TextBox>
      )}
    </>
  );
};

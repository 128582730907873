import React, { FC, useRef, useState } from 'react';
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { MenuCreate } from './MenuCreate';
import { themeGrid } from '../../../theme/themeGrid';

const theme = createTheme(themeGrid);
const PopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -15],
      },
    },
  ],
};
export const Create: FC = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const handleClose = () => {
    setOpenMenu(false);
  };
  const handleOpen = () => {
    setOpenMenu(true);
  };
  const refButton = useRef(null);
  return (
    <>
      <Tooltip
        title="Создать"
        placement="bottom"
        disableInteractive
        PopperProps={PopperProps}
      >
        <IconButton ref={refButton} color="inherit" onClick={handleOpen}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <ThemeProvider theme={theme}>
        <MenuCreate
          anchorEl={refButton.current}
          openMenu={isOpenMenu}
          handleClose={handleClose}
        />
      </ThemeProvider>
    </>
  );
};

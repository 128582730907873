import React, { FC, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  onResetError,
  signInError$,
  signInFx,
} from '../../model/authorization/signIn';
import { useErrors } from './useErrors';
import { addressesSign } from '../../addresses/sign';

export const SignIn: FC = () => {
  const pendingRequests = useUnit(signInFx.pending);
  const signInError = useUnit(signInError$);

  useEffect(() => {
    onResetError();
    return () => onResetError();
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Обязательно для заполнения')
        .min(3, 'Пароль слишком короткий'),
      email: Yup.string()
        .required('Обязательно для заполнения')
        .email('Неверный формат email'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values) => {
      signInFx(values);
    },
  });

  useErrors(signInError, formik);

  return (
    <>
      <Typography component="h1" variant="h5" align="center">
        Sign in
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate mt={1}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email "
          name="email"
          autoComplete="email"
          autoFocus
          onChange={formik.handleChange}
          value={formik.values.email}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email || ' '}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Пароль"
          type="password"
          id="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.password)}
          helperText={formik.errors.password || ' '}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={pendingRequests}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item>
            <Link component={RouterLink} to={addressesSign.upPath}>
              Если у вас еще нет аккаунта , то зарегистрируйте новый.
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

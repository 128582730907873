import React, { FC } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useUnit } from 'effector-react';
import {
  $OpenInputAddFor,
  onOpenLastInputAdd,
} from '../../../../../model/card/AddCardInput';

type Props = { uuid: string };
export const AddCardButton: FC<Props> = ({ uuid }) => {
  const openInputAddFor = useUnit($OpenInputAddFor);

  if (openInputAddFor === uuid) return null;
  return (
    <Button
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={() => {
        onOpenLastInputAdd(uuid);
      }}
      sx={{ mt: 2 }}
    >
      Добавить карточку
    </Button>
  );
};

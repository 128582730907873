import { createEvent, createStore } from 'effector';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import {
  ordGetList,
  organizationGetList,
  ROrdGetList,
  ROrganizationGetList,
} from '../../../../apiRPC/organization';

export const organizationGetCatalogFX = errorHandlerFx(
  createEffectJWT(organizationGetList),
);

export const organizationCatalogReset = createEvent();
export const $organizationCatalog = createStore<
  ROrganizationGetList['rows'] | null
>(null)
  .on(organizationGetCatalogFX.doneData, (_, payload) => payload.rows)
  .reset(organizationCatalogReset);

export const $organizationOption = $organizationCatalog.map((state) => {
  const innList = new Set<string>();
  const result: {
    label: string;
    value: string;
  }[] = [];

  state?.forEach((item) => {
    if (!innList.has(item.number)) {
      result.push({ label: item.name, value: item.number });
      innList.add(item.number);
    }
  });

  return result;
});

export type OrganizationDataIten = { name: string; inn: string };
export type OrganizationData = Record<string, OrganizationDataIten>;
export const $organizationData = $organizationCatalog.map((state) => {
  const result: OrganizationData = {};

  state?.forEach((item) => {
    result[item.uuid] = { name: item.name, inn: item.number };
  });
  return result;
});

export const $organizationByORD = $organizationCatalog.map((state) => {
  const result: Map<
    number,
    { label: string; value: string; search: string }[]
  > = new Map();

  state?.forEach((item) => {
    const label = `${item.name} ${item.aliases ? `(${item.aliases})` : ''}`;

    if (result.has(item.ord)) {
      const arr = result.get(item.ord);
      arr?.push({
        label,
        value: item.uuid,
        search: item.number,
      });
      return;
    }

    result.set(item.ord, [
      {
        label,
        value: item.uuid,
        search: item.number,
      },
    ]);
  });
  return result;
});

export const ordGetCatalogReset = createEvent();
export const ordGetCatalogFX = errorHandlerFx(createEffectJWT(ordGetList));
export const $ordCatalog = createStore<ROrdGetList>([])
  .on(ordGetCatalogFX.doneData, (_, payload) => payload)
  .reset(ordGetCatalogReset);

export const $ordOption = $ordCatalog.map((state) =>
  state?.map((item) => ({
    label: item.title,
    value: item.id,
  })),
);

export function catalogsGet() {
  organizationGetCatalogFX(undefined);
  ordGetCatalogFX(undefined);
}
export function catalogsReset() {
  organizationCatalogReset();
  ordGetCatalogReset();
}

import { createEvent, restore, sample } from 'effector';
import { Status } from '../../../../../../apiRPC/contracts';
import { $lastFilter, contractGetListFX, limitContracts } from '../index';

export const onSetStatusFilter = createEvent<Status | null>();

export const resetStatusFilter = createEvent();
export const $statusFilter = restore(onSetStatusFilter, 'ACTIVE').reset(
  resetStatusFilter,
);

sample({
  clock: onSetStatusFilter,
  source: {
    lastFilter: $lastFilter,
  },

  fn: ({ lastFilter }, payload) => {
    const oldFilter = { ...(lastFilter?.filter || {}) };
    if (!payload && oldFilter.status) {
      delete oldFilter.status;
    }
    if (payload) oldFilter.status = payload;
    const filter = {
      ...oldFilter,
      limit: limitContracts,
      offset: 0,
    };

    return { params: { filter } };
  },
  target: contractGetListFX,
});

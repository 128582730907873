import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { FiTrash } from 'react-icons/fi';
import { useUnit } from 'effector-react';
import { CardCreativeItem } from '../../../../../../../apiRPC/card/creatives';
import {
  $cardCreativesGroupsList,
  $cardUid,
  $dictionaryCreativeSizes,
  cardCreativesCreateFX,
} from '../model';
import { BootstrapTooltip } from '../../../../../../../components/BootstrapTooltip';
import { isErrorProps } from '../../../../../../../apiRPC/request';
import { CodeEditorLight } from '../../../../../../../UI/CodeEditorLight';

type FormCreateItem = Omit<CardCreativeItem, 'uuid' | 'card' | 'url'> & {
  id: string;
};
type Form = FormCreateItem;

const regexSize = /^[1-9][0-9]{1,3}x[1-9][0-9]{1,3}$/;
const validationSchema = Yup.object().shape({
  size: Yup.string()
    .matches(regexSize, 'Невалидное значение')
    .required('Обязательное для заполнения поле'),
  html: Yup.string()
    .required('Обязательное для заполнения поле')
    .nullable()
    .when('type', ([type], schema) => {
      if (type === 'html')
        return schema.matches(
          /(^$)|((?:.|\n|\r)*<html(?:.|\n|\r)*<body)/,
          'Невалидное значение',
        );
      if (type === 'video')
        return schema.matches(/^(https?:\/\/)/, 'Невалидное значение');
      return schema.required('Обязательное для заполнения поле');
    }),
  auditCode: Yup.string().trim(),
  name: Yup.string().trim(),
  groupUuid: Yup.string().nullable().trim(),
  type: Yup.string().required('Обязательное для заполнения поле'),
});
type Props = {
  id: string;
  onDelItem: (id: string) => void;
};
export const ItemCreate: FC<Props> = ({ id, onDelItem }) => {
  const [dictionaryCreativeSizes, cardUid, cardCreativesGroupsList] = useUnit([
    $dictionaryCreativeSizes,
    $cardUid,
    $cardCreativesGroupsList,
  ]);

  const [disabled, setDisable] = useState(false);
  const formik = useFormik<Form>({
    initialValues: {
      size: '',
      html: '',
      auditCode: '',
      id,
      name: '',
      groupUuid: 'null',
      type: 'html',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (!cardUid) return;
      setDisable(true);
      const isHtml = values.type === 'html';
      try {
        await cardCreativesCreateFX({
          card: cardUid,
          fields: {
            size: values.size,
            html: values.html,
            auditCode: isHtml ? values.auditCode : undefined,
            name: values.name ?? '',
            groupUuid: values.groupUuid === 'null' ? null : values.groupUuid,
            type: values.type,
          },
        });
        onDelItem(id);
      } catch (e) {
        console.error(e);

        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      } finally {
        setDisable(false);
      }
    },
  });

  return (
    <Paper>
      <Stack
        padding={1}
        spacing={2}
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Stack display="flex" direction="row" spacing={2} alignItems="center">
          <TextField
            label="Имя"
            variant="standard"
            name="name"
            inputProps={{ maxLength: 255 }}
            autoComplete="none"
            value={formik.values.name}
            error={Boolean(formik.errors.name)}
            helperText={formik.errors.name}
            onChange={formik.handleChange}
            disabled={disabled}
            size="small"
            fullWidth
          />

          <Autocomplete
            size="small"
            value={formik.values.size}
            onChange={(event, newValue) => {
              if (!newValue) return;
              formik.setFieldValue(`size`, newValue);
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              if (!inputValue) return options;
              const filtered = options.filter((item) =>
                item.toUpperCase().includes(inputValue.toUpperCase()),
              );
              const isExisting = options.includes(inputValue);
              if (
                inputValue !== '' &&
                !isExisting &&
                regexSize.test(inputValue)
              ) {
                filtered.push(inputValue);
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={dictionaryCreativeSizes}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                label="Размер"
                required
                inputProps={{ ...params.inputProps, maxLength: 255 }}
                helperText={formik.errors.size}
                error={Boolean(formik.errors.size)}
                sx={{
                  '.MuiFormHelperText-root': {
                    position: 'absolute',
                    textWrap: 'nowrap',
                    bottom: '-20px',
                  },
                }}
              />
            )}
          />

          <Button sx={{ ml: 2 }} variant="contained" size="small" type="submit">
            Сохранить
          </Button>
          <BootstrapTooltip title="Удалить" placement="top">
            <IconButton onClick={() => onDelItem(id)}>
              <FiTrash color="#D32F2F" size={20} />
            </IconButton>
          </BootstrapTooltip>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center" paddingTop={1}>
          <FormControl size="small" fullWidth>
            <InputLabel>Группа</InputLabel>
            <Select
              size="small"
              defaultValue="null"
              value={formik.values.groupUuid || 'null'}
              label="Группа"
              onChange={(e: SelectChangeEvent<string>) => {
                formik.setFieldValue('groupUuid', e.target.value, true);
              }}
              MenuProps={{
                sx: { '& .MuiPopover-paper': { width: 600 } },
              }}
            >
              <MenuItem
                sx={{
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                }}
                value="null"
              >
                <em>Без группы</em>
              </MenuItem>
              {cardCreativesGroupsList?.map((item) => (
                <MenuItem
                  sx={{
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-word',
                  }}
                  key={item.uuid}
                  value={item.uuid}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ width: '100px', flexShrink: 0 }}>
            <InputLabel>Тип</InputLabel>
            <Select
              size="small"
              value={formik.values.type}
              label="Тип"
              onChange={(e: SelectChangeEvent<string>) => {
                formik.setFieldValue('type', e.target.value, true);
              }}
            >
              <MenuItem
                sx={{
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                }}
                value="html"
              >
                HTML
              </MenuItem>
              <MenuItem
                sx={{
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                }}
                value="video"
              >
                Видео
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {formik.values.type === 'html' && (
          <TextField
            label="Код аудита"
            variant="standard"
            name="auditCode"
            inputProps={{ maxLength: 10000 }}
            autoComplete="none"
            multiline
            value={formik.values.auditCode}
            error={Boolean(formik.errors.auditCode)}
            helperText={formik.errors.auditCode}
            onChange={formik.handleChange}
            disabled={disabled}
          />
        )}

        {formik.values.type === 'html' && (
          <CodeEditorLight
            label="HTML"
            placeholder="HTML"
            multiline
            name="html"
            inputProps={{ maxLength: 20000 }}
            required
            value={formik.values.html}
            onChange={(e) => {
              formik.setFieldValue('html', e.target.value, true);
            }}
            error={Boolean(formik.errors.html)}
            helperText={formik.errors.html}
            disabled={disabled}
          />
        )}
        {formik.values.type === 'video' && (
          <TextField
            label="Ссылка"
            variant="standard"
            name="html"
            inputProps={{ maxLength: 20000 }}
            autoComplete="none"
            multiline
            value={formik.values.html}
            error={Boolean(formik.errors.html)}
            helperText={formik.errors.html}
            onChange={formik.handleChange}
            disabled={disabled}
          />
        )}
      </Stack>
    </Paper>
  );
};

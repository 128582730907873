import { FC, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import DiscountIcon from '@mui/icons-material/Discount';
import PersonIcon from '@mui/icons-material/Person';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import ReplayIcon from '@mui/icons-material/Replay';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { useUnit } from 'effector-react';
import { updateStatusFX } from '../../../../../model/card/update/status';
import { Status } from '../../../../../../apiRPC/column';

import { MoveMenu } from '../MoveMenu';
import { onToggleMenuMove } from '../MoveMenu/model';
import { onOpenMenuDate } from '../DateMenu/model';
import { DateMenu } from '../DateMenu';
import {
  joinToMembers,
  joinToMembersFX,
} from '../../../../../model/card/accounts/join';
import { $isYouMember } from '../../../../../model/card/accounts';
import { ReactComponent as CreativeIconSvg } from '../../../../../../assets/icons/creativeIcon.svg';
import { onOpenMenuCreatives } from '../Creatives/model';
import { CreativesMenuMemo } from '../Creatives';
import { onOpenMenuCreativesGroup } from '../CardCreativesGroupMenu/model';
import { CardCreativesGroupMenu } from '../CardCreativesGroupMenu';

export type Props = {
  uuid: string;
  onClickMark: () => void;
  onClickMembers: () => void;
  onClickAttachments: () => void;
  status?: Status;
  onCloseModal: () => void;
  countCreatives: number;
};
export const RightMenu: FC<Props> = ({
  uuid,
  onClickMark,
  onClickMembers,
  onClickAttachments,
  status,
  onCloseModal,
  countCreatives,
}) => {
  const theme = useTheme();
  const pendingUpdateStatus = useUnit(updateStatusFX.pending);
  const onSetArchive = () => {
    updateStatusFX({ uuid, fields: { status: 'CLOSED' } });
  };
  const onSetActive = () => {
    updateStatusFX({ uuid, fields: { status: 'ACTIVE' } });
  };

  const anchorMenuMove = useRef(null);
  const onOpenMenuMove = () => {
    onToggleMenuMove(true);
  };

  const anchorMenuDate = useRef<HTMLLIElement>(null);
  const openMenuDate = () => {
    if (!anchorMenuDate.current) return;
    onOpenMenuDate({
      anchorEl: anchorMenuDate.current,
      cardUid: uuid,
    });
  };

  const anchorMenuCreatives = useRef<HTMLLIElement>(null);
  const openMenuCreatives = () => {
    if (!anchorMenuCreatives.current) return;
    onOpenMenuCreatives({
      anchorEl: anchorMenuCreatives.current,
      cardUid: uuid,
    });
  };

  const anchorMenuCreativesGroup = useRef<HTMLLIElement>(null);

  const openMenuCreativesGroup = () => {
    if (!anchorMenuCreativesGroup.current) return;
    onOpenMenuCreativesGroup({
      anchorEl: anchorMenuCreativesGroup.current,
      cardUid: uuid,
    });
  };

  const [isOpenCopyTitle, setOpenCopyTitle] = useState(false);
  useEffect(() => {
    if (!isOpenCopyTitle) return;
    setTimeout(() => {
      setOpenCopyTitle(false);
    }, 3000);
  }, [isOpenCopyTitle]);
  const pendingJoinToMembers = useUnit(joinToMembersFX.pending);
  const isYouMember = useUnit($isYouMember);
  return (
    <>
      <Paper variant="outlined" sx={{ position: 'sticky', top: 0 }}>
        <MenuList>
          <Typography color="text.secondary" ml={1} variant="body2">
            Добавить на карточку
          </Typography>
          <Divider />

          <MenuItem onClick={openMenuCreatives} ref={anchorMenuCreatives}>
            <ListItemIcon>
              <Badge
                color="primary"
                badgeContent={countCreatives}
                max={99999}
                sx={{
                  '.MuiBadge-badge': {
                    transform: 'translate(-4px,-8px)',
                    width: 'min-content',
                  },
                }}
              >
                <CreativeIconSvg color={theme.palette.primary.main} />
              </Badge>
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" variant="body1">
                Креативы
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem
            onClick={openMenuCreativesGroup}
            ref={anchorMenuCreativesGroup}
          >
            <ListItemIcon>
              <GroupWorkIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                color="primary"
                variant="body1"
                whiteSpace="break-spaces"
                fontSize={14}
              >
                Группы для креативов
              </Typography>
            </ListItemText>
          </MenuItem>
          {!isYouMember && (
            <MenuItem
              onClick={() => joinToMembers()}
              disabled={pendingJoinToMembers}
            >
              <ListItemIcon>
                <PersonAddIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="primary" variant="body1">
                  Присоединиться
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={onClickMembers}>
            <ListItemIcon>
              <PersonIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" variant="body1">
                Участники
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={onClickMark}>
            <ListItemIcon>
              <DiscountIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" variant="body1">
                Метки
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={onClickAttachments}>
            <ListItemIcon>
              <AttachFileIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" variant="body1">
                Вложения
              </Typography>
            </ListItemText>
          </MenuItem>

          {status === 'ACTIVE' && (
            <MenuItem onClick={onSetArchive} disabled={pendingUpdateStatus}>
              <ListItemIcon>
                <ArchiveIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="primary" variant="body1">
                  Архивировать
                </Typography>
              </ListItemText>
            </MenuItem>
          )}

          {status && status !== 'ACTIVE' && (
            <MenuItem onClick={onSetActive} disabled={pendingUpdateStatus}>
              <ListItemIcon>
                <ReplayIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="primary" variant="body1">
                  Восстановить
                </Typography>
              </ListItemText>
            </MenuItem>
          )}

          <MenuItem onClick={onOpenMenuMove} ref={anchorMenuMove}>
            <ListItemIcon>
              <TrendingFlatIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" variant="body1">
                Перемещение
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={openMenuDate} ref={anchorMenuDate}>
            <ListItemIcon>
              <QueryBuilderIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary" variant="body1">
                Даты
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigator?.clipboard?.writeText(uuid).then(() => {
                setOpenCopyTitle(true);
              });
            }}
          >
            <ListItemIcon>
              <ContentCopyIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Tooltip title="Скопирован" open={isOpenCopyTitle}>
                <Typography color="primary" variant="body1">
                  Скопировать id
                </Typography>
              </Tooltip>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
      <MoveMenu anchorEl={anchorMenuMove.current} onCloseModal={onCloseModal} />
      <DateMenu />
      <CreativesMenuMemo />
      <CardCreativesGroupMenu />
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useUnit } from 'effector-react';
import {
  contentDeleteFX,
  contentRestoreFX,
} from '../../../../../../model/card/update/attachments';
import { $accountListForFile } from '../../../../../../../helper/attachments';
import { Row } from '../../../../../../../apiRPC/account';

const PaperComponent: React.JSXElementConstructor<PaperProps<'div'>> = ({
  children,
}) => (
  <Box
    minHeight="100%"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    maxWidth="calc(100% - 80px)"
    overflow="auto"
    flexWrap="nowrap"
  >
    {children}
  </Box>
);
const PaperComponentIframe: React.JSXElementConstructor<PaperProps<'div'>> = ({
  children,
}) => (
  <Box
    height="100%"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    width="calc(100% - 80px)"
    overflow="auto"
  >
    {children}
  </Box>
);
const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
type Props = {
  open: boolean;
  url: string;
  name: string;
  close: () => void;
  ext: string;
  createdAt: number;
  size: number;
  cardId: string;
  uuidFile: string;
  downloadUrl: string;
  hideDelBtn?: boolean;
  deleted: boolean;
  account: string;
};
export const AttachmentViewer: FC<Props> = ({
  open,
  url,
  name,
  close,
  ext,
  createdAt,
  size,
  cardId,
  uuidFile,
  downloadUrl,
  hideDelBtn,
  deleted,
  account,
}) => {
  const [openMenuDel, setOpenMenuDel] = useState(false);
  const accountListForFile = useUnit($accountListForFile);
  useEffect(() => {
    setOpenMenuDel(false);
  }, [open]);

  const onDelete = () => {
    contentDeleteFX({
      uuid: uuidFile,
    }).finally(() => close());
  };

  const recoverАile = () => {
    contentRestoreFX({
      uuid: uuidFile,
    }).finally(() => close());
  };
  const pendingDelete = useUnit(contentDeleteFX.pending);
  const isIframe = ext.toUpperCase() === 'TXT';
  return (
    <Dialog
      open={open}
      PaperComponent={isIframe ? PaperComponentIframe : PaperComponent}
      onClose={close}
      sx={{
        overflow: 'auto',
        '.MuiDialog-container': { height: isIframe ? '100%' : 'auto' },
      }}
    >
      <IconButton
        sx={{ position: 'fixed', right: 2, top: 2, color: '#fff9' }}
        edge="start"
        color="inherit"
        onClick={close}
        aria-label="close"
      >
        <CloseIcon color="inherit" />
      </IconButton>
      <Paper sx={{ padding: 1, height: 'inherit' }}>
        {isIframe ? (
          <iframe src={url} title={name} width="100%" height="100%" />
        ) : (
          <img src={downloadUrl} alt={name} width="100%" />
        )}
      </Paper>

      <Box
        color="#fff"
        textAlign="center"
        paddingBottom={2}
        position={isIframe ? 'inherit' : 'fixed'}
        left={0}
        right={0}
        bottom={0}
        sx={{ background: 'rgba(0, 0, 0, 0.5)' }}
      >
        <Typography variant="h6" sx={{ wordBreak: 'break-all' }}>
          {name}.{ext}
        </Typography>
        <Typography>
          Добавлено {accountListForFile.get(account || '')?.email || null}{' '}
          {new Intl.DateTimeFormat('ru', optionsTime).format(createdAt * 1000)}{' '}
          • {(size / 1e6).toFixed(2)} MB
        </Typography>

        {!openMenuDel && (
          <Stack direction="row" spacing={1} justifyContent="center">
            <Button
              sx={{ color: '#fff' }}
              startIcon={<ArrowDownwardIcon />}
              component="a"
              href={downloadUrl}
            >
              Скачать
            </Button>
            <Button
              component="a"
              href={url}
              sx={{ color: '#fff' }}
              startIcon={<ArrowOutwardIcon />}
              target="_blank"
            >
              Открыть в новой вкладке
            </Button>
            {!hideDelBtn && !deleted && (
              <Button
                sx={{ color: '#fff' }}
                startIcon={<CloseIcon />}
                onClick={() => setOpenMenuDel(true)}
              >
                Удалить
              </Button>
            )}
            {deleted && (
              <Button
                sx={{ color: '#fff' }}
                onClick={recoverАile}
                disabled={pendingDelete}
              >
                Восстановить.
              </Button>
            )}
          </Stack>
        )}

        {openMenuDel && (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="baseline"
          >
            <Typography>Удалить ?</Typography>

            <Button
              sx={{ color: '#fff' }}
              onClick={onDelete}
              disabled={pendingDelete}
            >
              Удалить.
            </Button>

            <Button
              sx={{ color: '#fff' }}
              onClick={() => setOpenMenuDel(false)}
            >
              Отмена
            </Button>
          </Stack>
        )}
      </Box>
    </Dialog>
  );
};

import React, {
  FC,
  RefObject,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import {
  ButtonGroup,
  createTheme,
  Dialog,
  DialogContent,
  FormGroup,
  IconButton,
  Slide,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { useUnit } from 'effector-react';
import { TransitionProps } from '@mui/material/transitions';
import {
  $countSearches,
  $cursor,
  decreaseCursor,
  increaseCursor,
  resetCursor,
  resetSearchVal,
  setCursor,
  setSearchVal,
} from './model';
import { themeGrid } from '../../../../theme/themeGrid';

const onFocusText = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
  e.target.select();
};
const keys = ['f', 'F', 'а', 'А'];
const theme = createTheme(themeGrid);

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="down" ref={ref} {...props} />,
);

function setFocus(refInput: RefObject<HTMLDivElement>) {
  const inputs = refInput.current?.getElementsByTagName('input');
  inputs?.item(0)?.focus({ preventScroll: true });
}
export const Search: FC = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [value, setValue] = useState('');

  const { uuid } = useParams();
  const oldUid = useRef(uuid);

  const countSearches = useUnit($countSearches);
  const cursor = useUnit($cursor);

  const onChange: StandardInputProps['onChange'] = (e) => {
    setValue(e.target.value);
  };
  const reset = () => {
    // setValue('');
    resetSearchVal();
    resetCursor();
  };

  const [_, startTransition] = useTransition();
  useEffect(() => {
    startTransition(() => {
      setSearchVal(value);
    });
  }, [value]);

  useEffect(() => {
    const matchCard = matchPath('c/*', location.pathname);
    if (matchCard) return;
    if (!oldUid.current) return;
    const matchDesk = matchPath(`d/${oldUid.current}/*`, location.pathname);
    if (matchDesk) return;
    oldUid.current = uuid;
  }, [location.pathname]);

  const refInput = useRef<HTMLDivElement>(null);
  const onIncrease = () => {
    setFocus(refInput);
    if (!countSearches) return;
    if (cursor >= countSearches - 1) {
      setCursor(0);
      return;
    }
    increaseCursor();
  };

  const onDecrease = () => {
    setFocus(refInput);
    if (cursor <= 0) {
      setCursor(countSearches - 1);
      return;
    }
    decreaseCursor();
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;
    onIncrease();
  };

  const onClose = () => {
    setOpen(false);
    reset();
  };
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && keys.includes(e.key)) {
        setFocus(refInput);
        setOpen(true);
        e.stopPropagation();
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    startTransition(() => {
      setSearchVal(value);
    });
    setFocus(refInput);
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        hideBackdrop
        sx={{ bottom: 'auto', left: 'auto' }}
        PaperProps={{ sx: { margin: '10px' } }}
        disableScrollLock
        TransitionComponent={Transition}
        onClose={onClose}
      >
        <DialogContent>
          <FormGroup row>
            <TextField
              size="small"
              label="Поиск по названию карточки"
              variant="outlined"
              sx={{ alignItems: 'center' }}
              value={value}
              onChange={onChange}
              onKeyPress={onPressEnter}
              ref={refInput}
              focused
              InputProps={{ autoFocus: true, onFocus: onFocusText }}
            />
            <Typography ml={1} minWidth={50} alignSelf="center">
              {cursor + 1}/{countSearches}
            </Typography>

            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{ alignItems: 'center' }}
            >
              <IconButton size="small" onClick={onDecrease}>
                <ExpandLessIcon fontSize="inherit" color="primary" />
              </IconButton>
              <IconButton size="small" onClick={onIncrease}>
                <ExpandMoreIcon fontSize="inherit" color="primary" />
              </IconButton>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon fontSize="inherit" color="primary" />
              </IconButton>
            </ButtonGroup>
          </FormGroup>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

import { createEffect, Effect } from 'effector';
import { CancelToken } from 'axios';
import { refreshToken } from '../../apiRPC/account';
import { logOut } from './logOut';
import {
  getRefreshToken,
  hasJWTInfo,
  isActualJWTInfo,
  setJWTInfo,
} from './methods';

import { isErrorProps } from '../../apiRPC/request';
import { lastUrl } from './lastUrl';

function waitEffect<Params, Done, Fail>(effect: Effect<Params, Done, Fail>) {
  return () =>
    new Promise((resolve, reject) => {
      const count = effect.inFlight.getState();
      if (count === 0) resolve(true);

      const id = setTimeout(
        () => reject(new Error('Timed out update JWT')),
        5000,
      );
      effect.inFlight.watch((state) => {
        if (state === 0) {
          clearTimeout(id);
          resolve(true);
        }
      });
    });
}

const _refreshTokenFx = createEffect(refreshToken);
_refreshTokenFx.doneData.watch(setJWTInfo);

const waitRefresh = waitEffect(_refreshTokenFx);

async function checkJWT(): Promise<boolean> {
  if (_refreshTokenFx.pending.getState()) {
    await waitRefresh();
  }
  if (!hasJWTInfo()) return false;
  if (isActualJWTInfo()) return true;

  const token = getRefreshToken();
  if (!token) return false;
  try {
    await _refreshTokenFx({ token });
  } catch (e) {
    return false;
  }

  return true;
}

const checkJWTFx = createEffect(checkJWT);

const waitCheckJWTFx = waitEffect(checkJWTFx);

export function createEffectJWT<Params, Done, Fail = Error>(
  handler: (params: Params, cancelToken?: CancelToken) => Done | Promise<Done>,
) {
  const fx = createEffect(async (p: Params, cancelToken?: CancelToken) => {
    if (checkJWTFx.pending.getState()) {
      await waitCheckJWTFx();
    }
    const check = await checkJWTFx();
    if (!check) {
      lastUrl.setUrl(window.location.pathname);
      logOut();
      throw new Error('Неверный токен');
    }
    return handler(p, cancelToken);
  });
  fx.failData.watch((payload: unknown) => {
    if (isErrorProps(payload)) {
      if (payload?.code === 100) {
        lastUrl.setUrl(window.location.pathname);
        logOut();
      }
    }
  });
  return fx;
}

import {
  blue,
  cyan,
  green,
  grey,
  lime,
  orange,
  pink,
  purple,
  red,
  yellow,
} from '@mui/material/colors';
import type { ColorsMark } from '../../../../model/Marks';

export const mapColors: Record<ColorsMark, string> = {
  yellow: yellow[500],
  purple: purple[500],
  blue: blue[500],
  red: red[500],
  green: green[500],
  orange: orange[500],
  black: grey[900],
  sky: cyan[500],
  pink: pink[500],
  lime: lime[500],
  none: grey[500],
};

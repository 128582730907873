import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { $card, cardGetFX } from '../card';
import { $markList } from './index';
import { RMarkGetList } from '../../../apiRPC/card/mark';
import { CardUpdateMarks, updateMarksFX } from '../card/update/marks';

export const setMarksForAdd = createEvent<string>();
export const setMarksForDel = createEvent<string>();

export const resetMarksForAdd = createEvent();
export const $marksUuidForAdd = createStore<string[]>([])
  .on(setMarksForAdd, (state, payload) => {
    if (state.includes(payload)) return undefined;
    return [...state, payload];
  })
  .on(setMarksForDel, (state, payload) => {
    if (!state.includes(payload)) return undefined;
    return state.filter((item) => item !== payload);
  })
  .reset(resetMarksForAdd);

export const resetMarksForDel = createEvent();
export const $marksUuidForDel = createStore<string[]>([])
  .on(setMarksForDel, (state, payload) => {
    if (state.includes(payload)) return undefined;
    return [...state, payload];
  })
  .on(setMarksForAdd, (state, payload) => {
    if (!state.includes(payload)) return undefined;
    return state.filter((item) => item !== payload);
  })
  .reset(resetMarksForDel);

export const $ListUuidMarkForCard = combine(
  $marksUuidForAdd,
  $marksUuidForDel,
  $card,
  (marksUuidForAdd, marksUuidForDel, card) => {
    const currentUuids = card?.marks.map((item) => item.uuid) || [];
    const afterDel = currentUuids.filter(
      (item) => !marksUuidForDel.includes(item),
    );
    const unique = new Set([...afterDel, ...marksUuidForAdd]);
    return Array.from(unique);
  },
);

export const $listMarksForCard = combine(
  $ListUuidMarkForCard,
  $markList,
  (ListUuidMarkForCard, markList) => {
    const result: RMarkGetList = [];
    if (!markList) return result;

    ListUuidMarkForCard.forEach((uuid) => {
      const mark = markList.find((item) => item.uuid === uuid);
      if (!mark) return;
      result.push(mark);
    });

    return result;
  },
);

export const updateMarksForCard = createEvent();

type Card = { marks: { uuid: string }[] };
export function isDel(card: Card | null, marksUuidForDel: string[]): boolean {
  return (
    card?.marks.some((item) => marksUuidForDel.includes(item.uuid)) ?? false
  );
}
export function isAdd(card: Card | null, marksUuidForAdd: string[]): boolean {
  return marksUuidForAdd.some((uuid) => {
    const find = card?.marks.find((item) => item.uuid === uuid);
    return !find;
  });
}

sample({
  clock: updateMarksForCard,
  source: { $card, $marksUuidForAdd, $marksUuidForDel },
  filter: ({
    $card: card,
    $marksUuidForAdd: marksUuidForAdd,
    $marksUuidForDel: marksUuidForDel,
  }) => {
    if (!marksUuidForAdd.length && !marksUuidForDel.length) return false;

    return isDel(card, marksUuidForDel) || isAdd(card, marksUuidForAdd);
  },
  fn: ({
    $card: card,
    $marksUuidForAdd: marksUuidForAdd,
    $marksUuidForDel: marksUuidForDel,
  }) => {
    const add: string[] = [];
    marksUuidForAdd.forEach((uuid) => {
      if (!card?.marks.find((item) => item.uuid === uuid)) {
        add.push(uuid);
      }
    });
    const del: string[] = [];
    marksUuidForDel.forEach((uuid) => {
      if (card?.marks.find((item) => item.uuid === uuid)) {
        del.push(uuid);
      }
    });

    return { uuid: card?.uuid || '', fields: { marks: { add, delete: del } } };
  },
  target: createEffect(async (d: CardUpdateMarks) => {
    await updateMarksFX(d);
    await cardGetFX({ uuid: d.uuid });
    resetMarksForAdd();
    resetMarksForDel();
  }),
});

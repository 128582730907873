import React, { FC, useState } from 'react';
import { Fade, MenuItem } from '@mui/material';

import { useUnit } from 'effector-react';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import {
  ContainerMarksMenu,
  PropsContainerMarksMenu,
} from '../../Marks/ContanerMarksMenu';
import { MenuMembers } from '../../Members/MenuMembers';
import { MenuFixed } from '../../../../../../UI/MenuFixed';
import {
  $accountsSort,
  $membersForCreate,
  delMembersForCreate,
  setMembersForCreate,
} from '../../../../../model/card/accounts';
import type { Account } from '../../../../../../apiRPC/board';

const onClickMember = (member: Account, isChecked: boolean) => {
  if (isChecked) {
    delMembersForCreate(member.uuid);
    return;
  }
  setMembersForCreate(member.uuid);
};
interface Props
  extends Omit<PropsContainerMarksMenu, 'openMenuMarks' | 'setOpenMenuMarks'> {
  anchorEl?: Element | null;
  openMenu: boolean;
  handleClose: () => void;
  refMenu?: React.RefObject<HTMLDivElement>;
  refMenuMembers?: React.RefObject<HTMLDivElement>;
}
export const MenuForAdd: FC<Props> = ({
  anchorEl,
  openMenu,
  handleClose,
  refMenu,
  board,
  handleOpen,
  listChecked,
  onClickMark,
  refMenuMarks,
  refMarksCreate,
  refMarksDelete,
  refMenuMembers,
}) => {
  const [openMenuMarks, setOpenMenuMarks] = useState(false);
  const [openMenuMembers, setOpenMenuMembers] = useState(false);

  const membersForCreate = useUnit($membersForCreate);
  const accountsSort = useUnit($accountsSort);

  return (
    <>
      <MenuFixed
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        TransitionComponent={Fade}
        ref={refMenu}
      >
        <MenuHeader title="Параметры" onClose={handleClose} />
        <MenuItem
          onClick={() => {
            setOpenMenuMembers(true);
            handleClose();
          }}
        >
          Участники...
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenMenuMarks(true);
            handleClose();
          }}
        >
          Метки...
        </MenuItem>
      </MenuFixed>

      <ContainerMarksMenu
        openMenuMarks={openMenuMarks}
        setOpenMenuMarks={setOpenMenuMarks}
        anchorEl={anchorEl}
        board={board}
        handleOpen={handleOpen}
        handleClose={handleClose}
        listChecked={listChecked}
        onClickMark={onClickMark}
        refMenuMarks={refMenuMarks}
        refMarksCreate={refMarksCreate}
        refMarksDelete={refMarksDelete}
      />

      <MenuMembers
        openMenu={openMenuMembers}
        anchorEl={anchorEl}
        handleClose={() => setOpenMenuMembers(false)}
        ref={refMenuMembers}
        onBack={() => {
          setOpenMenuMembers(false);
          handleOpen?.();
        }}
        accounts={accountsSort}
        listChecked={membersForCreate}
        onClickMember={onClickMember}
      />
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import {
  $workspace,
  workspaceGetFX,
  workspaceGetReset,
} from '../../../model/workspace';
import { Info } from './Info';
import { ChangeInfo } from './ChangeInfo';

type Props = { currentWorkspace: string };
export const WorkspaceInfo: FC<Props> = ({ currentWorkspace }) => {
  const [isChanged, setChanged] = useState(false);

  const workspaceData = useUnit($workspace);
  const onChange = () => {
    setChanged(true);
  };
  const onChangeClose = () => {
    setChanged(false);
  };
  useEffect(() => {
    workspaceGetFX({ identifier: currentWorkspace });
    return () => {
      workspaceGetReset();
      onChangeClose();
    };
  }, [currentWorkspace]);

  if (workspaceData && !isChanged)
    return <Info workspaceData={workspaceData} onChange={onChange} />;
  if (workspaceData && isChanged)
    return (
      <ChangeInfo
        workspaceData={workspaceData}
        onChangeClose={onChangeClose}
        currentWorkspace={currentWorkspace}
      />
    );

  return null;
};

import request from '../request';

export type Status = 'ACTIVE' | 'ARCHIVE' | 'DELETED';
export type Types = 'cpa' | 'cpc' | 'cpm' | 'other';
export type Variant = 0 | 1 | 2 | 3;

export type CampaignItem = {
  uuid: string;
  name: string;
  contract: string;
  contractNumber: string;
  invoiceContract?: string;
  invoiceContractNumber?: string;
  dateStart: number;
  dateEnd: number;
  initialContractId: string;
  budget: number;
  budgetSC?: number;
  status: Status;
  rateSC?: number;
};

export type SortCampaignItem = [keyof CampaignItem, 'asc' | 'desc'];
export type SortCampaign = SortCampaignItem[];

export type PCampaignGetList = {
  filter: {
    limit: number;
    offset: number;
    status?: Status;
    search?: string;
    month?: string;
    invoiceContract?: string;
    sort?: SortCampaign;
  };
};

export type RCampaignGetList = { total: number; rows: CampaignItem[] };
export const campaignGetList = request<RCampaignGetList, PCampaignGetList>({
  method: 'campaign.getList',
});

export type RCampaignSetStatus = boolean;
export type PCampaignSetStatus = { uuid: string; status: Status };

export const campaignSetStatus = request<
  RCampaignSetStatus,
  PCampaignSetStatus
>({
  method: 'campaign.setStatus',
});

export type PCampaignCreate = {
  fields: Omit<
    CampaignItem,
    'uuid' | 'contractNumber' | 'initialContractId' | 'status' | 'budget'
  >;
};

export type RCampaignCreate = CampaignItem;
export const campaignCreate = request<RCampaignCreate, PCampaignCreate>({
  method: 'campaign.create',
});

export type PCampaignGet = {
  uuid: string;
};

export type RCampaignGet = Omit<CampaignItem, 'rateSC'>;
export const campaignGet = request<RCampaignGet, PCampaignGet>({
  method: 'campaign.get',
});

export type PCampaignUpdate = {
  uuid: string;
  fields: {
    name?: CampaignItem['name'];
    invoiceContract?: CampaignItem['invoiceContract'];
    dateStart?: CampaignItem['dateStart'];
    dateEnd?: CampaignItem['dateEnd'];
    budget?: CampaignItem['budget'];
    budgetSC?: CampaignItem['budgetSC'];
    rateSC?: CampaignItem['rateSC'];
  };
};

export type RCampaignUpdate = RCampaignGet;
export const campaignUpdate = request<RCampaignUpdate, PCampaignUpdate>({
  method: 'campaign.update',
});

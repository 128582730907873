import axios, { Canceler, CancelToken } from 'axios';
import { createEvent, createStore, sample } from 'effector';
import { debounce } from 'patronum/debounce';
import { cardSearch, PCardSearch, RCardSearch } from '../../../../apiRPC/card';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';

const limit = 30;

type FasP = {
  params: PCardSearch;
  cancelToken?: CancelToken;
};

export const cardSearchCancel = (p: FasP) =>
  cardSearch(p.params, p?.cancelToken);

const cardSearchFX = errorHandlerFx(createEffectJWT(cardSearchCancel));
const cardSearchForScrollFX = errorHandlerFx(createEffectJWT(cardSearch));

export const onResetCardsList = createEvent();
export const $cardsList = createStore<RCardSearch['rows'] | null>(null)
  .on(cardSearchFX.doneData, (_, payload) => payload.rows)
  .on(cardSearchForScrollFX.doneData, (state, payload) => [
    ...(state || []),
    ...payload.rows,
  ])
  .reset(onResetCardsList);

const $totalRows = createStore<number>(0)
  .on(cardSearchFX.doneData, (_, payload) => payload.total)
  .on(cardSearchForScrollFX.doneData, (_, payload) => payload.total);

const $lastFilter = createStore<PCardSearch | null>(null)
  .on(cardSearchFX.done, (_, { params }) => params?.params ?? null)
  .reset(onResetCardsList);

export const searchCards = createEvent<string>();
const cancelTokenSearchFilter: Canceler[] = [];

sample({
  clock: searchCards,
  filter: (payload) => payload.length < 3,
  target: onResetCardsList,
});
sample({
  clock: debounce({ source: searchCards, timeout: 300 }),
  source: {
    lastFilter: $lastFilter,
  },
  filter: (_, payload) => payload.length >= 3,
  fn: ({ lastFilter }, payload) => {
    cancelTokenSearchFilter.forEach((item) => item());
    const source = axios.CancelToken.source();
    cancelTokenSearchFilter.push(source.cancel);
    const filter = {
      text: payload,
      limit,
      offset: 0,
    };
    return {
      params: filter,
      cancelToken: source.token,
    };
  },
  target: cardSearchFX,
});

export const onLoadCards = createEvent();
sample({
  clock: onLoadCards,
  source: {
    lastFilter: $lastFilter,
    cardsList: $cardsList,
    totalRows: $totalRows,
  },
  filter: ({ cardsList, totalRows, lastFilter }) =>
    Boolean(cardsList?.length) &&
    totalRows > (cardsList?.length || 0) &&
    (lastFilter?.text.length ?? 0) >= 3,
  fn: ({ cardsList, lastFilter }) => ({
    ...lastFilter!,
    limit,
    offset: cardsList?.length || 0,
  }),
  target: cardSearchForScrollFX,
});

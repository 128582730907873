import React, { FC } from 'react';
import {
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { MenuFixed } from '../../../../../../UI/MenuFixed';

import { MenuHeader } from '../../../../../../components/MenuHeader';

import {
  $boardUid,
  $cardIndex,
  $cardListsSorted,
  $columnListSort,
  $columnUid,
  $currentCardIndex,
  $isOpenMenuMove,
  listBoard$,
  onToggleMenuMove,
  setBoardUid,
  setCardIndex,
  setColumnUid,
} from './model';
import { $board } from '../../../../../model/board';
import { $column } from '../../../../../model/column';
import { $card, setSortCart } from '../../../../../model/card';

const selectItemStyle = {
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};
type Props = {
  anchorEl?: Element | null;
  onCloseModal: () => void;
};

export const MoveMenu: FC<Props> = ({ anchorEl, onCloseModal }) => {
  const open = useUnit($isOpenMenuMove);

  const listBoard = useUnit(listBoard$);
  const currentBoard = useUnit($board);

  const columnList = useUnit($columnListSort);
  const currentColumn = useUnit($column);

  const cardListsSorted = useUnit($cardListsSorted);
  const cardCurrent = useUnit($card);

  const boardUid = useUnit($boardUid);
  const columnUid = useUnit($columnUid);

  const cardIndex = useUnit($cardIndex);

  const isChangedColumn = columnUid !== currentColumn?.uuid;

  const currentCardListsSorted = cardListsSorted[columnUid || ''] || [];

  const currentCardIndex = useUnit($currentCardIndex);

  const onClose = () => {
    onToggleMenuMove(false);
  };

  const onMove = () => {
    const isChangeBoard = boardUid !== currentBoard?.uuid;
    if (!isChangedColumn && currentCardIndex === cardIndex) {
      onClose();
      return;
    }
    if (!columnUid) return;

    setSortCart({
      currentIndex: isChangedColumn ? 0 : currentCardIndex,
      targetIndex: cardIndex,
      columnId: columnUid,
      soursColumnId: currentColumn?.uuid || '',
      uuid: cardCurrent?.uuid || '',
      isChangedColumn: isChangedColumn || isChangeBoard,
      newCardListsSorted: cardListsSorted,
    });

    onClose();
    if (isChangeBoard) {
      onCloseModal();
    }
  };

  const nextIndexCardLists = currentCardListsSorted.length;

  return (
    <MenuFixed
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuHeader title="Перемещение карточки" onClose={onClose} />
      <Box paddingX={2} paddingY={1}>
        <Typography variant="body2" fontWeight={600}>
          Выберите колонку
        </Typography>

        <FormControl fullWidth size="small" margin="dense">
          <InputLabel size="small">Доска</InputLabel>
          <Select
            size="small"
            value={boardUid}
            label="Доска"
            onChange={(e) => setBoardUid(e.target.value || '')}
          >
            {listBoard?.rows.map((board) => (
              <MenuItem
                key={board.uuid}
                value={board.uuid}
                sx={selectItemStyle}
              >
                {board.title}
                {currentBoard?.uuid === board?.uuid ? ' (текущая) ' : null}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box display="grid" gap={1} gridTemplateColumns="auto 75px">
          <FormControl size="small" margin="dense">
            <InputLabel size="small">Список</InputLabel>
            <Select
              size="small"
              value={columnUid}
              label="Список"
              onChange={(e) => setColumnUid(e.target.value || '')}
            >
              {columnList?.map((column) => (
                <MenuItem
                  sx={selectItemStyle}
                  key={column.uuid}
                  value={column.uuid}
                >
                  {column.title}
                  {currentColumn?.uuid === column.uuid ? ' (текущий) ' : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" margin="dense">
            <InputLabel size="small">Позиция</InputLabel>
            <Select
              size="small"
              value={cardIndex}
              label="Позиция  "
              renderValue={(d) => d + 1}
              onChange={(e) => setCardIndex(+e.target.value)}
            >
              {currentCardListsSorted.map((_, index) => (
                <MenuItem key={index} value={index}>
                  {index + 1}
                  {!isChangedColumn && currentCardIndex === index
                    ? ' (текущая) '
                    : null}
                </MenuItem>
              ))}
              {isChangedColumn && (
                <MenuItem key={nextIndexCardLists} value={nextIndexCardLists}>
                  {nextIndexCardLists + 1}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <FormControl fullWidth size="small" margin="dense">
          <Button variant="outlined" size="small" onClick={onMove}>
            Переместить
          </Button>
        </FormControl>
      </Box>
    </MenuFixed>
  );
};

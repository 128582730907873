import React, { FC } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AvatarMenu } from '../../../../../../components/AvatarMenu';
import { Member } from '../../../../../../apiRPC/card';

const PopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -15],
      },
    },
  ],
};

export type Props = {
  members: Member[];
  onAdd: () => void;
};
export const Members: FC<Props> = ({ members, onAdd }) => (
  <Box>
    <Typography variant="body2" color="text.secondary" fontWeight={600}>
      Участники
    </Typography>

    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      flexWrap="wrap"
      gap={0.5}
    >
      {members.map((item) => (
        <Tooltip
          key={item.uuid}
          title={item.email}
          placement="bottom"
          disableInteractive
          PopperProps={PopperProps}
        >
          <AvatarMenu>{item.email}</AvatarMenu>
        </Tooltip>
      ))}
      <IconButton aria-label="add" size="small" color="primary" onClick={onAdd}>
        <AddIcon fontSize="small" />
      </IconButton>
    </Stack>
  </Box>
);

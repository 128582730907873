import axios, { AxiosRequestConfig, AxiosInstance, CancelToken } from 'axios';
import { currentApi } from '../model/currentApi';
import { getAccessToken } from '../model/JWT/methods';

export type Field = {
  description: string;
  field: string;
  index?: number;
};
export interface ErrorProps {
  code?: number;
  data?: {
    debugCode?: string;
    description?: string;
    fields?: Field[];
  };
  message: string;
  method?: string;
}

export function isUnknownObject(p: unknown): p is Record<string, unknown> {
  if (typeof p === 'object') {
    return true;
  }
  return false;
}
export function isErrorProps(p: unknown): p is ErrorProps {
  if (isUnknownObject(p) && typeof p?.message === 'string') {
    return true;
  }
  return false;
}

export class JsonRPCError implements ErrorProps {
  code: number | undefined;

  data: ErrorProps['data'] | undefined;

  message: string;

  method: string | undefined;

  constructor(props: ErrorProps) {
    this.code = props.code;
    this.data = props.data;
    this.message = props.message;
    this.method = props.method;
  }
}

type TPRequest = {
  method: string;
  token?: boolean;
  aXconfig?: AxiosRequestConfig;
};

function request<R>({
  method,
  token,
  aXconfig,
}: TPRequest): (cancelToken?: CancelToken) => Promise<R>;
function request<R, P extends Record<string, unknown> | unknown[] | unknown>({
  method,
  token,
  aXconfig,
}: /* A type guard. */
/* A type guard. */
/* A type guard. */
TPRequest): (params: P, cancelToken?: CancelToken) => Promise<R>;

function request<R, P extends Record<string, unknown> | undefined = undefined>({
  method,
  token = true,
  aXconfig,
}: TPRequest): (params?: P, cancelToken?: CancelToken) => Promise<R> {
  return async function (params?: P, cancelToken?: CancelToken) {
    const axiosOptions: AxiosRequestConfig = {
      headers: { 'Content-Type': 'application/json' },
      baseURL: currentApi.value,
    };

    const instance: AxiosInstance = axios.create(aXconfig ?? axiosOptions);

    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const access_token: string | null = getAccessToken();
      const clonedConfig = config;

      if (access_token && token) {
        clonedConfig.headers = {
          ...clonedConfig.headers,
          Authorization: `Bearer ${access_token}`,
        };
      }

      return clonedConfig;
    });
    try {
      const response = await instance.post(
        `/?method=${method}`,
        {
          id: 1,
          method,
          jsonrpc: '2.0',
          params: params ?? {},
        },
        {
          cancelToken,
        },
      );

      if (response?.data?.error) {
        throw new JsonRPCError(response?.data?.error);
      }

      if (response?.data?.result === undefined) {
        throw new JsonRPCError({ message: 'unknown error', method });
      }

      return response.data.result;
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new JsonRPCError({ message: 'offline error', method });
      }
      if (err instanceof JsonRPCError) {
        err.method = method;
        throw err;
      }
      if (isErrorProps(err)) {
        throw new JsonRPCError({ ...err, method });
      }
      const unknownError = { error: err, method };
      throw unknownError;
    }
  };
}

export default request;

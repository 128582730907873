import { FC } from 'react';
import { Typography } from '@mui/material';
import { Container } from '../Container';
import { ReactComponent as NoConnectSvg } from '../../../../assets/icons/errors/noConnect.svg';

export const NoConnect: FC = () => (
  <Container>
    <NoConnectSvg />
    <Typography fontSize={20} mt={5} fontWeight={700} mb={1.3}>
      Ошибка загрузки данных
    </Typography>
    <Typography>
      Не удалось получить связь с сервером. Обновите страницу или попробуйте
      позже.
    </Typography>
  </Container>
);

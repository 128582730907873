import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Info } from '../../../../../../components/Info';

export const Shop: FC = () => (
  <Box
    sx={{
      height: '100%',
    }}
  >
    <Info
      text="Загрузка ресурсов для креатива ретаргетинга выполняется в автоматическом режиме"
      setOpenInfo={() => null}
      error
    />
  </Box>
);

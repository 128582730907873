import { Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as ImportSvg } from '../../../../../assets/icons/import.svg';
import { IconButtonSquare } from '../../../../../UI/IconButtonSquare';

interface IImportProps {
  handleClick: () => void;
}

const Import: FC<IImportProps> = ({ handleClick }) => (
  <Tooltip
    title="Импорт"
    sx={{
      marginLeft: '10px',
    }}
  >
    <IconButtonSquare disabled color="primary" onClick={handleClick}>
      <ImportSvg />
    </IconButtonSquare>
  </Tooltip>
);

export default Import;

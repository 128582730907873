import request from '../../../request';

export type CardCreativeGroupItem = {
  uuid: string;
  title: string;
  card: string;
};

export type PCardCreativesGroupsCreate = {
  card: string;
  fields: Pick<CardCreativeGroupItem, 'title'>;
};
export type RCardCreativesGroupsCreate = CardCreativeGroupItem;
export const cardCreativesGroupsCreate = request<
  RCardCreativesGroupsCreate,
  PCardCreativesGroupsCreate
>({
  method: 'cardCreativesGroups.create',
});

export type PCardCreativesGroupsUpdate = {
  uuid: string;
  fields: Pick<CardCreativeGroupItem, 'title'>;
};
export type RCardCreativesGroupsUpdate = CardCreativeGroupItem;
export const cardCreativesGroupsUpdate = request<
  RCardCreativesGroupsUpdate,
  PCardCreativesGroupsUpdate
>({
  method: 'cardCreativesGroups.update',
});

export type PCardCreativesGroupsGetList = { card: string };
export type RCardCreativesGroupsGetList = CardCreativeGroupItem[];
export const cardCreativesGroupsGetList = request<
  RCardCreativesGroupsGetList,
  PCardCreativesGroupsGetList
>({
  method: 'cardCreativesGroups.getList',
});

export type PCardCreativesGroupsDelete = { uuid: string };
export type RCardCreativesGroupsDelete = true;
export const cardCreativesGroupsDelete = request<
  RCardCreativesGroupsDelete,
  PCardCreativesGroupsDelete
>({
  method: 'cardCreativesGroups.delete',
});

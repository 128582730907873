import { createEvent, restore } from 'effector';

export type ModalType =
  | 'resource'
  | 'text'
  | 'list'
  | 'HTML'
  | 'archive'
  | 'none';

export const setModalType = createEvent<ModalType>();
export const resetModalType = createEvent();
export const $modalType = restore(setModalType, 'none').reset(resetModalType);

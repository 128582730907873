import { Box } from '@mui/material';
import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

export type CreativeInfoStatuses = '_isSuccess' | '_isWarring' | '_isError';
interface ICreativeInfoProps {
  text: string;
  status: CreativeInfoStatuses | null;
}

const CreativeInfo: FC<ICreativeInfoProps> = ({ text, status }) => (
  <Box className={css.erir}>
    <Box className={css.erir_info}>
      <Box
        className={cn(css.status, status, {
          _isSuccess: false,
          _isWarring: false,
          _isError: false,
        })}
      />
      <span>{text}</span>
    </Box>
  </Box>
);

export default CreativeInfo;

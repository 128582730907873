import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate } from '../../../../apiRPC/card';

export type CardUpdateMarks = {
  uuid: string;
  fields: { marks: { add?: string[]; delete?: string[] } };
};

export const updateMarksFX = errorHandlerFx(
  createEffectJWT<CardUpdateMarks, RUpdate, Error>(cardUpdate),
);

import { Box, IconButton, Paper, TextField } from '@mui/material';
import { FiTrash } from 'react-icons/fi';
import React, { FC } from 'react';
import css from '../../../styles.module.scss';
import { BootstrapTooltip } from '../../../../../../../components/BootstrapTooltip';

type Props = {
  mediaExampleUrl?: string;
  mediaExampleUrlError?: string;
  description?: string;
  descriptionError?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  disabled?: boolean;
};

export const ResourceType: FC<Props> = ({
  mediaExampleUrl,
  mediaExampleUrlError,
  description,
  descriptionError,
  onChange,
  onDelete,
  disabled,
}) => (
  <Paper
    elevation={0}
    sx={{
      padding: '30px',
    }}
  >
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 40px"
      gap="0 30px"
      alignItems="flex-start"
    >
      <TextField
        label="Ссылка на ресурс"
        variant="standard"
        value={mediaExampleUrl}
        name="mediaExampleUrl"
        inputProps={{ maxLength: 1000 }}
        autoComplete="none"
        onChange={onChange}
        error={Boolean(mediaExampleUrlError)}
        helperText={mediaExampleUrlError}
        disabled={disabled}
        multiline
      />
      <TextField
        label="Описание"
        variant="standard"
        value={description}
        name="description"
        inputProps={{ maxLength: 1000 }}
        autoComplete="none"
        onChange={onChange}
        error={Boolean(descriptionError)}
        helperText={descriptionError}
        disabled={disabled}
        multiline
      />
      <BootstrapTooltip title="Удалить" placement="top">
        <IconButton onClick={onDelete} disabled={disabled}>
          <FiTrash color="#D32F2F" size={20} className={css.del_btn} />
        </IconButton>
      </BootstrapTooltip>
    </Box>
  </Paper>
);

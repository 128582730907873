import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Header } from '../../Header';
import { Menu } from '../../Menu';

const DrawerWidth = 240;
type Props = {
  children: ReactNode;
};
export const WithMenuHeader: FC<Props> = ({ children }) => (
  <Box display="flex">
    <Header />
    <Menu drawerWidth={DrawerWidth} />
    {children}
  </Box>
);

import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Fade,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import { mapColors } from '../const';
import { ColorsMark } from '../../../../../model/Marks';
import { useMarkCreate } from './useMarkCreate';
import { MenuFixed } from '../../../../../../UI/MenuFixed';

const styleBoxColors = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridAutoRows: '32px',
  alignItems: 'center',
  gap: 0.5,
  width: 300,
};

interface PColorBox extends BoxProps {
  color: string;
  checked: boolean;
}
const ColorBox = React.forwardRef<HTMLDivElement, PColorBox>(
  ({ color, checked, ...props }, ref) => (
    <Box
      ref={ref}
      display="flex"
      height="100%"
      borderRadius="10%"
      justifyContent="center"
      alignItems="center"
      {...props}
      sx={{ background: color, cursor: 'pointer' }}
    >
      {checked && <DoneIcon fontSize="small" sx={{ color: 'white' }} />}
    </Box>
  ),
);

type Props = {
  name?: string;
  open: boolean;
  board: string;
  anchorEl?: Element | null;
  onClose: () => void;
  onBack?: () => void;
  openMenuDeleted: () => void;
};
export const MarksCreate = React.forwardRef<HTMLDivElement, Props>(
  ({ open, anchorEl, onClose, onBack, name, openMenuDeleted, board }, ref) => {
    const theme = useTheme();

    const {
      onCloseReset,
      onBackReset,
      title,
      setTitle,
      setColor,
      color,
      disabledCreate,
      onSubmit,
      isUpdate,
      onDelete,
    } = useMarkCreate({ board, onClose, onBack, openMenuDeleted });

    return (
      <MenuFixed
        ref={ref}
        open={open}
        onClose={onCloseReset}
        TransitionComponent={Fade}
        anchorEl={anchorEl}
      >
        <MenuHeader
          title={isUpdate ? 'Изменение метки' : 'Создание метки'}
          onBack={onBackReset}
          onClose={onCloseReset}
        />

        <Box padding={2}>
          <TextField
            fullWidth
            label="Название"
            inputProps={{ maxLength: 50, minLength: 1 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box paddingX={2} paddingY={0}>
          <Typography
            component="span"
            fontSize={12}
            variant="body1"
            fontWeight={600}
            color={theme.palette.grey[700]}
          >
            Цвет
          </Typography>
        </Box>

        <Box sx={styleBoxColors} paddingX={2}>
          {Object.entries(mapColors).map(([key, value]) => {
            if (key === 'none') {
              return (
                <Tooltip
                  followCursor
                  title={
                    <span>
                      Без цвета.
                      <br /> Не будет отображаться на карточке.
                    </span>
                  }
                  key={key}
                >
                  <ColorBox
                    color={value}
                    onClick={() => setColor(key as ColorsMark)}
                    checked={key === color}
                  />
                </Tooltip>
              );
            }
            return (
              <ColorBox
                key={key}
                color={value}
                onClick={() => setColor(key as ColorsMark)}
                checked={key === color}
              />
            );
          })}
        </Box>

        <Box paddingX={2} pt={4} justifyContent="space-between" display="flex">
          <Button
            variant="contained"
            disabled={disabledCreate}
            onClick={onSubmit}
          >
            {isUpdate ? 'Сохранить' : 'Создать'}
          </Button>
          {isUpdate && (
            <Button onClick={onDelete} variant="contained" color="error">
              Удалить
            </Button>
          )}
        </Box>
      </MenuFixed>
    );
  },
);

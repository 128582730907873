import { Box, Paper, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { LabelForContracts } from '../../../../Campaigns/UI/LabelForContracts';
import { FormCreateCreative } from '../types';
import { CodeMirrorInput } from '../../../../../../UI/CodeMirrorInput';

type Props = {
  formik: ReturnType<typeof useFormik<FormCreateCreative>>;
  handleChange: ReturnType<
    typeof useFormik<FormCreateCreative>
  >['handleChange'];
};

const ParamResourse: FC<Props> = ({ formik, handleChange }) => (
  <Paper
    elevation={0}
    sx={{
      padding: '30px',
    }}
  >
    <Typography fontWeight={700} mb={3.8}>
      Параметры ресурсов
    </Typography>
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="30px" mb={4}>
      <TextField
        label="Общее описание объекта рекламирования"
        placeholder="Общее описание объекта рекламирования"
        multiline
        required
        rows={5}
        value={formik.values.description}
        name="description"
        onBlur={formik.handleBlur}
        onChange={handleChange}
        error={Boolean(formik.errors.description)}
        helperText={formik.errors.description}
        inputProps={{ maxLength: 2000 }}
      />
      <TextField
        label={
          <LabelForContracts
            text="Описание для ресурса по умолчанию"
            title="Только для ссылок"
          />
        }
        placeholder="Описание для ресурса по умолчанию"
        multiline
        rows={5}
        value={formik.values.defaultResourceDescription}
        name="defaultResourceDescription"
        onBlur={formik.handleBlur}
        onChange={handleChange}
        error={Boolean(formik.errors.defaultResourceDescription)}
        helperText={formik.errors.defaultResourceDescription}
        inputProps={{ maxLength: 1000 }}
      />
    </Box>
    <Box display="grid" gridTemplateColumns="1fr">
      <CodeMirrorInput
        label={
          <LabelForContracts
            text="HTML"
            title="Используется для получения ресурсов. Ресурсы автоматически не загружаются из этого поля. Для загрузки необходимо перейти на вкладку ресурсов и воспользоваться опцией “Загрузить из HTML”"
          />
        }
        placeholder="HTML"
        multiline
        value={formik.values.html}
        name="html"
        onBlur={formik.handleBlur}
        onChange={(v: string) => {
          formik.setFieldValue('html', v).then(() => {
            formik.validateField('html');
          });
        }}
        inputProps={{ maxLength: 2000 }}
        error={Boolean(formik.errors.html)}
        helperText={formik.errors.html}
        minHeight="180px"
      />
    </Box>
  </Paper>
);

export default ParamResourse;

import { FilterOptionsState } from "@mui/material";
import { TOption } from '../../../../UI/form/SelectField';


export function filterOptionsOrganizations<T extends TOption>(
  options: T[],
  params: FilterOptionsState<T>,
) {
  return options.filter((item) => {
    if (
      typeof item.value === 'string' &&
      item.value?.toUpperCase().includes(params.inputValue.toUpperCase())
    )
      return true;
    if (typeof item.label === 'string') {
      return item.label.toUpperCase().includes(params.inputValue.toUpperCase());
    }
    return false;
  });
}

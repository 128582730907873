import { createEffect, createEvent, restore, sample } from 'effector';
import { $card } from '../../../../../../model/card';

type OpenMenuDate = {
  anchorEl: Element;
  cardUid: string;
};
export const onOpenMenuDate = createEvent<OpenMenuDate>();
export const onCloseMenuDate = createEvent();

const setAnchorEl = createEvent<Element>();
export const $anchorEl = restore(setAnchorEl, null).reset(onCloseMenuDate);

export const $isOpen = $anchorEl.map((state) => Boolean(state));

const setCardUid = createEvent<string | null>();
export const $cardUid = restore(setCardUid, null).reset(onCloseMenuDate);

export const $dates = $card.map((state) => state?.dates || null);

sample({
  clock: onOpenMenuDate,
  target: createEffect(({ cardUid, anchorEl }: OpenMenuDate) => {
    setCardUid(cardUid);
    setAnchorEl(anchorEl);
  }),
});

import { RouteObject } from 'react-router-dom';
import React from 'react';
import { Organization } from './pages/Organization';
import { Organizations } from './pages/Organizations';
import { Create as CreateOrganisation } from './pages/Organization/Create';
import { Update as UpdateOrganisation } from './pages/Organization/Update';
import Contracts from './pages/Contracts/ContractsList';
import { Contract } from './pages/Contracts/Contract';
import { Create as CreateContract } from './pages/Contracts/Contract/Create';
import { Update as UpdateContract } from './pages/Contracts/Contract/Update';
import { WorkContainerContent } from '../components/containers/WorkContainerContent';
import { Create as CreateCampaign } from './pages/Campaigns/Campaign/Create';
import { Update as UpdateCampaign } from './pages/Campaigns/Campaign/Update';
import {
  Create,
  Create as CreateCreative,
} from './pages/Creatives/Creative/Create';
import { Update as UpdateCreative } from './pages/Creatives/Creative/Update';
import { WithMenuHeader } from '../components/containers/WithMenuHeader';
import {
  contractPath,
  contractsPath,
  ORDPath,
  organizationPath,
  organizationsPath,
  uuidSegment,
  campaignsPath,
  campaignPath,
  creativesPath,
  creativePath,
} from './addresses';
import Campaigns from './pages/Campaigns/CampaignsList';
import { Campaign } from './pages/Campaigns/Campaign';
import Creatives from './pages/Creatives/CreativesList';
import { Creative } from './pages/Creatives/Creative';

/**
 * @deprecated since 14.02.2023
 */
export const ORDRoutesDeprecated: RouteObject[] = [
  { path: 'Organizations', element: <Organizations /> },
  {
    path: 'Organization',
    element: <Organization />,
    children: [
      { index: true, element: <CreateOrganisation /> },
      { path: ':uuid', element: <UpdateOrganisation /> },
    ],
  },
  { path: 'Contracts', element: <Contracts /> },
  {
    path: 'Contract',
    element: <Contract />,
    children: [
      { index: true, element: <CreateContract /> },
      { path: ':uuid', element: <UpdateContract /> },
    ],
  },
];

const Comp = () => (
  <WithMenuHeader>
    <WorkContainerContent />
  </WithMenuHeader>
);
export const ORDRoutes: RouteObject = {
  path: `${ORDPath}/*`,
  element: <Comp />,
  children: [
    { index: true, element: <Organizations /> },

    { path: organizationsPath, element: <Organizations /> },
    {
      path: organizationPath,
      element: <Organization />,
      children: [
        { index: true, element: <CreateOrganisation /> },
        { path: uuidSegment, element: <UpdateOrganisation /> },
      ],
    },
    { path: contractsPath, element: <Contracts /> },
    {
      path: contractPath,
      element: <Contract />,
      children: [
        { index: true, element: <CreateContract /> },
        { path: uuidSegment, element: <UpdateContract /> },
      ],
    },
    { path: campaignsPath, element: <Campaigns /> },
    {
      path: campaignPath,
      element: <Campaign />,
      children: [
        { index: true, element: <CreateCampaign /> },
        {
          path: uuidSegment,
          element: <UpdateCampaign />,
        },
      ],
    },
    { path: creativesPath, element: <Creatives /> },
    {
      path: creativePath,
      element: <Creative />,
      children: [
        { index: true, element: <Create /> },
        {
          path: uuidSegment,
          element: <UpdateCreative />,
        },
      ],
    },
  ],
};

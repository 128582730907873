import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { LoadingButton } from '@mui/lab';
import Header from '../../../../components/Header';
import css from '../styles.module.scss';

import { addressesORD } from '../../../../addresses';

const LoadingBasicForm: FC = () => {
  const theme = useTheme();
  return (
    <Box component="form" noValidate>
      <Header>
        <Box display="flex">
          <NavLink to={addressesORD.campaignsPath}>
            <Tooltip title="Назад">
              <IconButton>
                <FiChevronLeft size={20} />
              </IconButton>
            </Tooltip>
          </NavLink>
          <Box flex={1}>
            <Typography
              className={css.name}
              variant="h1"
              sx={{
                marginLeft: '2px',
              }}
            >
              <Skeleton height={28} width={200} />
            </Typography>
            <Typography
              mt={0.5}
              sx={{
                color: theme.palette.secondary.light,
              }}
            >
              <Skeleton height={24} width={400} />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled
            sx={{
              marginLeft: '36px',
            }}
          >
            Отмена
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled
            sx={{
              marginLeft: '36px',
            }}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      <Box>
        <Paper
          elevation={0}
          sx={{
            padding: '30px',
          }}
        >
          <Skeleton
            width={130}
            height={20}
            sx={{
              marginBottom: '30px',
            }}
          />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '38px 30px',
            }}
          >
            <Box>
              <Skeleton width={167} height={14} />
              <Skeleton width="100%" height={20} />
            </Box>
            <Box>
              <Skeleton width={167} height={14} />
              <Skeleton width="100%" height={20} />
            </Box>
            <Box>
              <Skeleton width={167} height={14} />
              <Skeleton width="100%" height={20} />
            </Box>
            <Box>
              <Skeleton width={167} height={14} />
              <Skeleton width="100%" height={20} />
            </Box>
            <Box>
              <Skeleton width={167} height={14} />
              <Skeleton width="100%" height={20} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default LoadingBasicForm;

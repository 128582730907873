import * as React from 'react';
import type { FC, ReactNode } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { DrawerHeader } from '../../../components/DrawerHeader';

import { CreateBoard } from '../CreateBoard';
import { AddColumn } from '../../pages/desk/modals/AddColumn';
import { TaskOverview } from '../../pages/desk/modals/TaskOverview';
import { WorkspaceCreate } from '../../pages/desk/modals/Workspace/WorkspaceCreate';

type Props = {
  children: ReactNode;
  minWidth?: string;
};
export const ContainerGrid: FC<Props> = ({ children, minWidth }) => (
  <Container
    maxWidth={false}
    sx={{ width: 'fit-content', ml: 'inherit', minWidth }}
  >
    <Box width="100%" display="flex" flexDirection="column" height="100vh">
      <DrawerHeader />

      {children}
      <CreateBoard />
      <AddColumn />
      <TaskOverview />
      <WorkspaceCreate />
    </Box>
  </Container>
);

import { combine, createEvent, createStore, restore, sample } from 'effector';
import { cardGetList, Filter, RCardGetList } from '../../../../apiRPC/card';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { $board } from '../../../model/board';
import { updateStatusFX } from '../../../model/card/update/status';
import {
  clockForReloadCardList,
  updateCarts,
  updateItem,
} from '../../../model/card';
import { defaultSort } from '../../../../helper/sort';

export type PCardGetListArh = { filter: Omit<Filter, 'status'> };
export const cardGetListArhFX = errorHandlerFx(
  createEffectJWT((p: PCardGetListArh) =>
    cardGetList({ filter: { ...p.filter, status: 'CLOSED' } }),
  ),
);
const noReloadCardGetListArhFX = errorHandlerFx(
  createEffectJWT((p: PCardGetListArh) =>
    cardGetList({ filter: { ...p.filter, status: 'CLOSED' } }),
  ),
);

export const onResetCardListArh = createEvent();
export const cardListArh$ = createStore<RCardGetList['rows'] | null>(null)
  .on(
    [cardGetListArhFX.doneData, noReloadCardGetListArhFX.doneData],
    (_, p) => p.rows,
  )
  .on(updateItem, (state, payload) => {
    if (!state) return undefined;

    const itemIndex = state.findIndex((row) => row.uuid === payload.uuid);
    if (itemIndex < 0) return undefined;
    const item = { ...state[itemIndex] };

    (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
      if (key in item) {
        const val = payload[key];
        if (typeof item[key] === typeof val) {
          (item[key] as typeof val) = val;
        }
      }
    });

    state[itemIndex] = item;
    return [...state];
  })
  .on(updateCarts, (state, payload) => {
    if (!state) return undefined;

    payload.forEach((item) => {
      const index = state.findIndex((i) => i.uuid === item.uuid);
      if (index < 0) return;
      state[index] = item;
    });
    return [...state];
  })
  .on(updateStatusFX.doneData, (state, payload) => {
    if (payload.status === 'CLOSED') {
      const newState = !state ? [] : [...state];
      newState.push(payload);
      return newState;
    }
    if (payload.status === 'ACTIVE') {
      const newState = !state
        ? []
        : [...state.filter((item) => item.uuid !== payload.uuid)];
      return newState;
    }
    return undefined;
  })

  .reset(onResetCardListArh);

export const onListArhFilter = createEvent<string>();
export const onListArhFilterReset = createEvent();
export const ListArhFilter$ = restore(onListArhFilter, '').reset(
  onListArhFilterReset,
);

export const cardListArhFilter$ = combine(
  [cardListArh$, ListArhFilter$],
  ([cardListArh, listArhFilter]) => {
    if (!listArhFilter) return cardListArh?.sort(defaultSort);
    if (!cardListArh) return null;

    return cardListArh
      .filter((item) =>
        item.title.toUpperCase().includes(listArhFilter.toUpperCase()),
      )
      .sort(defaultSort);
  },
);

export type CardGetListArhByBoard = {
  filter: Omit<Filter, 'status' | 'board'>;
};
export const onStartListening = createEvent<CardGetListArhByBoard>();
export const onStopListening = createEvent();

export const listeningArhCardByDesk$ =
  createStore<CardGetListArhByBoard | null>(null)
    .on(onStartListening, (_, p) => p)
    .on(onStopListening, () => null);

sample({
  clock: [onStartListening, $board],
  source: { params: listeningArhCardByDesk$, board: $board },
  filter: ({ params, board }) => params !== null && board !== null,
  fn: ({ params, board }) => ({
    filter: { ...params!.filter, board: board!.uuid },
  }),
  target: cardGetListArhFX,
});

sample({
  clock: clockForReloadCardList,
  source: { params: listeningArhCardByDesk$, board: $board },
  filter: ({ params, board }) => params !== null && board !== null,
  fn: ({ params, board }) => ({
    filter: { ...params!.filter, board: board!.uuid },
  }),
  target: noReloadCardGetListArhFX,
});

sample({
  clock: $board,
  target: onListArhFilterReset,
});
sample({
  clock: $board,
  target: onResetCardListArh,
});

export function reset() {
  onResetCardListArh();
  onStopListening();
  onListArhFilterReset();
}

import request from '../request';

export type PColumnCreate = {
  title: string;
  board: string;
  sort?: number;
};
export type RColumnCreate = {
  uuid: string;
};

export const columnCreate = request<RColumnCreate, PColumnCreate>({
  method: 'column.create',
});

export type Status = 'ACTIVE' | 'CLOSED';
export type Fields = {
  title?: string;
  status?: Status;
  sort?: number;
};
export type PColumnUpdate = {
  uuid: string;
  fields: Partial<Fields>;
};

export type RColumnUpdate = boolean;
export const columnUpdate = request<
  RColumnUpdate,
  PColumnUpdate | PColumnUpdate[]
>({
  method: 'column.update',
});

export type PColumnGet = {
  uuid: string;
};
export type RColumnGet = {
  uuid: string;
  title: string;
  sort: number;
  status: Status;
  board: string;
  createdAt: number;
};
export const columnGet = request<RColumnGet, PColumnGet>({
  method: 'column.get',
});

export type RColumnGetList = {
  total: number;
  rows: RColumnGet[];
};
export type Filter = {
  limit?: number;
  offset?: number;
  board?: string;
  status?: Status;
};
export type PColumnGetList = { filter: Filter };
export const columnGetList = request<RColumnGetList, PColumnGetList>({
  method: 'column.getList',
});

export type PColumnMove = {
  id: string;
  board: string;
};

export type RColumnMove = boolean;
export const columnMove = request<RColumnMove, PColumnMove>({
  method: 'column.move',
});

export type Item = { uuid: string; sort: number };
export type PColumnSetPosition = {
  items: Item[];
};
export const columnSetPosition = request<boolean, PColumnSetPosition>({
  method: 'column.setPosition',
});

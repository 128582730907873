import { Box, IconButton, Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { FC, useState } from 'react';
import { FiMoreHorizontal, FiArchive } from 'react-icons/fi';

import cn from 'classnames';

import { NavLink } from 'react-router-dom';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import css from './styles.module.scss';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';

import { CampaignItem, Status } from '../../../../../apiRPC/сampaign';
import { updateCampaignStatus } from '../model';
import {
  generateCampaignPath,
  generateContractPath,
} from '../../../../addresses';

function getStatus(status: Status | undefined): string {
  if (!status) return '';

  const map: Record<Status, string> = {
    ACTIVE: 'Активна',
    ARCHIVE: `В архиве`,
    DELETED: `Удалена`,
  };

  return map?.[status] || '';
}

const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
type Props = {
  row: CampaignItem;
};

const Item: FC<Props> = ({ row }) => {
  const [toggle, setToggle] = useState(false);
  const theme = useTheme();

  return (
    <>
      <TableCell component="th" scope="row">
        <BootstrapTooltip title={getStatus(row.status)} placement="top">
          <Box
            className={cn(css.status, {
              _isSuccess: row.status === 'ACTIVE',
              _isError: row.status === 'ARCHIVE' || row.status === 'DELETED',
            })}
          />
        </BootstrapTooltip>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        size="small"
        sx={{ width: 240, maxWidth: 'fit-content' }}
      >
        <Box
          sx={{
            maxWidth: '240px',
          }}
        >
          <BootstrapTooltip title={row.name} placement="top-start">
            <Box className={css.name}>{row.name}</Box>
          </BootstrapTooltip>
          <Typography
            sx={{
              color: theme.palette.secondary.light,
              fontSize: '14px',
              maxWidth: '300px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {row.uuid}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row" width={200}>
        <a
          href={generateContractPath(row.contract)}
          target="_blank"
          rel="noreferrer"
        >
          {row.contractNumber}
        </a>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxwidth: '430px',
          }}
        >
          <Box className={css.name}>
            {row.invoiceContract ? (
              <a
                href={generateContractPath(row.invoiceContract)}
                target="_blank"
                rel="noreferrer"
              >
                {row.invoiceContractNumber}
              </a>
            ) : (
              '---'
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            maxWidth: '200px',
          }}
        >
          <Box className={css.name}>
            {new Intl.DateTimeFormat('ru', optionsTime).format(
              row.dateStart * 1000,
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell component="th" scope="row" width={150}>
        {new Intl.DateTimeFormat('ru', optionsTime).format(row.dateEnd * 1000)}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.budget}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          width: '57px',
        }}
      >
        <Box
          className={cn('dropDownRow', {
            _isOpen: toggle,
          })}
          onMouseEnter={() => {
            setToggle(true);
          }}
          onMouseLeave={() => {
            setToggle(false);
          }}
        >
          <Box className="dropDownIcon">
            <FiMoreHorizontal size={24} color="#A6A6A6" />
          </Box>
          <Box className="dropDownMenu">
            {toggle && row.status === 'ARCHIVE' && (
              <BootstrapTooltip title="Извлечение из архива" placement="top">
                <IconButton
                  color="primary"
                  onClick={() =>
                    updateCampaignStatus({
                      uuid: row.uuid,
                      status: 'ACTIVE',
                      name: row.name,
                    })
                  }
                >
                  <FiArchive size={24} color="#4B61BF" />
                </IconButton>
              </BootstrapTooltip>
            )}
            {toggle && row.status === 'ACTIVE' && (
              <BootstrapTooltip title="Добавление в архив" placement="top">
                <IconButton
                  color="primary"
                  onClick={() =>
                    updateCampaignStatus({
                      uuid: row.uuid,
                      status: 'ARCHIVE',
                      name: row.name,
                    })
                  }
                >
                  <FiArchive size={24} color="#4B61BF" />
                </IconButton>
              </BootstrapTooltip>
            )}
            {toggle && (
              <NavLink to={generateCampaignPath(row.uuid)}>
                <IconButton title="Редактировать" color="primary">
                  <ModeEditOutlinedIcon color="primary" />
                </IconButton>
              </NavLink>
            )}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default Item;

import { createEvent, restore } from 'effector';

const MENU_IS_OPEN = 'MENU_IS_OPEN';
type MenuState = 'true' | 'false';
const setMenuState = (v: MenuState) =>
  window.localStorage.setItem(MENU_IS_OPEN, v);

const getMenuState = (): MenuState =>
  (window.localStorage.getItem(MENU_IS_OPEN) || 'false') as MenuState;

const getMenuStateBoolean = () => getMenuState() === 'true';

export const setOpen = createEvent<boolean>();
export const $open = restore(setOpen, getMenuStateBoolean());

setOpen.watch((p) => (p ? setMenuState('true') : setMenuState('false')));

import { FC, useEffect } from 'react';
import { variant } from '@effector/reflect';
import { useParams } from 'react-router-dom';
import Resourse from './Resourse';
import LoadingAdditionalForm from '../../../../Campaigns/Campaign/Update/additional/LoadingAdditionalForm';
import {
  $fieldAdditional,
  resetResourcesModel,
  resourcesGetListFX,
} from './model';
import { Shop } from '../Shop';

const ComponentResourse = variant({
  source: $fieldAdditional,
  cases: {
    Success: Resourse,
    loading: LoadingAdditionalForm,
    IsShop: Shop,
  },
  default: LoadingAdditionalForm,
  hooks: {
    unmounted: resetResourcesModel,
  },
});

export const ContainerResourse: FC = () => {
  const { uuid } = useParams();

  useEffect(() => {
    if (!uuid) return;
    resourcesGetListFX({ creative: uuid });
  }, [uuid]);
  return <ComponentResourse />;
};

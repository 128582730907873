import { useCallback, useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import {
  $listMarksForCard,
  $ListUuidMarkForCard,
  resetMarksForAdd,
  resetMarksForDel,
  setMarksForAdd,
  setMarksForDel,
  updateMarksForCard,
} from '../../../../../model/Marks/MarcsForCard';
import { RMarkGet } from '../../../../../../apiRPC/card/mark';
import { markCreateFX } from '../../../../../model/Marks';
import { cardGetFX } from '../../../../../model/card';

type Parameters = {
  isOpenDialog: boolean;
  board: string;
  card?: string;
};
export function useMarks({ isOpenDialog, board, card }: Parameters) {
  const listMarksForCard = useUnit($listMarksForCard);
  const [openMenuMarks, setOpenMenuMarks] = useState(false);
  const showMarks = listMarksForCard.length > 0;

  useEffect(() => {
    if (!isOpenDialog) {
      resetMarksForAdd();
      resetMarksForDel();
    }
  }, [isOpenDialog]);

  useEffect(() => {
    if (!openMenuMarks) return;
    if (!card) return;
    cardGetFX({ uuid: card });
  }, [openMenuMarks, card]);

  const ListUuidMarkForCard = useUnit($ListUuidMarkForCard);
  const onClickMark = useCallback(
    (item: RMarkGet) => {
      const isChecked = ListUuidMarkForCard.includes(item.uuid);

      if (!item.uuid) {
        markCreateFX({ title: '', color: item.color, board: board || '' }).then(
          (d) => setMarksForAdd(d.uuid),
        );
        return;
      }
      if (isChecked) {
        setMarksForDel(item.uuid);
        return;
      }
      setMarksForAdd(item.uuid);
    },
    [ListUuidMarkForCard],
  );

  const onOpenMenuMarks = () => setOpenMenuMarks(true);

  return {
    showMarks,
    listMarksForCard,
    onOpenMenuMarks,
    openMenuMarks,
    setOpenMenuMarks,
    ListUuidMarkForCard,
    onClickMark,
    updateMarksForCard,
  };
}

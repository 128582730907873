import { FC, useEffect } from 'react';
import { useUnit } from 'effector-react';
import {
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { generatePath } from 'react-router-dom';
import {
  $selectWorkspace,
  $workspaceList,
  onSelectWorkspace,
  workspaceGetListFX,
} from '../../../GRID/model/workspace';
import { themeOptions } from '../../../theme';
import { navigateForEffect } from '../../../model/routing';
import { addressesGRID } from '../../../GRID/addresses';

const FormSelect = styled(FormControl)({
  minWidth: 220,
  maxWidth: 300,
  marginRight: 10,
  '& .MuiFormLabel-root': {
    color: 'white',
    '&.Mui-focused': { color: grey[200] },
  },

  '& .MuiInputBase-root': {
    color: 'white',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: grey[200],
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '& .MuiSvgIcon-root': { color: 'white' },
});

const theme = createTheme(themeOptions);

export const SelectWorkspace: FC = () => {
  const workspaceList = useUnit($workspaceList);
  const selectWorkspace = useUnit($selectWorkspace);

  useEffect(() => {
    workspaceGetListFX(undefined);
  }, []);

  const onChange = (e: SelectChangeEvent<string>) => {
    onSelectWorkspace(e.target.value);
    navigateForEffect(
      generatePath(addressesGRID.desksWorkspacePath, {
        workspace: e.target.value,
      }),
    );
  };
  return (
    <FormSelect size="small">
      <InputLabel size="small" id="workspace-select-label">
        Рабочее пространство
      </InputLabel>
      <ThemeProvider theme={theme}>
        <Select
          size="small"
          labelId="workspace-select-label"
          id="workspace-select"
          value={workspaceList?.rows ? selectWorkspace : ''}
          label="Рабочее пространство"
          onChange={onChange}
        >
          {workspaceList?.rows.map((item) => (
            <MenuItem
              key={item.uuid}
              value={item.uuid}
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </FormSelect>
  );
};

import { Box } from '@mui/material';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { DrawerHeader } from '../../../../components/DrawerHeader';

export const Creative: FC = () => (
  <Box sx={{ width: '100%', minWidth: 'auto' }}>
    <DrawerHeader />
    <Outlet />
  </Box>
);

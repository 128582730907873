import { ThemeOptions } from '@mui/material';

export const themeGrid: ThemeOptions = {
  palette: {
    primary: {
      contrastText: '#F1F4FF',
      light: '#42a5f5',
      main: '#1976d2',
      dark: '#1565c0',
    },
    secondary: {
      light: '#ba68c8',
      main: '#9c27b0',
      contrastText: '#fff',
      dark: '#171717',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#42a5f5',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgb(25 118 210 / 10%)',
            svg: {
              color: '#1976d2',
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            svg: {
              color: '#1976d2',
            },
          },
          '&:hover': {
            backgroundColor: 'rgb(25 118 210 / 10%)',
            svg: {
              color: '#1976d2',
            },
          },
        },
      },
    },
  },
};

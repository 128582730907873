import { createEvent } from 'effector';
import { removeJWTInfo } from './methods';
import { navigateForEffect } from '../routing';
import { addressesSign } from '../../addresses/sign';

export const logOut = createEvent();

logOut.watch(() => {
  removeJWTInfo();
  navigateForEffect(addressesSign.signPath);
});

import { Box } from '@mui/material';
import React, {
  Fragment,
  FC,
  useCallback,
  useState,
  useEffect,
  Ref,
  useLayoutEffect,
  useRef,
} from 'react';
import { DroppableProvided, DroppableStateSnapshot } from '@hello-pangea/dnd';
import { useStoreMap } from 'effector-react';
import deepEqual from 'deep-equal';
import {
  Components,
  ItemContent,
  Virtuoso,
  VirtuosoHandle,
  VirtuosoProps,
} from 'react-virtuoso';
import {
  AddCardContainer,
  AddCardContainerMemo,
} from '../Card/AddCard/AddCardContaner';
import { DraggableTaskMemo } from '../Card/DraggableTask';
import { $cardListsSorted } from '../../../model/card';
import { AddCardButton } from '../Card/AddCard/AddCardButton';
import { RCardGet } from '../../../../apiRPC/card';
import { $currentFindItem } from '../../../../components/Header/GRID/Search/model';

type Context = {
  board: string;
  uuid: string;
  length: number;
  setScrollerRef: (v: React.RefObject<HTMLDivElement>) => void;
  providedIn: DroppableProvided;
  snapshot: DroppableStateSnapshot;
};

const computeItemKey = (_: number, d: string) => d;
// чтобы задать хоть какуюто высоту для вьюпорта
const virtuosoStyle = { minHeight: 1 };
const Scroller: Components<Context>['Scroller'] = React.forwardRef(
  ({ context, ...props }, ref: Ref<HTMLDivElement>) => {
    const onLoadBottomAddCard = useCallback(() => {
      if (typeof ref === 'function') return undefined;
      return ref?.current?.scrollTo(0, ref.current.scrollHeight);
    }, [ref]);

    useEffect(() => {
      if (typeof ref !== 'function' && ref !== null) {
        context?.setScrollerRef(ref);
      }
    }, [ref]);

    const [height, setHeight] = useState('100%');
    useLayoutEffect(() => {
      setTimeout(() => setHeight('max-content !important'));
    }, []);
    if (!context) return null;

    return (
      <>
        <Box height={height} overflow="hidden" ref={ref} {...props} />
        <AddCardButton uuid={context.uuid} />
        <AddCardContainer
          uuid={context.uuid}
          board={context.board}
          onLoad={onLoadBottomAddCard}
          cardListLength={context.length}
        />
      </>
    );
  },
);

const HeightPreservingItem: Components<Context>['Item'] = ({
  children,
  context,
  ...props
}) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  useEffect(() => {
    setSize((prevSize) => (knownSize === 0 ? prevSize : knownSize));
  }, [knownSize]);
  const style: React.CSSProperties = {
    '--child-height': `${size}px`,
  } as React.CSSProperties;
  return (
    <div {...props} className="height-preserving-container" style={style}>
      {children}
    </div>
  );
};

type CardProps = {
  i: number;
  cardUuid: string;
  length: number;
  uuid: string;
  board: string;
  placeholder: DroppableProvided['placeholder'];
  isDraggingOver: boolean;
  draggingFromThisWith: string | null;
};
const Card: FC<CardProps> = ({
  i,
  cardUuid,
  length,
  uuid,
  board,
  placeholder,
  isDraggingOver,
  draggingFromThisWith,
}) => {
  const showPlaceholder =
    i === length - 1 && isDraggingOver && draggingFromThisWith === null;

  return (
    <Fragment key={cardUuid}>
      <AddCardContainerMemo uuid={uuid} board={board} index={i} />
      <DraggableTaskMemo
        uuidColumn={uuid}
        index={i}
        board={board}
        cardUuid={cardUuid}
      />
      {showPlaceholder && placeholder}
    </Fragment>
  );
};

const CardMemo = React.memo(Card);

const Item: ItemContent<string, Context> = (i, item, context) => (
  <CardMemo
    cardUuid={item}
    i={i}
    length={context.length}
    uuid={context.uuid}
    board={context.board}
    placeholder={context?.providedIn.placeholder}
    isDraggingOver={context.snapshot.isDraggingOver}
    draggingFromThisWith={context.snapshot.draggingFromThisWith}
  />
);

const components = {
  Item: HeightPreservingItem,
  Scroller,
  EmptyPlaceholder: () => <Box height={30} />,
};

type Props = {
  uuid: string;
  board: string;
  setScrollerRef: (v: React.RefObject<HTMLDivElement>) => void;
  providedIn: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  cardRef: React.RefObject<HTMLDivElement>;
};
export const ListCards: FC<Props> = ({
  uuid,
  board,
  providedIn,
  setScrollerRef,
  snapshot,
  cardRef,
}) => {
  const cardList = useStoreMap({
    store: $cardListsSorted,
    keys: [uuid],
    fn: (cards, [id]) => cards[id]?.map((item) => item.uuid),
    defaultValue: [],
    updateFilter: (newResult, oldResult) => !deepEqual(newResult, oldResult),
  });

  const currentFindItem = useStoreMap({
    store: $currentFindItem,
    keys: [uuid],
    fn: (state, columnUuid) => (state?.at(0) === columnUuid[0] ? state : null),
    defaultValue: null,
  });

  const virtuosoRef = useRef<VirtuosoHandle>(null);
  useEffect(() => {
    if (!currentFindItem) return;
    const cardIndex = currentFindItem[2];
    cardRef.current?.scrollIntoView();
    virtuosoRef.current?.scrollToIndex({
      index: cardIndex,
    });
  }, [currentFindItem]);

  return (
    <Box display="flex" flexDirection="column" height="inherit">
      <Virtuoso
        style={virtuosoStyle}
        components={components}
        ref={virtuosoRef}
        scrollerRef={
          providedIn.innerRef as VirtuosoProps<RCardGet, Context>['scrollerRef']
        }
        data={cardList}
        context={{
          board,
          uuid,
          length: cardList.length,
          setScrollerRef,
          providedIn,
          snapshot,
        }}
        computeItemKey={computeItemKey}
        itemContent={Item}
      />
    </Box>
  );
};

export const ListCardsMemo = React.memo(ListCards);

import { combine, Store } from 'effector';
import { $currentTab, creativeGetFX, Tabs } from './index';

export const $fieldFormUpdateCreative: Store<'loading' | Tabs> = combine(
  {
    creativeGetPending: creativeGetFX.pending,
    currentTab: $currentTab,
  },
  ({ creativeGetPending, currentTab }) =>
    creativeGetPending ? 'loading' : currentTab,
);

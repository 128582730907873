import { FC, useEffect } from 'react';
import { variant } from '@effector/reflect';
import { useParams } from 'react-router-dom';
import { $fieldAdditional } from '../model/fieldFormUpdateCampaign';
import Additional from './Additional';
import LoadingAdditionalForm from './LoadingAdditionalForm';
import {
  campaignInvoiceParamsGetListFX,
  resetAdditional,
} from '../model/additional';

const ComponentAdditional = variant({
  source: $fieldAdditional,
  cases: {
    Success: Additional,
    loading: LoadingAdditionalForm,
  },
  default: LoadingAdditionalForm,
  hooks: {
    unmounted: () => {
      resetAdditional();
    },
  },
});

export const ContainerAdditional: FC = () => {
  const { uuid } = useParams();
  useEffect(() => {
    if (!uuid) return;
    campaignInvoiceParamsGetListFX({ campaign: uuid });
  }, [uuid]);

  return <ComponentAdditional />;
};

import React, { FC } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useUnit } from 'effector-react';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { useLocation, useRoutes } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { $open, setOpen } from './model';
import { routing } from './routing';
import css from './styles.module.scss';
import { ORD } from './modules/ORD';
import { addressesORD } from '../../ORD/addresses';

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(2)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(2)} + 1px)`,
  },
});

interface MyDrawerProps extends DrawerProps {
  drawerWidth: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    !['open', 'drawerWidth'].includes(prop.toString()),
})<MyDrawerProps>(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '& .MuiDrawer-paper': {
    ...openedMixin(theme, drawerWidth),
    top: '64px',
    // overflow: 'initial',
    backgroundColor: !open ? theme.palette.info.main : theme.palette.info.light,
    borderRight: open ? 'none' : '1px solid #A6A6A6',
    height: 'calc(100% - 64px)',
    ...(!open && {
      ...closedMixin(theme),
    }),
  },
}));

type Props = {
  drawerWidth: number;
};
export const Menu: FC<Props> = ({ drawerWidth }) => {
  const location = useLocation();
  const Routing = useRoutes(routing);
  const theme = useTheme();
  const open = useUnit($open);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Drawer variant="permanent" open={open} drawerWidth={drawerWidth}>
        <Box
          sx={{
            display: 'block',
            width: !open ? '0' : drawerWidth,
            overflow: !open ? 'hidden' : 'hidden auto',
          }}
        >
          {location.pathname.includes(addressesORD.ORDPath) ? <ORD /> : Routing}
        </Box>
      </Drawer>
      <Box
        className={css.DividerShow}
        onClick={handleDrawerToggle}
        id="btmOpenLeftMenu"
      >
        {!open && <ChevronRightIcon />}
      </Box>
      <Box
        className={css.DividerHide}
        sx={{
          display: 'flex',
          width: !open ? '0' : drawerWidth,
          transition: !open ? '0s' : '0.4s',
          paddingLeft: !open ? '0' : '22px',
        }}
        onClick={handleDrawerToggle}
      >
        <Box className="menuToggle">
          <ChevronLeftIcon />
        </Box>

        <Typography
          sx={{
            marginLeft: '32px',
            color: theme.palette.secondary.dark,
          }}
        >
          Свернуть
        </Typography>
      </Box>
    </>
  );
};

import { Box, Button, Container, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactRouterPrompt from 'react-router-prompt';
import { useUnit } from 'effector-react';
import { toast } from 'react-toastify';
import deepEquals from 'deep-equal';
import Header from '../../../../components/Header';
import { addressesORD } from '../../../../addresses';
import { Info } from '../../../../../components/Info';
import Parametrs from './Parametrs';
import ParamResourse from './ParamResourse';
import type { FormCreateCreative } from './types';
import { creativeCreateFX } from './model';
import { PCreativeCreate } from '../../../../../apiRPC/creative';
import Modal from '../../../../../components/Modal';
import { navigateForEffect } from '../../../../../model/routing';
import { isErrorProps } from '../../../../../apiRPC/request';

const initialValues: FormCreateCreative = {
  name: '',
  campaign: undefined,
  creativeOkveds: '',
  description: '',
  type: undefined,
  form: undefined,
  targetAudienceDescription: '',
  url: [''],
  html: '',
  defaultResourceDescription: '',
  shop: '',
};

export const Create: FC = () => {
  const [openInfo, setOpenInfo] = useState(true);
  const [disableModal, setDisableModal] = useState(false);
  const pending = useUnit(creativeCreateFX.pending);

  const formik = useFormik<FormCreateCreative>({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Обязательное для заполнения поле'),
      campaign: Yup.object().required('Обязательное для заполнения поле'),
      creativeOkveds: Yup.string()
        .matches(/(^$)|(^[0-9]+(?:[,.][0-9]+)*$)/i, 'Невалидное значение')
        .matches(
          /(^$)|(^([^\s,]{5,},)*[^\s,]{5,}$)/gi,
          'Строка должна содержать минимум 5 знаков между запятыми',
        ),
      description: Yup.string().required('Обязательное для заполнения поле'),
      type: Yup.string().required('Обязательное для заполнения поле'),
      form: Yup.string().required('Обязательное для заполнения поле'),
      targetAudienceDescription: Yup.string(),
      url: Yup.array().of(
        Yup.string()
          .required('Обязательное для заполнения поле')
          .matches(
            /^(http|https):\/\/[^\s/$.?#].[^\s]*$/,
            'Невалидное значение',
          ),
      ),
      html: Yup.string()
        .matches(
          /(^$)|((?:.|\n|\r)*<html(?:.|\n|\r)*<body)/,
          'Невалидное значение',
        )
        .nullable(),
      defaultResourceDescription: Yup.string(),
      shop: Yup.string(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      setDisableModal(true);
      const data: PCreativeCreate = {
        fields: {
          name: values.name,
          campaign: values.campaign!.uuid,
          creativeOkveds: values.creativeOkveds.split(',') || undefined,
          description: values.description,
          type: values.type!,
          form: values.form!,
          targetAudienceDescription: values.targetAudienceDescription,
          url: values.url,
          html: values.html,
          defaultResourceDescription: values.defaultResourceDescription,
          shop: values.shop ? +values.shop : null,
        },
      };
      try {
        await creativeCreateFX(data);

        f.resetForm();
        toast.success('Креатив успешно создан', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigateForEffect(addressesORD.creativesPath);
      } catch (e) {
        setDisableModal(false);
        console.error({ e });
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const handleChange: typeof formik['handleChange'] = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value).then(() => {
      formik.validateField(name);
    });
  };

  const isPrompt = disableModal
    ? false
    : !deepEquals(formik.values, initialValues);

  return (
    <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="calc(100vh - 108px )"
      >
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <Header>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  marginLeft: '2px',
                }}
              >
                Новый креатив
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <NavLink to={addressesORD.creativesPath}>
                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: '36px',
                  }}
                  disabled={pending}
                >
                  Отмена
                </Button>
              </NavLink>
              <LoadingButton
                loading={pending}
                disabled={!formik.isValid}
                variant="contained"
                sx={{
                  marginLeft: '36px',
                }}
                type="submit"
              >
                Сохранить
              </LoadingButton>
            </Box>
          </Header>
          {openInfo && (
            <Info
              text="Дополнительные вкладки будут доступны после сохранения креатива"
              setOpenInfo={setOpenInfo}
            />
          )}
          <Box display="flex" flexDirection="column" gap="20px 0">
            <Parametrs formik={formik} handleChange={handleChange} />
            <ParamResourse formik={formik} handleChange={handleChange} />
          </Box>
        </Box>
      </Box>
      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Container>
  );
};

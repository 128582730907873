import { useUnit } from 'effector-react';
import { useState, useCallback, DragEvent, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { Params, SetFilesP, useFileUploadHook } from './types';
import { $files, addFile, delFile, resetFiles } from '../attachments';
import { isUnknownObject } from '../../apiRPC/request';

function isDragEvent(e: unknown): e is DragEvent<HTMLDivElement> {
  return isUnknownObject(e) && Boolean(e?.dataTransfer);
}
function isChangeEvent(e: unknown): e is ChangeEvent<HTMLInputElement> {
  return (
    isUnknownObject(e) &&
    isUnknownObject(e?.currentTarget) &&
    e.currentTarget.type === 'file'
  );
}
const maxBytes = 2.5e8;

function getId(): string {
  return (
    window.crypto?.randomUUID?.() ||
    Date.now().toString(10) + Math.random().toString()
  );
}

export const setFiles =
  ({ cardId, options, commentUid }: SetFilesP) =>
  (
    e:
      | DragEvent<HTMLDivElement>
      | ChangeEvent<HTMLInputElement>
      | string
      | File[],
    p?: Params,
  ): void => {
    if (typeof e === 'string') {
      const id = getId();
      addFile({ id, file: e, cardId, params: p, commentUid });
      return;
    }
    let filesArr: File[] = [];

    if (isChangeEvent(e)) {
      filesArr = Array.from(e.currentTarget.files || []);
    }

    if (isDragEvent(e)) {
      filesArr = Array.from(e.dataTransfer.files);
    }

    if (Array.isArray(e)) filesArr = e;

    if (!filesArr.length) return;

    let isSendFile = false;
    filesArr.forEach((file) => {
      if (file.size > maxBytes) {
        toast.error(
          `Для файла ${file.name}: максимальный размер файла не должен превышать 250 МБ`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          },
        );
        return;
      }
      const id = getId();
      addFile({ id, file, cardId, params: p, commentUid });
      isSendFile = true;
    });

    if (options?.showSend && isSendFile) {
      toast.success(`Файлы отправлены`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };
export const useFileUpload = (): useFileUploadHook => {
  const files = useUnit($files);

  const [eventType, setEventType] = useState('');

  /**
   * @function handleDragDropEvent
   */
  const handleDragDropEvent = useCallback((e: DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.dropEffect === 'none') {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    const { type } = e;
    if (type !== eventType) {
      setEventType(e.type);
    }

    e.stopPropagation();
    e.preventDefault();
  }, []);

  /** @function removeFile */
  const removeFile = useCallback((id: string): void => {
    delFile(id);
  }, []);

  /** @function clearAllFiles */
  const clearAllFiles = useCallback((): void => {
    resetFiles();
  }, []);

  return {
    files,
    clearAllFiles,
    handleDragDropEvent,
    removeFile,
    setFiles,
    eventType,
  };
};

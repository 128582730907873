import { useEffect } from 'react';
import { variant } from '@effector/reflect';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { BasicTable } from './Table';
import { Loadding } from '../../../components/Loadding';
import { Empty } from '../../../components/Errors/Empty';
import { NoConnect } from '../../../components/Errors/NoConnect/NoConnect';
import CampaignsHeader from './CampaignsHeader';
import { $fieldTypeCampaign, restCampaign } from './model';
import { DrawerHeader } from '../../../../components/DrawerHeader';
import { TabHeader } from './CampaignsHeader/TabHeader';
import { onSetStatus, StatusTabs } from './model/filter/statuses';
import { resetFiltersCampaigns } from './model/filter';

export const ComponentTable = variant({
  source: $fieldTypeCampaign,
  cases: {
    Success: BasicTable,
    loading: Loadding,
    ErrorEmpty: Empty,
    ErrorNoContent: NoConnect,
  },
  default: Loadding,
});

const Campaigns = () => {
  const [searchParams] = useSearchParams();

  const statusParam = searchParams.get('status') as StatusTabs | null;

  useEffect(() => {
    onSetStatus(statusParam || 'ACTIVE');
  }, [statusParam]);

  useEffect(
    () => () => {
      restCampaign();
      resetFiltersCampaigns();
    },
    [],
  );
  return (
    <Box sx={{ width: '100%', minWidth: 'auto' }}>
      <DrawerHeader />
      <TabHeader />
      <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          height="calc(100vh - 108px )"
        >
          <CampaignsHeader />
          <ComponentTable />
        </Box>
      </Container>
    </Box>
  );
};

export default Campaigns;

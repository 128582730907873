import React, { FC, useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { FiTrash } from 'react-icons/fi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIMask } from 'react-imask';
import { useParams } from 'react-router-dom';
import { BootstrapTooltip } from '../../../../../../components/BootstrapTooltip';
import CalendarField from '../../../../../../components/CalendarField';
import CampaignAccordion from '../../../../../../components/CampaignAccordion';
import {
  campaignBudgetCreateFX,
  delErrorListBudgetCreate,
  onDelNewBudget,
  onSubmitCreateBudgetForm,
  setErrorListBudgetCreate,
} from '../model/budget';
import { formatStringToInt } from '../../../../../../helper/methods/formatStringToint';
import { PCampaignBudgetCreate } from '../../../../../../apiRPC/сampaign/campaignBudget';
import { getOptsMask } from './mask';
import { isErrorProps } from '../../../../../../apiRPC/request';
import { mapName } from '../../../../Organization/options';

type Form = { month: null | Date; value: string; valueSC: string };
const minDate = new Date(2020, 1, 1);
type Props = {
  createId: string;
};
export const CreateBudget: FC<Props> = ({ createId }) => {
  const { uuid } = useParams();
  const [disabledForm, setDisabledForm] = useState(false);
  const formikCreate = useFormik<Form>({
    initialValues: { month: null, value: '', valueSC: '' },
    validationSchema: Yup.object({
      month: Yup.date()
        .typeError('Некорректное значение')
        .nullable()
        .required('Обязательное для заполнения поле'),
      value: Yup.string().required('Обязательное для заполнения поле'),
      valueSC: Yup.string().required('Обязательное для заполнения поле'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (values.month === null) return;
      if (!uuid) return;
      setDisabledForm(true);
      const fields: PCampaignBudgetCreate['fields'] = {
        month: +values.month / 1000,
        value: formatStringToInt(values.value),
        valueSC: formatStringToInt(values.valueSC),
      };
      try {
        await campaignBudgetCreateFX({ campaign: uuid, fields });
        onDelNewBudget(createId);
      } catch (e) {
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            let text = field.description;
            Array.from(mapName).forEach(([key, val]) => {
              text = text.replace(key, val);
            });
            f.setFieldError(field.field, text);
          });
        }
      } finally {
        setDisabledForm(false);
      }
    },
  });

  const { ref: refValueSCMask, value: valValueSCMask } = useIMask(
    ...getOptsMask('valueSC', formikCreate),
  );
  const { ref: refValueMask, value: valValueMask } = useIMask(
    ...getOptsMask('value', formikCreate),
  );

  const onDelete = () => {
    onDelNewBudget(createId);
  };

  useEffect(() => {
    const stop = onSubmitCreateBudgetForm.watch(() => {
      formikCreate.submitForm();
    });
    return stop;
  }, [formikCreate]);

  useEffect(() => {
    if (!formikCreate.isValid) {
      setErrorListBudgetCreate(createId);
    }
    if (formikCreate.isValid) {
      delErrorListBudgetCreate(createId);
    }
  }, [formikCreate, createId]);
  return (
    <CampaignAccordion
      expanded
      title="Новый бюджет"
      hideIcon
      icon={
        <>
          <BootstrapTooltip title="Удалить" placement="top">
            <IconButton onClick={onDelete} disabled={disabledForm}>
              <FiTrash color="#D32F2F" size={20} />
            </IconButton>
          </BootstrapTooltip>
          <Button
            variant="contained"
            type="submit"
            onClick={formikCreate.submitForm}
            sx={{ ml: 1 }}
            disabled={!formikCreate.isValid || disabledForm}
          >
            Сохранить
          </Button>
        </>
      }
      sx={{
        '.MuiAccordionSummary-content': { margin: 2.5, marginLeft: 0 },
        '.MuiButtonBase-root.MuiAccordionSummary-root': { padding: '0 20px' },
      }}
    >
      <Box
        sx={{
          padding: '12px 20px 30px 20px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: '30px',
          }}
        >
          <CalendarField
            required
            disabled={disabledForm}
            minDate={minDate}
            label="Месяц"
            openTo="month"
            views={['year', 'month']}
            inputFormat="MM.yyyy"
            placeholder="ММ.ГГГГ"
            disableMaskedInput={false}
            value={formikCreate.values.month}
            onChange={(v) => {
              formikCreate.setFieldValue('month', v).then(() => {
                formikCreate.validateField('month');
              });
            }}
            error={formikCreate.errors.month}
            helperText={formikCreate.errors.month}
            onClear={() =>
              formikCreate.setFieldValue('month', null).then(() => {
                formikCreate.validateField('month');
              })
            }
          />

          <TextField
            required
            disabled={disabledForm}
            label="До НДС"
            inputRef={refValueMask}
            variant="standard"
            value={valValueMask}
            name="value"
            autoComplete="none"
            error={Boolean(formikCreate.errors.value)}
            helperText={formikCreate.errors.value}
          />

          <TextField
            required
            disabled={disabledForm}
            label="До НДС после СК"
            inputRef={refValueSCMask}
            variant="standard"
            value={valValueSCMask}
            name="valueSC"
            autoComplete="none"
            error={Boolean(formikCreate.errors.valueSC)}
            helperText={formikCreate.errors.valueSC}
          />

          <TextField
            label="Размер СК, %"
            variant="standard"
            autoComplete="none"
            aria-readonly
            disabled
          />
        </Box>
      </Box>
    </CampaignAccordion>
  );
};

import { Box } from '@mui/material';
import React, { FC } from 'react';
import css from './styles.module.scss';

const Loading: FC = () => (
  <Box className={css.loader}>
    <Box  className={css.loader_circle}/>
  </Box>
);

export default Loading;

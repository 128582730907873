import React, { FC, useEffect } from 'react';
import List from '@mui/material/List';
import { Avatar, ListItemButton, Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUnit } from 'effector-react';
import { MenuItem } from './MenuItem';
import {
  getListBoardFx,
  listBoard$,
  setOpenModal,
} from '../../GRID/model/board';
import { $selectWorkspace } from '../../GRID/model/workspace';
import { makeSlug } from '../../helper/makeSlug';
import { $open } from './model';
import { generateDeskPathWithName } from '../../GRID/addresses';

const PopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -15],
      },
    },
  ],
};

export const MenuProjects: FC = () => {
  const open = useUnit($open);
  const theme = useTheme();
  const selectWorkspace = useUnit($selectWorkspace);
  useEffect(() => {
    getListBoardFx({
      filter: { limit: 1000, offset: 0, workspace: selectWorkspace },
    });
  }, [selectWorkspace]);

  const listBoard = useUnit(listBoard$);
  return (
    <List
      sx={{
        paddingBottom: '64px',
      }}
    >
      <ListItem
        disablePadding
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          mx: 1,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <Tooltip
            title="Проекты"
            disableHoverListener={open}
            placement="bottom"
            disableInteractive
            PopperProps={PopperProps}
          >
            <Avatar
              variant="square"
              alt="Проекты"
              sx={{
                width: 24,
                height: 24,
                fontSize: theme.typography.fontSize,
              }}
            >
              P
            </Avatar>
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Проекты" sx={{ opacity: open ? 1 : 0 }} />
        {open && (
          <Tooltip
            title="Создать новую доску"
            placement="bottom"
            disableInteractive
            PopperProps={PopperProps}
            sx={{
              padding: '5px 0',
              margin: 0,
              justifyContent: 'center',
            }}
          >
            <ListItemButton onClick={() => setOpenModal(true)}>
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                }}
              >
                <AddCircleOutlineIcon color="primary" />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        )}
      </ListItem>

      {listBoard?.rows.map((item) => (
        <MenuItem
          key={item.uuid}
          open={open}
          text={item.title}
          to={generateDeskPathWithName(item.uuid, makeSlug(item.title))}
        />
      ))}
    </List>
  );
};

import { createEffect, createEvent, sample } from 'effector';
import { $account } from '../../../../model/account';
import { $card, cardGetFX } from '../index';
import { CardUpdateMembers, updateMembersFX } from '../update/members';

export const joinToMembers = createEvent();

export const joinToMembersFX = createEffect(async (d: CardUpdateMembers) => {
  await updateMembersFX(d);
  await cardGetFX({ uuid: d.uuid });
});

sample({
  clock: joinToMembers,
  source: { account: $account, card: $card },
  filter: ({ account }) => Boolean(account?.uuid),
  fn: ({ account, card }) => ({
    uuid: card!.uuid,
    fields: { members: { add: [account!.uuid] } },
  }),
  target: joinToMembersFX,
});

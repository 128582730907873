import React, { FC } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  FormControl,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useUnit } from 'effector-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { WorkspaceItem } from '../../../../apiRPC/workspace';

import { workspaceUpdateFX } from '../../../model/workspace';

const TextAreaBody2 = styled(TextField)(({ theme }) => ({
  '& textarea': {
    width: '100%',
    resize: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    ...theme.typography.body2,
  },
}));
type Props = {
  workspaceData: WorkspaceItem;
  onChangeClose: () => void;
  currentWorkspace: string;
};
export const ChangeInfo: FC<Props> = ({
  workspaceData,
  onChangeClose,
  currentWorkspace,
}) => {
  const pending = useUnit(workspaceUpdateFX.pending);
  const formik = useFormik({
    initialValues: {
      title: workspaceData.title,
      description: workspaceData.description,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Обязательное поле')
        .trim()
        .min(3, 'Описание слишком короткое'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      if (
        values.title === workspaceData.title &&
        values.description === workspaceData.description
      ) {
        f.resetForm();
        onChangeClose();
        return;
      }

      workspaceUpdateFX({
        identifier: currentWorkspace,
        fields: { title: values.title, description: values.description },
      }).then(() => {
        f.resetForm();
        onChangeClose();
      });
    },
  });

  const handleClose = () => {
    onChangeClose();
    formik.resetForm();
  };

  const disabled =
    Boolean(formik.errors.title) || !formik.values.title || pending;
  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Card>
        <CardHeader
          title={
            <Typography
              gutterBottom
              variant="h5"
              color="primary"
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              Редактирование рабочего пространства: {workspaceData.title}
            </Typography>
          }
        />
        <CardContent>
          <FormControl variant="standard" fullWidth margin="dense">
            <TextField
              required
              variant="filled"
              autoFocus
              margin="dense"
              id="title"
              label="Название рабочего пространства"
              name="title"
              inputProps={{ maxLength: 255 }}
              onChange={formik.handleChange}
              value={formik.values.title}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.title)}
              helperText={formik.errors.title || ' '}
            />
          </FormControl>

          <FormControl variant="standard" fullWidth margin="dense">
            <TextAreaBody2
              inputProps={{ maxLength: 4096 }}
              margin="dense"
              fullWidth
              label="Описание рабочего пространства (необязательно)"
              multiline
              minRows={5}
              id="description"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              onBlur={formik.handleBlur}
              variant="filled"
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Stack direction="row" spacing={1}>
            <Button type="reset" size="small" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              endIcon={<CheckIcon />}
              disabled={disabled}
            >
              Сохранить
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Box>
  );
};

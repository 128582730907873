import { createEvent, restore, sample } from 'effector';
import axios, { Canceler } from 'axios';
import { debounce } from 'patronum/debounce';
import {
  $lastFilterCampaign,
  campaignGetListFX,
  limitCampaign,
} from '../index';

export const onSetSearchFilter = createEvent<string>();
export const onResetSearchFilter = createEvent();
export const $searchFilter = restore(onSetSearchFilter, '').reset(
  onResetSearchFilter,
);

const cancelTokenSearchFilter: Canceler[] = [];
sample({
  clock: debounce({ source: $searchFilter, timeout: 300 }),
  source: {
    lastFilter: $lastFilterCampaign,
  },
  fn: ({ lastFilter }, payload) => {
    cancelTokenSearchFilter.forEach((item) => item());
    const source = axios.CancelToken.source();
    cancelTokenSearchFilter.push(source.cancel);
    const oldFilter = { ...(lastFilter?.filter || {}) };
    const filter = {
      ...oldFilter,
      search: payload,
      limit: limitCampaign,
      offset: 0,
    };

    return {
      params: { filter },
      cancelToken: source.token,
    };
  },
  target: campaignGetListFX,
});

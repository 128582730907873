import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import {
  $markForUpdate,
  ColorsMark,
  markCreateFX,
  markUpdateFX,
  resetDataForUpdate,
  setUuidForDelete,
} from '../../../../../model/Marks';

type Data = {
  board: string;
  onClose: () => void;
  onBack?: () => void;
  openMenuDeleted: () => void;
};
export function useMarkCreate({
  board,
  onClose,
  onBack,
  openMenuDeleted,
}: Data) {
  const [title, setTitle] = useState<string>('');
  const [color, setColor] = useState<ColorsMark>();
  const pendingCreate = useUnit(markCreateFX.pending);
  const pendingUpdate = useUnit(markUpdateFX.pending);
  const markForUpdate = useUnit($markForUpdate);
  const disabledCreate = !color || pendingCreate || pendingUpdate;

  const isUpdate = Boolean(markForUpdate?.uuid);

  const resetState = () => {
    setTitle('');
    setColor(undefined);
  };

  useEffect(() => {
    if (!markForUpdate) {
      resetState();
      return;
    }
    if (markForUpdate?.title) {
      setTitle(markForUpdate.title);
    }
    if (markForUpdate?.color) {
      setColor(markForUpdate.color);
    }
  }, [markForUpdate]);

  const reset = () => {
    resetState();
    resetDataForUpdate();
  };
  const onBackReset = () => {
    reset();
    onBack?.();
  };
  const onCloseReset = () => {
    reset();
    onClose();
  };
  const onCreate = () => {
    if (!color) return;
    markCreateFX({ board, title, color }).then(() => onBackReset());
  };

  const onUpdate = () => {
    if (!color) return;
    if (!markForUpdate?.uuid) return;

    markUpdateFX({
      uuid: markForUpdate.uuid,
      fields: { color, title },
    }).then(() => onBackReset());
  };

  const onSubmit = () => {
    if (markForUpdate?.uuid) {
      onUpdate();
      return;
    }
    onCreate();
  };

  const onDelete = () => {
    if (!markForUpdate?.uuid) return;
    setUuidForDelete(markForUpdate.uuid);
    openMenuDeleted();
  };

  return {
    onCloseReset,
    onBackReset,
    title,
    setTitle,
    setColor,
    color,
    disabledCreate,
    onSubmit,
    isUpdate,
    onDelete,
  };
}

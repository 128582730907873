import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { NavLink, useParams } from 'react-router-dom';
import { useStoreMap, useUnit } from 'effector-react';
import { FiChevronLeft } from 'react-icons/fi';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { LoadingButton } from '@mui/lab';
import ReactRouterPrompt from 'react-router-prompt';
import { number } from 'yup';
import Header from '../../../components/Header';
import Modal from '../../../../components/Modal';
import {
  $organization,
  organizationGetFX,
  organizationUpdateFX,
  restOrganization,
} from './model';

import type { ROrganizationGet } from '../../../../apiRPC/organization';
import { navigateForEffect } from '../../../../model/routing';
import {
  $ordList,
  $organizationPlatformsUids,
  $platformList,
  $platformOption,
  getOrganizationPlatformsFX,
  onOrdGetList,
  onPlatformGetList,
  onResetOrganizationPlatforms,
} from '../../../model/ord';
import css from '../styles.module.scss';
import { addressesORD } from '../../../addresses';
import { okms } from '../oksm';
import { isErrorProps } from '../../../../apiRPC/request';
import { mapName } from '../options';

type Form = {
  name: string;
  aliases: string;
  exportName: string;
  inn: string;
  type: string;
  ord: number;
  mobilePhone: string;
  epayNumber: string;
  regNumber: string;
  alternativeInn: string;
  oksmNumber: string;
  directClient: boolean;
  isRr?: boolean;
  isOrs?: boolean;
  rsUrl?: string;
  isRd: boolean;
  isRa: boolean;
  platforms?: string[];
};

function formIsChanged(
  formVal: Form,
  organization: ROrganizationGet | null,
  organizationPlatformsUids: string[],
): boolean {
  let res = false;
  if (!organization) return res;

  const keys = Object.keys(formVal);

  for (const key of keys) {
    if (key === 'platforms') {
      if (
        JSON.stringify(formVal[key as keyof Form]) !==
        JSON.stringify(organizationPlatformsUids)
      ) {
        res = true;
        break;
      }
      continue;
    }
    if (
      formVal[key as keyof Form] !== (organization[key as keyof Form] ?? '')
    ) {
      res = true;
      break;
    }
  }

  return res;
}
const styleMuiFormHelperText = { '.MuiFormHelperText-root': { mb: '-23px' } };
const types = [
  ['ul', 'Юридическое лицо'],
  ['fl', 'Физическое лицо'],
  ['ip', 'Индивидуальный предприниматель'],
  ['ffl', 'Иностранное физическое лицо'],
  ['ful', 'Иностранное юридическое лицо'],
];
const foreignL = ['ffl', 'ful'];
const typesForFL = ['fl', 'ip', 'ffl'];
const styleDisabled = { '.Mui-disabled': { cursor: 'not-allowed' } };
export const Update: FC = () => {
  const { uuid } = useParams();
  const [organization, organizationPlatformsUids] = useUnit([
    $organization,
    $organizationPlatformsUids,
  ]);
  const pendingUpdate = useUnit(organizationUpdateFX.pending);
  const [disableModal, setDisableModal] = useState(false);

  const formik = useFormik<Form>({
    initialValues: {
      name: '',
      aliases: '',
      exportName: '',
      inn: '',
      type: '',
      ord: 0,
      mobilePhone: '',
      epayNumber: '',
      regNumber: '',
      alternativeInn: '',
      oksmNumber: '',
      directClient: false,
      isRr: false,
      isOrs: false,
      isRd: false,
      isRa: false,
      rsUrl: '',
      platforms: [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Обязательное для заполнения поле')
        .trim()
        .min(1, 'Минимум 1 знак')
        .max(255, 'Максимум 255 знаков'),
      aliases: Yup.string().max(1000, 'Максимум 1000 знаков'),
      exportName: Yup.string(),
      ord: Yup.number()
        .required('Обязательное для заполнения поле')
        .when(['isOrs', 'isRr', 'isRd', 'isRa'], {
          is: (...values: boolean[]) => {
            const isCheked = values.includes(true);
            return !isCheked;
          },
          then: (schema) =>
            schema.test({
              name: 'is-name',
              message: () => 'Должен быть выбран хотя бы один тип контрагента',
              test: (value) => value !== 5,
            }),

          otherwise: (schema) => schema.notRequired(),
        }),
      isRr: Yup.boolean(),
      isOrs: Yup.boolean(),
      rsUrl: Yup.string()
        .url('Неверный формат')
        .when(['isOrs'], {
          is: (isOrs: boolean) => isOrs,
          then: (schema) => schema.required('Обязательное для заполнения поле'),
          otherwise: (schema) => schema.notRequired(),
        }),
      platforms: Yup.array()
        .of(Yup.string())
        .when(['isRr', 'isOrs', 'ord'], {
          is: (isRr: boolean, isOrs: boolean, ord: number) =>
            ord === 4 && (isRr || isOrs),
          then: (schema) => schema.min(1, 'Выберите хотя бы один элемент'),
          otherwise: (schema) => schema.notRequired(),
        }),
      isRd: Yup.boolean(),
      isRa: Yup.boolean(),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      if (!uuid) return;

      const isOrdForType = [4, 5].includes(values.ord);
      setDisableModal(true);
      organizationUpdateFX({
        uuid,
        fields: {
          name: values.name,
          aliases: values.aliases,
          exportName: values.exportName,
          isRr: isOrdForType ? values.isRr || false : undefined,
          isOrs: isOrdForType ? values.isOrs || false : undefined,
          isRd: isOrdForType ? values.isRd || false : undefined,
          isRa: isOrdForType ? values.isRa || false : undefined,
          rsUrl: isOrdForType && values.isOrs ? values.rsUrl || '' : undefined,
          platforms: values.ord === 4 ? values.platforms || [] : undefined,
        },
      })
        .then(() => {
          toast.success('Организация была изменена ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          navigateForEffect(addressesORD.organizationsPath);
        })
        .catch((e) => {
          setDisableModal(false);
          if (isErrorProps(e)) {
            e?.data?.fields?.forEach((field) => {
              let text = field.description;
              Array.from(mapName).forEach(([key, val]) => {
                text = text.replace(key, val);
              });
              f.setFieldError(field.field, text);
            });
          }
        });
    },
  });

  const platformOption = useStoreMap({
    store: $platformList,
    keys: [formik.values.ord],
    fn: (state, [ord]) =>
      state
        ?.filter((item) => item.ord === ord)
        ?.map((item) => ({
          label: item.name,
          value: item.uuid,
        })),
    defaultValue: [],
  });
  useEffect(() => {
    onPlatformGetList();
  }, []);
  useEffect(() => {
    if (!uuid) return undefined;
    organizationGetFX({ uuid });
    getOrganizationPlatformsFX({ organization: uuid });
    return () => {
      restOrganization();
      onResetOrganizationPlatforms();
    };
  }, [uuid]);
  useEffect(() => {
    if (!organization) return;

    formik.setValues({
      name: organization.name,
      aliases: organization.aliases || '',
      exportName: organization.exportName || '',
      inn: organization?.inn || '',
      type: organization.type,
      ord: organization.ord,
      mobilePhone: organization.mobilePhone || '',
      epayNumber: organization.epayNumber || '',
      regNumber: organization.regNumber || '',
      alternativeInn: organization.alternativeInn || '',
      oksmNumber: organization.oksmNumber || '',
      directClient: organization.directClient || false,
      isRr: organization.isRr || false,
      isOrs: organization.isOrs || false,
      isRd: organization.isRd || false,
      isRa: organization.isRa || false,
      rsUrl: organization.rsUrl || '',
      platforms: formik.values.platforms || [],
    });
  }, [organization]);

  const resetForm = () => {
    if (!organization) return;
    formik.setValues(
      {
        name: organization.name,
        aliases: organization.aliases || '',
        exportName: organization.exportName || '',
        inn: organization?.inn || '',
        type: organization.type,
        ord: organization.ord,
        mobilePhone: organization.mobilePhone || '',
        epayNumber: organization.epayNumber || '',
        regNumber: organization.regNumber || '',
        alternativeInn: organization.alternativeInn || '',
        oksmNumber: organization.oksmNumber || '',
        directClient: organization.directClient || false,
        isRr: organization.isRr || false,
        isOrs: organization.isOrs || false,
        isRd: organization.isRd || false,
        isRa: organization.isRa || false,
        rsUrl: organization.rsUrl || '',
        platforms: organizationPlatformsUids || [],
      },
      true,
    );
  };

  useEffect(() => {
    formik.setFieldValue('platforms', organizationPlatformsUids);
  }, [organizationPlatformsUids]);
  const ordList = useUnit($ordList);
  useEffect(() => {
    onOrdGetList();
  }, []);

  const isChangeExportName = useRef<boolean>(false);
  const handleChange: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      if (name === 'exportName') {
        isChangeExportName.current = true;
      }

      formik.setFieldValue(name, e.target.value).then(async () => {
        if (name === 'platforms' && Array.isArray(e.target.value)) {
          await formik.setFieldValue('platforms', e.target.value);
          await formik.validateField('platforms');
          return;
        }

        if (name === 'isRr') {
          await formik.setFieldValue('isRr', !formik.values.isRr);
          await formik.validateField('isRr');
          if (!formik.values.isRr && !formik.values.isOrs) {
            await formik.setFieldValue('platforms', []);
            await formik.validateField('platforms');
          }
        }
        if (name === 'isOrs') {
          await formik.setFieldValue('isOrs', !formik.values.isOrs);
          await formik.validateField('isOrs');
          await formik.setFieldValue('rsUrl', '');
          await formik.validateField('rsUrl');
          if (!formik.values.isRr && !formik.values.isOrs) {
            await formik.setFieldValue('platforms', []);
            await formik.validateField('platforms');
          }
        }

        await formik.validateField(name);
      });
    },
    [formik],
  );

  const theme = useTheme();

  const formChanged = formIsChanged(
    formik.values,
    organization,
    organizationPlatformsUids,
  );
  const btnDisabled = !formChanged || pendingUpdate;
  const disableSubmit = btnDisabled || !formik.isValid;
  const isPrompt = disableModal ? false : formChanged;
  const showRsUrl = formik.values.isOrs;
  const showPlatform =
    (formik.values.isOrs || formik.values.isRr) && formik.values.ord === 4;
  return (
    <Box>
      <Header>
        <Box display="flex">
          <NavLink to={addressesORD.organizationsPath}>
            <Tooltip title="Назад">
              <IconButton>
                <FiChevronLeft size={20} />
              </IconButton>
            </Tooltip>
          </NavLink>
          <Box flex={1}>
            <Typography
              className={css.name}
              variant="h1"
              sx={{
                marginLeft: '2px',
              }}
              title={organization?.name}
            >
              Организация {organization?.name}
            </Typography>
            <Typography
              mt={0.5}
              sx={{
                color: theme.palette.secondary.light,
              }}
            >
              {organization?.uuid || '  '}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            sx={{
              marginLeft: pendingUpdate ? '36px' : '0px',
            }}
            onClick={resetForm}
            disabled={btnDisabled}
          >
            Отмена
          </Button>
          <LoadingButton
            disabled={disableSubmit}
            loading={pendingUpdate}
            variant="contained"
            sx={{
              marginLeft: '36px',
            }}
            onClick={formik.submitForm}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      <Box display="grid" gridTemplateColumns="auto 370px" gap="0 40px">
        <Paper
          elevation={0}
          sx={{
            padding: '30px',
          }}
        >
          <Typography fontWeight={700} mb={3.8}>
            Параметры
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px" mb={4}>
            <FormControl
              variant="standard"
              error={Boolean(formik.errors.type)}
              disabled
              sx={{ '.Mui-disabled': { cursor: 'not-allowed !important' } }}
            >
              <InputLabel id="type-label">Тип</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={formik.values.type}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                label="Тип*"
                required
                readOnly
                sx={{ '.MuiInput-root': { cursor: 'not-allowed' } }}
              >
                {types.map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: '-24px' }}>
                {formik.errors.type}
              </FormHelperText>
            </FormControl>

            <TextField
              label={
                typesForFL.includes(formik.values.type)
                  ? 'ФИО'
                  : 'Название юр. лица'
              }
              variant="standard"
              value={formik.values.name}
              name="name"
              required
              onChange={handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.errors.name || ''}
              error={Boolean(formik.errors.name)}
              inputProps={{ maxLength: 255 }}
              sx={styleMuiFormHelperText}
              autoComplete="none"
            />
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px" mb={4}>
            <TextField
              label="Синонимы названия (через запятую)"
              variant="standard"
              sx={styleMuiFormHelperText}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              name="aliases"
              helperText={formik.errors.aliases || ''}
              error={Boolean(formik.errors.aliases)}
              value={formik.values.aliases}
              inputProps={{ maxLength: 1000 }}
              autoComplete="none"
            />
            <TextField
              label="Название для выгрузки"
              variant="standard"
              sx={styleMuiFormHelperText}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              name="exportName"
              helperText={
                isChangeExportName.current && !formik.values.exportName
                  ? 'Если не заполнено будет подставлено значение из поля "Название юр. лица"'
                  : formik.errors.exportName
              }
              error={Boolean(formik.errors.exportName)}
              value={formik.values.exportName}
              inputProps={{ maxLength: 255 }}
              autoComplete="none"
            />
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px">
            <FormControl
              variant="standard"
              error={Boolean(formik.errors.ord)}
              disabled
            >
              <InputLabel id="ord-label">ОРД</InputLabel>
              <Select
                labelId="ord-label"
                label="ОРД*"
                name="ord"
                required
                value={formik.values.ord}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                sx={{ '.MuiSelect-select': { cursor: 'not-allowed' } }}
              >
                {ordList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: '-24px' }}>
                {formik.errors.ord}
              </FormHelperText>
            </FormControl>

            {['ul', 'fl', 'ip'].includes(formik.values.type) && (
              <TextField
                label="ИНН"
                variant="standard"
                value={formik.values.inn || ''}
                name="inn"
                disabled
                onChange={handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.inn || ''}
                error={Boolean(formik.errors.inn)}
                inputProps={{ maxLength: 40 }}
                sx={styleDisabled}
                autoComplete="none"
              />
            )}
          </Box>
          {formik.values.type === 'ffl' && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mt={4}
            >
              <TextField
                label="Абонентский номер мобильного телефона"
                variant="standard"
                value={formik.values.mobilePhone || ''}
                name="mobilePhone"
                autoComplete="none"
                sx={styleDisabled}
                disabled
              />
              <TextField
                label="EPAY"
                variant="standard"
                value={formik.values.epayNumber || ''}
                name="epayNumber"
                inputProps={{ maxLength: 255 }}
                autoComplete="none"
                sx={styleDisabled}
                disabled
              />
            </Box>
          )}
          {formik.values.type === 'ful' && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mt={4}
            >
              <TextField
                label="Регистрационный номер"
                variant="standard"
                value={formik.values.regNumber || ''}
                name="regNumber"
                inputProps={{ maxLength: 255 }}
                sx={styleDisabled}
                disabled
              />
              <TextField
                label="Номер налогоплательщика либо его аналог"
                variant="standard"
                value={formik.values.alternativeInn || ''}
                name="alternativeInn"
                inputProps={{ maxLength: 255 }}
                sx={styleDisabled}
                disabled
              />
            </Box>
          )}
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0 30px" mt={4}>
            {foreignL.includes(formik.values.type) && (
              <FormControl
                variant="standard"
                error={Boolean(formik.errors.oksmNumber)}
                disabled
              >
                <InputLabel id="oksmNumber-label">
                  Страна регистрации
                </InputLabel>
                <Select
                  labelId="oksmNumber-label"
                  label="Страна регистрации"
                  name="oksmNumber"
                  value={formik.values.oksmNumber}
                >
                  {okms.map((item) => (
                    <MenuItem key={item.guid} value={item.code}>
                      {item.shortname}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ mb: '-24px' }}>
                  {formik.errors.oksmNumber}
                </FormHelperText>
              </FormControl>
            )}

            {formik.values.ord === 3 && (
              <FormControl
                variant="standard"
                error={Boolean(formik.errors.directClient)}
                disabled
              >
                <InputLabel id="directClient-label">Прямой клиент</InputLabel>
                <Select
                  labelId="directClient-label"
                  label="Прямой клиент"
                  name="directClient"
                  value={formik.values.directClient ? 1 : 0}
                >
                  <MenuItem value={1}>ДА</MenuItem>
                  <MenuItem value={0}>НЕТ</MenuItem>
                </Select>
                <FormHelperText sx={{ mb: '-24px' }}>
                  {formik.errors.directClient}
                </FormHelperText>
              </FormControl>
            )}
          </Box>

          {[4, 5].includes(formik.values.ord) && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mb={4}
              mt={4}
            >
              <FormControl variant="standard">
                <FormLabel component="legend">Тип контрагента</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isRr}
                        onChange={handleChange}
                        name="isRr"
                      />
                    }
                    label="Рекламораспространитель"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isOrs}
                        onChange={handleChange}
                        name="isOrs"
                      />
                    }
                    label="Оператор рекламной системы"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isRd}
                        onChange={formik.handleChange}
                        name="isRd"
                      />
                    }
                    label="Рекламодатель"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isRa}
                        onChange={formik.handleChange}
                        name="isRa"
                      />
                    }
                    label="Рекламное агентство"
                  />
                </FormGroup>
              </FormControl>

              {showRsUrl && (
                <TextField
                  label="Ссылка на рекламную систему"
                  variant="standard"
                  sx={styleMuiFormHelperText}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="rsUrl"
                  helperText={formik.errors.rsUrl || ''}
                  error={Boolean(formik.errors.rsUrl)}
                  value={formik.values.rsUrl}
                  inputProps={{ maxLength: 255 }}
                  autoComplete="none"
                />
              )}
            </Box>
          )}

          {showPlatform && (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="0 30px"
              mb={4}
            >
              <FormControl
                variant="standard"
                error={Boolean(formik.errors.platforms)}
              >
                <InputLabel id="platforms-label">Платформы</InputLabel>
                <Select
                  labelId="platforms-label"
                  id="platforms-checkbox"
                  multiple
                  error={Boolean(formik.errors.platforms)}
                  value={formik.values.platforms}
                  onChange={handleChange}
                  name="platforms"
                  renderValue={(selected) => {
                    const names: string[] = [];
                    selected.forEach((value) => {
                      const name = platformOption.find(
                        (item) => item.value === value,
                      )?.label;
                      if (name) names.push(name);
                    });
                    return names.join(', ');
                  }}
                >
                  {platformOption.map(({ label, value }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      sx={{ paddingY: '5px', paddingX: '8px' }}
                    >
                      <Checkbox
                        checked={Boolean(
                          formik.values.platforms?.includes(value),
                        )}
                        size="small"
                      />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ mb: '-24px' }}>
                  {formik.errors.platforms}
                </FormHelperText>
              </FormControl>
            </Box>
          )}
        </Paper>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '37px 0',
            maxHeight: 160,
          }}
        >
          <Avatar
            sx={{
              bgcolor: '#F1F4FF',
              width: 86,
              height: 86,
            }}
          >
            <PersonSharpIcon
              sx={{
                color: '#A1B2FC',
                fontSize: '2.5rem',
              }}
            />
          </Avatar>
        </Paper>
      </Box>
      <ReactRouterPrompt when={isPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Box>
  );
};

import React, { FC } from 'react';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import { useStoreMap } from 'effector-react';

import { Task } from './Task';
import { $cardListsSorted } from '../../../model/card';
import { RCardGet } from '../../../../apiRPC/card';

type TaskDataProps = {
  uuidColumn: string;
  index: number;
  board: string;
  provided: DraggableProvided;
};

const TaskData: FC<TaskDataProps> = ({
  uuidColumn,
  index,
  board,
  provided,
}) => {
  const item: RCardGet | undefined = useStoreMap({
    store: $cardListsSorted,
    keys: [uuidColumn, index],
    fn: (cards, [column, indexCart]) => cards[column]?.[indexCart],
    updateFilter: (newResult, oldResult) =>
      newResult?.title !== oldResult?.title ||
      newResult?.createdAt !== oldResult?.createdAt ||
      newResult?.uuid !== oldResult?.uuid ||
      newResult?.marks !== oldResult?.marks ||
      newResult?.attachments?.length !== oldResult?.attachments?.length ||
      newResult?.dates !== oldResult?.dates,
  });

  if (!item) return null;
  return (
    <Task
      draggableProps={provided.draggableProps}
      dragHandleProps={provided.dragHandleProps || undefined}
      ref={provided.innerRef}
      title={item.title}
      createdAt={item.createdAt}
      uuid={item.uuid}
      board={board}
      marks={item.marks}
      members={item.members}
      attachmentsCount={item?.attachments?.length || 0}
      dates={item.dates}
      countComments={item.countComments}
      countAttachments={item.countAttachments}
    />
  );
};

type Props = {
  uuidColumn: string;
  index: number;
  board: string;
  cardUuid: string;
};
export const DraggableTask: FC<Props> = ({
  uuidColumn,
  index,
  board,
  cardUuid,
}) => (
  <Draggable draggableId={cardUuid} index={index} key={cardUuid}>
    {(provided) => (
      <TaskData
        board={board}
        index={index}
        uuidColumn={uuidColumn}
        provided={provided}
      />
    )}
  </Draggable>
);

export const DraggableTaskMemo = React.memo(DraggableTask);

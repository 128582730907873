import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ContainerGRID } from '../components/containers/ContainerGRID';
import { Desks } from './pages/desks';
import { Desk } from './pages/desk';
import NotFound from '../pages/errors/NotFound';
import {
  cardIdSegment,
  cardPath,
  cNameSegment,
  deskPath,
  desksPath,
  dNameSegment,
  uuidSegment,
  workspaceSegment,
} from './addresses';

export const GRIDRouter: RouteObject = {
  path: '*',
  element: <ContainerGRID />,
  children: [
    { index: true, element: <Desks /> },
    { path: desksPath, element: <Desks /> },
    { path: `${desksPath}/${workspaceSegment}`, element: <Desks /> },
    // @deprecated
    { path: 'desk/:uuid/', element: <Desk /> },
    // @deprecated
    { path: 'desk/:uuid/:cardId', element: <Desk /> },
    { path: `${deskPath}/${uuidSegment}`, element: <Desk /> },
    { path: `${deskPath}/${uuidSegment}/${dNameSegment}`, element: <Desk /> },
    { path: `${cardPath}/${cardIdSegment}`, element: <Desk /> },
    { path: `${cardPath}/${cardIdSegment}/${cNameSegment}`, element: <Desk /> },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

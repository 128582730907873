import { createEvent, restore } from 'effector';

class CurrentApi {
  api: string;

  constructor(api: string) {
    this.api = api;
  }

  get value() {
    return this.api;
  }

  set value(api: string) {
    this.api = api;
  }
}

const defaultApi =
  process.env.REACT_APP_IS_PRODUCTION === 'true'
    ? process.env.REACT_APP_API_PROD
    : localStorage.API
    ? localStorage.API
    : process.env.REACT_APP_API_DEV;

export const currentApi = new CurrentApi(defaultApi);
export const changeAPI = createEvent<string>();

changeAPI.watch((payload) => {
  currentApi.value = payload;
  localStorage.setItem('API', payload);
});

export const onSetOpenAlert = createEvent<boolean>();
export const $open = restore(onSetOpenAlert, false);

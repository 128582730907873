import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FormikProps } from 'formik';
import { ErrorProps, isErrorProps } from '../../apiRPC/request';
import { errorsMessage } from '../../apiRPC/errors';

const errorsMail = [120, 121, 122, 125, 127];
const errorsPassword = [126];

export function useErrors(
  signError: Error | ErrorProps | null,
  formik:
    | FormikProps<{
        password: string;
        email: string;
        confirmPassword: string;
      }>
    | FormikProps<{
        password: string;
        email: string;
      }>,
) {
  useEffect(() => {
    if (signError == null) return;

    if (isErrorProps(signError)) {
      const code = signError?.code;
      const message = code ? errorsMessage[code] : undefined;

      if (message === undefined || code === undefined) {
        toast.error(signError.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }

      if (errorsMail.includes(code)) {
        formik.setFieldError('email', message);
        return;
      }
      if (errorsPassword.includes(code)) {
        formik.setFieldError('password', message);
        return;
      }

      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }

    toast.error(signError.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }, [signError]);
}

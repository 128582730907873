import React, { FC } from 'react';
import { useFormik } from 'formik';

import { Box, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import Modal from '../../../../../../../../components/Modal';
import { LabelForContracts } from '../../../../../../Campaigns/UI/LabelForContracts';
import { resetModalType } from '../../model/switcherForModal';
import { Resource } from '../../../../../../../../apiRPC/creative/resources';
import { resourcesCreateFX } from '../../model';
import { isErrorProps } from '../../../../../../../../apiRPC/request';

type Form = {
  description: Resource['description'];
  mediaExampleUrl: Resource['mediaExampleUrl'];
};

const initialValues: Form = {
  description: '',
  mediaExampleUrl: '',
};

export const ResourceModal: FC = () => {
  const { uuid } = useParams();
  const pending = useUnit(resourcesCreateFX.pending);
  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object().shape({
      description: Yup.string().required('Обязательное для заполнения поле'),
      mediaExampleUrl: Yup.string().required(
        'Обязательное для заполнения поле',
      ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (!uuid) return;

      try {
        await resourcesCreateFX({
          creative: uuid,
          items: [
            {
              type: 'resource',
              description: values.description,
              mediaExampleUrl: values.mediaExampleUrl,
            },
          ],
        });
        resetModalType();
      } catch (e) {
        console.error({ e });
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const handleChange: typeof formik['handleChange'] = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value, true);
  };

  return (
    <Modal
      open
      handleClose={resetModalType}
      onConfirm={formik.submitForm}
      title="Добавление ресурса"
      outlinedText="Отмена"
      containedText="Заполнить"
      disabledContained={!formik.isValid || pending}
      disabledOutlined={pending}
    >
      <Box
        mb={3}
        sx={{
          span: {
            color: '#666666',
          },
        }}
      >
        <TextField
          label={
            <LabelForContracts
              text="Ссылка на ресурс"
              title="Описание изображения креатива"
            />
          }
          sx={{ '.MuiInputLabel-root': { zIndex: 99 } }}
          inputProps={{ maxLength: 1000 }}
          variant="standard"
          placeholder="Ссылка на ресурс"
          fullWidth
          autoComplete="none"
          name="mediaExampleUrl"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.mediaExampleUrl)}
          helperText={formik.errors.mediaExampleUrl}
          value={formik.values.mediaExampleUrl}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr">
        <TextField
          label={
            <LabelForContracts
              text="Описание"
              title="Ссылка на образец. Под файлом понимается графическая составляющая креатива: используемые изображения, логотипы, видео-, аудиозаписи."
            />
          }
          placeholder="Описание"
          multiline
          rows={10}
          inputProps={{ maxLength: 1000 }}
          name="description"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description}
          value={formik.values.description}
        />
      </Box>
    </Modal>
  );
};

import { fabricContracts } from '../../../Campaigns/Campaign/Create/model/fabricContracts';

export const {
  contractGetListFX: finalContractGetListFX,
  $contractList: $finalContractList,
  searchContract: searchFinalContract,
  onLoadContracts: onLoadFinalContracts,
  resetCatalogs: resetFinalContractsCatalogs,
} = fabricContracts({ category: 'Final' });

export const {
  contractGetListFX: parentContractGetListFX,
  $contractList: $parentContractList,
  searchContract: searchParentContract,
  onLoadContracts: onLoadParentContracts,
  resetCatalogs: resetParentContractsCatalogs,
} = fabricContracts();

export function resetCatalogs() {
  resetParentContractsCatalogs();
  resetFinalContractsCatalogs();
}

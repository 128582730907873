import { createEffect, createEvent, createStore, sample } from 'effector';
import {
  PSendMultipleFiles,
  RSendMultipleFiles,
  sendMultipleFiles,
} from '../../apiRPC/card/file';
import { errorHandlerFx } from '../../model/errorHandler';
import { createEffectJWT } from '../../model/JWT';
import {
  contentDeleteFX,
  contentRestoreFX,
  updateAttachmentsFX,
} from '../../GRID/model/card/update/attachments';
import { $card, cardGetFX } from '../../GRID/model/card';
import { Params } from '../useFileUpload/types';
import { resetOpenTaskOverview } from '../../GRID/model/taskOverview';
import { cardDeleteCommentFX } from '../../GRID/model/card/comment';
import { getList, Row } from '../../apiRPC/account';

export const sendMultipleFilesFX = errorHandlerFx(
  createEffectJWT(sendMultipleFiles),
);

export const accountListForFileFX = errorHandlerFx(createEffectJWT(getList));

export const resetAccountListForFile = createEvent();
export const addAccountListForFile = createEvent<Row>();
export const $accountListForFile = createStore<Map<string, Row>>(new Map())
  .on(
    accountListForFileFX.doneData,
    (_, payload) => new Map(payload.rows.map((item) => [item.uuid, item])),
  )
  .on(addAccountListForFile, (state, payload) => {
    state.set(payload.uuid, payload);
    return new Map(Array.from(state));
  })
  .reset(resetAccountListForFile);

export const addFile = createEvent<{
  id: string;
  file: File | string;
  cardId: string;
  params?: Params;
  commentUid?: string;
}>();
export const delFile = createEvent<string>();
export const resetFiles = createEvent();
export const $files = createStore<
  Map<
    string,
    {
      file: File | string;
      cardId: string;
      params?: Params;
      commentUid?: string;
    }
  >
>(new Map())
  .on(addFile, (state, payload) => {
    state.set(payload.id, {
      file: payload.file,
      cardId: payload.cardId,
      params: payload.params,
      commentUid: payload.commentUid,
    });
    return new Map(Array.from(state));
  })
  .on(delFile, (state, payload) => {
    state.delete(payload);
    return new Map(Array.from(state));
  })
  .reset(resetFiles);

const onIdSendFile = sendMultipleFilesFX.map((data) => data.idFile);
const dellIdInProgress = createEvent<string>();
export const $listIdInProgress = createStore<string[]>([])
  .on(onIdSendFile, (state, payload) => [...state, payload])
  .on(dellIdInProgress, (state, payload) =>
    state.filter((item) => item !== payload),
  );

sample({
  clock: $files,
  source: $listIdInProgress,
  filter: (_, f) => Boolean(f?.size),
  fn: (listIdInProgress, files) => {
    const uploadFilesArr = Array.from(files);
    const resultArr: PSendMultipleFiles[] = [];

    uploadFilesArr.forEach(([id, data]) => {
      if (listIdInProgress.includes(id)) return;
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('type', 'card');
      const params = data?.params;
      if (params) {
        formData.append('params', JSON.stringify(params));
      }
      resultArr.push({
        formData,
        idFile: id,
        cardId: data.cardId,
        commentUid: data.commentUid,
      });
    });

    return resultArr;
  },
  target: createEffect((resultArr: PSendMultipleFiles[]) =>
    resultArr.forEach((item) => sendMultipleFilesFX(item)),
  ),
});

sample({
  clock: sendMultipleFilesFX.done,
  fn: ({ params, result }) => ({
    uuid: params.cardId,
    fields: { attachments: { add: [result.uuid] } },
    idFile: params.idFile,
  }),
  target: updateAttachmentsFX,
});

export const resetListFilesForComments = createEvent();
export const delFileForComment = createEvent<string | string[]>();
export const $listFilesForComments = createStore<RSendMultipleFiles[]>([])
  .on(sendMultipleFilesFX.doneData, (state, payload) => {
    if (!payload.commentUid) return state;
    if ($card.getState()?.uuid !== payload.cardId) return undefined;
    return [...state, payload];
  })
  .on(delFileForComment, (state, payload) =>
    state.filter((item) => {
      if (typeof payload === 'string') return item.uuid !== payload;

      return !payload.includes(item.uuid);
    }),
  )
  .on(contentDeleteFX.done, (state, { params }) =>
    state.filter((item) => item.uuid !== params.uuid),
  )
  .reset([resetListFilesForComments, resetOpenTaskOverview]);

sample({
  clock: [
    updateAttachmentsFX.done,
    contentDeleteFX.done,
    contentRestoreFX.done,
    cardDeleteCommentFX.done,
  ],
  source: $card,
  filter: (state) => Boolean(state),
  fn: (state) => ({ uuid: state?.uuid || '' }),
  target: cardGetFX,
});

sample({
  clock: [sendMultipleFilesFX.fail, updateAttachmentsFX.finally],
  fn: ({ params }) => params.idFile,
  target: [dellIdInProgress, delFile],
});

const setListUsers = createEvent<string[]>();
const resetListUsers = createEvent();
const $listUsers = createStore<string[]>([])
  .on(setListUsers, (state, payload) => payload)
  .reset(resetListUsers);

sample({
  clock: $card,
  source: $accountListForFile,
  filter: (_, card) => Boolean(card?.attachments),
  fn: (accountListForFile, card) => {
    const existing = Array.from(accountListForFile).map(
      ([key, val]) => val.uuid,
    );
    const users: string[] = [];
    card!.attachments!.forEach((item) => {
      const accountUid = item.account;
      if (!accountUid) return;
      if (existing.includes(accountUid)) return;
      if (users.includes(accountUid)) return;
      users.push(accountUid);
    });
    return users;
  },
  target: setListUsers,
});

sample({
  clock: $listUsers,
  filter: (listUsers) => Boolean(listUsers.length),
  fn: (listUsers) => ({
    filter: { uuid: listUsers },
  }),
  target: accountListForFileFX,
});

export const onSetOpenContent = createEvent<string>();
export const onResetOpenContent = createEvent();
export const $openContent = createStore<string | null>(null)
  .on(onSetOpenContent, (_, payload) => payload)
  .reset([onResetOpenContent, resetOpenTaskOverview]);

sample({
  clock: resetOpenTaskOverview,
  target: [resetListUsers, resetAccountListForFile],
});

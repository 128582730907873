import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import cn from 'classnames';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import css from './styles.module.scss';

type SearchProps = {
  onClose?: () => void;
} & TextFieldProps;
const Search: FC<SearchProps> = ({ onClose: closeEvent, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const refInput = useRef<HTMLDivElement>(null);

  const onClose = () => {
    setIsOpen(false);
    closeEvent?.();
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  useLayoutEffect(() => {
    const input = refInput.current?.getElementsByTagName('input')?.item(0);

    if (isOpen) {
      input?.focus({ preventScroll: true });
    }
    if (!isOpen && input?.value) {
      input.value = '';
    }
  }, [isOpen]);
  return (
    <Box
      className={cn(css.search, {
        isOpen,
      })}
    >
      <Tooltip title="" className={css.icon} onClick={onOpen}>
        <IconButton color="primary">
          <FiSearch />
        </IconButton>
      </Tooltip>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <TextField
          ref={refInput}
          className={css.input}
          hiddenLabel
          variant="outlined"
          {...props}
        />
        <FiX className={css.close} onClick={onClose} size={16} />
      </Box>
    </Box>
  );
};

export default Search;

import React, { useEffect } from 'react';
import { Box, Button, Fade, Typography, useTheme } from '@mui/material';
import { useUnit } from 'effector-react';
import { MenuHeader } from '../../../../../../components/MenuHeader';
import {
  $uuidForDelete,
  markDeleteFX,
  resetDataForUpdate,
  resetUuidForDelete,
} from '../../../../../model/Marks';
import { MenuFixed } from '../../../../../../UI/MenuFixed';

type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  onBack?: () => void;
  onDeleted: () => void;
};
export const MarksDelete = React.forwardRef<HTMLDivElement, Props>(
  ({ open, anchorEl, onClose, onBack, onDeleted }, ref) => {
    const theme = useTheme();
    const uuidForDelete = useUnit($uuidForDelete);
    const pending = useUnit(markDeleteFX.pending);
    useEffect(() => {
      if (!open) {
        resetUuidForDelete();
      }
    }, [open]);

    const reset = () => {
      resetDataForUpdate();
      resetUuidForDelete();
    };
    const onCloseReset = () => {
      reset();
      onClose();
    };
    const onDelete = () => {
      if (!uuidForDelete) return;
      markDeleteFX({ uuid: uuidForDelete }).then(() => {
        onDeleted();
        reset();
      });
    };
    return (
      <MenuFixed
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseReset}
        TransitionComponent={Fade}
      >
        <MenuHeader
          title="Удаление метки"
          onBack={onBack}
          onClose={onCloseReset}
        />

        <Box paddingX={2} paddingTop={2} width={300}>
          <Typography
            component="span"
            variant="body1"
            color={theme.palette.grey[700]}
          >
            Отмена невозможна. Метка будет удалена со всех карточек и убрана из
            истории.
          </Typography>
        </Box>

        <Box paddingX={2} pt={4} justifyContent="space-between" display="flex">
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={onDelete}
            disabled={pending}
          >
            Удалить
          </Button>
        </Box>
      </MenuFixed>
    );
  },
);

import React, { FC, Fragment } from 'react';

export type PropsHighlight = {
  filter: string;
  str: string;
  color: string;
};
export const Highlight: FC<PropsHighlight> = ({ filter, str, color }) => {
  if (!filter) return <span>{str}</span>;
  const regexp = new RegExp(filter, 'ig');
  const matchValue = str.match(regexp);

  if (!matchValue) {
    return <span>{str}</span>;
  }
  return (
    <>
      {str.split(regexp).map((s, index, array) => {
        if (index < array.length - 1) {
          const c = matchValue.shift();
          return (
            <Fragment key={index}>
              {s}
              <span style={{ background: color }}>{c}</span>
            </Fragment>
          );
        }
        return s;
      })}
    </>
  );
};

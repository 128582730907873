import { createEvent, Effect } from 'effector';
import { logOut } from '../JWT/logOut';

export function effectListener<Params, Done, Fail = Error>(
  fx: Effect<Params, Done, Fail>,
  timeout: number,
) {
  let timerId: NodeJS.Timeout;

  function event(params: Params) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      if (document.hidden) {
        timerId = setTimeout(() => {
          event(params);
        }, timeout);
        return;
      }
      fx(params);
    }, timeout);
  }

  fx.finally.watch(({ params }) => {
    event(params);
  });

  const abort = createEvent();
  abort.watch(() => {
    clearTimeout(timerId);
  });

  logOut.watch(() => {
    clearTimeout(timerId);
  });
  return { abort, fx };
}

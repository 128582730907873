import { FC, useCallback } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Typography,
  Toolbar,
  DialogContent,
  TextField,
  DialogActions,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUnit } from 'effector-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  $openModal,
  createBoardForSelWSFx,
  setOpenModal,
} from '../../model/board';
import { $selectWorkspaceData } from '../../model/workspace';

export const CreateBoard: FC = () => {
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Обязательное поле')
        .trim()
        .min(3, 'Минимум 3 знака'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      setOpenModal(false);
      f.resetForm();
      createBoardForSelWSFx(values.title);
    },
  });

  const onClose = useCallback(() => {
    setOpenModal(false);
    formik.resetForm();
  }, []);
  const open = useUnit($openModal);
  const selectWorkspaceData = useUnit($selectWorkspaceData);
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar disableGutters>
          <Typography
            sx={{
              ml: 2,
              flex: 1,
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
            variant="h6"
            component="div"
          >
            Новая доска в рабочем пространстве {selectWorkspaceData?.title}
          </Typography>
          <IconButton
            sx={{ alignSelf: 'center', marginLeft: 'auto' }}
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate mt={1}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="title"
            label="Название"
            type="text"
            fullWidth
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values.title}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.title)}
            helperText={formik.errors.title || ' '}
            inputProps={{ maxLength: 255 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={formik.submitForm}>Создать</Button>
      </DialogActions>
    </Dialog>
  );
};

import { FC, useCallback } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Typography,
  Toolbar,
  DialogContent,
  TextField,
  DialogActions,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUnit } from 'effector-react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  $openModal,
  onColumnCreate,
  setOpenModal,
} from '../../../../model/Columns';

export const AddColumn: FC = () => {
  const { uuid } = useParams();

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Обязательное для заполнения поле')
        .trim()
        .min(3, 'Минимум 3 знака'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values, f) => {
      if (!uuid) {
        toast.error('Нет board Uuid', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }

      setOpenModal(false);
      f.resetForm();
      onColumnCreate({ title: values.title, board: uuid });
    },
  });

  const onClose = useCallback(() => {
    setOpenModal(false);
    formik.resetForm();
  }, []);
  const open = useUnit($openModal);
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Добавить колонку
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate mt={1}>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Название"
            type="text"
            fullWidth
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values.title}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.title)}
            helperText={formik.errors.title || ' '}
            inputProps={{ maxLength: 255 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={formik.submitForm}>Добавить колонку</Button>
      </DialogActions>
    </Dialog>
  );
};

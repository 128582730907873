import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { errorHandlerFx } from '../../../model/errorHandler';
import { createEffectJWT } from '../../../model/JWT';
import {
  columnCreate,
  columnGetList,
  RColumnGetList,
  RColumnGet,
  PColumnUpdate,
  columnSetPosition,
  Item,
  Filter,
} from '../../../apiRPC/column';
import { DataForSort, defaultSort, moveSortArray } from '../../../helper/sort';
import { columnUpdateFX, columnUpdateStatusFX } from '../column';

export const setOpenModal = createEvent<boolean>();
export const $openModal = restore(setOpenModal, false);

export const columnCreateFx = errorHandlerFx(createEffectJWT(columnCreate));
export const onColumnCreate = createEvent<{ title: string; board: string }>();

type PColumnGetListNoStatus = { filter: Omit<Filter, 'status'> };

export const columnGetListFx = errorHandlerFx(
  createEffectJWT((p: PColumnGetListNoStatus) =>
    columnGetList({ filter: { ...p.filter, status: 'ACTIVE' } }),
  ),
);

export const columnGetListFistLoadFx = errorHandlerFx(
  createEffectJWT((p: PColumnGetListNoStatus) =>
    columnGetList({ filter: { ...p.filter, status: 'ACTIVE' } }),
  ),
);
export type DnDData = {
  index: number;
  difference: number;
};
export const setSortColumn = createEvent<DnDData>();
export const updateColumn = createEvent<PColumnUpdate>();
const updateRows = createEvent<RColumnGet[]>();

export const resetColumnList = createEvent();
export const $columnList = createStore<RColumnGetList | null>(null)
  .on(
    [columnGetListFx.doneData, columnGetListFistLoadFx.doneData],
    (_, payload) => payload,
  )
  .on(updateColumn, (state, payload) => {
    if (!state) return undefined;
    const cellIndex = state?.rows?.findIndex(
      (item) => item.uuid === payload.uuid,
    );

    if (cellIndex < 0) return undefined;
    const cell = state.rows[cellIndex];
    state.rows[cellIndex] = { ...cell, ...payload.fields };

    return { total: state.total, rows: [...state.rows] };
  })
  .on(updateRows, (state, payload) => {
    if (!state) return undefined;

    return { total: state.total, rows: payload };
  })
  .reset(resetColumnList);

export const addDefaultColumnFx = createEffect(async (uuid: string) => {
  try {
    const createDefFx = errorHandlerFx(createEffectJWT(columnCreate));
    createDefFx({ title: 'Готово', board: uuid, sort: 502 });
    createDefFx({ title: 'Нужно сделать', board: uuid, sort: 501 });
    createDefFx({ title: 'В процессе', board: uuid, sort: 500 });
  } catch (e) {
    console.error(e);
  }
});

export const columnSetPositionFx = errorHandlerFx(
  createEffectJWT(columnSetPosition),
);

sample({
  clock: setSortColumn,
  source: $columnList,
  filter: (state) => Boolean(state?.rows?.length),
  fn: (state, payload) =>
    moveSortArray({
      array: state?.rows || [],
      difference: payload.difference,
      index: payload.index,
    }),
  target: createEffect(
    ([sorted, dataForSort]: [RColumnGet[], DataForSort[]]) => {
      updateRows(sorted);
      const items: Item[] = dataForSort.map((item) => ({
        uuid: item.uuid,
        sort: item.fields.sort,
      }));
      return columnSetPositionFx({ items });
    },
  ),
});

sample({
  clock: [columnCreateFx.done],
  fn: ({ params }) => ({
    filter: { board: params.board },
  }),
  target: columnGetListFx,
});

sample({
  clock: [columnUpdateFX.done, columnUpdateStatusFX.done],
  source: $columnList,
  filter: (state) => Boolean(state),
  fn: (state) => ({
    filter: { board: state?.rows[0].board || '' },
  }),
  target: columnGetListFx,
});

export const sortColumnList = createEvent<{
  currentIndex: number;
  targetIndex: number;
}>();
export const $columnListSort = $columnList.map<RColumnGet[]>((item) => {
  if (!item?.rows) return [];

  return item?.rows.sort(defaultSort);
});

sample({
  clock: onColumnCreate,
  source: $columnListSort,
  fn: (columnListSort, payload) => {
    const sort = columnListSort.at(-1)?.sort || 500;
    return { ...payload, sort };
  },
  target: columnCreateFx,
});

import { combine, createEvent, createStore } from 'effector';
import { Account } from '../../../apiRPC/board';
import { RMarkGet, RMarkGetList } from '../../../apiRPC/card/mark';

export const onHandleEmptyMembers = createEvent();
export const resetEmptyMembers = createEvent();
export const $isEmptyMembers = createStore(false)
  .on(onHandleEmptyMembers, (state) => !state)
  .reset(resetEmptyMembers);

export const onHandleSelfMember = createEvent();
export const resetSelfMember = createEvent();
export const $isSelfMember = createStore(false)
  .on(onHandleSelfMember, (state) => !state)
  .reset(resetSelfMember);

export const onSetMembers = createEvent<Account[]>();
export const resetListMembers = createEvent();
export const $listMembers = createStore<Account[]>([])
  .on(onSetMembers, (_, payload) => payload)
  .reset(resetListMembers);

export const onSetExcludeMembers = createEvent<Account[]>();
export const resetListExcludeMembers = createEvent();
export const $listExcludeMembers = createStore<Account[]>([])
  .on(onSetExcludeMembers, (_, payload) => payload)
  .reset(resetListExcludeMembers);

export const onHandleEmptyMarks = createEvent();
export const resetEmptyMarks = createEvent();
export const $isEmptyMarks = createStore(false)
  .on(onHandleEmptyMarks, (state) => !state)
  .reset(resetEmptyMarks);

export const onSetMarks = createEvent<RMarkGetList>();
export const onHandleMark = createEvent<RMarkGet>();
export const resetListMarks = createEvent();
export const $listMarks = createStore<RMarkGetList>([])
  .on(onSetMarks, (_, payload) => payload)
  .on(onHandleMark, (state, payload) => {
    const clone = [...state];
    if (clone.find((item) => item.uuid === payload.uuid)) {
      return clone.filter((item) => item.uuid !== payload.uuid);
    }
    clone.push(payload);
    return clone;
  })
  .reset(resetListMarks);

export const onSetExcludeMarks = createEvent<RMarkGetList>();
export const resetListExcludeMarks = createEvent();
export const $listExcludeMarks = createStore<RMarkGetList>([])
  .on(onSetExcludeMarks, (_, payload) => payload)
  .reset(resetListExcludeMarks);

export type MatchFilter = 'ANY' | 'FULL';

export const onSetMatchFilter = createEvent<MatchFilter>();
export const resetMatchFilter = createEvent();
export const $matchFilter = createStore<MatchFilter>('ANY')
  .on(onSetMatchFilter, (_, payload) => payload)
  .reset(resetMatchFilter);

export const $isFilter = combine(
  {
    isEmptyMembers: $isEmptyMembers,
    isSelfMember: $isSelfMember,
    listMembers: $listMembers,
    isEmptyMarks: $isEmptyMarks,
    listMarks: $listMarks,
    listExcludeMembers: $listExcludeMembers,
    listExcludeMarks: $listExcludeMarks,
  },
  ({
    isEmptyMembers,
    isSelfMember,
    listMembers,
    isEmptyMarks,
    listMarks,
    listExcludeMembers,
    listExcludeMarks,
  }) =>
    isEmptyMembers ||
    isSelfMember ||
    Boolean(listMembers.length) ||
    Boolean(listMarks.length) ||
    isEmptyMarks ||
    Boolean(listExcludeMembers.length) ||
    Boolean(listExcludeMarks.length),
);

export function resetFilters() {
  resetEmptyMembers();
  resetSelfMember();
  resetListMembers();
  resetEmptyMarks();
  resetListMarks();
  resetListExcludeMembers();
  resetListExcludeMarks();
  resetMatchFilter();
}

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import Search from '../../../../../components/Search';
import {
  $searchFilter,
  onResetSearchFilter,
  onSetSearchFilter,
} from '../model/filtres/search';

export const SearchFilter: FC = () => {
  const searchFilter = useUnit($searchFilter);
  return (
    <Search
      placeholder="UUID / Номер договора / Внешний id / ИНН заказчика /  ИНН исполнителя"
      onChange={(e) => onSetSearchFilter(e.target.value)}
      onClose={() => onResetSearchFilter()}
      value={searchFilter}
      inputProps={{ maxLength: 80 }}
    />
  );
};

import React, { FC } from 'react';
import { Box, Button, Fade, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MenuFixed } from '../../../../../../UI/MenuFixed';
import { MenuHeader } from '../../../../../../components/MenuHeader';

type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  pending: boolean;
  onDelete: () => void;
};
export const MenuDelete: FC<Props> = ({
  open,
  anchorEl,
  onClose,
  pending,
  onDelete,
}) => (
  <MenuFixed
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    TransitionComponent={Fade}
  >
    <MenuHeader title="Удаление комментария" onClose={onClose} />

    <Box paddingX={2} paddingTop={2}>
      <Typography component="span" variant="body1" color={grey[700]}>
        Комментарий удаляется навсегда. Отмена невозможна.
      </Typography>
    </Box>

    <Box paddingX={2} pt={4} justifyContent="space-between" display="flex">
      <Button
        fullWidth
        variant="contained"
        color="error"
        onClick={onDelete}
        disabled={pending}
      >
        Удалить комментарий
      </Button>
    </Box>
  </MenuFixed>
);

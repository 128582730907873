import React, { FC, useTransition } from 'react';
import { useUnit } from 'effector-react';
import {
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { $accountsSort } from '../../../GRID/model/card/accounts';
import {
  $listExcludeMembers,
  onSetExcludeMembers,
  resetListExcludeMembers,
} from './model';

export const ExcludeMembers: FC = () => {
  const accounts = useUnit($accountsSort);
  const listExcludeMembers = useUnit($listExcludeMembers);

  const [isPendingListMembers, startTransitionListMembers] = useTransition();

  const isAllChecked = listExcludeMembers.length === accounts.length;
  const indeterminate = Boolean(listExcludeMembers.length) && !isAllChecked;

  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: 'normal' }}>
          Исключить участников
        </ListSubheader>
      }
      disablePadding
    >
      <ListItem role={undefined} dense sx={{ py: 0, height: 32, mt: 2 }}>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={isAllChecked}
          indeterminate={indeterminate}
          onChange={() => {
            if (isPendingListMembers) return;
            if (isAllChecked || indeterminate) {
              startTransitionListMembers(resetListExcludeMembers);
              return;
            }
            startTransitionListMembers(() => {
              onSetExcludeMembers(accounts);
            });
          }}
        />

        <FormControl fullWidth>
          <Autocomplete
            options={accounts}
            getOptionLabel={(v) => v.email}
            multiple
            size="small"
            disableCloseOnSelect
            fullWidth
            onChange={(_, value) => {
              if (isPendingListMembers) return;
              startTransitionListMembers(() => {
                onSetExcludeMembers(value);
              });
            }}
            openOnFocus
            value={listExcludeMembers}
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            renderOption={(props, option, { selected }) => (
              <MenuItem
                {...props}
                key={option.uuid}
                value={option.uuid}
                sx={{
                  pl: '0px !important',
                  py: 0,
                  height: 32,
                }}
              >
                <Checkbox checked={selected} />
                <Tooltip
                  title={option.email}
                  placement="bottom"
                  disableInteractive
                >
                  <ListItemText
                    sx={{
                      span: {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                    primary={option.email}
                    id={option.uuid}
                  />
                </Tooltip>
              </MenuItem>
            )}
            renderTags={(value) => (
              <Typography variant="body2">Выбрано {value.length}</Typography>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Выбор участников" />
            )}
            noOptionsText="Не найдено"
          />
        </FormControl>
      </ListItem>
    </List>
  );
};

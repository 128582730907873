import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import { campaignCreate } from '../../../../../../apiRPC/сampaign';
import { fabricContracts } from './fabricContracts';

export const campaignCreateFX = errorHandlerFx(createEffectJWT(campaignCreate));

export const {
  contractGetListFX,
  contractGetListForScrollFX,
  onGetContracts,
  $contractList,
  searchContract,
  onLoadContracts,
  resetCatalogs,
} = fabricContracts();
export const {
  contractGetListFX: iContractGetListFX,
  contractGetListForScrollFX: iContractGetListForScrollFX,
  onGetContracts: onGetIContracts,
  $contractList: $iContractList,
  searchContract: searchIContract,
  onLoadContracts: onLoadIContracts,
  resetCatalogs: resetICatalogs,
} = fabricContracts({ isInvoiceContract: true });

import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import { Avatar, Tooltip } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const PopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -15],
      },
    },
  ],
};

type PropsItem = {
  open: boolean;
  text: string;
  Icon?: React.ReactNode;
  to: string;
  // uuid: string;
};
export const MenuItem: FC<PropsItem> = ({ open, text, Icon, to }) => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        selected={to === decodeURI(location.pathname)}
        component={RouterLink}
        to={to}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <Tooltip
            title={text}
            disableHoverListener={open}
            PopperProps={PopperProps}
            disableInteractive
          >
            <div>
              {!Icon && (
                <Avatar
                  variant="square"
                  alt={text}
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: theme.typography.fontSize,
                    background: '#1976d2',
                  }}
                >
                  {text.split(' ')?.[0]?.[0]?.toUpperCase()}
                  {text.split(' ')?.[1]?.[0]?.toUpperCase()}
                </Avatar>
              )}
              {Icon}
            </div>
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{ noWrap: true }}
          sx={{
            opacity: open ? 1 : 0,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

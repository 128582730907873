import { FC } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import Loading from '../../../UI/Loading';

export const Loadding: FC = () => (
  <TableContainer
    component={Paper}
    sx={{
      flex: '1',
      marginBottom: '20px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box height={40} width={40} display="flex" alignItems="center">
      <Loading />
    </Box>
  </TableContainer>
);

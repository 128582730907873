import { createEvent, createStore, sample } from 'effector';
import { debounce } from 'patronum/debounce';
import axios, { Canceler, CancelToken } from 'axios';
import {
  contractGetList,
  Filter,
  PContractGetList,
  RContractGetList,
  Status,
} from '../../../../../../apiRPC/contracts';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';

export function fabricContracts(AdditionFilter?: Filter) {
  const limitContracts = 30;

  type FasP = {
    params?: PContractGetList;
    cancelToken?: CancelToken;
  };
  const contractGetListCancel = (p?: FasP) =>
    contractGetList(p?.params, p?.cancelToken);

  const contractGetListFX = errorHandlerFx(
    createEffectJWT(contractGetListCancel),
  );
  const contractGetListForScrollFX = errorHandlerFx(
    createEffectJWT(contractGetList),
  );

  const onGetContracts = createEvent();
  const onResetContractList = createEvent();
  const $contractList = createStore<RContractGetList['rows']>([])
    .on(contractGetListFX.doneData, (_, payload) => payload.rows)
    .on(contractGetListForScrollFX.doneData, (state, payload) => [
      ...state,
      ...payload.rows,
    ])
    .reset(onResetContractList);

  const $totalRows = createStore<number>(0)
    .on(contractGetListFX.doneData, (_, payload) => payload.total)
    .on(contractGetListForScrollFX.doneData, (_, payload) => payload.total);

  const $lastFilter = createStore<PContractGetList | null>(null)
    .on(contractGetListFX.done, (_, { params }) => params?.params ?? null)
    .reset(onResetContractList);

  sample({
    clock: onGetContracts,
    source: {
      lastFilter: $lastFilter,
    },
    fn: ({ lastFilter }) => {
      const oldFilter = { ...(lastFilter?.filter || {}) };
      const status: Status = 'ACTIVE';
      const filter = {
        ...oldFilter,
        status,
        limit: limitContracts,
        offset: 0,
        ...AdditionFilter,
      };
      return {
        params: { filter },
      };
    },
    target: contractGetListFX,
  });

  const searchContract = createEvent<string>();

  const cancelTokenSearchFilter: Canceler[] = [];
  sample({
    clock: debounce({ source: searchContract, timeout: 300 }),
    source: {
      lastFilter: $lastFilter,
    },
    fn: ({ lastFilter }, payload) => {
      cancelTokenSearchFilter.forEach((item) => item());
      const source = axios.CancelToken.source();
      cancelTokenSearchFilter.push(source.cancel);
      const oldFilter = { ...(lastFilter?.filter || {}) };
      const filter = {
        ...oldFilter,
        search: payload,
        limit: limitContracts,
        offset: 0,
        ...AdditionFilter,
      };

      return {
        params: { filter },
        cancelToken: source.token,
      };
    },
    target: contractGetListFX,
  });

  const onLoadContracts = createEvent();
  sample({
    clock: onLoadContracts,
    source: {
      lastFilter: $lastFilter,
      contractList: $contractList,
      totalRows: $totalRows,
    },
    filter: ({ contractList, totalRows }) =>
      Boolean(contractList.length) && totalRows > contractList.length,
    fn: ({ contractList, lastFilter }) => ({
      filter: {
        ...(lastFilter?.filter || {}),
        limit: limitContracts,
        offset: contractList.length,
        ...AdditionFilter,
      },
    }),
    target: contractGetListForScrollFX,
  });

  function resetCatalogs(): void {
    onResetContractList();
  }

  return {
    contractGetListFX,
    contractGetListForScrollFX,
    onGetContracts,
    $contractList,
    searchContract,
    onLoadContracts,
    resetCatalogs,
  };
}

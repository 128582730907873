import {
  ComponentType,
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useTransition,
} from 'react';
import * as React from 'react';
import { Box, Button, Divider, List, ListItem, TextField } from '@mui/material';
import { useUnit } from 'effector-react';

import {
  ComputeItemKey,
  ItemContent,
  ListProps,
  Virtuoso,
} from 'react-virtuoso';
import { setMenuView } from '../model';
import { TitleItem } from '../TitleItem';
import {
  cardGetListArhFX,
  cardListArhFilter$,
  onListArhFilter,
  onStartListening,
  reset,
} from './model';
import { Task } from '../../../pages/desk/Card/Task';
import { RCardGet } from '../../../../apiRPC/card';
import { $board } from '../../../model/board';
import { updateStatusFX } from '../../../model/card/update/status';
import Loading from '../../../../UI/Loading';

const ListVirtuoso: ComponentType<ListProps> = React.forwardRef(
  (props, ref) => (
    <Box
      {...props}
      ref={ref}
      gap={1}
      display="flex"
      flexDirection="column"
      paddingX={2}
    />
  ),
);

const components = { List: ListVirtuoso };
const Item: ItemContent<RCardGet, null> = (i, item) => (
  <>
    <Task
      key={item.uuid}
      title={item.title}
      createdAt={item.createdAt}
      uuid={item.uuid}
      board={item.board}
      marks={item.marks}
      members={item.members}
      attachmentsCount={item?.attachments?.length || 0}
      dates={item.dates}
      countComments={item.countComments}
      countAttachments={item.countAttachments}
      hideMenu
    />
    <Button
      variant="text"
      size="small"
      sx={{ fontSize: 11, mt: 0.5 }}
      onClick={() =>
        updateStatusFX({ uuid: item.uuid, fields: { status: 'ACTIVE' } })
      }
    >
      Вернуть на доску
    </Button>
  </>
);

const computeItemKey: ComputeItemKey<RCardGet, null> = (_, d) => d.uuid;
export const CardArchival: FC = () => {
  useEffect(() => {
    onStartListening({ filter: {} });
    return reset;
  }, []);
  const cardListArh = useUnit(cardListArhFilter$);
  const pending = useUnit(cardGetListArhFX.pending);
  const board = useUnit($board);

  const [isPendingFilter, startTransition] = useTransition();

  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      onListArhFilter(e.target.value);
    });
  };

  const refTextField = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const input = refTextField.current?.getElementsByTagName('input')?.item(0);
    input?.focus({ preventScroll: true });
    if (input?.value) {
      input.value = '';
    }
  }, [board]);

  return (
    <>
      <TitleItem
        title="Архивные карточки"
        onBack={() => setMenuView('Default')}
      />

      <List>
        <ListItem sx={{ justifyContent: 'space-between' }}>
          <TextField
            size="small"
            label="Поиск в архиве"
            variant="outlined"
            sx={{ alignItems: 'center', width: 195 }}
            onChange={onChangeFilter}
            ref={refTextField}
            // focused
          />
          <Button
            variant="outlined"
            onClick={() => setMenuView('ColumnArchival')}
          >
            К спискам
          </Button>
        </ListItem>
      </List>
      <Divider />
      <Box
        gap={1}
        display="flex"
        mt={1}
        flexDirection="column"
        height="inherit"
      >
        {!pending && (
          <Virtuoso
            data={cardListArh || []}
            computeItemKey={computeItemKey}
            itemContent={Item}
            components={components}
          />
        )}
        {pending && (
          <Box display="flex" alignSelf="center" padding={1}>
            <Loading />
          </Box>
        )}
      </Box>
    </>
  );
};

import React, { FC, useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Badge,
  Box,
  Button,
  createTheme,
  Divider,
  Fade,
  ThemeProvider,
} from '@mui/material';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { themeGrid } from '../../../theme/themeGrid';
import { MenuFixed } from '../../../UI/MenuFixed';
import { MenuHeader } from '../../MenuHeader';

import { Members } from './Members';
import { Marks } from './Marks';
import { $isFilter, resetFilters } from './model';
import { $countCardListsSorted } from '../../../GRID/model/card';
import { ExcludeMembers } from './ExcludeMembers';
import { ExcludeMarks } from './ExcludeMarks';
import { OptionsFilter } from './OptionsFilter';

const theme = createTheme(themeGrid);
export const Filter: FC = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const handleClose = () => {
    setOpenMenu(false);
  };
  const handleOpen = () => {
    setOpenMenu(true);
  };
  const refButton = useRef(null);

  const countCardListsSorted = useUnit($countCardListsSorted);
  const isFilter = useUnit($isFilter);

  const location = useLocation();
  const { uuid } = useParams();
  const oldUid = useRef(uuid);

  useEffect(() => {
    const matchCard = matchPath('c/*', location.pathname);
    if (matchCard) return;
    if (!oldUid.current) return;
    const matchDesk = matchPath(`d/${oldUid.current}/*`, location.pathname);
    if (matchDesk) return;
    resetFilters();
    oldUid.current = uuid;
  }, [location.pathname]);

  return (
    <>
      <Badge
        badgeContent={countCardListsSorted}
        color="primary"
        max={Infinity}
        showZero
        invisible={!isFilter}
      >
        <IconButton
          ref={refButton}
          color="inherit"
          onClick={handleOpen}
          sx={{ backgroundColor: '#42a5f5' }}
        >
          <FilterListIcon />
        </IconButton>
      </Badge>
      <ThemeProvider theme={theme}>
        <MenuFixed
          anchorEl={refButton.current}
          open={isOpenMenu}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuHeader title="Фильтр" onClose={handleClose} />
          <Members />
          <ExcludeMembers />
          <Divider />
          <Marks />
          <ExcludeMarks />
          <Divider />
          <OptionsFilter />
          <Divider />
          <Box paddingX={2} paddingY={2}>
            <Button fullWidth variant="outlined" onClick={resetFilters}>
              Очистить фильтр
            </Button>
          </Box>
        </MenuFixed>
      </ThemeProvider>
    </>
  );
};

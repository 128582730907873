import { FC } from 'react';
import { Box } from '@mui/material';

type Props = {
  children?: React.ReactNode;
};
export const Container: FC<Props> = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    flex="1"
    justifyContent="center"
    alignItems="center"
    maxWidth={482}
    alignSelf="center"
    textAlign="center"
  >
    {children}
  </Box>
);

import { attach, createEvent, createStore, restore, sample } from 'effector';
import { createEffectJWT } from '../../../model/JWT';
import {
  Board,
  createBoard,
  getBoard,
  getListBoard,
  PGetListBoard,
  RCreate,
  RGetListBoard,
  updateBoard,
} from '../../../apiRPC/board';
import { errorHandlerFx } from '../../../model/errorHandler';
import { addDefaultColumnFx } from '../Columns';
import { $selectWorkspace, onSelectWorkspace } from '../workspace';

export const setOpenModal = createEvent<boolean>();
export const $openModal = restore(setOpenModal, false);

export const createBoardFx = errorHandlerFx(createEffectJWT(createBoard));

export const createBoardForSelWSFx = attach({
  effect: createBoardFx,
  source: $selectWorkspace,
  mapParams: (title: string, selectWorkspace) => ({
    title,
    workspace: selectWorkspace,
  }),
});

export const resetCreateBoard = createEvent();
export const $createBoard = createStore<RCreate | null>(null)
  .on(createBoardFx.doneData, (_, payload) => payload)
  .reset(resetCreateBoard);

export const updateBoardFx = errorHandlerFx(createEffectJWT(updateBoard));

export const getBoardFx = errorHandlerFx(createEffectJWT(getBoard));
export const resetBoard = createEvent();
export const $board = createStore<Board | null>(null)
  .on(getBoardFx.doneData, (_, payload) => payload)
  .reset(resetBoard);

export const updateBoardFX = errorHandlerFx(createEffectJWT(updateBoard));

export const getListBoardFx = errorHandlerFx(createEffectJWT(getListBoard));
export const resetListBoard = createEvent();

export const listBoard$ = createStore<RGetListBoard | null>(null)
  .on(getListBoardFx.doneData, (state, payload) => payload)
  .on(updateBoardFX, (state, payload) => {
    if (!state) return undefined;
    const updateIndex = state.rows.findIndex(
      (item) => item.uuid === payload.uuid,
    );
    if (updateIndex < 0) return undefined;

    let updateItem = state.rows[updateIndex];
    updateItem = { ...updateItem, ...payload.fields };
    state.rows[updateIndex] = updateItem;
    return { rows: [...state.rows], total: state.total };
  })
  .reset(resetListBoard);

export const getListBoardForPageFx = errorHandlerFx(
  createEffectJWT(getListBoard),
);
export const resetListBoardForPage = createEvent();
export const $ListBoardForPage = createStore<RGetListBoard | null>(null)
  .on(getListBoardForPageFx.doneData, (state, payload) => payload)
  .reset(resetListBoardForPage);

export const $ListBoardForPageLastParams = createStore<PGetListBoard | null>(
  null,
)
  .on(getListBoardForPageFx.done, (_, { params }) => params)
  .reset(resetListBoardForPage);

sample({
  clock: updateBoardFX.done,
  fn: ({ params }) => ({ uuid: params.uuid }),
  target: getBoardFx,
});

sample({
  clock: [createBoardFx.doneData, updateBoardFX.finally],
  source: $selectWorkspace,
  fn: (selectWorkspace) => ({
    filter: { limit: 1000, offset: 0, workspace: selectWorkspace },
  }),
  target: getListBoardFx,
});
sample({
  clock: createBoardFx.done,
  source: $ListBoardForPageLastParams,
  filter: (state, { params }) => state?.filter.workspace === params.workspace,
  fn: (_, { params }) => ({
    filter: { limit: 1000, offset: 0, workspace: params.workspace },
  }),
  target: getListBoardForPageFx,
});
sample({
  clock: createBoardFx.doneData,
  fn: (d) => d.uuid,
  target: addDefaultColumnFx,
});

sample({
  clock: $board,
  filter: (board) => Boolean(board?.workspace),
  fn: (board) => board!.workspace,
  target: onSelectWorkspace,
});

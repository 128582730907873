import { variant } from '@effector/reflect';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import * as React from 'react';
import { Empty } from '../../../components/Errors/Empty';
import { NoConnect } from '../../../components/Errors/NoConnect/NoConnect';
import { DrawerHeader } from '../../../../components/DrawerHeader';
import CreativesHeader from './CreativesHeader';

import { Loadding } from '../../../components/Loadding';

import { BasicTable } from './Table';
import {
  $fieldTypeCreative,
  onGetListCreative,
  resetCatalogsCreative,
} from './model';
import { onOrdGetList } from '../../../model/ord';
import { resetFiltres } from './model/filters';

export const ComponentTable = variant({
  source: $fieldTypeCreative,
  cases: {
    Success: BasicTable,
    loading: Loadding,
    ErrorEmpty: Empty,
    ErrorNoContent: NoConnect,
  },
  default: Loadding,
  hooks: {
    mounted: () => {
      onGetListCreative();
      onOrdGetList();
    },
    unmounted: () => {
      resetCatalogsCreative();
      resetFiltres();
    },
  },
});

const Creatives = () => (
  <Box sx={{ width: '100%', minWidth: 'auto' }}>
    <DrawerHeader />
    <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="calc(100vh - 64px)"
      >
        <CreativesHeader />
        <ComponentTable />
      </Box>
    </Container>
  </Box>
);

export default Creatives;

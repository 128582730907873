import { Effect } from 'effector';
import { toast } from 'react-toastify';
import {
  ErrorProps,
  isErrorProps,
  isUnknownObject,
} from '../../apiRPC/request';
import { errorsMessage } from '../../apiRPC/errors';

type CounterMessage = {
  _messages: string[];
  hasMessage: (message: string) => boolean;
  addMessage: (message: string) => void;
  delMessage: (message: string) => void;
};
const counterMessage: CounterMessage = {
  _messages: [],
  hasMessage(this: CounterMessage, message: string) {
    return this._messages.includes(message);
  },
  addMessage(this: CounterMessage, message: string) {
    this._messages.push(message);
  },
  delMessage(this: CounterMessage, message: string) {
    this._messages = this._messages.filter((item) => item !== message);
  },
};

type ExceptionErrors = number[];

export function hasErrorCode(p: unknown): p is { code: number | string } {
  if (
    isUnknownObject(p) &&
    (typeof p?.code === 'string' || typeof p?.code === 'number')
  ) {
    return true;
  }
  return false;
}

export function errorHandlerFx<Params, Done, Fail = Error>(
  fx: Effect<Params, Done, Fail>,
  exceptionErrors?: ExceptionErrors,
) {
  fx.failData.watch((payload) => {
    let text = 'Не известная ошибка';
    if (hasErrorCode(payload) && payload.code === 'ERR_CANCELED') return;
    if (hasErrorCode(payload) && payload.code === 632) return;
    if (hasErrorCode(payload) && exceptionErrors?.includes(+payload.code))
      return;

    if (isErrorProps(payload)) {
      const code = payload?.code;
      const message = code ? errorsMessage[code] : undefined;
      text = ` ${message || payload.message} \r\n ${
        payload?.data?.debugCode ? `debugCode:${payload?.data?.debugCode}` : ''
      } `;
    }

    if (counterMessage.hasMessage(text)) return;
    counterMessage.addMessage(text);

    toast.error(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      onClose: () => counterMessage.delMessage(text),
    });
  });

  return fx;
}

import { RCardGet } from '../../../../apiRPC/card';

export type FilterData = {
  card: RCardGet;
  selfUid: string | null | undefined;
  isEmptyMembers: boolean;
  isSelfMember: boolean;
  isEmptyMarks: boolean;
  listMembersUuids: Set<string>;
  listMarksUuids: Set<string>;
  listExcludeMembersUuids: Set<string>;
  listExcludeMarksUuids: Set<string>;
};

export function isAnyFilter({
  card,
  selfUid,
  isEmptyMembers,
  isSelfMember,
  isEmptyMarks,
  listMembersUuids,
  listMarksUuids,
  listExcludeMembersUuids,
  listExcludeMarksUuids,
}: FilterData): boolean {
  if (
    listExcludeMembersUuids.size &&
    Boolean(card.members.find((item) => listExcludeMembersUuids.has(item.uuid)))
  ) {
    return false;
  }

  if (
    listExcludeMarksUuids.size &&
    Boolean(card.marks.find((item) => listExcludeMarksUuids.has(item.uuid)))
  ) {
    return false;
  }

  const isCardSelfMember = isSelfMember
    ? Boolean(card.members.find((item) => item.uuid === selfUid))
    : false;

  if (isSelfMember && isCardSelfMember) {
    return true;
  }

  if (isEmptyMembers && !card.members.length) {
    return true;
  }

  const cardHasMembersUuid = listMembersUuids.size
    ? Boolean(card.members.find((item) => listMembersUuids.has(item.uuid)))
    : false;

  if (cardHasMembersUuid) {
    return true;
  }

  if (isEmptyMarks && !card.marks.length) {
    return true;
  }

  if (
    listMarksUuids.size &&
    card.marks.find((item) => listMarksUuids.has(item.uuid))
  ) {
    return true;
  }

  const noAnyFilter =
    !isEmptyMembers &&
    !isSelfMember &&
    !listMembersUuids.size &&
    !isEmptyMarks &&
    !listMarksUuids.size;

  if (listExcludeMembersUuids.size && noAnyFilter) {
    return true;
  }

  if (listExcludeMarksUuids.size && noAnyFilter) {
    return true;
  }

  if (noAnyFilter) return true;
  return false;
}

export function isFullFilter({
  card,
  selfUid,
  isEmptyMembers,
  isSelfMember,
  isEmptyMarks,
  listMembersUuids,
  listMarksUuids,
  listExcludeMembersUuids,
  listExcludeMarksUuids,
}: FilterData): boolean {
  if (
    listExcludeMembersUuids.size &&
    Boolean(card.members.find((item) => listExcludeMembersUuids.has(item.uuid)))
  ) {
    return false;
  }

  if (
    listExcludeMarksUuids.size &&
    Boolean(card.marks.find((item) => listExcludeMarksUuids.has(item.uuid)))
  ) {
    return false;
  }

  if (isEmptyMembers && card.members.length) {
    return false;
  }

  const isCardSelfMember = isSelfMember
    ? Boolean(card.members.find((item) => item.uuid === selfUid))
    : false;

  if (isSelfMember && !isCardSelfMember) {
    return false;
  }

  let sizeMembers = 0;
  for (const value of card.members) {
    if (listMembersUuids.has(value.uuid)) sizeMembers += 1;
    if (sizeMembers >= listMembersUuids.size) break;
  }

  const cardHasMembersUuid = sizeMembers === listMembersUuids.size;

  if (listMembersUuids.size && !cardHasMembersUuid) {
    return false;
  }

  if (isEmptyMarks && card.marks.length) {
    return false;
  }

  let sizeMarks = 0;
  for (const value of card.marks) {
    if (listMarksUuids.has(value.uuid)) sizeMarks += 1;
    if (sizeMarks >= listMarksUuids.size) break;
  }

  const cardHasMarksUuid = sizeMarks === listMarksUuids.size;

  if (listMarksUuids.size && !cardHasMarksUuid) {
    return false;
  }

  return true;
}

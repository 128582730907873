export function workerToPromise<SendData, ResponseData>(
  worker: Worker,
  data: SendData,
): Promise<ResponseData> {
  return new Promise((resolve, reject) => {
    worker.postMessage(data);

    // eslint-disable-next-line no-param-reassign
    worker.onerror = (error) => {
      reject(error);
    };
    // eslint-disable-next-line no-param-reassign
    worker.onmessage = (e: MessageEvent<ResponseData>) => {
      resolve(e.data);
    };
  });
}

import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { CancelToken } from 'axios';
import { errorHandlerFx } from '../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../model/JWT';
import {
  PCampaignGetList,
  RCampaignGetList,
  campaignGetList,
  Status,
  CampaignItem,
  campaignSetStatus,
} from '../../../../../apiRPC/сampaign';

type FasP = {
  params: PCampaignGetList;
  cancelToken?: CancelToken;
};
const campaignGetListCancel = (p: FasP) =>
  campaignGetList(p.params, p.cancelToken);

export const campaignGetListFX = errorHandlerFx(
  createEffectJWT(campaignGetListCancel),
);
export const campaignGetListForScrollFX = errorHandlerFx(
  createEffectJWT(campaignGetList),
);

export const onLoadCampaign = createEvent();

export const limitCampaign = 30;
export const onResetCampaignList = createEvent();

const delUid = createEvent<string>();
const updateCampaign = createEvent<{
  uuid: string;
  data: Partial<CampaignItem>;
}>();
export const $campaignList = createStore<RCampaignGetList['rows']>([])
  .on(campaignGetListFX.doneData, (_, payload) => payload.rows)
  .on(campaignGetListForScrollFX.doneData, (state, payload) => [
    ...state,
    ...payload.rows,
  ])
  .on(delUid, (state, payload) => {
    const index = state.findIndex((item) => item.uuid === payload);
    if (index === -1) return undefined;
    state.splice(index, 1);
    return [...state];
  })
  .on(updateCampaign, (state, payload) => {
    const index = state.findIndex((item) => item.uuid === payload.uuid);
    if (index === -1) return undefined;
    state[index] = {
      ...state[index],
      ...payload.data,
    };
    return [...state];
  })
  .reset(onResetCampaignList);

const $totalRows = createStore<number>(0).on(
  [campaignGetListFX.doneData, campaignGetListForScrollFX.doneData],
  (_, payload) => payload.total,
);

export const $lastFilterCampaign = createStore<PCampaignGetList | null>(null)
  .on(campaignGetListFX.done, (_, { params }) => params.params)
  .reset(onResetCampaignList);

export const onRestFieldTypeCampaign = createEvent();
export const $fieldTypeCampaign = createStore<
  'loading' | 'ErrorEmpty' | 'ErrorNoContent' | 'Success'
>('loading')
  .on(campaignGetListFX.fail, () => 'ErrorNoContent')
  .on(campaignGetListFX.pending, (_, payload) =>
    payload ? 'loading' : undefined,
  )
  .on(campaignGetListFX.doneData, (_, payload) =>
    payload?.rows.length ? 'Success' : 'ErrorEmpty',
  )
  .reset(onRestFieldTypeCampaign);

sample({
  clock: onLoadCampaign,
  source: {
    lastFilterCampaign: $lastFilterCampaign,
    campaignList: $campaignList,
    totalRows: $totalRows,
  },
  filter: ({ campaignList, totalRows }) =>
    Boolean(campaignList.length) && totalRows > campaignList.length,
  fn: ({ campaignList, lastFilterCampaign }) => ({
    filter: {
      ...(lastFilterCampaign?.filter || {}),
      limit: limitCampaign,
      offset: campaignList.length,
    },
  }),
  target: campaignGetListForScrollFX,
});

export type UpdateCampaignStatus = {
  uuid: string;
  status: Status;
  name: string;
};
export const updateCampaignStatus = createEvent<UpdateCampaignStatus>();
export const resetCampaignStatus = createEvent();
export const $campaignStatus = restore(updateCampaignStatus, null).reset(
  resetCampaignStatus,
);
export const onSendCampaignStatus = createEvent();

export const campaignSetStatusFX = errorHandlerFx(
  createEffectJWT(campaignSetStatus),
);

sample({
  source: {
    lastFilterCampaign: $lastFilterCampaign,
    campaignStatus: $campaignStatus,
  },
  clock: onSendCampaignStatus,
  fn: ({ lastFilterCampaign, campaignStatus }) => ({
    lastFilterCampaign,
    campaignStatus,
  }),
  target: createEffect(
    async ({
      lastFilterCampaign,
      campaignStatus,
    }: {
      lastFilterCampaign: PCampaignGetList | null;
      campaignStatus: UpdateCampaignStatus | null;
    }) => {
      if (!campaignStatus) return;
      if (!lastFilterCampaign?.filter.status) {
        updateCampaign({
          uuid: campaignStatus.uuid,
          data: { status: campaignStatus.status },
        });
        await campaignSetStatusFX(campaignStatus);
        resetCampaignStatus();
        return;
      }
      if (lastFilterCampaign?.filter.status !== campaignStatus.status) {
        delUid(campaignStatus.uuid);
        await campaignSetStatusFX(campaignStatus);
        resetCampaignStatus();
        return;
      }

      updateCampaign({
        uuid: campaignStatus.uuid,
        data: { status: campaignStatus.status },
      });
      await campaignSetStatusFX(campaignStatus);
      resetCampaignStatus();
    },
  ),
});
export function restCampaign() {
  onRestFieldTypeCampaign();
  onResetCampaignList();
  resetCampaignStatus();
}

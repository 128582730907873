import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

interface Props<T>
  extends Omit<
    AutocompleteProps<T, undefined, undefined, undefined>,
    'renderInput'
  > {
  value?: T;
  error?: boolean;
  helperText?: string;
  onScrollEnd?: () => void;
  search: (v: string) => void;
  label: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  variant?: BaseTextFieldProps['variant'];
}

const styleInput = { '.MuiInputLabel-root': { zIndex: 0 } };
export function ContractAutocomplete<T>({
  error,
  helperText,
  onScrollEnd,
  search,
  label,
  placeholder,
  required,
  variant,
  ...autocompleteProps
}: Props<T>) {
  return (
    <Autocomplete
      {...autocompleteProps}
      ListboxProps={{
        role: 'list-box',
        onScroll: ({ currentTarget }) => {
          const scrollPosition =
            currentTarget.scrollTop + currentTarget.clientHeight;
          if (currentTarget.scrollHeight - scrollPosition <= 1) {
            onScrollEnd?.();
          }
        },
      }}
      filterOptions={(v) => v}
      noOptionsText="Не найдено"
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          placeholder={placeholder}
          variant={variant || 'standard'}
          onChange={(e) => search(e.target.value)}
          error={error}
          helperText={helperText}
          sx={styleInput}
          InputLabelProps={{
            children: label,
          }}
        />
      )}
    />
  );
}

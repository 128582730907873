import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';

type props = {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
  onConfirm: () => void;
  title: string;
  outlinedText: string;
  containedText: string;
  disabledContained?: boolean;
  disabledOutlined?: boolean;
};

const Modal: FC<props> = ({
  children,
  open,
  handleClose,
  onConfirm,
  title,
  outlinedText,
  containedText,
  disabledContained,
  disabledOutlined,
}) => {
  const theme = useTheme();

  const [titleCash, setTitleCash] = useState(title);
  const [outlinedTextCash, setOutlinedTextCash] = useState(outlinedText);
  const [containedTextCash, setContainedTextCash] = useState(containedText);
  const [childrenCash, setChildrenCash] = useState(children);

  useEffect(() => {
    if (!containedText) return;
    setContainedTextCash(containedText);
  }, [containedText]);

  useEffect(() => {
    if (!outlinedText) return;
    setOutlinedTextCash(outlinedText);
  }, [outlinedText]);

  useEffect(() => {
    if (!title) return;
    setTitleCash(title);
  }, [title]);
  useEffect(() => {
    if (!children) return;
    setChildrenCash(children);
  }, [children]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle>{titleCash}</DialogTitle>
      <DialogContent>{childrenCash}</DialogContent>
      <DialogActions>
        <Button
          disabled={disabledOutlined}
          variant="outlined"
          onClick={handleClose}
        >
          {outlinedTextCash}
        </Button>
        <Button
          disabled={disabledContained}
          variant="contained"
          onClick={onConfirm}
        >
          {containedTextCash}
        </Button>
      </DialogActions>
      <Box className="MuiDialogClose" onClick={handleClose}>
        <FiX color={theme.palette.primary.main} size={24} />
      </Box>
    </Dialog>
  );
};

export default Modal;

export function getIndexNEntry(
  str: string,
  searchValue: string,
  entry: number,
): number {
  let indexNEntry = -1;
  let lastIndex = 0;

  while (entry >= lastIndex) {
    indexNEntry = str.indexOf(searchValue, indexNEntry + 1);
    if (indexNEntry === -1) break;
    lastIndex += 1;
  }

  return indexNEntry;
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Fade,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@mui/material';
import { MenuFixed } from '../../../../../../UI/MenuFixed';

import { MenuHeader } from '../../../../../../components/MenuHeader';
import styles from '../Attachments/styles.module.scss';
import { useFileUpload } from '../../../../../../helper/useFileUpload/useFileUpload';

export type DataLink = {
  url: string;
  urlName?: string;
};
type Props = {
  open: boolean;
  anchorEl?: Element | null;
  onClose: () => void;
  onSubmit: (d: DataLink) => void;
  cardUuid: string;
  commentUid: string;
};
export const LinkMenu = React.forwardRef<HTMLDivElement, Props>(
  ({ open, anchorEl, onClose, onSubmit, cardUuid, commentUid }, ref) => {
    const [url, setUrl] = useState('');
    const [urlName, setUrlName] = useState('');

    useEffect(() => {
      if (!open) {
        setUrl('');
        setUrlName('');
      }
    }, [open]);

    const onSendUrl = () => {
      onSubmit({ url, urlName });
    };

    const { setFiles } = useFileUpload();

    return (
      <MenuFixed
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
        ref={ref}
      >
        <MenuHeader title="Прикрепить…" onClose={onClose} />
        <MenuList disabledItemsFocusable disableListWrap>
          <MenuItem component="label">
            <Typography variant="body2">С компьютера</Typography>
            <input
              type="file"
              multiple
              className={styles.hideInput}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFiles({ cardId: cardUuid, commentUid })(e);
                onClose();
              }}
            />
          </MenuItem>

          <Box paddingX={2} paddingY={1}>
            <Typography variant="body2" fontWeight={600}>
              Прикрепить ссылку{' '}
            </Typography>

            <TextField
              margin="dense"
              hiddenLabel
              variant="filled"
              size="small"
              placeholder="Вставте сюда любую ссылку"
              fullWidth
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />

            {url && (
              <TextField
                margin="dense"
                hiddenLabel
                variant="filled"
                size="small"
                placeholder="Название ссылки (необязательно)"
                inputProps={{ maxLength: 255 }}
                fullWidth
                value={urlName}
                onChange={(e) => {
                  setUrlName(e.target.value);
                }}
              />
            )}

            <Button
              variant="outlined"
              size="small"
              onClick={onSendUrl}
              disabled={!url}
            >
              Прикрепить
            </Button>
          </Box>
        </MenuList>
      </MenuFixed>
    );
  },
);

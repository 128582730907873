import React, { useCallback, useEffect, useState } from 'react';
import { useStoreMap, useUnit } from 'effector-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import deepEquals from 'deep-equal';
import {
  $ordOption,
  $organizationByORD,
  ordGetCatalogFX,
  organizationGetCatalogFX,
} from '../../model/catalogs';
import { Form } from '../../../Organization/types';
import { contractCreateFX } from './model';
import { PContractCreate } from '../../../../../apiRPC/contracts';
import { navigateForEffect } from '../../../../../model/routing';
import { errorsMessage } from '../../../../../apiRPC/errors';
import { isErrorProps } from '../../../../../apiRPC/request';
import { addressesORD } from '../../../../addresses';
import {
  $finalContractList,
  $parentContractList,
  finalContractGetListFX,
  parentContractGetListFX,
  resetCatalogs,
} from '../model';
import { mapName } from '../../../Organization/options';

const initialValues: Form = {
  ord: 1, // Основная ОРД
  externalId: '', // Идентификатор договора в ORD
  customer: null, // Заказчик
  executor: null, // Исполнитель
  number: '', // Номер договора
  date: null, // Дата договора
  type: 'Intermediary', // Тип договора
  actionType: 'Other', // Действия, осуществляемые посредником-представителем
  subjectType: 'Other', // Сведения о предмете договора
  amount: 0, // Сумма договора (руб)
  isVat: 0, // С НДС - выпадающий список
  executorIsObligedForRegistration: 0, // Обязанность регистрировать и репортировать креативы исполнителем
  isInvoiceContract: 0, // Договор отчета. По умолчанию - false
  category: null, // Категория договора.
  agentActingForPublisher: null, // Направление денежных средств.
  finalContract: null, // Доходный договор.
  parent: null, // Родительский договор.
  useNetworkStat: 0,
};

export function useCreate() {
  useEffect(() => {
    organizationGetCatalogFX(undefined);
    ordGetCatalogFX(undefined);
  }, []);

  const ordOption = useUnit($ordOption);
  const [finalContractList, parentContractList] = useUnit([
    $finalContractList,
    $parentContractList,
  ]);
  const [disableModal, setDisableModal] = useState(false);

  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object().shape(
      {
        ord: Yup.number().required('Обязательное для заполнения поле'),
        externalId: Yup.string(),
        customer: Yup.object()
          .shape({ value: Yup.string().required() })
          .when(['executor', 'type'], ([executor, type], schema) => {
            if (type === 'SelfPromotion') {
              return schema.test({
                test: (customer) => customer?.value === executor?.value,
                message:
                  'Заказчик и исполнитель  должны совпадать для данного типа',
              });
            }
            return schema.test({
              test: (customer) => customer?.value !== executor?.value,
              message: 'Заказчик и исполнитель не должны совпадать',
            });
          })
          .nullable()
          .required('Обязательное для заполнения поле'),

        executor: Yup.object()
          .shape({ value: Yup.string().required() })
          .when(['customer', 'type'], ([customer, type], schema) => {
            if (type === 'SelfPromotion') {
              return schema.test({
                test: (executor) => customer?.value === executor?.value,
                message:
                  'Заказчик и исполнитель  должны совпадать для данного типа',
              });
            }
            return schema.test({
              test: (executor) => executor?.value !== customer?.value,
              message: 'Заказчик и исполнитель не должны совпадать',
            });
          })
          .nullable()
          .required('Обязательное для заполнения поле'),
        number: Yup.string().when(['ord'], {
          // eslint-disable-next-line eqeqeq
          is: (val: number) => val == 1,
          then: (schema) =>
            schema
              .required('Обязательное для заполнения поле')
              .trim('Обязательное для заполнения поле'),
          otherwise: (schema) => schema.notRequired(),
        }),
        date: Yup.date()
          .typeError('Некорректное значение')
          .nullable()
          .required('Обязательное для заполнения поле'),
        type: Yup.string().required('Обязательное для заполнения поле'),

        actionType: Yup.string().required('Обязательное для заполнения поле'),
        subjectType: Yup.string().required('Обязательное для заполнения поле'),
        amount: Yup.number()
          .min(0, 'Минимальное значение 0')
          .max(1e16, 'Превышено максимальное значение')
          .typeError('Некорректное значение')
          .required('Обязательное для заполнения поле'),
        isVat: Yup.number(),
        executorIsObligedForRegistration: Yup.number(),
        isInvoiceContract: Yup.number(),
        category: Yup.string()
          .nullable()
          .required('Обязательное для заполнения поле'),
        agentActingForPublisher: Yup.number()
          .nullable()
          .required('Обязательное для заполнения поле'),
        finalContract: Yup.object()
          .when(['ord', 'category'], {
            is: (ord: number, category: string) =>
              // eslint-disable-next-line eqeqeq
              ord == 3 && category === 'Initial',
            then: (schema) =>
              schema.required('Обязательное для заполнения поле'),
            otherwise: (schema) => schema.notRequired(),
          })
          .notRequired(),
        parent: Yup.object()
          .shape({ uuid: Yup.string().required() })
          .when('type', ([type], schema) =>
            schema.test({
              test: (value) => !(!value?.uuid && type === 'Additional'),
              message: 'Обязательное для заполнения поле',
            }),
          )
          .nullable(),
        useNetworkStat: Yup.number(),
      },
      [['customer', 'executor']],
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      try {
        setDisableModal(true);
        const fields: PContractCreate['fields'] = {
          ...values,
          number: values.number || '',
          customer: values.customer!.value,
          executor: values.executor!.value,
          date: format(values.date!, 'yyyy-MM-dd'),
          isVat: Boolean(values.isVat),
          executorIsObligedForRegistration: Boolean(
            values.executorIsObligedForRegistration,
          ),
          isInvoiceContract: Boolean(values.isInvoiceContract),
          category: values.category!,
          agentActingForPublisher: Boolean(values.agentActingForPublisher),
          finalContract: values.finalContract?.uuid,
          parent: values.parent?.uuid,
          useNetworkStat: Boolean(values.useNetworkStat),
        };
        await contractCreateFX({ fields });
        f.resetForm();
        toast.success('Договор успешно создан ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigateForEffect(addressesORD.contractsPath);
      } catch (e) {
        setDisableModal(false);

        if (isErrorProps(e) && e?.code === 632) {
          f.setFieldError('externalId', errorsMessage[632]);
        }
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            let text = field.description;
            Array.from(mapName).forEach(([key, val]) => {
              text = text.replace(key, val);
            });
            f.setFieldError(field.field, text);
          });
        }

        console.error(e);
      }
    },
  });

  useEffect(() => {
    if (!formik.values.ord) return undefined;
    formik.setFieldValue('finalContract', null);
    formik.setFieldValue('parent', null);
    finalContractGetListFX({
      params: {
        filter: { ord: formik.values.ord, limit: 30, category: 'Final' },
      },
    });
    parentContractGetListFX({
      params: { filter: { ord: formik.values.ord, limit: 30 } },
    });
    return resetCatalogs;
  }, [formik.values.ord]);

  function onDefaultsParentContractList() {
    parentContractGetListFX({
      params: { filter: { ord: formik.values.ord, limit: 30 } },
    });
  }
  function onDefaultsFinalContractList() {
    finalContractGetListFX({
      params: {
        filter: { ord: formik.values.ord, limit: 30, category: 'Final' },
      },
    });
  }
  const pending = useUnit(contractCreateFX.pending);
  const isPrompt = disableModal
    ? false
    : !deepEquals(formik.values, initialValues);

  const organizationOption = useStoreMap({
    store: $organizationByORD,
    keys: [formik.values.ord],
    fn: (state, keys) => state.get(keys[0]),
    defaultValue: [],
  });

  const handleChange: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
        if (name === 'type') {
          formik.validateField('customer');
          formik.validateField('executor');
          formik.validateField('parent');
        }
        if (
          name === 'type' &&
          value === 'Intermediary' &&
          formik.values.actionType === 'None'
        ) {
          formik.setFieldValue('actionType', '');
        }
        if (name === 'ord') {
          formik.setFieldValue('customer', null);
          formik.setFieldValue('executor', null);
          formik.validateField('finalContract');
          formik.validateField('number');
        }
        if (name === 'category') {
          formik.validateField('finalContract');
        }
        if (
          name === 'ord' &&
          formik.values.type === 'SelfPromotion' &&
          +value === 5
        ) {
          formik.setFieldValue('type', '');
        }
      });
    },
    [formik],
  );

  const handleChangeForAmount: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const d = value.split('.').at(1);
      if ((d?.length ?? 0) > 4) return;
      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
      });
    },
    [formik],
  );

  const btnSendDisabled = pending || !formik.isValid;
  return {
    formik,
    handleChange,
    organizationOption,
    handleChangeForAmount,
    ordOption,
    isPrompt,
    pending,
    btnSendDisabled,
    finalContractList,
    parentContractList,
    onDefaultsParentContractList,
    onDefaultsFinalContractList,
  };
}

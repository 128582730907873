import React, { FC } from 'react';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { Create } from '../Create';
import { SelectWorkspace } from '../SelectWorkspace';
import { InputTitle } from '../InputTitle';
import { Search } from '../GRID/Search';
import { themeHeader } from '../theme';
import { Filter } from '../Filter';
import { GlobalSearch } from '../GRID/GlobalSearch';

const theme = createTheme(themeHeader);
type Props = {
  isSearch?: boolean;
};
export const GRID: FC<Props> = ({ isSearch }) => (
  <ThemeProvider theme={theme}>
    <Create />

    <SelectWorkspace />

    <InputTitle />

    <Box
      ml="auto"
      mr={1}
      display="flex"
      padding={1}
      alignItems="center"
      flexGrow={1}
    >
      <GlobalSearch />
      {isSearch && (
        <>
          <Search />
          <Filter />
        </>
      )}
    </Box>
  </ThemeProvider>
);

import request from '../../request';

export type TypeCreative = 'html' | 'video';
export type CardCreativeItem = {
  uuid: string;
  size: string;
  html: string;
  auditCode?: string;
  card: string;
  name?: string;
  url: string;
  groupUuid: string | null;
  type?: TypeCreative;
};

export type RDictionaryGetCreativeSizes = string[];
export const dictionaryGetCreativeSizes = request<RDictionaryGetCreativeSizes>({
  method: 'dictionary.getCreativeSizes',
});

export type PCardCreativesCreate = {
  card: string;
  fields: Omit<CardCreativeItem, 'uuid' | 'card' | 'url'>;
};
export type RCardCreativesCreate = CardCreativeItem;
export const cardCreativesCreate = request<
  RCardCreativesCreate,
  PCardCreativesCreate
>({
  method: 'cardCreatives.create',
});

export type PCardCreativesUpdate = {
  uuid: string;
  fields: Partial<Omit<CardCreativeItem, 'uuid' | 'card' | 'url' | 'type'>>;
};
export type RCardCreativesUpdate = CardCreativeItem;
export const cardCreativesUpdate = request<
  RCardCreativesUpdate,
  PCardCreativesUpdate
>({
  method: 'cardCreatives.update',
});

export type PCardCreativesGet = {
  uuid: string;
};
export type RCardCreativesGet = CardCreativeItem;
export const cardCreativesGet = request<RCardCreativesGet, PCardCreativesGet>({
  method: 'cardCreatives.get',
});

export type PCardCreativesGetList = {
  card: string;
};
export type RCardCreativesGetList = Pick<
  CardCreativeItem,
  'uuid' | 'size' | 'name' | 'url' | 'groupUuid'
>[];
export const cardCreativesGetList = request<
  RCardCreativesGetList,
  PCardCreativesGetList
>({
  method: 'cardCreatives.getList',
});

export type PCardCreativesDelete = {
  uuid: string;
};
export type RCardCreativesDelete = true;
export const cardCreativesDelete = request<
  RCardCreativesDelete,
  PCardCreativesDelete
>({
  method: 'cardCreatives.delete',
});

export type AuditCode = { name?: string; code?: string };
export type PCardCreativesCreateFromZip = {
  card: string;
  fields: {
    name?: string;
    auditCode?: AuditCode[];
    file: string;
    groupUuid: string | null;
  };
};
export type RCardCreativesCreateFromZip = CardCreativeItem[];
export const cardCreativesCreateFromZip = request<
  RCardCreativesCreateFromZip,
  PCardCreativesCreateFromZip
>({
  method: 'cardCreatives.createFromZip',
});

export type PCardCreativesMoveToGroup = {
  group: string | null;
  items: string[];
};
export type RCardCreativesMoveToGroup = true;
export const cardCreativesMoveToGroup = request<
  RCardCreativesMoveToGroup,
  PCardCreativesMoveToGroup
>({
  method: 'cardCreatives.moveToGroup',
});

import { attach, createEffect, createEvent } from 'effector';
import { ORDItem } from '../../../../../apiRPC/organization';
import { $lastFilter } from './index';
import {
  contractGetList,
  ContractItem,
  PContractGetList,
} from '../../../../../apiRPC/contracts';
import {
  $ordCatalog,
  $organizationData,
  OrganizationData,
} from '../../model/catalogs';
import { workerToPromise } from '../../../../../helper/workerToPromise';
import { errorHandlerFx } from '../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../model/JWT';

import { downloadBlob } from '../../../../../helper/downloadBlob';

type SetData = {
  contractList: ContractItem[];
  ordList: ORDItem[];
  organizationData: OrganizationData;
};

type Catalogs = {
  ordList: ORDItem[];
  organizationData: OrganizationData;
  lastFilter: PContractGetList | null;
};

const contractGetListFullFX = errorHandlerFx(createEffectJWT(contractGetList));

let WorkerForReport: Worker | null = null;

async function setData({
  ordList,
  organizationData,
  lastFilter,
}: Catalogs): Promise<BlobPart> {
  try {
    const contractList = await contractGetListFullFX({
      filter: {
        ...(lastFilter?.filter || {}),
        limit: 1000000,
        offset: 0,
      },
    });

    const data: SetData = {
      ordList,
      organizationData,
      contractList: contractList.rows,
    };

    WorkerForReport =
      WorkerForReport ||
      new Worker(new URL('../workers/ContractsListReport.ts', import.meta.url));
    const result = await workerToPromise<string, BlobPart>(
      WorkerForReport,
      JSON.stringify(data),
    );
    return result;
  } catch (e) {
    console.error({ e });
    throw e;
  }
}

export const resetWorkerForReport = createEvent();

resetWorkerForReport.watch(() => {
  WorkerForReport?.terminate();
  WorkerForReport = null;
});

const setDataReportFX = createEffect(setData);

export const getReportContractListFx = attach({
  effect: setDataReportFX,
  source: {
    ordList: $ordCatalog,
    organizationData: $organizationData,
    lastFilter: $lastFilter,
  },
  mapParams: (_, data) => data,
});

getReportContractListFx.doneData.watch((payload) =>
  downloadBlob([payload], 'Договора.csv', {
    type: 'text/csv;charset=utf-8;',
  }),
);

import { combine, createEvent, forward } from 'effector';
import {
  $sizeItems,
  $validItems,
  resetResourcesCreateForm,
  submitResourcesCreateForm,
} from '../Form/model';
import {
  $anyPending,
  $isDellAllResources,
  $resourceForDelete,
  onDeleteResources,
  resetResourceForDelete,
} from './index';
import {
  $changeUpdate,
  $validUpdate,
  resetResourcesUpdateForm,
  submitResourcesUpdateForm,
} from '../Update/model';

export const submitResourcesChanged = createEvent();
export const resetResourcesChanged = createEvent();

forward({
  from: submitResourcesChanged,
  to: [submitResourcesCreateForm, onDeleteResources, submitResourcesUpdateForm],
});
forward({
  from: resetResourcesChanged,
  to: [
    resetResourcesCreateForm,
    resetResourceForDelete,
    resetResourcesUpdateForm,
  ],
});

export const $disabledResourcesChanged = combine(
  {
    sizeItems: $sizeItems,
    resourceForDelete: $resourceForDelete,
    anyPending: $anyPending,
    changeUpdate: $changeUpdate,
  },
  ({ sizeItems, resourceForDelete, anyPending, changeUpdate }) => {
    if (anyPending) return true;
    if (sizeItems) return false;
    if (changeUpdate) return false;
    if (resourceForDelete.size) return false;

    return true;
  },
);
export const $disabledResourcesSubmit = combine(
  {
    sizeItems: $sizeItems,
    resourceForDelete: $resourceForDelete,
    anyPending: $anyPending,
    validItems: $validItems,
    isDellAllResources: $isDellAllResources,
    validUpdate: $validUpdate,
    changeUpdate: $changeUpdate,
  },
  ({
    sizeItems,
    resourceForDelete,
    anyPending,
    validItems,
    isDellAllResources,
    validUpdate,
    changeUpdate,
  }) => {
    if (isDellAllResources) return true;
    if (!validItems) return true;
    if (!validUpdate) return true;
    if (anyPending) return true;
    if (sizeItems) return false;
    if (resourceForDelete.size) return false;
    if (changeUpdate) return false;

    return true;
  },
);

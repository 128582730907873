import request from '../request';

export type Type = 'resource' | 'text';
export type Resource = {
  uuid: string;
  description: string;
  mediaExampleUrl: string;
  textData: string;
  hash: string;
  creative: string;
  type: Type;
  loadingStatus: string;
  ord: number;
};
export type PResourcesGetList = { creative: string };
export type RResourcesGetList = { total: number; items: Resource[] };
export const resourcesGetList = request<RResourcesGetList, PResourcesGetList>({
  method: 'creativeResources.getList',
});

type ItemCreate = {
  type: Resource['type'];
  description?: Resource['description'];
  mediaExampleUrl?: Resource['mediaExampleUrl'];
  textData?: Resource['textData'];
};
export type PResourcesCreate = {
  creative: string;
  items: ItemCreate[];
};

export type RResourcesCreate = Resource;
export const resourcesCreate = request<RResourcesCreate, PResourcesCreate>({
  method: 'creativeResources.create',
});

export type PResourcesDelete = { creative: string; items: string[] };
export type RResourcesDelete = [];
export const resourcesDelete = request<RResourcesDelete, PResourcesDelete>({
  method: 'creativeResources.delete',
});

export type PResourcesGetCount = { creative: string };
export type RResourcesGetCount = number;
export const resourcesGetCount = request<
  RResourcesGetCount,
  PResourcesGetCount
>({
  method: 'creativeResources.getCount',
});

export type UpdateItem = { uuid: string; textData?: string; type?: string };
export type PResourcesUpdate = { creative: string; items: UpdateItem[] };
export type RResourcesUpdate = Resource[];
export const resourcesUpdate = request<RResourcesUpdate, PResourcesUpdate>({
  method: 'creativeResources.update',
});

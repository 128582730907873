import React, { FC, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUnit } from 'effector-react';
import { contentSetNameFX } from './model';

type Props = {
  name: string;
  uuid: string;
};
export const LinkName: FC<Props> = ({ name, uuid }) => {
  const [isEdit, setEdit] = useState(false);
  const pending = useUnit(contentSetNameFX.pending);
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Обязательно для заполнения')
        .trim()
        .min(3, 'Минимум 3 знака'),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values) => {
      if (values.name === name) {
        setEdit(false);
        return;
      }

      contentSetNameFX({ uuid, name: values.name }).then(() => {
        setEdit(false);
      });
    },
  });

  useEffect(() => {
    setEdit(false);
    formik.setFieldValue('name', name);
  }, [name]);

  function onFormError() {
    if (formik.errors.name) {
      setEdit(false);
      formik.resetForm({ values: { name }, errors: undefined });
      return true;
    }
    return false;
  }

  const onBlur = () => {
    if (onFormError()) return;
    formik.submitForm();
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;
    if (onFormError()) return;
    formik.submitForm();
  };
  const onOpenInput: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(true);
  };
  if (!isEdit)
    return (
      <Box display="flex" gap={1}>
        <Typography
          variant="subtitle1"
          sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
          onClick={onOpenInput}
        >
          {name}
        </Typography>
        <Button size="small" variant="text" onClick={onOpenInput}>
          <Typography variant="body2" component="div" fontStyle="italic">
            Изменить
          </Typography>
        </Button>
      </Box>
    );

  return (
    <TextField
      variant="standard"
      name="name"
      autoFocus
      onChange={formik.handleChange}
      value={formik.values.name}
      onBlur={onBlur}
      error={Boolean(formik.errors.name)}
      helperText={formik.errors.name || ' '}
      inputProps={{ maxLength: 255 }}
      onKeyDown={onPressEnter}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      disabled={pending}
      fullWidth
    />
  );
};

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { Droppable } from '@hello-pangea/dnd';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { $columnListSort } from '../../model/Columns';
import { Column } from './Column';
import { AddColumn } from './AddColumn';
import { $currentUuid } from './model';

export const ColumnsList: FC = () => {
  const columnList = useUnit($columnListSort);

  const theme = useTheme();
  const minHeight = `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 33px)`;
  const currentUuid = useUnit($currentUuid);
  if (!currentUuid) return null;

  return (
    <Droppable droppableId={currentUuid} type="COLUMN" direction="horizontal">
      {(provided) => (
        <Stack
          direction="row"
          spacing={2}
          minWidth={900}
          pt={2.5}
          height={minHeight}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {columnList.map((item, index) => (
            <Column
              uuid={item.uuid}
              title={item.title}
              key={item.uuid}
              index={index}
              board={currentUuid}
            />
          ))}
          {provided.placeholder}
          <AddColumn />
        </Stack>
      )}
    </Droppable>
  );
};

import { createEvent, createStore, forward, sample } from 'effector';
import deepEquals from 'deep-equal';
import axios, { Canceler } from 'axios';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import {
  cardAddComment,
  cardDeleteComment,
  cardGetComments,
  cardUpdateComment,
  CommentCard,
  PDeleteComment,
  PGetComments,
  RGetComments,
  RUpdateComment,
} from '../../../../apiRPC/card/comment';
import { effectListener } from '../../../../model/effectListener';
import { contentDeleteFX, contentRestoreFX } from '../update/attachments';

export const cardAddCommentFX = errorHandlerFx(createEffectJWT(cardAddComment));
export const cardDeleteCommentFX = errorHandlerFx(
  createEffectJWT(cardDeleteComment),
);
export const cardUpdateCommentFX = errorHandlerFx(
  createEffectJWT(cardUpdateComment),
);

const cancelTokenCardGetComments: Canceler[] = [];
export const { abort: abortCardGetComments, fx: cardGetCommentsFX } =
  effectListener(
    errorHandlerFx(
      createEffectJWT((params: PGetComments) => {
        const source = axios.CancelToken.source();
        cancelTokenCardGetComments.push(source.cancel);
        return cardGetComments(params, source.token);
      }),
    ),
    30 * 1000,
  );

const onAbortReqwestCardGetComments = createEvent();

onAbortReqwestCardGetComments.watch(() => {
  cancelTokenCardGetComments.forEach((item) => item());
  cancelTokenCardGetComments.splice(0, cancelTokenCardGetComments.length);
});

export const commentsReset = createEvent();
export const addComment = createEvent<CommentCard>();
export const delComment = createEvent<PDeleteComment>();
export const updateComment = createEvent<RUpdateComment>();

export const $cardComments = createStore<RGetComments['rows']>([])
  .on(cardGetCommentsFX.doneData, (state, payload) => {
    if (deepEquals(payload.rows, state)) return undefined;
    return payload.rows;
  })
  .on(addComment, (state, payload) => {
    state.unshift(payload);
    return [...state];
  })
  .on(delComment, (state, payload) => {
    const fState = state.filter((item) => item.uuid !== payload.uuid);
    return [...fState];
  })
  .on(updateComment, (state, payload) => {
    const index = state.findIndex((item) => item.uuid === payload.uuid);
    if (index < 0) return undefined;
    state[index] = payload;
    return [...state];
  })
  .on(contentDeleteFX.done, (state, { params }) => {
    const newState = [...state];
    newState.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.contents = item.contents.filter(
        (content) => content.uuid !== params.uuid,
      );
    });
    return newState;
  })
  .on(contentRestoreFX.done, (state, { params }) => {
    const newState = [...state];
    for (const comment of newState) {
      const content = comment.contents.find(
        (item) => item.uuid === params.uuid,
      );
      if (content) {
        content.deleted = false;
        break;
      }
    }
    return newState;
  })
  .on(commentsReset, () => []);

export const $cardCommentsTotal = createStore<RGetComments['total'] | null>(
  null,
)
  .on(cardGetCommentsFX.doneData, (state, payload) => {
    if (payload.total === state) return undefined;
    return payload.total;
  })
  .on(addComment, (state) => (state ?? 0) + 1)
  .on(delComment, (state) => (state ?? 1) - 1)
  .reset(commentsReset);

sample({
  clock: cardAddCommentFX.doneData,
  target: addComment,
});
sample({
  clock: cardUpdateCommentFX.doneData,
  target: updateComment,
});
sample({
  clock: cardDeleteCommentFX.done,
  fn: ({ params }) => params,
  target: delComment,
});

export const $cardCommentsUid = $cardComments.map(
  (state) => new Set(state.map((item) => item.uuid)),
);
forward({
  from: commentsReset,
  to: abortCardGetComments,
});
forward({
  from: commentsReset,
  to: onAbortReqwestCardGetComments,
});

import { FC } from 'react';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { setOpenModal } from '../../../model/Columns';

export const AddColumn: FC = () => (
  <Box width={360}>
    <Button
      variant="outlined"
      startIcon={<AddIcon />}
      sx={{ width: 'inherit', mr: 2 }}
      onClick={() => setOpenModal(true)}
    >
      Добавить колонку
    </Button>
  </Box>
);

import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUnit } from 'effector-react';
import { PResourcesCreate } from '../../../../../../../apiRPC/creative/resources';
import {
  resetResourcesCreateForm,
  setItemsForm,
  setSizeItems,
  setValidItems,
  submitResourcesCreateForm,
} from './model';
import { TextType } from './TextType';
import { ResourceType } from './ResourceType';
import { isErrorProps } from '../../../../../../../apiRPC/request';
import { resourcesCreateFX } from '../model';

type FormCreateResources = PResourcesCreate['items'];

const initialValues: FormCreateResources = [];

const validationSchema = Yup.array().of(
  Yup.object().shape({
    type: Yup.string().required('Обязательное для заполнения поле'),
    description: Yup.string().when('type', ([type], schema) =>
      schema.test({
        test: (value) => !(!value && type === 'resource'),
        message: 'Обязательное для заполнения поле',
      }),
    ),
    mediaExampleUrl: Yup.string()
      .when('type', ([type], schema) =>
        schema.test({
          test: (value) => !(!value && type === 'resource'),
          message: 'Обязательное для заполнения поле',
        }),
      )
      .url('Неверный формат URL'),
    textData: Yup.string().when('type', ([type], schema) =>
      schema.test({
        test: (value) => !(!value && type === 'text'),
        message: 'Обязательное для заполнения поле',
      }),
    ),
  }),
);

export const Form: FC = () => {
  const { uuid } = useParams();

  const pending = useUnit(resourcesCreateFX.pending);

  const formik = useFormik<FormCreateResources>({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      if (!values.length) return;
      if (!uuid) return;

      try {
        await resourcesCreateFX({
          creative: uuid,
          items: values,
        });
        f.resetForm();
      } catch (e) {
        console.error({ e });
        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            const text = field.description;
            if (typeof field.index === 'number')
              f.setFieldError(`[${field.index}]${field.field}`, text);
          });
        }
      }
    },
  });

  useEffect(() => {
    const stop = setItemsForm.watch((payload) => {
      formik.setValues([...payload, ...formik.values], true);
    });
    return stop;
  }, [formik]);
  useEffect(() => {
    const stop = submitResourcesCreateForm.watch(() => {
      formik.submitForm();
    });
    return stop;
  }, [formik]);
  useEffect(() => {
    const stop = resetResourcesCreateForm.watch(() => {
      formik.resetForm();
    });
    return stop;
  }, [formik]);
  useEffect(() => {
    setSizeItems(formik.values.length);
  }, [formik.values.length]);
  useEffect(() => {
    setValidItems(formik.isValid);
  }, [formik.isValid]);

  const handleChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      formik.setFieldValue(`[${index}]${name}`, value, true);
    };

  const onDelete = (index: number) => {
    const arStart = formik.values.slice(0, index);
    const arEnd = formik.values.slice(index + 1, formik.values.length);
    formik.setValues([...arStart, ...arEnd], true);
  };
  return (
    <>
      {formik.values.map((form, index) => {
        if (form.type === 'text')
          return (
            <TextType
              key={index}
              textData={form.textData}
              textDataError={formik.errors[index]?.textData}
              onChange={handleChange(index)}
              onDelete={() => onDelete(index)}
              disabled={pending}
            />
          );
        if (form.type === 'resource')
          return (
            <ResourceType
              key={index}
              mediaExampleUrl={form.mediaExampleUrl}
              mediaExampleUrlError={formik.errors[index]?.mediaExampleUrl}
              description={form.description}
              descriptionError={formik.errors[index]?.description}
              onChange={handleChange(index)}
              onDelete={() => onDelete(index)}
              disabled={pending}
            />
          );

        return null;
      })}
    </>
  );
};

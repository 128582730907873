import { attach, createEffect, createEvent } from 'effector';
import { $organizationListFilter } from './index';
import { ORDItem, ROrganizationGet } from '../../../../apiRPC/organization';
import { downloadBlob } from '../../../../helper/downloadBlob';
import { $ordList } from '../../../model/ord';
import { workerToPromise } from '../../../../helper/workerToPromise';

type SetData = {
  organizationListFilter: ROrganizationGet[] | null;
  ordList: ORDItem[];
};
let WorkerForReport: Worker | null = null;
async function setData(data: SetData): Promise<BlobPart> {
  try {
    WorkerForReport =
      WorkerForReport ||
      new Worker(new URL('../workers/organizationsReport.ts', import.meta.url));
    const result = await workerToPromise<string, BlobPart>(
      WorkerForReport,
      JSON.stringify(data),
    );
    return result;
  } catch (e) {
    console.error({ e });
    throw e;
  }
}

export const resetWorkerForReport = createEvent();

resetWorkerForReport.watch(() => {
  WorkerForReport?.terminate();
  WorkerForReport = null;
});

export const setDataReportFX = createEffect(setData);

export const getReportFx = attach({
  effect: setDataReportFX,
  source: {
    organizationListFilter: $organizationListFilter,
    ordList: $ordList,
  },
  mapParams: (_, data) => data,
});

setDataReportFX.doneData.watch((payload) =>
  downloadBlob([payload], 'организации.csv', {
    type: 'text/csv;charset=utf-8;',
  }),
);

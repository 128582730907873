import React, { FC, useLayoutEffect } from 'react';
import { useUnit } from 'effector-react';
import { ItemContent, Virtuoso } from 'react-virtuoso';
import { $cardCreativesFilteredList } from '../model';
import { ItemUpdate } from './ItemUpdate';
import { RCardCreativesGetList } from '../../../../../../../apiRPC/card/creatives';
import css from './styles.module.scss';

type Context = { updatePosition: () => void };
const computeItemKey = (_: number, d: RCardCreativesGetList[0]) => d.uuid;

const Item: ItemContent<RCardCreativesGetList[0], Context> = (
  i,
  item,
  context,
) => (
  <ItemUpdate
    key={item.uuid}
    uuid={item.uuid}
    size={item.size}
    name={item.name}
    updatePosition={context.updatePosition}
    url={item.url}
    groupUuid={item.groupUuid}
  />
);

type Props = {
  updatePosition: () => void;
};

export const FormUpdate: FC<Props> = ({ updatePosition }) => {
  const [cardCreativesGetLis] = useUnit([$cardCreativesFilteredList]);

  useLayoutEffect(() => {
    setTimeout(() => {
      updatePosition();
    }, 10);
  }, [cardCreativesGetLis]);

  if (!cardCreativesGetLis?.length) return null;
  return (
    <Virtuoso
      data={cardCreativesGetLis ?? undefined}
      computeItemKey={computeItemKey}
      itemContent={Item}
      context={{ updatePosition }}
      totalCount={cardCreativesGetLis?.length}
      defaultItemHeight={56}
      className={css.container}
    />
  );
};

import { Box, IconButton, Paper, TextField } from '@mui/material';
import React, { FC } from 'react';
import { FiTrash } from 'react-icons/fi';
import css from '../../../../../styles.module.scss';
import { BootstrapTooltip } from '../../../../../../../../../components/BootstrapTooltip';

interface IItemProps {
  mediaExampleUrl?: string;
  description?: string;
  disabled?: boolean;
  onDelete: () => void;
}

export const ItemResource: FC<IItemProps> = ({
  mediaExampleUrl,
  description,
  disabled,
  onDelete,
}) => (
  <Paper
    elevation={0}
    sx={{
      padding: '30px',
    }}
  >
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 40px"
      gap="0 30px"
      alignItems="flex-start"
    >
      <TextField
        label="Ссылка на ресурс"
        disabled
        variant="standard"
        value={mediaExampleUrl}
        autoComplete="none"
        inputProps={{ maxLength: 1000 }}
        multiline
      />
      <TextField
        label="Описание"
        disabled
        variant="standard"
        value={description}
        autoComplete="none"
        inputProps={{ maxLength: 1000 }}
        multiline
      />
      <BootstrapTooltip title="Удалить" placement="top">
        <IconButton onClick={onDelete} disabled={disabled}>
          <FiTrash color="#D32F2F" size={20} className={css.del_btn} />
        </IconButton>
      </BootstrapTooltip>
    </Box>
  </Paper>
);

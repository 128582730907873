import React, { FC } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Marc } from '../../../../../components/Marck';
import type { Mark } from '../../../../../../apiRPC/card';

export type Props = {
  marks: Mark[];
  onClickButton: () => void;
};
export const Marks: FC<Props> = ({ marks, onClickButton }) => (
  <Box>
    <Typography variant="body2" color="text.secondary" fontWeight={600}>
      Метки
    </Typography>
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={0.5}
    >
      {marks.map((item) => (
        <Grid item key={item.uuid}>
          <Marc label={item.title} colorMark={item.color} size="small" />
        </Grid>
      ))}

      <Grid item>
        <IconButton
          aria-label="add"
          size="small"
          color="primary"
          onClick={() => onClickButton()}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  </Box>
);

import React, { FC } from 'react';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FiTrello } from 'react-icons/fi';
import Typography from '@mui/material/Typography';
import { ReactSVG } from 'react-svg';
import IconButton from '@mui/material/IconButton';
import OrdSvg from '../../../assets/icons/ord.svg';
import css from '../styles.module.scss';
import ToggleMenuSvg from '../../../assets/icons/toggleMenu.svg';
import { addressesORD } from '../../../ORD/addresses';

const PaperProps = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 0,
  width: 10,
  height: 10,
  bgcolor: 'background.paper',
  transform: 'translateY(-50%) translateX(-50%) rotate(45deg)',
  zIndex: 0,
};

export const NavigateMenu: FC = () => {
  const [toggleMenu, setToggleMenu] = React.useState<null | HTMLElement>(null);

  const handleMainMenu = (event: React.MouseEvent<HTMLElement>) => {
    setToggleMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setToggleMenu(null);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton
          color="inherit"
          edge="start"
          size="large"
          onClick={handleMainMenu}
        >
          <ReactSVG src={ToggleMenuSvg} className="icon" />
        </IconButton>
      </Box>
      <Menu
        id="main"
        anchorEl={toggleMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(toggleMenu)}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              left: '25px',
              ...PaperProps,
            },
          },
        }}
      >
        <NavLink to="/" onClick={handleCloseMenu}>
          <MenuItem>
            <ListItemIcon>
              <Box
                className={css.toggleMenuItem}
                sx={{
                  background: '#D3E6FF',
                }}
              >
                <FiTrello size={24} color="#2F80ED" />
              </Box>
            </ListItemIcon>
            <ListItemText>
              <Typography>GRID</Typography>
            </ListItemText>
          </MenuItem>
        </NavLink>
        <NavLink to={addressesORD.organizationsPath} onClick={handleCloseMenu}>
          <MenuItem>
            <ListItemIcon>
              <Box
                className={css.toggleMenuItem}
                sx={{
                  background: '#DCE2FF',
                }}
              >
                <ReactSVG src={OrdSvg} className="icon" />
              </Box>
            </ListItemIcon>

            <ListItemText>
              <Typography>ОРД</Typography>
            </ListItemText>
          </MenuItem>
        </NavLink>
      </Menu>
    </>
  );
};

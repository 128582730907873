import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { $card, cardGetFX } from '../index';
import { $accounts } from './index';
import { RGetAccounts } from '../../../../apiRPC/board';
import { isAdd, isDel } from '../../../../helper/chekedUuids';
import { CardUpdateMembers, updateMembersFX } from '../update/members';

export const setMembersUuidForAdd = createEvent<string>();
export const setMembersUuidForDel = createEvent<string>();

export const resetMembersUuidForAdd = createEvent();
export const $membersUuidForAdd = createStore<string[]>([])
  .on(setMembersUuidForAdd, (state, payload) => {
    if (state.includes(payload)) return undefined;
    return [...state, payload];
  })
  .on(setMembersUuidForDel, (state, payload) => {
    if (!state.includes(payload)) return undefined;
    return state.filter((item) => item !== payload);
  })
  .reset(resetMembersUuidForAdd);

export const resetMembersUuidForDel = createEvent();
export const $membersUuidForDel = createStore<string[]>([])
  .on(setMembersUuidForDel, (state, payload) => {
    if (state.includes(payload)) return undefined;
    return [...state, payload];
  })
  .on(setMembersUuidForAdd, (state, payload) => {
    if (!state.includes(payload)) return undefined;
    return state.filter((item) => item !== payload);
  })
  .reset(resetMembersUuidForDel);

export const resetMembersUuid = () => {
  resetMembersUuidForDel();
  resetMembersUuidForAdd();
};

export const $ListUuidMembersForCard = combine(
  $membersUuidForAdd,
  $membersUuidForDel,
  $card,
  (membersUuidForAdd, membersUuidForDel, card) => {
    const currentUuids = card?.members.map((item) => item.uuid) || [];
    const afterDel = currentUuids.filter(
      (item) => !membersUuidForDel.includes(item),
    );
    const unique = new Set([...afterDel, ...membersUuidForAdd]);
    return Array.from(unique);
  },
);

export const $listMembersForCard = combine(
  $ListUuidMembersForCard,
  $accounts,
  (ListUuidMembersForCard, accounts) => {
    const result: RGetAccounts = [];
    if (!accounts) return result;

    ListUuidMembersForCard.forEach((uuid) => {
      const mark = accounts.find((item) => item.uuid === uuid);
      if (!mark) return;
      result.push(mark);
    });

    return result;
  },
);

export const updateMembersForCard = createEvent();

sample({
  clock: updateMembersForCard,
  source: { $card, $membersUuidForAdd, $membersUuidForDel },
  filter: ({
    $card: card,
    $membersUuidForAdd: membersUuidForAdd,
    $membersUuidForDel: membersUuidForDel,
  }) => {
    if (!membersUuidForAdd.length && !membersUuidForDel.length) return false;

    return (
      isDel(card?.members, membersUuidForDel) ||
      isAdd(card?.members, membersUuidForAdd)
    );
  },
  fn: ({
    $card: card,
    $membersUuidForAdd: membersUuidForAdd,
    $membersUuidForDel: membersUuidForDel,
  }) => {
    const add: string[] = [];
    membersUuidForAdd.forEach((uuid) => {
      if (!card?.members.find((item) => item.uuid === uuid)) {
        add.push(uuid);
      }
    });
    const del: string[] = [];
    membersUuidForDel.forEach((uuid) => {
      if (card?.members.find((item) => item.uuid === uuid)) {
        del.push(uuid);
      }
    });

    return {
      uuid: card?.uuid || '',
      fields: { members: { add, delete: del } },
    };
  },
  target: createEffect(async (d: CardUpdateMembers) => {
    await updateMembersFX(d);
    await cardGetFX({ uuid: d.uuid });
    resetMembersUuid();
  }),
});

import { sample } from 'effector';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import { contentSetName } from '../../../../../../apiRPC/card/file/content';
import { onSetNameContent } from '../../../../../model/card';

export const contentSetNameFX = errorHandlerFx(createEffectJWT(contentSetName));

sample({
  clock: contentSetNameFX.done,
  fn: ({ params }) => params,
  target: onSetNameContent,
});

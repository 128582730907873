import React, { FC } from 'react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { setSortColumn } from '../../model/Columns';
import { setSortCart } from '../../model/card';
import { ColumnsList } from './ColumnsList';

function onDragEnd(d: DropResult) {
  if (d.reason !== 'DROP') return;
  if (!d?.destination) return;
  const difference = (d?.destination?.index || 0) - d.source.index;
  if (d?.type === 'COLUMN') {
    setSortColumn({ index: d.source.index, difference });
  }
  if (d?.type === 'CARDS') {
    const isChangedColumn = d.source.droppableId !== d.destination.droppableId;

    setSortCart({
      currentIndex: isChangedColumn ? 0 : d.source.index,
      targetIndex: d.destination!.index,
      columnId: d.destination!.droppableId,
      soursColumnId: d.source.droppableId,
      uuid: d.draggableId,
      isChangedColumn,
    });
  }
}
export const DragColumnContext: FC = () => (
  <DragDropContext onDragEnd={onDragEnd}>
    <ColumnsList />
  </DragDropContext>
);

export const DragColumnContextMemo = React.memo(DragColumnContext);

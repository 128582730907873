import React, { FC } from 'react';
import { Fab } from '@mui/material';
import AlertApi from './AlertApi';
import { onSetOpenAlert } from '../../model/currentApi';

export const ButtonApi: FC = () => {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') return null;
  return (
    <>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        sx={{ right: 20, bottom: 40, position: 'fixed' }}
        onClick={() => onSetOpenAlert(true)}
      >
        API
      </Fab>

      <AlertApi />
    </>
  );
};

import {
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useUnit } from 'effector-react';
import ReplayIcon from '@mui/icons-material/Replay';
import { setMenuView } from '../model';
import { TitleItem } from '../TitleItem';
import {
  columnGetListArchivingFx,
  columnListArhFilter$,
  onListArhFilter,
  onStartListening,
  reset,
} from './model';
import { columnUpdateStatusFX } from '../../../model/column';
import { $board } from '../../../model/board';
import Loading from '../../../../UI/Loading';

type PBtnSetActiveStatus = { uuid: string };

const BtnSetActiveStatus: FC<PBtnSetActiveStatus> = ({ uuid }) => {
  const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    columnUpdateStatusFX({
      uuid,
      fields: { status: 'ACTIVE' },
    }).catch(() => {
      setDisabled(false);
    });
  };
  return (
    <Button
      startIcon={<ReplayIcon />}
      size="small"
      sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
      onClick={onClick}
      disabled={disabled}
    >
      Вернуть на доску
    </Button>
  );
};
export const ColumnArchival: FC = () => {
  const columnListArhFilter = useUnit(columnListArhFilter$);
  const pending = useUnit(columnGetListArchivingFx.pending);
  useEffect(() => {
    onStartListening({ filter: {} });
    return reset;
  }, []);

  const [isPendingFilter, startTransition] = useTransition();

  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      onListArhFilter(e.target.value);
    });
  };
  const board = useUnit($board);

  const refTextField = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const input = refTextField.current?.getElementsByTagName('input')?.item(0);
    input?.focus({ preventScroll: true });
    if (input?.value) {
      input.value = '';
    }
  }, [board]);

  return (
    <>
      <TitleItem
        title="Архивные списки"
        onBack={() => setMenuView('Default')}
      />

      <List>
        <ListItem sx={{ justifyContent: 'space-between' }}>
          <TextField
            size="small"
            label="Поиск в архиве"
            variant="outlined"
            sx={{ alignItems: 'center', width: 195 }}
            onChange={onChangeFilter}
            ref={refTextField}
          />
          <Button
            variant="outlined"
            onClick={() => setMenuView('CardArchival')}
          >
            К карточкам
          </Button>
        </ListItem>
      </List>

      <Divider />

      {pending && (
        <Box display="flex" alignSelf="center" padding={1}>
          <Loading />
        </Box>
      )}
      {!pending && (
        <List sx={{ height: 'inherit', overflow: 'auto' }}>
          {columnListArhFilter?.map((item) => (
            <ListItem key={item.uuid} divider sx={{ flexWrap: 'wrap' }}>
              <ListItemText primary={item.title} />
              <BtnSetActiveStatus uuid={item.uuid} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

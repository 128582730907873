import request from '../../request';
import { RContentSetName } from '../file/content';

type Sender = {
  uuid: string;
  email: string;
};
export type CommentCard = {
  uuid: string;
  card: string;
  text: string;
  sender: Sender;
  createdAt: number;
  updatedAt: number;
  deleted: boolean;
  contents: RContentSetName[];
};

export type FieldsAdd = { contents: string[] };
export type PAddComment = {
  card: string;
  text: string;
  fields?: FieldsAdd;
};

export type RAddComment = CommentCard;
export const cardAddComment = request<RAddComment, PAddComment>({
  method: 'card.addComment',
});

export type FieldsUpdate = { text: string; contents?: string[] };
export type PUpdateComment = {
  uuid: string;
  fields: FieldsUpdate;
};

export type RUpdateComment = CommentCard;
export const cardUpdateComment = request<RUpdateComment, PUpdateComment>({
  method: 'card.updateComment',
});

export type PDeleteComment = { uuid: string };
export type RDeleteComment = boolean;
export const cardDeleteComment = request<RDeleteComment, PDeleteComment>({
  method: 'card.deleteComment',
});

export type Filter = { limit: number; offset: number };
export type PGetComments = { card: string; filter?: Filter };
export type RGetComments = { rows: CommentCard[]; total: number };
export const cardGetComments = request<RGetComments, PGetComments>({
  method: 'card.getComments',
});

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { Sign } from '../../pages/sign';
import { SignIn } from '../../pages/sign/In';

import { inPath, signPath, upPath } from '../../addresses/sign';
import NoAccessError from '../../pages/errors/NoAccess';

export const signRouter: RouteObject[] = [
  {
    path: `${signPath}/${upPath}`,
    element: <NoAccessError />,
  },
  {
    path: signPath,
    element: <Sign />,
    children: [
      { index: true, element: <SignIn /> },
      { path: inPath, element: <SignIn /> },
      // { path: upPath, element: <NoAccessError /> },
    ],
  },
];

import { generatePath } from 'react-router-dom';

export const ORDPath = 'ORD';

export const organizationsPath = 'Organizations';

export const organizationPath = 'Organization';
export const uuidSegment = ':uuid';

export const contractsPath = 'Contracts';
export const contractPath = 'Contract';

export const campaignsPath = 'Campaigns';
export const campaignPath = 'Campaign';

export const creativesPath = 'Creatives';
export const creativePath = 'Creative';

export const addressesORD = {
  ORDPath: `/${ORDPath}`,
  organizationsPath: `/${ORDPath}/${organizationsPath}`,
  organizationCreatePath: `/${ORDPath}/${organizationPath}`,
  organizationPath: `/${ORDPath}/${organizationPath}/${uuidSegment}`,
  contractsPath: `/${ORDPath}/${contractsPath}`,
  contractCreatePath: `/${ORDPath}/${contractPath}`,
  contractPath: `/${ORDPath}/${contractPath}/${uuidSegment}`,
  campaignsPath: `/${ORDPath}/${campaignsPath}`,
  campaignCreatePath: `/${ORDPath}/${campaignPath}`,
  campaignPath: `/${ORDPath}/${campaignPath}/${uuidSegment}`,
  creativesPath: `/${ORDPath}/${creativesPath}`,
  creativeCreatePath: `/${ORDPath}/${creativePath}`,
  creativePath: `/${ORDPath}/${creativePath}/${uuidSegment}`,
};

export const generateOrganizationPath = (uuid: string) =>
  generatePath(addressesORD.organizationPath, { uuid });

export const generateContractPath = (uuid: string) =>
  generatePath(addressesORD.contractPath, { uuid });

export const generateCampaignPath = (uuid: string) =>
  generatePath(addressesORD.campaignPath, { uuid });
export const generateCreativePath = (uuid: string) =>
  generatePath(addressesORD.creativePath, { uuid });

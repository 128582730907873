import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { ChipProps } from '@mui/material';
import { mapColors } from '../../pages/desk/Card/Marks/const';
import { ColorsMark } from '../../model/Marks';

interface MarcProps extends ChipProps {
  colorMark: ColorsMark;
}
export const Marc = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'colorMark',
})<MarcProps>(({ colorMark }) => ({
  overflow: 'hidden',
  color: 'white',
  minWidth: 50,
  background: mapColors[colorMark] || mapColors.none,
}));

import React, { FC, useState } from 'react';
import { MenuMarks } from '../MenuMarks';
import { MarksCreate } from '../MarksCreate';
import { MarksDelete } from '../MarksDelete';
import { RMarkGet } from '../../../../../../apiRPC/card/mark';

export interface PropsContainerMarksMenu {
  openMenuMarks: boolean;
  setOpenMenuMarks: (v: boolean) => void;
  anchorEl?: Element | null;
  board: string;
  handleClose?: () => void;
  handleOpen?: () => void;
  listChecked: string[];
  onClickMark: (item: RMarkGet) => void;
  refMenuMarks?: React.RefObject<HTMLDivElement>;
  refMarksCreate?: React.RefObject<HTMLDivElement>;
  refMarksDelete?: React.RefObject<HTMLDivElement>;
}
export const ContainerMarksMenu: FC<PropsContainerMarksMenu> = ({
  openMenuMarks,
  setOpenMenuMarks,
  anchorEl,
  board,
  handleClose,
  handleOpen,
  listChecked,
  onClickMark,
  refMenuMarks,
  refMarksCreate,
  refMarksDelete,
}) => {
  const [openMenuMarksCreate, setOpenMenuMarksCreate] = useState(false);
  const [openMenuMarksDelete, setOpenMenuMarksDelete] = useState(false);

  return (
    <>
      <MenuMarks
        open={openMenuMarks}
        anchorEl={anchorEl}
        onClose={() => {
          setOpenMenuMarks(false);
          handleClose?.();
        }}
        onBack={
          handleOpen
            ? () => {
                handleOpen();
                setOpenMenuMarks(false);
              }
            : undefined
        }
        openMenuMarksCreate={() => {
          setOpenMenuMarks(false);
          setOpenMenuMarksCreate(true);
        }}
        onClickMark={onClickMark}
        listChecked={listChecked}
        ref={refMenuMarks}
        board={board}
      />
      <MarksCreate
        open={openMenuMarksCreate}
        anchorEl={anchorEl}
        onClose={() => {
          setOpenMenuMarksCreate(false);
        }}
        onBack={() => {
          setOpenMenuMarksCreate(false);
          setOpenMenuMarks(true);
        }}
        openMenuDeleted={() => {
          setOpenMenuMarksDelete(true);
          setOpenMenuMarksCreate(false);
        }}
        board={board}
        ref={refMarksCreate}
      />

      <MarksDelete
        open={openMenuMarksDelete}
        anchorEl={anchorEl}
        onClose={() => {
          setOpenMenuMarksDelete(false);
        }}
        onBack={() => {
          setOpenMenuMarksDelete(false);
          setOpenMenuMarksCreate(true);
        }}
        ref={refMarksDelete}
        onDeleted={() => {
          setOpenMenuMarks(true);
          setOpenMenuMarksDelete(false);
        }}
      />
    </>
  );
};

import React, { FC, useEffect, useTransition } from 'react';
import { FiDownload } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import Search from '../../../../components/Search';
import Header from '../../../components/Header';
import { onOrganizationsFilter, onOrganizationsFilterReset } from '../model';

import { getReportFx, resetWorkerForReport } from '../model/report';
import { addressesORD } from '../../../addresses';

export const OrgHeader: FC = () => {
  const [isPendingFilter, startTransition] = useTransition();
  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      onOrganizationsFilter(e.target.value);
    });
  };

  const onDownloadReport = () => {
    getReportFx(undefined);
  };

  const pending = useUnit(getReportFx.pending);
  useEffect(() => resetWorkerForReport, []);
  return (
    <Header>
      <Typography variant="h1">Организации</Typography>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Search
          placeholder="ИНН / Название юр. лица"
          onChange={onChangeFilter}
          onClose={onOrganizationsFilterReset}
        />
        <Tooltip
          title="Скачать"
          sx={{
            marginLeft: '15px',
          }}
        >
          <IconButton
            color="primary"
            onClick={onDownloadReport}
            disabled={pending}
          >
            <FiDownload />
          </IconButton>
        </Tooltip>
        <NavLink to={addressesORD.organizationCreatePath}>
          <Button
            variant="contained"
            sx={{
              marginLeft: '20px',
            }}
          >
            Добавить
          </Button>
        </NavLink>
      </Box>
    </Header>
  );
};

import cn from 'classnames';
import React, { FC, useState } from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import { useUnit } from 'effector-react';
import css from './styles.module.scss';
import { ErirStatus } from '../../../../../../../apiRPC/contracts';
import { contractSendErirFX } from '../../model';

const optionsTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

function getText(isSended: boolean, erirStatus?: ErirStatus): string {
  const MapStyseText: Record<ErirStatus, string> = {
    NONE: 'Не выгружается в ЕРИР',
    PENDING: 'Запланирован экспорт в ЕРИР',
    PENDING_RESOURCE: 'Запланирован экспорт в ЕРИР',
    SUCCESS: 'Экспортировано в ЕРИР',
    ERROR: 'Ошибка',
  };

  if (isSended && erirStatus === 'NONE') {
    return 'Запланирован экспорт в ЕРИР';
  }
  if (erirStatus) {
    return MapStyseText?.[erirStatus] ?? '';
  }
  return '';
}
type Props = {
  erirExportedOn?: number;
  erirStatus?: ErirStatus;
  uuid?: string;
};
export const ErirStatys: FC<Props> = ({ erirExportedOn, erirStatus, uuid }) => {
  const pending = useUnit(contractSendErirFX.pending);
  const [isSended, setIsSended] = useState(false);
  const onSend = async () => {
    if (!uuid) return;
    try {
      await contractSendErirFX({ uuid });
      setIsSended(true);
    } catch (_) {
      setIsSended(false);
    }
  };

  const showErieBtn = erirStatus === 'NONE' && !isSended;
  return (
    <Box className={css.erir}>
      <Box className={css.erir_info}>
        <Box
          className={cn(css.status, {
            _isSuccess: erirStatus === 'SUCCESS',
            _isWarring:
              ['PENDING', 'PENDING_RESOURCE'].includes(erirStatus || '') ||
              isSended,
            _isError: erirStatus === 'ERROR',
          })}
        />
        <span>
          {getText(isSended, erirStatus)}{' '}
          {erirExportedOn &&
            erirStatus === 'SUCCESS' &&
            `  •  ${new Intl.DateTimeFormat('ru', optionsTime).format(
              erirExportedOn * 1000,
            )}`}
        </span>
      </Box>
      {showErieBtn && (
        <Button disabled={pending} variant="text" onClick={onSend}>
          Отправить в ЕРИР
        </Button>
      )}
    </Box>
  );
};

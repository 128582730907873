import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';

export type TOption<Value = string | number | boolean | null> = {
  value: Value;
  label: string | number;
  search?: string;
};

export interface TSelectFieldProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  label: React.ReactNode;
  placeholder?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  error?: boolean;
  helperText?: string;
  required?: boolean;
}

export default function SelectField<
  T extends TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  label,
  placeholder,
  variant,
  error,
  helperText,
  required,
  ...autocompleteProps
}: TSelectFieldProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  return (
    <Autocomplete
      renderOption={(props, option) => (
        <li
          {...props}
          key={typeof option.value === 'string' ? option.value : option.label}
        >
          {option.label}
        </li>
      )}
      selectOnFocus
      handleHomeEndKeys
      noOptionsText="Не найдено"
      onKeyDown={(e) => {
        e.stopPropagation();
        return false;
      }}
      {...autocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          placeholder={placeholder}
          variant={variant}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}

import React, { FC } from 'react';
import List from '@mui/material/List';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import { useUnit } from 'effector-react';
import { MenuItem } from './MenuItem';
import { $open } from './model';
import { addressesGRID } from '../../GRID/addresses';

export const MainMenu: FC = () => {
  const open = useUnit($open);
  return (
    <List>
      <MenuItem
        open={open}
        text="Доски"
        Icon={<ViewKanbanIcon color="primary" />}
        to={addressesGRID.desksPath}
      />
    </List>
  );
};

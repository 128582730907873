import { combine, createEffect, createEvent, createStore } from 'effector';
import { getAccounts, RGetAccounts } from '../../../../apiRPC/board';
import { $account } from '../../../../model/account';
import { $card } from '../index';

export const getAccountsFX = createEffect(getAccounts);
export const $accounts = createStore<RGetAccounts>([]).on(
  getAccountsFX.doneData,
  (_, payload) => payload,
);
export const $accountsSort = combine(
  { account: $account, accounts: $accounts },
  ({ account, accounts }) =>
    [...accounts].sort((a) => (a.email === (account?.email || '') ? -1 : 0)),
);

export const setMembersForCreate = createEvent<string>();
export const delMembersForCreate = createEvent<string>();
export const resetMembersForCreate = createEvent();
export const $membersForCreate = createStore<string[]>([])
  .on(setMembersForCreate, (state, payload) => [...state, payload])
  .on(delMembersForCreate, (state, payload) =>
    state.filter((item) => item !== payload),
  )
  .reset(resetMembersForCreate);

export const $listMembersForCreate = combine(
  $membersForCreate,
  $accounts,
  (membersForCreate, accounts) => {
    if (!accounts) return [];
    const result: RGetAccounts = [];
    membersForCreate.forEach((uuid) => {
      const account = accounts.find((i) => i.uuid === uuid);
      if (account) result.push(account);
    });
    return result;
  },
);

export const $isYouMember = combine(
  { card: $card, account: $account },
  ({ card, account }) => {
    if (!account) return false;
    if (!card) return false;
    return Boolean(
      card.members.find((member) => member.email === account.email),
    );
  },
);

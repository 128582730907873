import { combine, createEvent, createStore, restore, sample } from 'effector';
import {
  columnGetList,
  Filter,
  RColumnGetList,
} from '../../../../apiRPC/column';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { defaultSort } from '../../../../helper/sort';
import { $board } from '../../../model/board';
import { columnUpdateStatusFX } from '../../../model/column';

type PColumnGetListNoStatus = { filter: Omit<Filter, 'status'> };

export const columnGetListArchivingFx = errorHandlerFx(
  createEffectJWT((p: PColumnGetListNoStatus) =>
    columnGetList({ filter: { ...p.filter, status: 'CLOSED' } }),
  ),
);
export const noReloadColumnGetListArchivingFx = errorHandlerFx(
  createEffectJWT((p: PColumnGetListNoStatus) =>
    columnGetList({ filter: { ...p.filter, status: 'CLOSED' } }),
  ),
);

export const onRestColumnListArchiving = createEvent();
export const columnListArchiving$ = createStore<RColumnGetList['rows'] | null>(
  null,
)
  .on(
    [
      columnGetListArchivingFx.doneData,
      noReloadColumnGetListArchivingFx.doneData,
    ],
    (_, payload) => payload.rows,
  )
  .reset(onRestColumnListArchiving);

export const onListArhFilter = createEvent<string>();
export const onListArhFilterReset = createEvent();
export const listArhFilter$ = restore(onListArhFilter, '').reset(
  onListArhFilterReset,
);

export const columnListArhFilter$ = combine(
  [columnListArchiving$, listArhFilter$],
  ([columnListArchiving, listArhFilter]) => {
    if (!listArhFilter) return columnListArchiving?.sort(defaultSort);
    if (!columnListArchiving) return null;

    return columnListArchiving
      .filter((item) =>
        item.title.toUpperCase().includes(listArhFilter.toUpperCase()),
      )
      .sort(defaultSort);
  },
);

export type ColumnGetListArhByBoard = {
  filter: Omit<Filter, 'status' | 'board'>;
};
export const onStartListening = createEvent<ColumnGetListArhByBoard>();
export const onStopListening = createEvent();
export const listeningArhColumnByDesk$ =
  createStore<ColumnGetListArhByBoard | null>(null)
    .on(onStartListening, (_, p) => p)
    .on(onStopListening, () => null);

sample({
  clock: [onStartListening, $board],
  source: { params: listeningArhColumnByDesk$, board: $board },
  filter: ({ params, board }) => params !== null && board !== null,
  fn: ({ params, board }) => ({
    filter: { ...params!.filter, board: board!.uuid },
  }),
  target: columnGetListArchivingFx,
});

sample({
  clock: columnUpdateStatusFX.done,
  source: { params: listeningArhColumnByDesk$, board: $board },
  filter: ({ params, board }) => params !== null && board !== null,
  fn: ({ params, board }) => ({
    filter: { ...params!.filter, board: board!.uuid },
  }),
  target: noReloadColumnGetListArchivingFx,
});

sample({
  clock: $board,
  target: onListArhFilterReset,
});
sample({
  clock: $board,
  target: onRestColumnListArchiving,
});

export function reset() {
  onRestColumnListArchiving();
  onStopListening();
  onListArhFilterReset();
}

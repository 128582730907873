import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUnit } from 'effector-react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  ComputeItemKey,
  FixedFooterContent,
  FixedHeaderContent,
  ItemContent,
  TableComponents,
  TableVirtuoso,
  VirtuosoHandle,
} from 'react-virtuoso';
import { Box } from '@mui/material';
import Item from './Item';
import css from './styles.module.scss';
import { FabSquare } from '../../../../../UI/FabSquare';

import { ReactComponent as StatusSvg } from '../../../../../assets/icons/status.svg';
import { BootstrapTooltip } from '../../../../../components/BootstrapTooltip';

import Loading from '../../../../../UI/Loading';
import { navigateForEffect } from '../../../../../model/routing';
import { generateCreativePath } from '../../../../addresses';
import {
  $creativeList,
  getListCreativeForScrollFX,
  onLoadListsCreative,
} from '../model';
import { CreativeItem, RCreativeGetList } from '../../../../../apiRPC/creative';

const ItemVirtual: ItemContent<CreativeItem, CreativeItem[]> = (index, row) => (
  <Item row={row} key={row.uuid} />
);

const computeItemKey: ComputeItemKey<CreativeItem, CreativeItem[]> = (_, d) =>
  d.uuid;

export type DataRow = {
  status: {
    value: 'success' | 'warning' | 'empty';
    tooltip: string;
  };
  name: string;
  uuid: string;
  date: string;
  customer: string;
  inn?: string;
  executor: string;
  ord: number;
  api: boolean;
};

const Footer: FixedFooterContent = () => {
  const pending = useUnit(getListCreativeForScrollFX.pending);
  if (!pending) return null;
  return (
    <TableRow>
      <TableCell colSpan={8}>
        <Box display="flex" justifyContent="center">
          <Loading />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const components: TableComponents<RCreativeGetList['rows']> = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      stickyHeader
      sx={{ minWidth: 650 }}
      aria-label="simple table"
    />
  ),
  TableHead,
  TableRow: ({ context, ...props }) => {
    const currentRow = context?.at(props['data-index']);
    const onClickRow: MouseEventHandler<HTMLTableRowElement> = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!currentRow?.uuid) return;
      navigateForEffect(generateCreativePath(currentRow.uuid));
    };
    return (
      <TableRow
        sx={{
          cursor: 'pointer',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        {...props}
        onDoubleClick={onClickRow}
      />
    );
  },
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: TableFooter,
};

const fixedHeader: FixedHeaderContent = () => (
  <TableRow>
    <TableCell
      align="center"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0 10px',
      }}
    >
      <BootstrapTooltip title="Статус ЕРИР" placement="top">
        <Box display="flex" alignItems="center" gap="0 10px">
          <Box>
            <StatusSvg />
          </Box>
          <Box>ERID</Box>
        </Box>
      </BootstrapTooltip>
    </TableCell>
    <TableCell>Название</TableCell>
    <TableCell align="left">Кампания</TableCell>
    <TableCell align="left">Тип</TableCell>
    <TableCell align="left">Форма</TableCell>
    <TableCell align="left">ORD</TableCell>
    <TableCell align="left">Статус</TableCell>
    <TableCell align="center" />
  </TableRow>
);
const onMore = () => onLoadListsCreative();
export const BasicTable: FC = () => {
  const creativeList = useUnit($creativeList);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [showBtn, setShowBtn] = useState(false);
  const onTop = () => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <TableVirtuoso
        ref={virtuosoRef}
        data={creativeList || []}
        context={creativeList || []}
        components={components}
        fixedHeaderContent={fixedHeader}
        itemContent={ItemVirtual}
        endReached={onMore}
        computeItemKey={computeItemKey}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop > 300) {
            setShowBtn(true);
            return;
          }
          setShowBtn(false);
        }}
        fixedFooterContent={Footer}
      />
      {showBtn && (
        <FabSquare
          color="primary"
          sx={{
            right: 0,
            bottom: 35,
            position: 'absolute',
            opacity: 0.4,
          }}
          onClick={onTop}
          className={css.tabToTop}
        >
          <ExpandLessIcon />
        </FabSquare>
      )}
      <Box
        sx={{
          marginBottom: '24px',
        }}
      />
    </>
  );
};

import { createEvent, createStore, restore, sample } from 'effector';
import { errorHandlerFx } from '../../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../../model/JWT';
import {
  creativeGet,
  creativeUpdate,
  RCreativeGet,
} from '../../../../../../apiRPC/creative';
import { factoryListFilter } from '../../../../../../model/factories/factoryListFilter';
import {
  campaignGet,
  campaignGetList,
  CampaignItem,
} from '../../../../../../apiRPC/сampaign';
import { resourcesGetCount } from '../../../../../../apiRPC/creative/resources';

export type Tabs = 'Basic' | 'Resources';
export const setTab = createEvent<Tabs>();
export const resetTab = createEvent();
export const $currentTab = restore(setTab, 'Basic').reset(resetTab);

export const creativeUpdateFX = errorHandlerFx(createEffectJWT(creativeUpdate));

export const campaignGetFX = errorHandlerFx(createEffectJWT(campaignGet));

export const creativeGetFX = errorHandlerFx(createEffectJWT(creativeGet));
export const resetCreative = createEvent();
export const setCreative = createEvent<RCreativeGet>();
export const $creative = createStore<RCreativeGet | null>(null)
  .on(creativeGetFX.doneData, (_, payload) => payload)
  .on(setCreative, (_, payload) => payload);

export const {
  getListForScrollFX: getListCampaignForScrollFX,
  onGetList: onGetListCampaign,
  $list: $campaignList,
  searchList: searchCampaign,
  onLoadLists: onLoadListsCampaign,
  resetCatalogs: resetCatalogsCampaign,
} = factoryListFilter({ handler: campaignGetList });

const resetCurrentCampaign = createEvent();
export const $currentCampaign = createStore<CampaignItem | null>(null)
  .on(campaignGetFX.doneData, (_, payload) => payload)
  .reset(resetCurrentCampaign);

sample({
  clock: $creative,
  filter: (payload) => Boolean(payload),
  fn: (payload) => ({ uuid: payload!.campaign }),
  target: campaignGetFX,
});

export const setDisabledBasicForm = createEvent<boolean>();
export const resetDisabledBasicForm = createEvent();
export const $disabledBasicForm = restore(setDisabledBasicForm, true).reset(
  resetDisabledBasicForm,
);

export const setChangedBasicForm = createEvent<boolean>();
export const resetChangedBasicForm = createEvent();
export const $changedBasicForm = restore(setChangedBasicForm, true).reset(
  resetChangedBasicForm,
);

export const onResetBasicForm = createEvent();
export const onSubmitBasicForm = createEvent();

export const resourcesGetCountFX = errorHandlerFx(
  createEffectJWT(resourcesGetCount),
);
export const resetResourcesGetCount = createEvent();
export const $resourcesCount = createStore<number | null>(null)
  .on(resourcesGetCountFX.doneData, (_, payload) => payload)
  .reset(resetResourcesGetCount);

export function resetCreativeModel() {
  resetTab();
  resetCreative();
  resetCatalogsCampaign();
  resetCurrentCampaign();
  resetDisabledBasicForm();
  resetChangedBasicForm();
  resetResourcesGetCount();
}

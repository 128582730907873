import { styled } from '@mui/material/styles';
import { Fab } from '@mui/material';

export const FabSquare = styled(Fab)(() => ({
  width: 40,
  height: 40,
  borderRadius: '10%',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
}));

import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { LoadingButton } from '@mui/lab';
import { useUnit } from 'effector-react';
import ReactRouterPrompt from 'react-router-prompt';
import { addressesORD } from '../../../../../../addresses';
import css from '../../../styles.module.scss';
import Header from '../../../../../../components/Header';
import { $creative } from '../../model';
import {
  $disabledResourcesChanged,
  $disabledResourcesSubmit,
  resetResourcesChanged,
  submitResourcesChanged,
} from '../model/events';
import { $anyPending } from '../model';
import Modal from '../../../../../../../components/Modal';

export const HeaderResourse: FC = () => {
  const theme = useTheme();
  const creative = useUnit($creative);
  const [disabledResourcesChanged, disabledResourcesSubmit] = useUnit([
    $disabledResourcesChanged,
    $disabledResourcesSubmit,
  ]);
  const anyPending = useUnit($anyPending);
  return (
    <Header>
      <Box display="flex">
        <NavLink to={addressesORD.creativesPath}>
          <Tooltip title="Назад">
            <IconButton>
              <FiChevronLeft size={20} />
            </IconButton>
          </Tooltip>
        </NavLink>
        <Box flex={1}>
          <Typography
            className={css.name}
            variant="h1"
            sx={{
              marginLeft: '2px',
            }}
            title={creative?.name}
          >
            Креатив {creative?.name}
          </Typography>
          <Typography
            mt={0.5}
            sx={{
              color: theme.palette.secondary.light,
            }}
          >
            {creative?.uuid}{' '}
            {creative?.externalId ? `• ${creative?.externalId}` : ''}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          variant="outlined"
          disabled={disabledResourcesChanged}
          sx={{
            marginLeft: anyPending ? '36px' : '0px',
          }}
          onClick={() => resetResourcesChanged()}
        >
          Отмена
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          onClick={() => submitResourcesChanged()}
          disabled={disabledResourcesSubmit}
          loading={anyPending}
          sx={{
            marginLeft: '36px',
          }}
        >
          Сохранить
        </LoadingButton>
      </Box>
      <ReactRouterPrompt when={!disabledResourcesChanged}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            open={isActive}
            handleClose={onCancel}
            onConfirm={onConfirm}
            title="Подтвердите переход"
            outlinedText="Остаться на этой странице"
            containedText="Покинуть эту страницу"
          >
            Вы действительно хотите покинуть страницу?
          </Modal>
        )}
      </ReactRouterPrompt>
    </Header>
  );
};

import { Box, Tab, Tabs } from '@mui/material';
import { useUnit } from 'effector-react';
import React from 'react';
import { $settings, setSettings } from '../model';

const TabHeader = () => {
  const settings = useUnit($settings);

  return (
    <Box
      sx={{
        background: '#3F55B5',
        boxShadow: '45px 0px 20px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tabs
        value={settings}
        textColor="secondary"
        indicatorColor="secondary"
        onChange={(_, val) => {
          setSettings(val);
        }}
      >
        <Tab value="Basic" label="Основные настройки" id="Tab_Basic" />
        <Tab
          value="Additional"
          label="Дополнительные настройки"
          id="Tab_Additional"
        />
      </Tabs>
    </Box>
  );
};

export default TabHeader;

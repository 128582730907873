import React, { FC } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { WithMenuHeader } from '../WithMenuHeader';
import { themeOptions } from '../../../theme';
import { themeGrid } from '../../../theme/themeGrid';
import { WorkContainerContent } from '../WorkContainerContent';

const theme = createTheme(themeOptions, themeGrid);
export const ContainerGRID: FC = () => (
  <ThemeProvider theme={theme}>
    <WithMenuHeader>
      <WorkContainerContent />
    </WithMenuHeader>
  </ThemeProvider>
);

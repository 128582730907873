import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { addressesORD } from '../../../../../../addresses';
import Header from '../../../../../../components/Header';
import LoadingGrid from '../LoadingGrid';
import css from '../../../styles.module.scss';

const LoadingUpdate: FC = () => {
  const theme = useTheme();

  return (
    <Box component="form">
      <Header>
        <Box display="flex">
          <NavLink to={addressesORD.contractsPath}>
            <Tooltip title="Назад">
              <IconButton>
                <FiChevronLeft size={20} />
              </IconButton>
            </Tooltip>
          </NavLink>
          <Box flex={1}>
            <Typography
              className={css.name}
              variant="h1"
              sx={{
                marginLeft: '2px',
              }}
            >
              <Skeleton height={28} width={200} />
            </Typography>
            <Typography
              mt={0.5}
              sx={{
                color: theme.palette.secondary.light,
              }}
            >
              <Skeleton height={24} width={400} />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled
            sx={{
              marginLeft: '36px',
            }}
          >
            Отмена
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled
            sx={{
              marginLeft: '36px',
            }}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </Header>
      <Box
        sx={{
          marginBottom: '20px',
          marginTop: '14px',
        }}
      >
        <Skeleton height={52} />
      </Box>
      <Paper
        elevation={0}
        sx={{
          padding: '30px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            marginBottom: '40px',
          }}
        >
          <Typography fontWeight={700}>Параметры</Typography>
          <Skeleton height={76} />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '30px',
          }}
        >
          <LoadingGrid count={14} />
        </Box>
      </Paper>
    </Box>
  );
};

export default LoadingUpdate;

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import { $title } from '../model';
import { useChangeTitle } from './useChangeTitle';

const TitleTextField = styled(TextField)({
  '& .MuiInput-root': {
    color: 'white',
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottomColor: red[100],
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },

  '& .MuiFormHelperText-root.Mui-error': {
    color: red[50],
  },
});

export const InputTitle: FC = () => {
  const title = useUnit($title);

  const {
    showInputTitle,
    formik,
    onPressEnter,
    pendingUpdateBoard,
    isChangeTitle,
    onShowInputTitle,
    onBlur,
  } = useChangeTitle(title);

  if (showInputTitle) {
    return (
      <TitleTextField
        variant="standard"
        sx={{ maxWidth: 600 }}
        autoFocus
        onChange={formik.handleChange}
        value={formik.values.title}
        onBlur={onBlur}
        error={Boolean(formik.errors.title)}
        helperText={formik.errors.title || ' '}
        inputProps={{ maxLength: 255 }}
        id="title"
        onKeyDown={onPressEnter}
        disabled={pendingUpdateBoard}
        fullWidth
      />
    );
  }

  return (
    <Typography
      sx={{ cursor: isChangeTitle ? 'pointer' : 'auto' }}
      variant="h6"
      noWrap
      component="div"
      onClick={onShowInputTitle}
    >
      {title}
    </Typography>
  );
};

import React, { FC, useEffect } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';

import {
  $ListBoardForPage,
  getListBoardForPageFx,
  resetListBoardForPage,
} from '../../model/board';
import { setTitle } from '../../../components/Header/model';
import { $selectWorkspace } from '../../model/workspace';
import { WorkspaceInfo } from './WorkspaceInfo';
import { ContainerGrid } from '../../components/ContainerGrid';
import { addressesGRID } from '../../addresses';

export const Desks: FC = () => {
  const { workspace } = useParams();
  const selectWorkspace = useUnit($selectWorkspace);
  const currentWorkspace = workspace || selectWorkspace;

  const listBoard = useUnit($ListBoardForPage);
  useEffect(() => {
    getListBoardForPageFx({
      filter: { limit: 1000, offset: 0, workspace: currentWorkspace },
    });
    return () => {
      resetListBoardForPage();
    };
  }, [currentWorkspace]);

  useEffect(() => {
    setTitle(' ');
  }, []);

  return (
    <ContainerGrid minWidth="100%">
      <Box width="100%" pt={2.5}>
        <WorkspaceInfo currentWorkspace={currentWorkspace} />
        <Divider sx={{ margin: 1 }} variant="fullWidth" />

        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <Typography variant="h4">Доски</Typography>
        </Stack>

        <Grid container spacing={1}>
          {listBoard?.rows.map((item) => (
            <Grid item xs="auto" key={item.uuid}>
              <Card>
                <CardActionArea
                  component={RouterLink}
                  to={generatePath(addressesGRID.deskPath, { uuid: item.uuid })}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      maxWidth={600}
                      noWrap
                    >
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ContainerGrid>
  );
};

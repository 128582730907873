import { sample } from 'effector';
import { errorHandlerFx } from '../../../../model/errorHandler';
import { createEffectJWT } from '../../../../model/JWT';
import { cardUpdate, RUpdate, UpdateDates } from '../../../../apiRPC/card';
import { updateItem } from '../index';

export type CardUpdateDates = {
  uuid: string;
  fields: { dates: UpdateDates };
};

export const updateDatesFX = errorHandlerFx(
  createEffectJWT<CardUpdateDates, RUpdate, Error>(cardUpdate),
);

sample({
  clock: updateDatesFX.done,
  fn: ({ params, result }) => ({
    uuid: params.uuid,
    dates: result.dates,
  }),
  target: updateItem,
});

import React, { FC } from 'react';
import { Box, ChipProps, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';

import { ColorsMark } from '../../../../../model/Marks';
import { mapColors } from '../const';

const styleBoxGridItem = {
  display: 'grid',
  gridTemplateColumns: 'auto max-content ',
  alignItems: 'center',
  gap: 0.5,
  width: 300,
};

interface MarcProps extends ChipProps {
  colorMark: ColorsMark;
}
const Marc = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'colorMark',
})<MarcProps>(({ theme, colorMark }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '10px',
  overflow: 'hidden',
  color: 'white',
  background: mapColors[colorMark] || mapColors.none,
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '&:hover': {
    transform: 'translate(-4px)',
  },
  span: {
    order: -1,
  },
  '.MuiChip-icon': {
    color: 'white',
  },
}));

type Props = {
  color: ColorsMark;
  name: string;
  onUpdate: () => void;
  onClickMark: () => void;
  isChecked: boolean;
};
export const MarksItem: FC<Props> = ({
  color,
  name,
  onUpdate,
  onClickMark,
  isChecked,
}) => (
  <Box paddingX={2} sx={styleBoxGridItem}>
    <Marc
      colorMark={color}
      label={name}
      size="small"
      icon={isChecked ? <DoneIcon /> : undefined}
      onClick={onClickMark}
      clickable={false}
    />
    <IconButton aria-label="back" size="small" onClick={onUpdate}>
      <ModeEditIcon fontSize="small" />
    </IconButton>
  </Box>
);

import React, { FC, useTransition } from 'react';
import { useUnit } from 'effector-react';
import { Autocomplete } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { $markList } from '../../../GRID/model/Marks';
import {
  $listExcludeMarks,
  onSetExcludeMarks,
  resetListExcludeMarks,
} from './model';

import { Marc } from '../../../GRID/components/Marck';

export const ExcludeMarks: FC = () => {
  const markList = useUnit($markList);
  const listExcludeMarks = useUnit($listExcludeMarks);
  const [isPendingListMarks, startTransitionListMarks] = useTransition();

  const isAllChecked = listExcludeMarks.length === markList?.length;
  const indeterminate = Boolean(listExcludeMarks.length) && !isAllChecked;
  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: 'normal' }}>
          Исключить метки
        </ListSubheader>
      }
      disablePadding
    >
      <ListItem role={undefined} dense sx={{ py: 0, height: 32, mt: 2 }}>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={isAllChecked}
          indeterminate={indeterminate}
          onChange={() => {
            if (isPendingListMarks) return;
            if (isAllChecked || indeterminate) {
              startTransitionListMarks(resetListExcludeMarks);
              return;
            }
            startTransitionListMarks(() => {
              if (markList) onSetExcludeMarks(markList);
            });
          }}
        />

        <FormControl fullWidth>
          <Autocomplete
            options={markList || []}
            getOptionLabel={(v) => v.title}
            multiple
            size="small"
            disableCloseOnSelect
            fullWidth
            onChange={(_, value) => {
              if (isPendingListMarks) return;
              startTransitionListMarks(() => {
                onSetExcludeMarks(value);
              });
            }}
            value={listExcludeMarks}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            renderOption={(props, option, { selected }) => (
              <MenuItem
                {...props}
                key={option.uuid}
                value={option.uuid}
                sx={{
                  pl: '0px !important',
                  py: 0,
                  height: 32,
                  width: 'fit-content',
                  overflow: 'auto',
                  minWidth: '100%',
                }}
              >
                <Checkbox checked={selected} />
                <Marc
                  label={option.title}
                  colorMark={option.color}
                  size="small"
                  sx={{ cursor: 'pointer' }}
                />
              </MenuItem>
            )}
            renderTags={(value) => (
              <Typography variant="body2">Выбрано {value.length}</Typography>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Выбор меток" />
            )}
            noOptionsText="Не найдено"
          />
        </FormControl>
      </ListItem>
    </List>
  );
};

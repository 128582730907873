import { Box } from '@mui/system';
import React, { FC } from 'react';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import cn from 'classnames';
import css from './styles.module.scss';

type TInfoProps = {
  text: string;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  error?: boolean;
  customIcon?: React.ReactNode;
  noCloseBtn?: boolean;
};

export const Info: FC<TInfoProps> = ({
  text,
  setOpenInfo,
  error,
  customIcon,
  noCloseBtn,
}) => (
  <Box
    className={cn(css.info, {
      _isError: error,
    })}
  >
    <Box display="flex" alignItems="center" gap="0 20px">
      {customIcon || (
        <FiAlertCircle size={22} color={error ? '#D32F2F' : '#0288D1'} />
      )}
      <span>{text}</span>
    </Box>
    {!error && noCloseBtn && (
      <FiX
        className={css.close}
        size={22}
        color="#0288D1"
        onClick={() => setOpenInfo?.(false)}
      />
    )}
  </Box>
);

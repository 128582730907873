import * as React from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { hasJWTInfo } from '../../../model/JWT/methods';
import { navigateForEffect } from '../../../model/routing';
import { useIsRightMenu } from '../../../helper/hooks/useIsrightMenu';
import { RightMen } from '../../../GRID/components/RightMenu';
import '../../../styles.module.scss';
import { addressesSign } from '../../../addresses/sign';

export function WorkContainerContent() {
  const matchers = useMatch(`${addressesSign.signPath}/*`);
  const [isStopRender, setStopRender] = useState(true);

  useEffect(() => {
    if (!hasJWTInfo() && matchers === null) {
      navigateForEffect(addressesSign.signPath, { replace: true });
      setStopRender(true);
    } else {
      setStopRender(false);
    }
  }, [matchers]);

  const isRightMenu = useIsRightMenu();
  if (isStopRender) {
    return null;
  }
  return (
    <>
      <Outlet />
      {isRightMenu && <RightMen />}
    </>
  );
}

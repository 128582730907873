import React from 'react';
import { Avatar } from '@mui/material';
import { stringToColor } from '../../helper/stringAvatar';

export const AvatarMenu = React.forwardRef<
  HTMLDivElement,
  { children: string }
>(({ children, ...props }, ref) => {
  const [one, two] = children.split(' ');
  return (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        fontSize: 'small',
        background: stringToColor(children),
      }}
      ref={ref}
      {...props}
    >
      {`${one?.[0] || ''}${two?.[0] || ''}`}
    </Avatar>
  );
});

import React, { FC, useState } from 'react';
import {
  Autocomplete,
  createTheme,
  FormControl,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  $cardsList,
  onLoadCards,
  onResetCardsList,
  searchCards,
} from './model';

import { themeGrid } from '../../../../theme/themeGrid';
import { makeSlug } from '../../../../helper/makeSlug';
import { navigateForEffect } from '../../../../model/routing';

const FormSelect = styled(FormControl)({
  minWidth: 220,
  flexGrow: 1,
  marginRight: 10,
  '& .MuiFormLabel-root': {
    color: 'white',
    '&.Mui-focused': { color: grey[200] },
  },

  '& .MuiInputBase-root': {
    color: 'white',
    '&:before': {
      borderColor: 'white !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: grey[200],
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },

  '& .MuiSvgIcon-root': { color: 'white' },
});
const theme = createTheme(themeGrid);

const PaperComponent: FC<React.HTMLAttributes<HTMLElement>> = (props) => (
  <ThemeProvider theme={theme}>
    <Paper {...props} />
  </ThemeProvider>
);

const EmptyPaperComponent: FC<React.HTMLAttributes<HTMLElement>> = (props) => (
  <ThemeProvider theme={theme}>
    <Paper>
      <ListItem alignItems="center">
        <ListItemText sx={{ textAlign: 'center' }}>
          Ни чего не найденно
        </ListItemText>
      </ListItem>
    </Paper>
  </ThemeProvider>
);
const minWidth = '150px';
const maxWidth = '500px';
export const GlobalSearch: FC = () => {
  const [open, setOpen] = useState(false);

  const cardsList = useUnit($cardsList);

  return (
    <FormSelect>
      <Autocomplete
        options={cardsList || []}
        onBlur={() => {
          setOpen(false);
          onResetCardsList();
        }}
        sx={{ width: open ? maxWidth : minWidth, ml: 'auto' }}
        ListboxProps={{
          role: 'list-box',
          onScroll: ({ currentTarget }) => {
            const scrollPosition =
              currentTarget.scrollTop + currentTarget.clientHeight;
            if (currentTarget.scrollHeight - scrollPosition <= 1) {
              onLoadCards();
            }
          },
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option;
          return `${option?.boardTitle} • ${option?.columnTitle} • ${option?.title}`;
        }}
        PaperComponent={
          cardsList?.length === 0 ? EmptyPaperComponent : PaperComponent
        }
        renderOption={(props, option) => (
          <MenuItem
            key={`${option.board}_${option.column}_${option.uuid}`}
            component="a"
            href={`/c/${option.uuid}/${makeSlug(option.title)}`}
            onClick={(e) => {
              navigateForEffect(`/c/${option.uuid}/${makeSlug(option.title)}`);
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            <span>
              {option.title} <br />
              <Typography variant="body2" color="grey" fontSize="small">
                {option.boardTitle} • {option.columnTitle}
              </Typography>
            </span>
          </MenuItem>
        )}
        filterOptions={(v) => v}
        freeSolo
        clearOnBlur
        onInputChange={(event, value, reason) => {
          if (reason === 'clear') onResetCardsList();
        }}
        renderInput={(params) => {
          const p = {
            ...params,
            inputProps: {
              ...params.inputProps,
              maxLength: 80,
              tabIndex: -1,
            },
          };
          return (
            <TextField
              {...p}
              label="Поиск"
              placeholder="Поиск"
              size="small"
              autoFocus={false}
              onChange={(e) => {
                searchCards(e.target.value);
                setOpen(true);
              }}
              onClick={() => {
                setOpen(true);
              }}
            />
          );
        }}
      />
    </FormSelect>
  );
};

import { createEvent, createStore } from 'effector';
import { errorHandlerFx } from '../../../../../model/errorHandler';
import { createEffectJWT } from '../../../../../model/JWT';
import {
  organizationGet,
  organizationUpdate,
  ROrganizationGet,
} from '../../../../../apiRPC/organization';

export const organizationGetFX = errorHandlerFx(
  createEffectJWT(organizationGet),
);
export const restOrganization = createEvent();
export const $organization = createStore<ROrganizationGet | null>(null)
  .on(organizationGetFX.doneData, (_, payload) => payload)
  .reset(restOrganization);

export const organizationUpdateFX = errorHandlerFx(
  createEffectJWT(organizationUpdate),
  [99],
);

import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { green, grey, red } from '@mui/material/colors';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useUnit } from 'effector-react';
import { $dates, onOpenMenuDate } from '../DateMenu/model';
import { updateDatesFX } from '../../../../../model/card/update/dates';

type Statuses = 'default' | 'success' | 'overdue';
type PropsStatus = {
  status: Statuses;
};

const statusColor: Record<Statuses, string> = {
  default: 'white',
  success: green[500],
  overdue: red[500],
};
const statusText: Record<Statuses, string> = {
  default: '',
  success: 'выполнено',
  overdue: 'просрочено',
};

const Status: FC<PropsStatus> = ({ status }) => {
  if (status === 'default') return null;

  return (
    <Box
      sx={{ background: statusColor[status] }}
      borderRadius={1}
      paddingX={1}
      marginLeft={1}
    >
      <Typography color="white" fontSize="small">
        {statusText[status]}
      </Typography>
    </Box>
  );
};

function generateTextDates(
  dateStart: Date | null,
  dateEnd: Date | null,
): string {
  const dateStartText = dateStart
    ? format(dateStart, 'PP', { locale: ru })
    : '';
  const dateEndText = dateEnd
    ? `${format(dateEnd, 'PP', { locale: ru })} в ${format(dateEnd, 'HH:mm')}`
    : '';

  if (dateStartText && dateEndText) {
    return `${dateStartText} - ${dateEndText}`;
  }

  return dateStartText || dateEndText;
}

type Props = {
  uuid: string;
};
export const Dates: FC<Props> = ({ uuid }) => {
  const dates = useUnit($dates);

  const anchorMenuDate = useRef<HTMLButtonElement>(null);
  const openMenuDate = () => {
    if (!anchorMenuDate.current) return;
    onOpenMenuDate({
      anchorEl: anchorMenuDate.current,
      cardUid: uuid,
    });
  };

  const pending = useUnit(updateDatesFX.pending);

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (pending) return;
    const val = e.target.checked;
    updateDatesFX({
      uuid,
      fields: {
        dates: {
          completed: val,
        },
      },
    });
  };

  const dateStart = dates?.dateStart ? new Date(dates.dateStart * 1000) : null;
  const dateEnd = dates?.dateEnd ? new Date(dates.dateEnd * 1000) : null;

  const [status, setStatus] = useState<Statuses>('default');
  const [dateText, setDateText] = useState('');

  useEffect(() => {
    function setStatusText() {
      const statusVal: Statuses = dates?.completed
        ? 'success'
        : dateEnd && Date.now() > +dateEnd
        ? 'overdue'
        : 'default';
      const dateTextVal = generateTextDates(dateStart, dateEnd);
      setStatus(statusVal);
      setDateText(dateTextVal);
    }
    setStatusText();
    const id = setInterval(setStatusText, 5000);
    return () => {
      clearInterval(id);
    };
  }, [dates?.dateStart, dates?.dateEnd, dates?.completed]);

  return (
    <Box>
      <Typography color="grey" fontSize="small" fontWeight={600}>
        Даты
      </Typography>

      <Stack direction="row" spacing={1} alignItems="center">
        <Checkbox size="small" checked={dates?.completed} onChange={onCheck} />

        <Button
          color="inherit"
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          size="small"
          onClick={openMenuDate}
          ref={anchorMenuDate}
          sx={{
            fontSize: 12,
            textTransform: 'inherit',
            borderColor: grey[500],
            height: 'max-content',
          }}
        >
          {dateText}
          <Status status={status} />
        </Button>
      </Stack>
    </Box>
  );
};

import request from '../request';

export type Types = 'cpa' | 'cpc' | 'cpm' | 'other';
export type Variant = 0 | 1 | 2 | 3;
export type InvoiceParam = {
  uuid: string;
  month: number;
  type: Types;
  numberOfUnitsPlan: number;
  numberOfUnits: number;
  amountPerUnit: number;
  totalAmount: number;
  totalExportAmount: number;
  totalActualShows: number;
  totalPlannedShows: number;
  variant: Variant;
  campaign: string;
};

export type InvoiceParamsCreatItem = Partial<
  Omit<InvoiceParam, 'uuid' | 'campaign'>
> &
  Pick<InvoiceParam, 'month' | 'type'>;

export type PCInvoiceParamsCreat = {
  campaign: string;
  items: InvoiceParamsCreatItem[];
};
export type RCInvoiceParamsCreat = InvoiceParam[];
export const campaignInvoiceParamsCreate = request<
  RCInvoiceParamsCreat,
  PCInvoiceParamsCreat
>({
  method: 'campaignInvoiceParams.create',
});

export type InvoiceParamsUpdateItem = Partial<
  Omit<InvoiceParam, 'uuid' | 'campaign' | 'variant'>
> &
  Pick<InvoiceParam, 'uuid'>;

export type PCInvoiceParamsUpdate = {
  campaign: string;
  items: InvoiceParamsUpdateItem[];
};
export type RCInvoiceParamsUpdate = InvoiceParam[];
export const campaignInvoiceParamsUpdate = request<
  RCInvoiceParamsUpdate,
  PCInvoiceParamsUpdate
>({
  method: 'campaignInvoiceParams.update',
});

export type RCInvoiceParamsGetList = {
  total: number;
  rows: InvoiceParam[];
};
export type PCInvoiceParamsGetList = { campaign: string };
export const campaignInvoiceParamsGetList = request<
  RCInvoiceParamsGetList,
  PCInvoiceParamsGetList
>({
  method: 'campaignInvoiceParams.getList',
});

export type RCInvoiceParamsDelete = null;
export type PCInvoiceParamsDelete = { campaign: string; items: string[] };
export const campaignInvoiceParamsDelete = request<
  RCInvoiceParamsDelete,
  PCInvoiceParamsDelete
>({
  method: 'campaignInvoiceParams.delete',
});

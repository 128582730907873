import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { FiTrash } from 'react-icons/fi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIMask } from 'react-imask';
import { useParams } from 'react-router-dom';

import { InputMask } from 'imask';
import { useUnit } from 'effector-react';
import { BootstrapTooltip } from '../../../../../../components/BootstrapTooltip';
import CalendarField from '../../../../../../components/CalendarField';
import CampaignAccordion from '../../../../../../components/CampaignAccordion';
import {
  campaignBudgetBatchManageFX,
  campaignBudgetDeleteFX,
  delChangedListBudget,
  delErrorListBudgetUpdate,
  onSubmitUpdateBudgetForm,
  pushListBatchUpdate,
  resetFormUpdate,
  setChangedListBudget,
  setErrorListBudgetUpdate,
} from '../model/budget';
import { CampaignBudgetFields } from '../../../../../../apiRPC/сampaign/campaignBudget';
import { getOptsMask } from './mask';

import { formatStringToInt } from '../../../../../../helper/methods/formatStringToint';

type Form = {
  month: null | Date;
  value: string;
  valueSC: string;
  rateSC: string;
};

function formIsChanged(formVal: Form, date: CampaignBudgetFields): boolean {
  let res = false;
  type CheckedObj = {
    month: number;
    value: number;
    valueSC: number;
    rateSC: number;
  };
  const checkedObj: CheckedObj = {
    month: formVal.month ? +formVal.month / 1000 : 0,
    value: formatStringToInt(formVal.value || ''),
    valueSC: formatStringToInt(formVal.valueSC || ''),
    rateSC: formatStringToInt(formVal.rateSC || ''),
  };
  const keys = Object.keys(checkedObj);
  for (const key of keys) {
    if (
      checkedObj[key as keyof CheckedObj] !==
      date[key as keyof CampaignBudgetFields]
    ) {
      res = true;
      break;
    }
  }

  return res;
}

type Opts = Parameters<typeof useIMask>['0'];
type SetDataParams = {
  data: CampaignBudgetFields;
  setRateSCMask: Dispatch<InputMask<Opts>['typedValue']>;
  setValueRateSCMask: Dispatch<InputMask<Opts>['value']>;

  setValueMask: Dispatch<InputMask<Opts>['typedValue']>;
  setValueValueMask: Dispatch<InputMask<Opts>['value']>;

  setValueSCMask: Dispatch<InputMask<Opts>['typedValue']>;
  setValueValueSCMask: Dispatch<InputMask<Opts>['value']>;

  formik: ReturnType<typeof useFormik<Form>>;
};

function setData({
  data,
  setRateSCMask,
  setValueRateSCMask,
  setValueMask,
  setValueValueMask,
  setValueSCMask,
  setValueValueSCMask,
  formik,
}: SetDataParams) {
  if (data.valueSC === 0) {
    setValueValueSCMask('0');
  } else setValueSCMask(data.valueSC);

  if (data.value === 0) {
    setValueValueMask('0');
  } else setValueMask(data.value);

  if (data.rateSC === 0) {
    setValueRateSCMask('0');
  } else setRateSCMask(data.rateSC);

  formik.setFieldValue('month', new Date(data.month * 1000));
  setTimeout(() => {
    formik.validateForm();
  }, 100);
}

type Props = {
  data: CampaignBudgetFields;
};

export const UpdateBudget: FC<Props> = ({ data }) => {
  const { uuid: campaign } = useParams();
  const [disabledForm, setDisabledForm] = useState(false);
  const pendingUpdate = useUnit(campaignBudgetBatchManageFX.pending);
  const formik = useFormik<Form>({
    initialValues: { month: null, value: '', valueSC: '', rateSC: '' },
    validationSchema: Yup.object({
      month: Yup.date()
        .typeError('Некорректное значение')
        .nullable()
        .required('Обязательное для заполнения поле'),
      value: Yup.string().required('Обязательное для заполнения поле'),
      valueSC: Yup.string().required('Обязательное для заполнения поле'),
      rateSC: Yup.string(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      const formChanged = formIsChanged(values, data);
      if (!formChanged) return;
      pushListBatchUpdate({
        uuid: data.uuid,
        value: formatStringToInt(values.value),
        valueSC: formatStringToInt(values.valueSC),
      });
    },
  });

  const {
    ref: refValueSCMask,
    value: valValueSCMask,
    setTypedValue: setValueSCMask,
    setValue: setValueValueSCMask,
  } = useIMask(...getOptsMask('valueSC', formik));

  const {
    ref: refValueMask,
    value: valValueMask,
    setTypedValue: setValueMask,
    setValue: setValueValueMask,
  } = useIMask(...getOptsMask('value', formik));

  const {
    ref: refRateSCMask,
    value: valRateSCMask,
    setTypedValue: setRateSCMask,
    setValue: setValueRateSCMask,
  } = useIMask(...getOptsMask('rateSC', formik, { min: -9999999 }));

  useEffect(() => {
    setData({
      data,
      setRateSCMask,
      setValueRateSCMask,
      setValueMask,
      setValueValueMask,
      setValueSCMask,
      setValueValueSCMask,
      formik,
    });
  }, [data]);

  useEffect(() => {
    const stop = resetFormUpdate.watch(() => {
      setData({
        data,
        setRateSCMask,
        setValueRateSCMask,
        setValueMask,
        setValueValueMask,
        setValueSCMask,
        setValueValueSCMask,
        formik,
      });
    });
    return stop;
  }, [data]);

  const onDelete: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    if (!campaign) return;
    setDisabledForm(true);
    try {
      await campaignBudgetDeleteFX({ campaign, items: [data.uuid] });
    } finally {
      setDisabledForm(false);
    }
  };

  useEffect(() => {
    const formChanged = formIsChanged(formik.values, data);
    if (formChanged) {
      setChangedListBudget(data.uuid);
    }
    if (!formChanged) {
      delChangedListBudget(data.uuid);
    }

    if (!formik.isValid) {
      setErrorListBudgetUpdate(data.uuid);
    }
    if (formik.isValid) {
      delErrorListBudgetUpdate(data.uuid);
    }
  }, [formik, data]);

  useEffect(() => {
    if (!pendingUpdate) {
      setDisabledForm(false);
      return;
    }
    const formChanged = formIsChanged(formik.values, data);
    if (formChanged && pendingUpdate) {
      setDisabledForm(true);
    }
  }, [pendingUpdate]);
  useEffect(() => {
    const stop = onSubmitUpdateBudgetForm.watch(() => {
      formik.submitForm();
    });
    return stop;
  }, [formik]);

  return (
    <CampaignAccordion
      title={new Intl.DateTimeFormat('ru', {
        month: 'long',
        year: 'numeric',
      }).format(new Date(data.month * 1000))}
      icon={
        <BootstrapTooltip title="Удалить" placement="top">
          <IconButton onClick={onDelete} disabled={disabledForm}>
            <FiTrash color={disabledForm ? '#A6A6A6' : '#D32F2F'} size={20} />
          </IconButton>
        </BootstrapTooltip>
      }
      sx={{
        '.MuiAccordionSummary-content': { margin: 2.5, marginLeft: 0 },
        '.MuiButtonBase-root.MuiAccordionSummary-root': { padding: '0 20px' },
      }}
    >
      <Box
        sx={{
          padding: '12px 20px 30px 20px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: '30px',
          }}
        >
          <CalendarField
            disabled
            label="Месяц"
            views={['month', 'year']}
            inputFormat="MM.yyyy"
            placeholder="ММ.ГГГГ"
            disableMaskedInput={false}
            value={formik.values.month}
            onChange={(v) => {
              formik.setFieldValue('month', v).then(() => {
                formik.validateField('month');
              });
            }}
            error={formik.errors.month}
            helperText={formik.errors.month}
            onClear={() =>
              formik.setFieldValue('month', null).then(() => {
                formik.validateField('month');
              })
            }
          />

          <TextField
            required
            disabled={disabledForm}
            label="До НДС"
            inputRef={refValueMask}
            variant="standard"
            value={valValueMask}
            name="value"
            autoComplete="none"
            error={Boolean(formik.errors.value)}
            helperText={formik.errors.value}
          />

          <TextField
            required
            disabled={disabledForm}
            label="До НДС после СК"
            inputRef={refValueSCMask}
            variant="standard"
            value={valValueSCMask}
            name="valueSC"
            autoComplete="none"
            error={Boolean(formik.errors.valueSC)}
            helperText={formik.errors.valueSC}
          />

          <TextField
            label="Размер СК, %"
            variant="standard"
            autoComplete="none"
            aria-readonly
            disabled
            name="rateSC"
            inputRef={refRateSCMask}
            value={valRateSCMask}
          />
        </Box>
      </Box>
    </CampaignAccordion>
  );
};
